import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {createActions} from '../../../baseClasses/ActionFactory';
import {createFileActions} from '../../../baseClasses/FileActionFactory';
import NoteService from '../../../services/NoteService';
import {entityType as entityTypes} from "../../../const";
import AssignmentService from "../../../services/AssignmentService";

const DELETE_NOTE = 'DELETE_NOTE__NOTES';
const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS__NOTES';
const LOAD_DISEASES = 'LOAD_DISEASES';
const LOAD_PESTS = 'LOAD_PESTS';
const LOAD_WEEDS = 'LOAD_WEEDS';

function onFetch({entityId, entityType}) {

  if (entityType === entityTypes.ASSIGNMENT) {
    const assignment = AssignmentService.getItem(entityId);
    if (isEmpty(get(assignment, 'comments', []))) {
      return {pending: false};
    }
    const comments = Array.isArray(assignment.comments) ? assignment.comments : [];
    // const notePoints = Array.isArray(assignment.points) ? assignment.points.flatMap(point => point.noteId) : [];
    NoteService.fetchId(entityId, [...comments] || []);
  } else if (entityType === entityTypes.FIELD) {
    NoteService.fetch(entityId, 'Agrosignal.Field');
  } else {
    NoteService.fetch(entityId);
  }

  return {pending: true};
}

function deleteNote({entityId, note}) {
  return async dispatch => {
    NoteService.deleteNote({entityId, note}).then(() => {
      dispatch({
        type: DELETE_NOTE,
      });
    });
  };
}

function deleteNoteSuccess() {
  return (dispatch) => {
    dispatch({
      type: DELETE_NOTE_SUCCESS,
    });
  };
}

const loadDiseases = (diseases) => {
  return (dispatch) => {
    dispatch({
      type: LOAD_DISEASES,
      payload: diseases,
    })
  }
}
const loadPests = (pests) => {
  return (dispatch) => {
    dispatch({
      type: LOAD_PESTS,
      payload: pests,
    })
  }
}
const loadWeeds = (weeds) => {
  return (dispatch) => {
    dispatch({
      type: LOAD_WEEDS,
      payload: weeds,
    })
  }
}

const {types: generalTypes, actions: generalActions} = createActions({store: 'NOTES', onFetch});
const {types: fileTypes, actions: fileActions} = createFileActions({store: 'NOTES'});
const allTypes = {...generalTypes, ...fileTypes, DELETE_NOTE, DELETE_NOTE_SUCCESS, LOAD_DISEASES, LOAD_PESTS, LOAD_WEEDS};
const allActions = {...generalActions, ...fileActions, deleteNote, deleteNoteSuccess, loadDiseases, loadPests, loadWeeds};

export {allTypes as types, allActions as actions};
