import {createActions} from "../../../baseClasses/ActionFactory";
import {createTreeActions} from '../../../baseClasses/TreeActionFactory';

const types = {
  SET_SENSORS: 'SET_SENSORS',
}

const actions = {
  setSensors: (sensors) => (dispatch) => dispatch({type: types.SET_SENSORS, payload: {sensors}})
}

const {types: generalTypes, actions: generalActions} = createActions({store: 'METEOSTATIONS'});
const {types: treeTypes, actions: treeActions} = createTreeActions({store: 'METEOSTATIONS'});
const allActions = {...generalActions, ...treeActions, ...actions};
const allTypes = {...generalTypes, ...treeTypes, ...types};
export {allTypes as types, allActions as actions};
