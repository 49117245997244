import Tree from "../treejs/Tree";
import AuthService from "./AuthService";
import moment from "moment";
import DepartmentService from "./DepartmentService";
import isEqual from "lodash/isEqual";
import store from "../store/store";
import {actions} from "../components/Main/TractorRecord/TractorRecordActions";
import {localStorageItems, LocalstorageService} from "./LocalstorageService";
import {url} from "../api";
import reportType from "../const/reportType";
import httpClient from "@infobis/api-module";
import {Notification} from "../components/common/Notification";
import get from "lodash/get";
import {getInitialPeriod} from "../helpers/initialPeriod";
import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";
import {parseTree} from "../helpers/parseTree";
import {expandTree} from "../helpers/expandTree";
import isObject from "lodash/isObject";
import {filterTreeKey} from "../helpers/treeFilterKey";
import {harvestUploadObjId} from "../helpers/harvesterUploadObjId";
import {getRandomId} from './../utils/generateNumber'


class TractorRecordService {

  constructor() {
    this.reset();
    window.TractorRecordService = this;
  }

  reset() {
    this.data = Tree.empty();
    this.prepareDataRow = []
    this.rawData = [];
    this.dates = {
      from: null,
      to: null,
    };
  }

  async fetch({from, to}) {
    const user = AuthService.getUser();
    const now = moment();
    const {companyId} = DepartmentService.getCurrentDepartmentForRequest();
    const datesBefore = {from: this.dates.from, to: this.dates.to};

    if (isEqual({from, to}, this.dates)) {
      const t = setTimeout(() => {
        store.dispatch(actions.fetchSuccess());
        clearTimeout(t);
      }, 0);
      return;
    }

    this.savePeriod({from, to});

    const request = {
      url: url.reportData,
      params: {
        method: 'POST',
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
      authorize: true,
      qs: {
        reportId: reportType.TRACTOR_RECORD,
      },
      body: JSON.stringify({
        activeChecks: "",
        companyId,
        entityId: [],
        forLeafs: null,
        filters: {
          fieldsFilter: [],
          unitsFilter: [],
          driversFilter: [],
        },
        from,
        to,
        isDay: false,
        limit: 25,
        page: 1,
        reportId: reportType.TRACTOR_RECORD,
        start: 0,
        strEntityId: [],
        tzOffset: -now.utcOffset(),
        // unitsFilter: []
      }),
    };
    //=========================>

    this.dates = {from, to};
    store.dispatch(actions.fetchPending());


    try {
      const response = await httpClient.makeRequest(request);
      if (response && response.success) {
        this.onFetchSuccess(response);
        store.dispatch(actions.fetchSuccess());
      } else {
        this.dates = datesBefore;
        store.dispatch(actions.fetchError());
      }
    } catch (error) {
      store.dispatch(actions.fetchError());
      Notification.error(error);
    }
  }


  onFetchSuccess(props) {

    let {
      data
    } = props

    this.rawData = data.map((elem) => {
      let {
        fieldTool,
        sourceName,
        shiftStart: start,
        date: end,
        driver,
        geoZone,
        operation,
        area: areaByDistance,
        areaCultivated: factArea,
        crop,
        duration: timeOperation,
        idleLength: downtimeOperation,
        fuelC: gsm,
      } = elem

      return {
        fieldTool,
        sourceName,
        start,
        end,
        driver,
        geoZone,
        operation,
        areaByDistance,
        factArea,
        crop,
        timeOperation,
        downtimeOperation,
        gsm
      }
    })
    this.rawData.forEach(x => this._mapUpload(x));
    this.buildTree(this.rawData)
  }

  buildTree(data) {
    const getGlid = n => n.data.id;
    let groupes = data.map(d => (
          {
            id: d.id,
            driver: d.driver,
            geoZone: d.geoZone,
            operation: d.operation,
            start: d.start,
            end: d.end,
            sourceName: d.sourceName
          }
        )
    )

    groupes = orderBy(groupes, x => x.sourceName.toLowerCase(), ['asc']);
    groupes.forEach(x => {
      x.data = orderBy(data.filter(d => d.id === x.id), ['start'], ['desc']);
    });


    this.prepareDataRow = groupes
    this.data = parseTree(groupes, getGlid);
    this.data.lastUpdate = Date.now();
    expandTree(this.data, 0);
  }

  getTree() {
    return this.data;
  }

  getPrepareTree() {
    return this.prepareDataRow
  }

  getItem(id) {
    return this.rawData.find(x => x.id === id) || {};
  }

  getGroupName(id) {
    return this.data && get(this.data.nodes().find(n => isObject(n.data) && Number(n.data.id) === Number(id)), 'data.name', '');
  }

  getFilteredTree({operation}) {
    return filterTreeKey({tree: this.data, query: {operation}});
  }


  savePeriod({from, to}) {
    if (from && to) {
      const cache = LocalstorageService.getCacheForUser({from, to});
      localStorage.setItem(localStorageItems.tractorRecordPeriod, JSON.stringify(cache));
    }
  }

  loadPeriod() {
    if (LocalstorageService.isCacheForSameUser(localStorageItems.tractorRecordPeriod)) {
      return get(JSON.parse(localStorage.getItem(localStorageItems.tractorRecordPeriod)), 'data');
    } else {
      localStorage.removeItem(localStorageItems.tractorRecordPeriod);
      return getInitialPeriod();
    }
  }

  _mapUpload(src) {
    src.id = getRandomId()
    src.group = src.driver
    src.sourceVehicle = src.sourceName
    // src.group = get(/^(.*) - /ig.exec(src.driver), '[1]', '').trim();
    // src.sourceVehicle = get(/- (.*)$/ig.exec(src.sourceName), '[1]', '').trim();
  }


}

export default new TractorRecordService()
