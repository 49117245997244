import ShiftService from "../../../../services/ShiftService";

const DELETE_SHIFT = 'DELETE_ASSIGNMENT__SHIFT_CARD';

function deleteShift(id, after) {
  return dispatch => {
    // dispatch(setSaving(true));
  };
}

export const types = {DELETE_SHIFT};
export const actions = {deleteShift};
