import {types as createNoteTypes} from '../../Notes/CreateNote/CreateNoteActions';
import {types as noteTypes} from '../../Notes/NotesActions';

const defaultState = {
  timestamp: Date.now()
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case createNoteTypes.RESET_FORM:
    case createNoteTypes.PENDING:
    case createNoteTypes.LOAD_NOTE:
    case createNoteTypes.SET_TITLE:
    case createNoteTypes.SET_TEXT:
    case createNoteTypes.SET_DATE_START:
    case createNoteTypes.SET_DATE_END:
    case createNoteTypes.SET_CLIENT:
    case createNoteTypes.FILE_UPLOAD:
    case createNoteTypes.FILE_UPLOAD_DONE:
    case createNoteTypes.FILE_DELETE:
    case createNoteTypes.FILE_DELETE_DONE:
    case noteTypes.FILE_FETCH:
    case noteTypes.FILE_FETCH_PENDING:
    case noteTypes.FILE_FETCH_SUCCESS:
    case noteTypes.FILE_FETCH_ERROR:
      state = Object.assign({}, state, {timestamp: Date.now()});
      break;
  }
  return state;
}
