import {types} from './EmployeesActions';

const defaultState = {
  data: [],
  filterStr: '',
  filterPath: 'root',
  filterMode: false,
  pending: true,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.FETCH:
    case types.FETCH_PENDING:
    case types.FETCH_ERROR:
    case types.FETCH_SUCCESS:
    case types.FILTER:
    case types.FILTER_PATH:
    case types.FILTER_MODE:
      state = Object.assign({}, state, {...action.payload});
      break;
  }

  return state;
}
