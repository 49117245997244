import store from '../store/store';
import { locationSuccess } from '../components/Main/Map/MapCanvas/MapCanvasActions';

const LOCATION_ENABLE_HIGH_ACCURACY = process.env.REACT_APP_LOCATION_ENABLE_HIGH_ACCURACY || false;
const LOCATION_TIMEOUT = process.env.REACT_APP_LOCATION_TIMEOUT || 2000;
const LOCATION_MAXIMUM_AGE = process.env.REACT_APP_LOCATION_TIMEOUT || 0;

class LocationService {
  constructor() {
    this.locationId = null;
    this.options = {
      enableHighAccuracy: LOCATION_ENABLE_HIGH_ACCURACY,
      timeout: LOCATION_TIMEOUT,
      maximumAge: LOCATION_MAXIMUM_AGE
    };
  }
  _locationSuccess(position) {
    this.position = position;
    store.dispatch(locationSuccess());
  }

  _locationError(error) {
    //TODO: add location error handler.
  }

  getPosition() {
    return this.position;
  }

  isLocationAvailable() {
    return 'geolocation' in navigator;
  }

  startWatchLocation() {
    if (this.locationId || !this.isLocationAvailable()) {
      return;
    }

    this.locationId = navigator.geolocation.watchPosition(
      (position) => this._locationSuccess(position),
      this._locationError, this.options);
  }

  stopWatchLocation() {
    if (this.locationId) {
      navigator.geolocation.clearWatch(this.locationId);
      this.locationId = null;
    }
  }
}

export default new LocationService();
