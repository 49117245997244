import values from 'lodash/values';
import isEmpty from "lodash/isEmpty";
import moment from 'moment';
import get from "lodash/get";
import last from "lodash/last";
import uniq from "lodash/uniq";
import { url } from '../api';
import BaseDataService from "../baseClasses/BaseDataService";
import { actions } from "../components/Main/Fields/FieldsActions";
import { actions as fieldActions } from '../components/Main/Fields/Field/FieldActions';
import { mapValuesForKeys } from "../helpers/mapValuesForKeys";
import store from "../store/store";
import TaskService from "./TaskService";
import { stringHash } from "../helpers/stringHash";
import L from "leaflet";
import {getMultiPolygonCoordinates, swapLatLon} from "../utils/MapUtils";

class FieldService extends BaseDataService {
  _listCache = [];

  constructor() {
    super({ urlAll: url.field, ...actions });
    window.FieldService = this;
    this._cacheOn = true;
    this._treeStateSaveOn = true;
  }

  dispatchDataChange() {
    super.dispatchDataChange();
    store.dispatch(fieldActions.fetchSuccess());
  }

  _mergeCustoms(dest, src, key, isArray) {
    if (!isArray) {
      if (key === 'cultivationInfo') {
        const operationId = get(src.values[key], 'base[0].operationId');
        //TODO: add insert and update operations
        //dest[key] = merge(src.values[key], this._glidMap[src.id][key]);
        // if (dest.glid == 1740) {
        //   debugger;
        // }
        if (!dest[key]) {
          dest[key] = {}
        }
        if (operationId) {
          dest[key][operationId] = src.values[key];
        }
      } else if (key === 'cropsInfo') {
        const crops = get(src.values[key], 'cropData', []);
        if (!isEmpty(crops)) {
          dest.crop = last(crops);
          dest.crop.title = uniq(crops.map(x => x.title)).join(', ');
        }
      } else {
        dest[key] = src.values[key];
      }
    } else {
      const v = this._glidMap[src.id][key] || [];
      dest[key] = [...v, src.values[key]];
    }
  }

  addBounds(geometry) {
    if (geometry && !geometry.bounds && Array.isArray(geometry.coordinates) && geometry.coordinates.length) {
      const coords = geometry.type === 'MultiPolygon'
        ? getMultiPolygonCoordinates(geometry.coordinates)
        : geometry.coordinates[0].filter(c => c.length).map(swapLatLon);
      const bounds = L.latLngBounds(coords);
      if (bounds.isValid()) {
        geometry.bounds = bounds;
      }
    }
  }

  _mapListItem(field) {
    this.addBounds(field.geometry);
    const tasks = TaskService.getTasksForField(field.glid);
    const currentTasks = tasks.filter(i => moment(i.from).valueOf() <= window.workPeriodMs && (moment(i.to).valueOf() && moment(i.to).valueOf() >= window.workPeriodMs || true)) || [];
    const operations = currentTasks.map(i => get(i, 'operation.shortTitle', null)).filter(x => x);

    const vehiclesCount = values(field.relatedVehicles).filter(x => x).length;
    const hash = Math.abs(stringHash(field.name) + vehiclesCount + stringHash(operations.join()) * 100 + field.color * 1000 + Number(field.glid) * 100000);

    return mapValuesForKeys(field, [
      '_lastUpdated',
      'glid',
      'name',
      'color',
      'area',
      'removed',
      'pending',
      'crop',
      {
        key: '_hash',
        value: hash,
      },
      {
        key: 'operations',
        value: operations,
      },
      {
        key: 'vehicles',
        value: vehiclesCount,
      }
    ]);
  }

  getFieldsList() {
    return values(this._glidMap);
  }
}

export default new FieldService();
