import {MODAL_CLOSE, MODAL_OPEN} from "./ModalActions";

// Состояние при инициализации
const defaultState = {
  // флаг открытости модального окна
  isModalOpened: false,

  // Конфигурационный файл (наполнение) модального окна
  modalConfig: null
}

// reducer для состояния модального окна
const modal = (state = defaultState, action) => {
  switch (action.type) {
    case MODAL_OPEN:
    case MODAL_CLOSE:
      state = Object.assign({}, state, {...action.payload});
      break;
  }

  return state;
};

export default modal
