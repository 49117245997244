import VehicleWorkService from '../../../services/VehicleWorkService';
import {createActions} from "../../../baseClasses/ActionFactory";

function onFetch({vehicleId, operationId, startTime}) {
  VehicleWorkService.fetch(vehicleId);
  return {vehicleId, operationId, startTime};
}

const {types, actions} = createActions({store: 'VEHICLE_WORK', onFetch});
export {types, actions};
