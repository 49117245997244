import {createActions} from "../../../baseClasses/ActionFactory";
import DepartmentService from '../../../services/DepartmentService';

export const SET_INITIAL_DEPARTMENT = 'SET_INITIAL_DEPARTMENT';
export const SET_DEPARTMENT = 'SET_DEPARTMENT';



export function setInitialDepartment() {
  return async dispatch => {
    await DepartmentService.setInitialDepartment();

    dispatch({
      type: SET_INITIAL_DEPARTMENT,
    });
  };
}

export function setDepartment(department, initial) {
  return async dispatch => {
    if (!initial) {
      const success = await DepartmentService.setDepartment(department);
      if (!success) {
        return;
      }
    }

    dispatch({
      type: SET_DEPARTMENT,
    });
  };
}

const {types, actions} = createActions({store: 'DEPARTMENT'});
const allActions = {...actions, setDepartment, setInitialDepartment};
const allTypes = {...types, SET_DEPARTMENT, SET_INITIAL_DEPARTMENT};
export {allTypes as types, allActions as actions};
