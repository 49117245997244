import TrackService from '../../../services/TrackService';
export const MAP_CHANGE_LAYER_TYPE = 'MAP_CHANGE_LAYER_TYPE';

export const LOAD_TRACK = 'LOAD_TRACK';
export const PENDING_TRACK = 'PENDING_TRACK';
export const SET_PERIOD = 'SET_PERIOD_TRACK';

export const DESELECT_FEATURE = 'DESELECT_FEATURE';
export const SET_VIEW_PORT = 'SET_VIEW_PORT';
export const SET_SELECTED = 'SET_SELECTED';

export function loadTrack(entityId) {
  return async (dispatch, getState) => {
    TrackService.clear();

    const { from, to } = getState().main.track;
    await TrackService.loadTrack(entityId, from, to);

    dispatch({
      type: LOAD_TRACK,
      payload: {
        trackTs: Date.now()
      }
    });
  };
}

export function loadTechTrack(entityId, from, to) {
  return async (dispatch) => {
    TrackService.clear();

    await TrackService.loadTrack(entityId, from, to);

    dispatch({
      type: LOAD_TRACK,
      payload: {
        trackTs: Date.now()
      }
    });
  };
}

export function setPeriod(date, isEnd) {
  return (dispatch, getState) => {
    const { from: fromPrev, to: toPrev } = getState().main.track;

    const fromNew = isEnd ? fromPrev : date;
    const toNew = isEnd ? date : toPrev;

    dispatch({
      type: SET_PERIOD,
      payload: {
        from: fromNew,
        to: toNew,
      },
    });
  };
}

export function setPending(isPending) {
  return (dispatch) => {
    dispatch({
      type: PENDING_TRACK,
      payload: { pending: isPending }
    });
  };
}


export function clearTrack() {
  return async () => {
    TrackService.clear();
  };
}

export function changeLayerType(type) {
  return (dispatch) => {
    dispatch({
      type: MAP_CHANGE_LAYER_TYPE,
      layerType: type
    });
  };
}

export function deselectFeature() {
  return (dispatch) => {
    dispatch({
      type: DESELECT_FEATURE,
    })
  };
}

export function setViewPort(viewport) {
  return (dispatch) => {
    dispatch({
      type: SET_VIEW_PORT,
      viewport: viewport
    });
  };
}

export function setSelectedPoint(selected) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED,
      selected,
    })
  };
}

export const actions = { loadTrack, loadTechTrack, setPeriod, setPending, clearTrack, setSelectedPoint };
