import values from 'lodash/values';
import cloneDeep from 'lodash/cloneDeep';
import Queue from './Queue';
import Node from './Node';

export default class Tree {
  constructor({getId, rootName = 'root'}) {
    Object.assign(this, {getId, rootName});

    this.createRoot({rootName});
    this.setMap();
    this.updateTimestamp();
  }

  createRoot({rootName}) {
    this.root = new Node(rootName);
    this.root.isRoot = true;
    this.root.name = rootName;
  }

  setMap() {
    this.map = {};
    this.root.tree = () => this;
    this.traverseBF((n) => this.addToMap(n));
  }

  setMapForParents(node) {
    const nodeArr = node.parents();
    for (const n of nodeArr) {
      this.addToMap(n);
    }
  }

  setMapForChildren(node) {
    for (const n of node.children) {
      this.addToMap(n);
    }
  }

  allNodes() {
    return values(this.map);
  }

  nodes() {
    return this.allNodes().filter(i => !i.leaf);
  }

  leaves() {
    return this.allNodes().filter(i => i.leaf && !i.isRoot);
  }

  addToMap(n) {
    n.getId = () => this.getId(n);
    n.setPath();
    n.tree = () => this;
    n.depth = n.parent() ? n.parent().depth + 1 : 0;
    this.map[n.path] = n;
  }

  removeNode(n) {
    if (n === this.root) {
      return;
    }

    delete this.map[n.path];

    const parent = n.parent();
    parent.children = parent.children.filter(i => this.getId(i) !== this.getId(n));

    this.setMap();
    this.updateTimestamp();
  }

  traverseDF(cb) {
    const traverse = (currentNode) => {
      for (const node of currentNode.children) {
        traverse(node);
      }

      cb(currentNode);
    };

    traverse(this.root);
  }

  traverseBF(cb) {
    const q = new Queue();

    q.add(this.root);

    let currentTree = q.get();

    while (currentTree) {
      for (const node of currentTree.children) {
        q.add(node);
      }

      cb(currentTree);
      currentTree = q.get();
    }
  }

  static empty() {
    const tree = new Tree({getId: () => 'nop'});
    tree.isEmpty = true;
    return tree;
  }

  subtractBranch(path) {
    const tree = new Tree({getId: this.getId, rootName: this.rootName});
    const firstNode = cloneDeep(this.map[path]);
    tree.root.addNode(firstNode);
    Tree._setParents(tree.root);
    tree.setMap();
    return tree;
  }

  static _setParents(node) {
    for (const child of node.children) {
      child.parent = () => node;

      Tree._setParents(child);
    }
  }

  updateTimestamp() {
    this.lastUpdate = Date.now();
  }

  static compare(t1, t2) {
    return t1.lastUpdate !== t2.lastUpdate;
  }

}

export {Node};
