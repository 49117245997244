import store from '../store/store';
import once from 'lodash/once';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import {fetchPending, fetchSuccess, update} from "../components/Main/MainActions";
import {WebsocketService, agroWsEvents} from './WebsocketService';
import DepartmentService from './DepartmentService';
import FieldService from './FieldService';
import MeteostationsService from './MeteostationsService';
import VehicleService from './VehicleService';
import EmployeeService from './EmployeeService';
import PlanByWorkService from './PlanByWorkService';
import PlanByCultureService from './PlanByCultureService';
import OperationsService from './OperationService';
import TaskService from "./TaskService";
import FieldToolService from './FieldToolService';
import LimitSpeedService from './LimitSpeedService';
import {responseDataShape} from '../baseClasses/BaseDataService';
import ActionService from './ActionService';
import MapService from './MapService';
import TransportRouteService from './TransportRouteService';
import GeoZoneLoadingTypesService from './GeoZoneLoadingTypesService';
import GoodsService from './GoodsService';
import WorkShiftService from "./WorkShiftService";
import FieldWorkService from "./FieldWorkService";
import AuthService from "./AuthService";
import CompanyService from "./CompanyService";
import WeightedService from "./WeightedService";
import HarvesterUploadService from "./HarvesterUploadService";
import AssignmentService from "./AssignmentService";
import UserService from "./UserService";
import defer from "promise-defer";
import ShiftService from "./ShiftService";

class MainService {
  _changed = false;
  _resetCb = [];

  constructor() {
    this._initialDataPulled = false;
    this._initDefer = defer();
    window.MainService = this;
  }

  async fetchInitialData() {
    if (!AuthService.isLoggedIn()) {
      return;
    }
    // TOKENCOMMENT
    // if (AuthService.isTokenExpired()) {
    //   AuthService.refreshToken().then(() => this.fetchInitialData());
    //   return;
    // }
    store.dispatch(fetchPending());

    Promise.all([
      DepartmentService.fetch(),
      FieldService.fetch(),
    ]).then(() => {
      DepartmentService.fetchOptions();
      VehicleService.fetch();
      MeteostationsService.fetch();
      OperationsService.fetch();
      EmployeeService.fetch();
      PlanByWorkService.fetch();
      PlanByCultureService.fetch();
      CompanyService.fetch();
      FieldToolService.fetch();
      LimitSpeedService.fetch();
      // TaskService.fetch();
      TransportRouteService.fetch();
      GeoZoneLoadingTypesService.fetch();
      // GoodsService.fetch();
      UserService.fetch();
      AssignmentService.autoFetchOn();

      WebsocketService.connect().then(() => {
        WebsocketService.removeListener(agroWsEvents.AG_CHANGES, data => this.onUpdate(data));
        WebsocketService.addListener(agroWsEvents.AG_CHANGES, data => this.onUpdate(data));
      });
      this._initDefer.resolve();
    }).finally(() => {
      store.dispatch(fetchSuccess());
      AssignmentService.update();
    });
  }

  clearAll() {
    this.reset();
    DepartmentService.clear();
    TaskService.clear();
    FieldService.clear();
    MeteostationsService.clear();
    VehicleService.clear();
    EmployeeService.clear();
    PlanByWorkService.clear();
    PlanByCultureService.clear();
    OperationsService.clear({_dataShape: responseDataShape.ARRAY});
    FieldToolService.clear({_dataShape: responseDataShape.ARRAY});
    LimitSpeedService.clear({_dataShape: responseDataShape.ARRAY});
    TransportRouteService.clear({_dataShape: responseDataShape.ARRAY});
    GeoZoneLoadingTypesService.clear({_dataShape: responseDataShape.ARRAY});
    GoodsService.clear({_dataShape: responseDataShape.ARRAY});
    ActionService.clear();
    WebsocketService.clear();
    WorkShiftService.clear();
    CompanyService.clear();
    WeightedService.reset();
    HarvesterUploadService.reset();
    UserService.reset();
    AssignmentService.reset();
    ShiftService.reset();
  }

  async resetDepartment() {
    this.reset();
    TaskService.reset();
    FieldService.reset();
    MeteostationsService.reset();
    VehicleService.reset();
    EmployeeService.reset();
    PlanByWorkService.reset();
    PlanByCultureService.reset();
    FieldService.reset();
    WebsocketService.reset();
    ActionService.reset();
    MapService.reset();
    WorkShiftService.reset();
    FieldWorkService.reset();
    WeightedService.reset();
    HarvesterUploadService.reset();
    UserService.reset();
    AssignmentService.reset();
    ShiftService.reset();
    DepartmentService.resetOptions();

    store.dispatch(fetchPending());

    await Promise.all([
      FieldService.fetch(),
    ]);

    DepartmentService.fetchOptions();
    VehicleService.fetch();
    MeteostationsService.fetch();
    EmployeeService.fetch();
    TaskService.fetch();
    PlanByWorkService.fetch();
    PlanByCultureService.fetch();
    FieldToolService.fetch();
    UserService.fetch();
    AssignmentService.autoFetchOn();

    await WebsocketService.connect();

    this._initDefer.resolve();
    store.dispatch(fetchSuccess());
  }

  async afterReconnect() {
    this._initDefer = defer();
    const serviceList = [
      DepartmentService,
      FieldService,
      VehicleService,
      MeteostationsService,
      EmployeeService,
      PlanByWorkService,
      PlanByCultureService,
      FieldToolService,
      LimitSpeedService,
      TaskService,
      TransportRouteService,
      GeoZoneLoadingTypesService,
      // GoodsService,
      CompanyService,
      UserService,
    ];

    for (const service of serviceList) {
      if (!service.inited) {
        service.fetch();
      }
    }

    DepartmentService.fetchOptions();
    AssignmentService.autoFetchOn();
    ActionService.fetch();
  }

  reset() {
    this._changed = false;
    this._initialDataPulled = false;
    this._initDefer = defer();

    this._resetCb.forEach(x => isFunction(x) && x());
  }

  dispatchChanges = once(() => setInterval(() => {
    if (!this._changed) {
      return;
    }
    this._changed = false;
    store.dispatch(update());
  }, 2500));

  onUpdate(data) {
    if (!window.updatingOn) {
      return;
    }
    if (!this._initialDataPulled) {
      this._initialDataPulled = true;
    }
    window.setWorkPeriodByUpdate(data.time);
    if (isEmpty(data.items)) {
      return;
    }
    this._changed = true;
    this.onUpdateBefore();
    VehicleService.update(data.items);
    MeteostationsService.update(data.items);
    FieldService.update(data.items);
    EmployeeService.update(data.items);

    this.dispatchChanges();
    this.onUpdateDone();
  }

  async onInit() {
    await this._initDefer.promise;
  }

  onReset(cb) {
    this._resetCb.push(cb);
  }

  onUpdateBefore() {
    FieldService.beforeUpdate();
    VehicleService.beforeUpdate();
    MeteostationsService.beforeUpdate();
    EmployeeService.beforeUpdate();
  }

  onUpdateDone() {
    FieldService.afterUpdate();
    VehicleService.afterUpdate();
    MeteostationsService.afterUpdate();
    EmployeeService.afterUpdate();
  }
}

export default new MainService();
