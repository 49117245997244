import immutable from 'immutable';

import {
  //LOADER_LOGIN,
  AUTHORIZE_PENDING,
  AUTHORIZE_SUCCESS,
  AUTHORIZE_ERROR,
  AUTHORIZE_LOGIN_ENTER,
  AUTHORIZE_PASSWORD_ENTER,
  AUTHORIZE_LOADER_CLEAR


} from './LoginActions';

const defaultState = {
  user: {}
};

export default function (state = immutable.fromJS(defaultState), action) {
  switch (action.type) {
    
    case AUTHORIZE_PENDING:
      state = state.set('authPending', true);
      break;
    case AUTHORIZE_LOADER_CLEAR:
      state = state.set('authPending', false);
      break;
    case AUTHORIZE_SUCCESS:
      state = state.set('user', action.payload);
      state = state.set('authPending', false);
      state = state.set('error', undefined);
      break;
    case AUTHORIZE_ERROR:
      state = state.set('error', action.payload);
      state = state.set('authPending', false);
      break;
    case AUTHORIZE_LOGIN_ENTER:
      state = state.set('login', action.payload);
      break;
    case AUTHORIZE_PASSWORD_ENTER:
      state = state.set('password', action.payload);
      break;
  }

  return state;
}
