/**
 *  special date (Sat Jan 01 2011 03:00:00 GMT+0300 (Самарское стандартное время)) for correct request of several dates
 */
const TIMESTAMP = 1293840000000;

const getAgrosignalTime = (time) => {
  return String(time - TIMESTAMP);
}

export default getAgrosignalTime;
