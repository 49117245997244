import moment from 'moment';
import {historyPush} from '../../../Main/MainActions';
import AssignmentService from "../../../../services/AssignmentService";
import routes from "../../../../routes";

export const types = {
  PENDING: 'PENDING__ASSIGNMENT_CREATE',
  SET_ITEM: 'SET_ITEM__ASSIGNMENT_CREATE',
  SET_TITLE: 'SET_TITLE__ASSIGNMENT_CREATE',
  SET_DESCRIPTION: 'SET_DESCRIPTION__ASSIGNMENT_CREATE',
  SET_SOURCE_TYPE: 'SET_SOURCE_TYPE__ASSIGNMENT_CREATE',
  SET_ASSIGNEE: 'SET_ASSIGNEE__ASSIGNMENT_CREATE',
  SET_COMMENT: 'SET_COMMENT__ASSIGNMENT_CREATE',
  SET_START_DATE: 'SET_START_DATE__ASSIGNMENT_CREATE',
  SET_END_DATE: 'SET_END_DATE__ASSIGNMENT_CREATE',
  SET_SOURCE: 'SET_SOURCE__ASSIGNMENT_CREATE',
  SET_FILTER: 'SET_FILTER__ASSIGNMENT_CREATE',
  SHOW_CHECKED_TOGGLE: 'SHOW_CHECKED_TOGGLE__ASSIGNMENT_CREATE',
  RESET_FORM: 'RESET_FORM__ASSIGNMENT_CREATE',
};

export const actions = {
  resetForm: function () {
    return dispatch => {
      dispatch({type: types.RESET_FORM});
    };
  },
  setItem: function (item) {
    return dispatch => {
      dispatch({
        type: types.SET_ITEM,
        payload: {item},
      });
    };
  },
  setTitle: function (title) {
    return (dispatch, getState) => {
      const {main: {assignmentEdit: {item: prevItem}}} = getState();
      const item = Object.assign({}, prevItem, {title});

      dispatch({
        type: types.SET_TITLE,
        payload: {item},
      });
    };
  },
  setDescription: function (description) {
    return (dispatch, getState) => {
      const {main: {assignmentEdit: {item: prevItem}}} = getState();
      const item = Object.assign({}, prevItem, {description});

      dispatch({
        type: types.SET_DESCRIPTION,
        payload: {item},
      });
    };
  },
  saveNewAssignment: function (item, after) {
    return dispatch => {
      dispatch({
        type: types.PENDING,
        payload: {pending: true}
      });
      AssignmentService.saveNewAssignment(item)
        .then(() => {
          dispatch({type: types.PENDING, payload: {pending: false}});
          after();
        });
    };
  },
  saveAssignment: function (item, after) {
    return dispatch => {
      dispatch({
        type: types.PENDING,
        payload: {pending: true}
      });
      AssignmentService.saveAssignment(item.id, item)
        .then(() => {
          dispatch({type: types.PENDING, payload: {pending: false}});
          after();
        });
    };
  },
  setSourceType: function (sourceType) {
    return (dispatch, getState) => {
      const {main: {assignmentEdit: {item: prevItem}}} = getState();
      const item = Object.assign({}, prevItem, {sourceType});

      dispatch({
        type: types.SET_SOURCE_TYPE,
        payload: {item},
      });
    };
  },
  setAssignee: function (assignee) {
    return (dispatch, getState) => {
      const {main: {assignmentEdit: {item: prevItem}}} = getState();
      const item = Object.assign({}, prevItem, {assignee: assignee.map(x => Number(x))});

      dispatch({
        type: types.SET_ASSIGNEE,
        payload: {item},
      });
    };
  },
  setComment: function (comment) {
    return dispatch => {
      dispatch({
        type: types.SET_COMMENT,
      });
    };
  },
  setStartDate: function (date) {
    return (dispatch, getState) => {
      const {main: {assignmentEdit: {item: prevItem}}} = getState();
      const item = {...prevItem, startDue: moment(date).format()}

      dispatch({
        type: types.SET_START_DATE,
        payload: {item},
      });
    };
  },
  setEndDate: function (date) {
    return (dispatch, getState) => {
      const {main: {assignmentEdit: {item: prevItem}}} = getState();
      const item = {...prevItem, due: moment(date).format()}

      dispatch({
        type: types.SET_END_DATE,
        payload: {item},
      });
    };
  },
  setSource: function (id) {
    return (dispatch, getState) => {
      const {main: {assignmentEdit: {item: prevItem}}} = getState();
      const item = Object.assign({}, prevItem, {sources: [id]});

      dispatch({
        type: types.SET_SOURCE,
        payload: {item},
      });
    };
  },
  filter: function (filterStr) {
    return dispatch => {
      dispatch({
        type: types.SET_FILTER,
        payload: {filterStr}
      });
    };
  },
  showCheckedToggle: function () {
    return (dispatch, getState) => {
      const {main: {assignmentEdit: {showChecked}}} = getState();

      dispatch({
        type: types.SHOW_CHECKED_TOGGLE,
        payload: {showChecked: !showChecked}
      });
    };
  },
};
