import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import orderBy from "lodash/orderBy";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";
import store from '../store/store';
import {WebsocketService, agroWsEvents} from './WebsocketService';
import VehicleService from './VehicleService';
import AddressService from './AddressService';
import {aspect} from "../const/aspect";
import {actions as actionListActions, update as listUpdate} from "../components/Main/Actions/ActionsActions";
import {actions as actionActions, update} from "../components/Main/Actions/Action/ActionActions";
import {positionTurn} from "../helpers/positionTurn";
import FieldService from "./FieldService";
import fetchStatus from "../const/fetchStatus";

class ActionService {
  _status = fetchStatus.PENDING;

  constructor() {
    this.reset();
    window.ActionService = this;
  }

  fetch(id) {
    if (this._vehId !== id) {
      this.data = [];
      this.dataInited = false;
    } else {
      return;
    }
    this._vehId = id;

    store.dispatch(actionListActions.fetchPending());
    store.dispatch(actionActions.fetchPending());

    this._status = fetchStatus.PENDING;

    WebsocketService.cbOnOpen(() => {
      WebsocketService.setDynamic({id, aspect: aspect.VEHICLE_STATE_HISTORY});
      WebsocketService.removeListener(agroWsEvents.AG_CHANGES, data => this.onUpdate(data));
      WebsocketService.addListener(agroWsEvents.AG_CHANGES, data => this.onUpdate(data));
    });
  }

  reset() {
    this.data = [];
    this._vehId = null;
    this._cacheTimeStamp = 0;
    this._lastUpdated = Date.now();
    this._status = fetchStatus.PENDING;
  }

  clear() {
    this.reset();
  }

  onUpdate(data) {
    if (!window.updatingOn) {
      return;
    }
    store.dispatch(actionListActions.fetchSuccess());
    store.dispatch(actionActions.fetchSuccess());

    const targetVehicleUpdates = data.items.find(i => Number(i.id) === Number(this._vehId));
    if (isEmpty(get(targetVehicleUpdates, 'values.stateHistory', null))) {
      return;
    }
    this._status = fetchStatus.DEFAULT;
    if (targetVehicleUpdates.values.stateHistory.base) {
      this.data = [...(targetVehicleUpdates.values.stateHistory.base || [])];
    }
    if (targetVehicleUpdates.values.stateHistory.insert) {
      this.data = [
        ...this.data,
        ...(targetVehicleUpdates.values.stateHistory.insert || [])
      ];
    }
    if (!isEmpty(this.data) && targetVehicleUpdates.values.stateHistory.modify) {
      each(targetVehicleUpdates.values.stateHistory.modify, mod => {
        const inBase = this.data.find(i => i.id === mod.id);
        if (!isEmpty(inBase)) {
          Object.assign(inBase, mod);
        }
      });
    }

    this._lastUpdated = Date.now();

    each(this.data, (action, index) => {
      action._lastUpdated = this._lastUpdated;
      if (index === 0 && action.tm === action.endTime) {
        action.endTime = Date.now();
      }
      if (action.zoneId) {
        action.field = FieldService.getItem(action.zoneId);
      }
    });

    store.dispatch(actionListActions.fetchSuccess());
    store.dispatch(actionActions.fetchSuccess());

    Promise.all(
      this.data
        .filter(action => !action.address && !action.zoneId && action.position)
        .map(action => {
          action.pending = true;
          action._lastUpdated = Date.now();
          this._lastUpdated = Date.now();
          store.dispatch(listUpdate());
          if (!isEmpty(this._item) && action.id === this._item.id) {
            store.dispatch(update());
          }

          return AddressService.getAddress(positionTurn(action.position), (data) => {
            action._lastUpdated = Date.now();
            action.address = data;
            action.pending = false;
            this._lastUpdated = Date.now();
            store.dispatch(listUpdate());
            if (!isEmpty(this._item) && action.id === this._item.id) {
              store.dispatch(update());
            }
          });

        })
    ).then(() => {
      this._lastUpdated = Date.now();
      store.dispatch(actionListActions.fetchSuccess());
      store.dispatch(actionActions.fetchSuccess());
    });
  }

  getList() {
    const data = orderBy(this.data, ['tm', 'endTime'], ['desc', 'desc']);
    for (const action of data) {
      action.isLast = false;
    }
    if (!isEmpty(data[0])) {
      set(data, '[0].isLast', true);
      set(data, '[0].endTimeLast', window.workPeriodMs);
    }
    return cloneDeep(data);
  }

  getVehicle() {
    const {name = 'Техника', glid} = VehicleService.getItem(this._vehId);
    return {name, glid};
  }

  isPending() {
    return this._status === fetchStatus.PENDING;
  }

  getItem(vehId, from) {
    this.getList();
    const item = !isEmpty(this.data) && this.data.length ? this.data.find(i => Number(i.tm) === Number(from)) : null;
    this._item = item;
    return cloneDeep(item);
  }
}

export default new ActionService();
