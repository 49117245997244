import moment from "moment";
import isEmpty from "lodash/isEmpty";
import AuthService from "./AuthService";
import httpClient from "@infobis/api-module";
import {url} from "../api";
import {actions} from '../components/Main/Track/TrackActions';
import store from "../store/store";
import reportType from "../const/reportType";
import {Notification} from './../components/common/Notification';
import { safeExtractTrackCoordinates } from "../helpers/safeExtractTrackCoordinates";

class TrackService {
  _track = undefined;

  constructor() {
    window.TrackService = this;
  }

  async loadTrack(entityId, from, to, operationId, fieldId) {
    const user = AuthService.getUser();

    const request = {
      url: url.unitTrack,
      params: {
        method: 'POST',
      },
      authorize: true,
      qs: {
        unitId: entityId,
        from: from,
        to: to,
        operationId: operationId,
        geoZones: fieldId,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "serverId": user.serverId,
        "Authorization": "Bearer " + user.accessToken,
      },
    };
    store.dispatch(actions.setPending(true));

    // TODO: вынести запрос в отдельную функцию
    // использовать его здесь и в контейнерах, которые получают
    // инфу по нескольким трекам
    try {
      const response = await httpClient.makeRequest(request);
      this.saveTrack(response);
      await this.loadReport(entityId, from, to);
      store.dispatch(actions.setPending(false));
      return response;
    } catch (error) {
      Notification.error(error);
      // need to check.
      return false;
    }
  }

  // TODO: скопировал, чтоб не менять логику везде
  // в целом по сервису комментарий выше оставил
  // этот метод просто возвращает не только трек, но и отчет
  async loadTrackWithReport(entityId, from, to, operationId, fieldId) {
    const user = AuthService.getUser();

    const request = {
      url: url.unitTrack,
      params: {
        method: 'POST',
      },
      authorize: true,
      qs: {
        unitId: entityId,
        from: from,
        to: to,
        operationId: operationId,
        geoZones: fieldId,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "serverId": user.serverId,
        "Authorization": "Bearer " + user.accessToken,
      },
    };
    store.dispatch(actions.setPending(true));

    // TODO: вынести запрос в отдельную функцию
    // использовать его здесь и в контейнерах, которые получают
    // инфу по нескольким трекам
    try {
      const track = await httpClient.makeRequest(request);
      this.saveTrack(track);
      const report = await this.loadReport(entityId, from, to);
      store.dispatch(actions.setPending(false));
      return {
        track,
        report: report ? report.data : [],
      };
    } catch (error) {
      Notification.error(error);
      // need to check.
      return false;
    }
  }

  async loadReport(entityId, from, to) {
    const user = AuthService.getUser();
    const now = moment();

    const request = {
      url: url.reportData,
      params: {
        method: 'POST',
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
      authorize: true,
      body: JSON.stringify({
        entityId: [parseInt(entityId)],
        filters: {
          fieldsFilter: [],
          unitsFilter: [],
          driversFilter: [],
        },
        from,
        to,
        limit: 25,
        page: 1,
        start: 0,
        strEntityId: [entityId],
        reportId: reportType.TRACK,
        reportMode: "Archive",
        tzOffset: -now.utcOffset(),
      }),
    };

    try {
      const response = await httpClient.makeRequest(request);
      if (response && response.success && response.data) {
        this.saveReport(response.data);
      }
      return response;
    } catch (error) {
      Notification.error(error);
    }
  }

  saveTrack(track) {
    const fullCoords = safeExtractTrackCoordinates(track);

    if (isEmpty(fullCoords)) {
      return;
    }

    this._track = track;
  }

  saveReport(data) {
    this._report = data;
  }

  getTrack() {
    return this._track;
  }

  getReport() {
    return this._report;
  }

  clear() {
    this._track = undefined;
  }
}

export default new TrackService();
