import moment from 'moment';
import isEqual from "lodash/isEqual";
import find from "lodash/find";
import delay from "lodash/delay";
import httpClient from "@infobis/api-module";
import {url} from "../api/index";
import store from '../store/store';
import {actions} from '../components/Main/Shifts/ShiftsActions';
import {actions as mActions} from '../components/Main/Shifts/CreateShift/ShiftCreateActions';
import AuthService from "./AuthService";
import DepartmentService from "./DepartmentService";
import {getInitialPeriod} from "../helpers/initialPeriod";
import MainService from "./MainService";
import {Notification} from './../components/common/Notification';

class ShiftService {
  shiftOptions = [];
  vehicleOptions = [];
  weekendOptions = [];
  fetchedFor = {};

  constructor() {
    this.reset();
    window.ShiftService = this;
  }

  reset() {
    this.data = [];
    this.shiftOptions = [];
    this.vehicleOptions = [];
    this.weekendOptions = [];
    this.fetchedFor = {};
  }

  requestHeaders() {
    const {clientId: companyId} = DepartmentService.getCurrentDepartment();
    const user = AuthService.getUser();
    const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "serverId": user.serverId,
      "Authorization": "Bearer " + user.accessToken,
      companyId,
    };

    // if (departmentId) {
    //   headers.departmentId = departmentId;
    // }

    return headers;
  }

  async fetch(from, to, force = false) {
    if (!force && isEqual(this.fetchedFor, {from, to})) {
      delay(() => store.dispatch(actions.fetchSuccess()), 0);
      return;
    }
    await MainService.onInit();

    const {clientId: companyId} = DepartmentService.getCurrentDepartment();
    const headers = this.requestHeaders();
    const request = {
      url: url.calendarDriver,
      params: {
        method: 'GET',
      },
      authorize: true,
      headers,
      qs: {
        from,
        to,
        companyId,
        tzOffset: -moment().utcOffset(),
        activeChecks: null,
        page: 1,
        start: 0,
        limit: 1000,
      }
    };

    try {
      const response = await httpClient.makeRequest(request);
      if (response && response.success && response.data) {
        this.data = response.data;
      }
      this._mapWorks(this.data);
      store.dispatch(actions.fetchSuccess());
      this.fetchedFor = {from, to};  
    } catch (error) {
      Notification.error(error);
    }
  }

  _mapWorks(date) {
    date.forEach(shift => {
      shift.operators.forEach(o => {
        o.works.forEach((x, i) => {
          x.from = moment(shift.from).add(i, 'd');
          if (x.from) {
            x.from.locale('ru');
          }
          x.title = x.title || o.defaultShiftName;
          x.unitName = x.unitName || o.defaultUnitName;
        });
      });
    });
  }

  async saveShift(shift) {
    const {clientId} = DepartmentService.getCurrentDepartment();
    const shiftParsed = {
      ...shift,
      operators: shift.operators.map(o => Object.assign({}, {...o}, {driverId: Number(o.driverId)})),
      from: moment(shift.from).utc().format(),
    };
    const headers = this.requestHeaders();

    shiftParsed.operators.forEach(o => {
      if (!o.defaultShiftId) {
        o.defaultShiftId = null;
      }

      o.works.forEach(w => {
        if (!w.shiftId) {
          w.shiftId = null;
        }
      });
    });

    const request = {
      // url: `${url.calendarDriver}/${shift.id || ''}`,
      url: shift.id 
            ? `${url.calendarDriver}/${shift.id}`
            : `${url.calendarDriver}`,
      params: {
        method: shift.id ? 'PUT' : 'POST',
      },
      authorize: true,
      headers,
      body: JSON.stringify({
        ...shiftParsed,
        clientId,
      }),
    };

    this.fetchedFor = {};

    store.dispatch(mActions.saving(true));
    
    try {
      const result = await httpClient.makeRequest(request);
      return result;
    } catch (error) {
      Notification.error(error);
    }
    store.dispatch(mActions.saving(false));

  }

  async deleteShift(id) {
    const headers = this.requestHeaders();

    const request = {
      url: `${url.calendarDriver}/${id}`,
      params: {
        method: 'DELETE',
      },
      authorize: true,
      headers,
    };
    try {
      await httpClient.makeRequest(request);

    } catch (error) {
      Notification.error(error);
    }
  }

  async fetchDictionaries() {
    await MainService.onInit();
    await this.fetchShiftList();
    await this.fetchVehicleList();
    await this.fetchWeekendList();
    store.dispatch(mActions.fetchShiftSubentitiesList(false));
    store.dispatch(actions.fetchSuccess());
  }

  async fetchShiftList() {
    const headers = this.requestHeaders();

    const request = {
      url: url.shiftTime,
      params: {
        method: 'GET',
      },
      authorize: true,
      headers,
    };
  
    try {
      const response = await httpClient.makeRequest(request);
      if (response && response.success && response.data) {
        this.shiftOptions = response.data;
        for (let shift of this.shiftOptions) {
          const from = moment(shift.from, 'H:mm');
          const duration = moment(shift.durationHours, 'H:mm');
          const hours = from.hours() + duration.hours();
          const minutes = from.minutes() + duration.minutes();
          const to = moment('00:00', 'HH:mm').add(hours, "hours").add(minutes, "minutes");
          shift.to = to.format('HH:mm');
        }
      } else {
        this.shiftOptions = [];
      }
    } catch (error) {
      Notification.error(error);
    }
  }

  async fetchVehicleList() {
    const headers = this.requestHeaders();

    const request = {
      url: url.units,
      params: {
        method: 'GET',
      },
      authorize: true,
      headers,
    };

    try {
      const response = await httpClient.makeRequest(request);
      if (response && response.success && response.data) {
        this.vehicleOptions = response.data;
      } else {
        this.vehicleOptions = [];
      }
    } catch (error) {
      Notification.error(error);
    }
  }

  async fetchWeekendList() {
    const headers = this.requestHeaders();

    const request = {
      url: url.weekend,
      params: {
        method: 'GET',
      },
      authorize: true,
      headers,
    };

    try {
      await httpClient.makeRequest(request);
      store.dispatch(mActions.setWeekend(this.weekendOptions[0]));
    }
    catch (error) {
      Notification.error(error);
    }
  }

  getList() {
    return this.data;
  }

  getShiftOptions() {
    return this.shiftOptions;
  }

  getVehicleOptions() {
    return this.vehicleOptions;
  }

  getWeekendOptions() {
    return this.weekendOptions;
  }

  getItem(id) {
    return find(this.data, x => x.id === id);
  }

  newShift(props) {
    return Object.assign({
      access: false,
      clientId: -1,
      days: 1,
      departmentId: -1,
      from: getInitialPeriod(0).from,
      id: null,
      operators: [],
    }, props);
  }

  newOperator(props) {
    return Object.assign({
      defaultShiftId: null,
      defaultUnitId: null,
      driverId: null,
      manualShiftDuration: null,
      manualShiftFrom: '00:00',
      manualShiftTo: null,
      name: '',
      works: [],
    }, props);
  }

  newShiftDay(props) {
    return Object.assign({
      day: 1,
      manualShiftDuration: null,
      manualShiftFrom: '00:00',
      manualShiftTo: null,
      noWorkReason: null,
      noWorkReasonName: "",
      shiftId: null,
      title: "",
      unitId: null,
      unitName: "",
    }, props);
  }
}

export default new ShiftService();
