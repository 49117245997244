import {createActions} from "../../../baseClasses/ActionFactory";
import FieldWorkService from "../../../services/FieldWorkService";

function onFetch({fieldId, operationId, dateStart}) {
  FieldWorkService.fetch(fieldId);
  return {fieldId, operationId, dateStart};
}

const {types, actions} = createActions({store: 'FIELD_WORK', onFetch});

Object.assign(actions, {});

export {types, actions};
