import {iconType} from '../controls/icon/Icon';

export const VEH_TYPE = {
  OTHER: 'other',
  HARVESTER: 'harvester',
  TANKER: 'tanker',
  TRACTOR: 'tractor',
  TRUCK: 'truck',
  AUTOMOBILE: 'vehicle',
  THREASHING_FLOOR: 'threshingFloor',
  SPRINKLING_MACHINE: 'sprinklingMachine',
  WEIGHT_BRIDGE_TERMINAL: 'weighBridgeTerminal',
  FUEL_STATION: 'fuelStation',
  GRAIN_CART: 'grainCart',
  NONE: 'none',
  PLANE: 'plane',
  WAREHOUSE: 'warehouse',
  SNAIL: 'snail'
};
export const typeTitleMap = {
  [VEH_TYPE.AUTOMOBILE]: 'Автомобиль',
  [VEH_TYPE.HARVESTER]: 'Комбайн',
  [VEH_TYPE.OTHER]: 'Другое',
  [VEH_TYPE.TANKER]: 'Заправщик',
  [VEH_TYPE.TRACTOR]: 'Трактор',
  [VEH_TYPE.TRUCK]: 'Грузовик',
  [VEH_TYPE.THREASHING_FLOOR]: 'Мех. ток',
  [VEH_TYPE.SPRINKLING_MACHINE]: 'Дождевальная машина',
  [VEH_TYPE.WEIGHT_BRIDGE_TERMINAL]: 'Весовой терминал',
  [VEH_TYPE.FUEL_STATION]: 'АЗС для топливозаправщиков',
  [VEH_TYPE.GRAIN_CART]: 'Бункер',
  [VEH_TYPE.NONE]: 'Не указана',
  [VEH_TYPE.PLANE]: 'Самолёт',
  [VEH_TYPE.WAREHOUSE]: 'Склад',
  [VEH_TYPE.SNAIL]: 'Тихоход',
};

//TODO: values
export const VEH_STATUS = {
  MOVE: 'Moving',
  STOP: 'Free',
  WORK: 'Work',
  DOWNTIME: 'Stop',
  INSUFFICIENT_DATA: 'Intermediate',
  NO_CONNECT: 'ConnectionLoss',
  NO_DEVICE: 'NoDevice',
  UNAUTHORIZED: 'Unauthorized',
  NO_DATA: 'Undefined',
  NO_COORDINATE: 'NoCoordinate',
  FREE: 'Free',
  OFF: 'off',
  ON: 'on',
  ANY: 'any',
};

export const statusTitleMap = {
  [VEH_STATUS.MOVE]: 'Движение',
  [VEH_STATUS.STOP]: 'Стоянка', //TODO: пересекается с free
  [VEH_STATUS.WORK]: 'Работает',
  [VEH_STATUS.DOWNTIME]: 'Простой',
  [VEH_STATUS.INSUFFICIENT_DATA]: 'Мало данных',
  [VEH_STATUS.NO_CONNECT]: 'Нет связи',
  [VEH_STATUS.NO_DEVICE]: 'Нет прибора',
  [VEH_STATUS.UNAUTHORIZED]: 'Вне плана',
  [VEH_STATUS.NO_DATA]: 'Нет данных',
  [VEH_STATUS.NO_COORDINATE]: 'Нет координат',
  [VEH_STATUS.FREE]: 'Стоянка',
  [VEH_STATUS.OFF]: 'Отключено',
  [VEH_STATUS.ON]: 'Включено',
  [VEH_STATUS.ANY]: 'Любой',
};

export const statusPriorityMap = {
  [VEH_STATUS.MOVE]: 2,
  [VEH_STATUS.STOP]: 5, //TODO: пересекается с free
  [VEH_STATUS.WORK]: 1,
  [VEH_STATUS.DOWNTIME]: 4,
  [VEH_STATUS.INSUFFICIENT_DATA]: 7,
  [VEH_STATUS.NO_CONNECT]: 6,
  [VEH_STATUS.NO_DEVICE]: 9,
  [VEH_STATUS.UNAUTHORIZED]: 3,
  [VEH_STATUS.NO_DATA]: 7,
  [VEH_STATUS.NO_COORDINATE]: 7,
  [VEH_STATUS.FREE]: 5,
  [VEH_STATUS.OFF]: 8,
  [VEH_STATUS.ON]: 7,
};

export const statusColorMap = {
  [VEH_STATUS.MOVE]: '#679436', // $apple
  [VEH_STATUS.STOP]: '#9B9B9B', // $dusty-gray
  [VEH_STATUS.WORK]: '#88AB75', // $chelsea-cucumber
  [VEH_STATUS.DOWNTIME]: '#59A5D8', // $shakespeare
  [VEH_STATUS.INSUFFICIENT_DATA]: '#AB8619', // $mandalay
  [VEH_STATUS.NO_CONNECT]: '#F25F5C', // $carnation
  [VEH_STATUS.NO_DEVICE]: '#170A1C',  // $ebony
  [VEH_STATUS.UNAUTHORIZED]: '#EF7E3B', // $pizazz
  [VEH_STATUS.NO_DATA]: '#230100', // $diesel
  [VEH_STATUS.NO_COORDINATE]: '#DA3486', // $cerise
  [VEH_STATUS.FREE]: '#9B9B9B', // $dusty-gray
  [VEH_STATUS.OFF]: '#6A4C93', // $butterfly-bush
  [VEH_STATUS.ON]: '#FE99AA', // $salmon-pink
};

export const typeIconMap = {
  [VEH_TYPE.SPRINKLING_MACHINE]: iconType.tractorShadow,
  [VEH_TYPE.THREASHING_FLOOR]: iconType.tractorShadow,
  [VEH_TYPE.OTHER]: iconType.tractorShadow,
  [VEH_TYPE.HARVESTER]: iconType.harvesterShadow,
  [VEH_TYPE.TANKER]: iconType.tankerShadow,
  [VEH_TYPE.TRACTOR]: iconType.tractorShadow,
  [VEH_TYPE.TRUCK]: iconType.truckShadow,
  [VEH_TYPE.AUTOMOBILE]: iconType.automobileShadow,
  [VEH_TYPE.FUEL_STATION]: iconType.tractorShadow, 
  [VEH_TYPE.GRAIN_CART]: iconType.tractorShadow,
  [VEH_TYPE.NONE]: iconType.tractorShadow,
  [VEH_TYPE.PLANE]: iconType.tractorShadow,
  [VEH_TYPE.WAREHOUSE]: iconType.tractorShadow,
  [VEH_TYPE.SNAIL]: iconType.tractorShadow,
};
