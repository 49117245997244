import {createActions} from "../../../baseClasses/ActionFactory";
import {createTreeActions} from '../../../baseClasses/TreeActionFactory';
import TrackService from "../../../services/TrackService";

const {types: generalTypes, actions: generalActions} = createActions({store: 'VEHICLES'});
const {types: treeTypes, actions: treeActions} = createTreeActions({store: 'VEHICLES'});


const TOGGLE_VEHICLE_TRACK = 'TOGGLE_VEHICLE_TRACK';
const SET_VEHICLE_TRACKS_PERIOD = 'SET_VEHICLE_TRACKS_PERIOD';

const LOAD_VEHICLE_TRACKS_REQUEST = 'LOAD_VEHICLE_TRACKS_REQUEST';
const LOAD_VEHICLE_TRACKS_SUCCESS = 'LOAD_VEHICLE_TRACKS_SUCCESS';
const LOAD_VEHICLE_TRACKS_FAIL = 'LOAD_VEHICLE_TRACKS_FAIL';

const REMOVE_VEHICLE_TRACK = 'REMOVE_VEHICLE_TRACK';

const toggleVehicleTrack = (data) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_VEHICLE_TRACK,
      payload: data,
    });
  }
};

const setVehicleTracksPeriod = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_VEHICLE_TRACKS_PERIOD,
      payload: data,
    });
  }
}

const loadVehicleTracksRequest = () => {
  return (dispatch) => {
    dispatch({
      type: LOAD_VEHICLE_TRACKS_REQUEST,
    });
  }
}

const loadVehicleTracksSuccess = (data) => {
  return (dispatch) => {
    dispatch({
      type: LOAD_VEHICLE_TRACKS_SUCCESS,
      payload: data,
    });
  }
};

const loadVehicleTracksFail = () => {
  return (dispatch) => {
    dispatch({
      type: LOAD_VEHICLE_TRACKS_FAIL
    });
  }
};

const loadVehicleTracks = () => {
  return async (dispatch, getStore) => {
    try {
      dispatch(loadVehicleTracksRequest());

      const { tracks } = getStore().main.vehicles;

      const newTracksPromises = tracks.vehicles
        .map(({ glid }) => glid)
        .map((glid) => (
          TrackService.loadTrackWithReport(glid, tracks.date.from, tracks.date.to)
        ));

      const newTracks = await Promise.all(newTracksPromises);

      dispatch(loadVehicleTracksSuccess(newTracks));
    } catch (e) {
      dispatch(loadVehicleTracksFail());

      console.error(e);
    }
  }
}

const removeVehicleTrack = (data) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_VEHICLE_TRACK,
      payload: data,
    });
  }
}

const allActions = {
  ...generalActions,
  ...treeActions,
  toggleVehicleTrack,
  loadVehicleTracks,
  setVehicleTracksPeriod,
  removeVehicleTrack,
};

const allTypes = {
  ...generalTypes,
  ...treeTypes,
  TOGGLE_VEHICLE_TRACK,
  SET_VEHICLE_TRACKS_PERIOD,
  LOAD_VEHICLE_TRACKS_REQUEST,
  LOAD_VEHICLE_TRACKS_SUCCESS,
  LOAD_VEHICLE_TRACKS_FAIL,
  REMOVE_VEHICLE_TRACK,
};

export {allTypes as types, allActions as actions};
