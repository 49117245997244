import {createActions} from '../../../baseClasses/ActionFactory';
import InventoryItemsService from "../../../services/InventoryItemsService";
import isEmpty from "lodash/isEmpty";

function onFetch({work, fieldId, operationId, dateStart}) {
  InventoryItemsService.fetch({work, fieldId, operationId, dateStart});
  if (!isEmpty(work) && !isEmpty(work.notes)) {
    const ids = InventoryItemsService.getWriteDowns(work).map(item => item.inventoryItemId);
    if (isEmpty(ids)) {
      InventoryItemsService.items = { ...InventoryItemsService.items, [`${fieldId}-${operationId}-${dateStart}`]: [] };
      return {work, fieldId, operationId, dateStart, pending: false};
    }
  }
  return {work, fieldId, operationId, dateStart, pending: true};
}

const {types: generalTypes, actions: generalActions} = createActions({store: 'GOODS_TMC', onFetch});
const allActions = {...generalActions};
const allTypes = {...generalTypes};

export {allTypes as types, allActions as actions};
