import {types} from './WorkShiftActions';

const defaultState = {};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.FETCH:
    case types.FETCH_SUCCESS:
      state = Object.assign({}, state, {...action.payload});
      break;
  }

  return state;
}
