import {types} from './AssignmentCreateActions';

export const defaultState = {
  pending: false,
  item: {},
  filterStr: '',
  showChecked: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.RESET_FORM:
      state = Object.assign({}, defaultState);
      break;
    case types.PENDING:
    case types.SET_TITLE:
    case types.SET_ITEM:
    case types.SET_DESCRIPTION:
    case types.SET_SOURCE_TYPE:
    case types.SET_ASSIGNEE:
    case types.SET_COMMENT:
    case types.SET_START_DATE:
    case types.SET_END_DATE:
    case types.SET_SOURCE:
    case types.SET_FILTER:
    case types.SHOW_CHECKED_TOGGLE:
      state = Object.assign({}, state, {...action.payload});
      break;
  }
  return state;
}
