import {types} from './ShiftsActions';
import {getInitialPeriod} from "../../../helpers/initialPeriod";

const {from, to} = getInitialPeriod(30);
const defaultState = {
  data: [],
  pending: false,
  from,
  to,
  timestamp: Date.now(),
  filterStr: '',
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.FETCH:
    case types.FETCH_PENDING:
    case types.FETCH_SUCCESS:
    case types.FETCH_ERROR:
    case types.SET_PERIOD:
    case types.SET_FILTER:
      state = Object.assign({}, state, {...action.payload});
      break;
  }

  return state;
}
