import {
  CHANGE_LAYER_TYPE,
  CHANGE_GEOZONE_TYPE,
  CHANGE_METEOSTATIONS_VISIBILITY,
} from './LayersSelectActions';
import { layerTypes, geozoneTypes } from './layerTypes';

const defaultState = {
  layerType: layerTypes.googleHybrid,
  geozoneType: geozoneTypes.all,
  showMeteostations: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CHANGE_GEOZONE_TYPE:
      state = Object.assign({}, state, { geozoneType: action.geozoneType });
      break;
    case CHANGE_LAYER_TYPE:
      state = Object.assign({}, state, { layerType: action.layerType });
      break;
    case CHANGE_METEOSTATIONS_VISIBILITY:
      state = Object.assign({}, state, { showMeteostations: action.showMeteostations });
      break;
  }

  return state;
}
