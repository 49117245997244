import {push} from 'react-router-redux';
import {createActions} from '../../../baseClasses/ActionFactory';
import TaskService from '../../../services/TaskService';
import {routes} from '../../../routes';
import {update} from '../MainActions';

const DELETE_TASK__SUCCESS = 'DELETE_TASK__SUCCESS';
const DELETE_TASK__ERROR = 'DELETE_TASK__ERROR';
const DELETE_TASK__PENDING = 'DELETE_TASK__PENDING';
const SET_PERIOD = 'SET_PERIOD__TASKS';

function onFetch(id, getState) {
  const {main: {task: {from, to}}} = getState();
  TaskService.fetch(from, to);
}

function setPeriod({from, to}) {
  return (dispatch) => {
    dispatch({
      type: SET_PERIOD,
      payload: {from, to, timestamp: Date.now()},
    });
  };
}

function onDeletingTask(taskId, params) {
  return async dispatch => {
    dispatch({
      type: DELETE_TASK__PENDING,
    });
    await TaskService.deleteTask(taskId, params);
  };
}

function deleteTaskSuccess() {
  return (dispatch, getState) => {
    const {main: {task: {from, to}}} = getState();

    dispatch({
      type: DELETE_TASK__SUCCESS,
    });
    dispatch(push(routes.task('')));
    TaskService.fetch(from, to, true);
    dispatch(update());
  };
}

function deleteTaskError(error) {
  return dispatch => {
    dispatch({
      type: DELETE_TASK__ERROR,
      payload: error
    });
  };
}

const {types: generalTypes, actions: generalActions} = createActions({store: 'TASKS', onFetch});
const allActions = {...generalActions, onDeletingTask, deleteTaskSuccess, deleteTaskError, setPeriod};
const allTypes = {...generalTypes, DELETE_TASK__PENDING, DELETE_TASK__SUCCESS, DELETE_TASK__ERROR, SET_PERIOD};
export {allTypes as types, allActions as actions};
