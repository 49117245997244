import get from 'lodash/get';
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import delay from "lodash/delay";
import moment from 'moment';
import VehicleService from "./VehicleService";
import httpClient from "@infobis/api-module";
import {url} from "../api";
import DepartmentService from "./DepartmentService";
import OperationService from "./OperationService";
import store from '../store/store';
import {actions} from '../components/Main/Tasks/TaskActions';
import {actions as createTaskActions} from '../components/Main/Tasks/CreateTask/CreateTaskActions';
import FieldService from './FieldService';
import AuthService from './AuthService';
import MainService from "./MainService";
import fetchStatus from '../const/fetchStatus';
import {Notification} from './../components/common/Notification';

class TaskService {
  _tasks = [];
  _status = '';
  fetchedFor = {};

  constructor() {
    window.TaskService = this;
  }
  // по умолчанию на текущую дату в верном формате.
  async fetch(from = moment(new Date()).format("YYYY-MM-DD") + "T00:00:00.000Z",
              to = moment(new Date()).format("YYYY-MM-DD") + "T00:00:00.000Z" ,
              force = false) {
    if (!force && isEqual(this.fetchedFor, {from, to})) {
      delay(() => store.dispatch(actions.fetchSuccess()), 0);
      return;
    }
    const department = DepartmentService.getCurrentDepartmentForRequest();
    const params = {
      method: 'GET',
    };
    const qs = {
      from,
      to,
      page: 1,
      start: 0,
      limit: 100000,
      ...department,
    };
    const user = AuthService.getUser();
    const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": "Bearer " + user.accessToken,
      "serverId": user.serverId
    };

    const groupAppointRequest = {url: url.groupAppointments, params, qs, headers, authorize: true};
    const appointRequest = {url: url.appointments, params, qs, headers, authorize: true};

    // store.dispatch(toggleLoader('task_list_fetch'));
    this._status = fetchStatus.PENDING;

    try {
      const groupAppResponse = await httpClient.makeRequest(groupAppointRequest);
      const appResponse = await httpClient.makeRequest(appointRequest);

      this._tasks = [...this.convertAppointments(get(groupAppResponse, 'data', {})), ...get(appResponse, 'data', {})];
      this._status = fetchStatus.DEFAULT;
      // store.dispatch(toggleLoader('task_list_fetch', true));
      store.dispatch(actions.fetchSuccess());

      FieldService._setListMap();
      MainService.dispatchChanges();
      this.fetchedFor = {from, to};
    }
    catch (error) {
      Notification.error(error);
    }
  }

  convertAppointments(appointments) {
    return appointments.map((appointment) => ({
      ...appointment,
      unitList: appointment.unitList.map((unit) => ({
        ...unit,
        fieldToolId: unit.fieldToolId === appointment.defaultFieldToolId
          ? -1
          : unit.fieldToolId
      }))
    }));
  }

  isPending() {
    return this._status === fetchStatus.PENDING;
  }

  reset() {
    this._tasks = [];
    this._status = fetchStatus.PENDING;
    this.fetchedFor = {};
    store.dispatch(createTaskActions.reset());
  }

  clear() {
    this.reset();
  }

  getTasksForVehicle(vehicleId) {
    const tasks = this._tasks
      .filter(task => task.unitList && !isEmpty(task.unitList
        .find(unit => Number(unit.unitId) === Number(vehicleId)))
      );
    tasks.forEach(task => task.operation = OperationService.getItem(task.operationId));
    return tasks;
  }

  getTasksForField(fieldId) {
    const tasks = this._tasks
      .filter(task => !isEmpty(task.fieldList && task.fieldList.find(field => Number(field.fieldId) === Number(fieldId))) || Number(task.fieldId) === Number(fieldId));
    tasks.forEach(task => task.operation = OperationService.getItem(task.operationId));
    return tasks;
  }

  getList() {
    const tasks = [...this._tasks];
    tasks.forEach(task => {
      task.operation = OperationService.getItem(task.operationId);
      task.unitList = task.unitList || [];
      task.vehicleList = !isEmpty(task.unitList) ? task.unitList.map(u => VehicleService.getItem(u.unitId)) : [VehicleService.getItem(task.unitId)];
      task.vehicleList = [...task.vehicleList].filter(x => !isEmpty(x));

      if (isEmpty(task.fileldList) && task.fieldId) {
        task.fieldList = [FieldService.getItem(task.fieldId)];
      }
    });
    return tasks.filter(t => !get(t, '_removed', false));
  }

  getItem(id) {
    return this.getList().find(t => t.id === id);
  }

  addDefaultFields(params) {
    params.geoRouteId = [];
    params.unitList.forEach(unit => unit.geoRouteId = []);
    return params;
  }

  _deleteLoadingType(params) {
    if (!isEmpty(params)) {
      !isEmpty(params.transportRoute) && params.transportRoute.transportRouteElements.forEach(el => {
        if (el.startEndRace) {
          delete el.loadingType
        }
      })
    }
  }

  async createTask(params) {
    const user = AuthService.getUser();
    params = this.addDefaultFields(params);

    this._deleteLoadingType(params);

    const request = {
      url: url.groupAppointments,
      params: {
        method: 'POST',
      },
      headers: {
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId
      },
      authorize: true,
      body: JSON.stringify(params)
    };

    try {
      const response = await httpClient.makeRequest(request, {errorMsg: 'Не удалось создать ежедневное задание'});
      if (response && response.success) {
        store.dispatch(createTaskActions.createTaskSuccess(response));
        return response.success;
      }
    } catch (error) {
      Notification.error(error);
      return null;
    }
  }

  async editTask(taskId, params) {
    if (!taskId) {
      return null;
    }
    params = this.addDefaultFields(params);
    const user = AuthService.getUser();

    this._deleteLoadingType(params);

    const request = {
      url: `${url.groupAppointments}/${taskId}`,
      params: {
        method: 'PUT',
      },
      headers: {
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId
      },
      authorize: true,
      body: JSON.stringify(params)
    };

    try {
      const response = await httpClient.makeRequest(request, {errorMsg: 'Не удалось отредактировать ежедневное задание'});
      if (response && response.success) {
        store.dispatch(createTaskActions.editTaskSuccess(response));
        return response.success;
      }
    } catch (error) {
      Notification.error(error);
      return null;
    }
  }

  async deleteTask(taskId, params) {
    if (!taskId) {
      return null;
    }
    params = this.addDefaultFields(params);
    const user = AuthService.getUser();
    const request = {
      url: `${url.groupAppointments}/${taskId}`,
      params: {
        method: 'DELETE',
      },
      headers: {
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId
      },
      authorize: true,
      body: JSON.stringify(params)
    };

    try {
      const response = await httpClient.makeRequest(request, {errorMsg: 'Удаление задания не удалось'});
      if (response && response.success) {
        const _task = this._tasks.find(task => task.id === taskId);
        _task._removed = true;
        store.dispatch(actions.deleteTaskSuccess());
        return response.success;
      }
    } catch (error) {
      Notification.error(error);
      return null;
    }
  }
}

export default new TaskService();
