import {localStorageItems} from "./LocalstorageService";

class MapService {

  saveMapPosition(center, zoom) {
    const mapPositon = {
      center,
      zoom
    };
    localStorage.setItem(localStorageItems.mapPosition, JSON.stringify(mapPositon));
  }

  loadMapPosition() {
    const mapPosition = localStorage.getItem(localStorageItems.mapPosition);
    if (mapPosition && mapPosition !== 'undefined') {
      return JSON.parse(mapPosition);
    } else {
      return null;
    }
  }


  reset() {
    localStorage.removeItem(localStorageItems.mapPosition);
  }
}

export default new MapService();
