import {createActions} from "../../../baseClasses/ActionFactory";
import {createTreeActions} from '../../../baseClasses/TreeActionFactory';

export const TOGGLE_VIEW__PLANS = 'TOGGLE_VIEW__PLANS';

export function toggleViewState(viewCulture) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_VIEW__PLANS,
      payload: {viewCulture},
    });
  };
}

const {types: generalTypesByWork, actions: generalActionsByWork} = createActions({store: 'PLANS_BY_WORK'});
const {types: treeTypesByWork, actions: treeActionsByWork} = createTreeActions({store: 'PLANS_BY_WORK'});
const allActionsByWork = {...generalActionsByWork, ...treeActionsByWork, toggleViewState};
const allTypesByWork = {...generalTypesByWork, ...treeTypesByWork, TOGGLE_VIEW__PLANS};

const {types: generalTypesByCulture, actions: generalActionsByCulture} = createActions({store: 'PLANS_BY_CULTURE'});
const {types: treeTypesByCulture, actions: treeActionsByCulture} = createTreeActions({store: 'PLANS_BY_CULTURE'});
const allActionsByCulture = {...generalActionsByCulture, ...treeActionsByCulture, toggleViewState};
const allTypesByCulture = {...generalTypesByCulture, ...treeTypesByCulture, TOGGLE_VIEW__PLANS};

export {allTypesByCulture as typesByCulture, allActionsByCulture as actionsByCulture};
export {allTypesByWork as typesByWork, allActionsByWork as actionsByWork};
