import uniq from 'lodash/uniq';
import {actions as wActions} from '../WeightedActions';

export const types = {
  SET_PERIOD: 'SET_PERIOD__WEIGHTED_FILTER',
  OPERATION_ADD: 'OPERATION_ADD__WEIGHTED_FILTER',
  OPERATION_REMOVE: 'OPERATION_REMOVE__WEIGHTED_FILTER',
  OPERATION_ADD_BULK: 'OPERATION_ADD_BULK__WEIGHTED_FILTER',
  OPERATIONS_SET_BULK: 'OPERATIONS_SET_BULK__WEIGHTED_FILTER',
};

export const actions = {
  setPeriod: function ({from, to}) {
    return dispatch => {
      dispatch(wActions.setPeriod({from, to}));
      dispatch({
        type: types.SET_PERIOD,
      });
    };
  },
  operationSetBulk: function (allOperations) {
    return (dispatch) => {
      dispatch({
        type: types.OPERATIONS_SET_BULK,
        payload: {allOperations}
      });
      dispatch(actions.operationAddBulk(allOperations));
    };
  },
  operationAddBulk: function (bulkOperations) {
    return (dispatch, getState) => {
      const {main: {weightedFilter: {operations: prevOperations}}} = getState();
      const operations = uniq([...prevOperations, ...bulkOperations]);
      dispatch({
        type: types.OPERATION_ADD_BULK,
        payload: {operations}
      });
    };
  },
  operationAdd: function (operation) {
    return (dispatch, getState) => {
      const {main: {weightedFilter: {operations: prevOperations}}} = getState();
      const operations = [...prevOperations, operation];

      dispatch({
        type: types.OPERATION_ADD,
        payload: {operations}
      });
    };
  },
  operationRemove: function (operation) {
    return (dispatch, getState) => {
      const {main: {weightedFilter: {operations: prevOperations}}} = getState();
      const operations = [...prevOperations].filter(o => o !== operation);

      dispatch({
        type: types.OPERATION_REMOVE,
        payload: {operations}
      });
    };
  },
};
