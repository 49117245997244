//TODO: remove debug methods
//timestamps for debugging
import moment from "moment/moment";
import {WebsocketService} from "../services/WebsocketService";

let last = 0;
const t = [];
window.tbl = () => {
  console.clear();
  console.table(t, ['msg', 'ts']);
};
window.ts = (msg, show = true) => {
  const m = {msg};
  if (show) {
    m.ts = Date.now() - last;
    m.ts = m.ts > 10000000 ? 'start' : m.ts;
  }
  t.push(m);
  last = new Date();
  return m.ts;
};
window.updatingOn = true;
window.toggleUpdate = () => {
  window.updatingOn = !window.updatingOn;
};
window.workPeriod = () => {
  return window.workPeriodMs ? new Date(window.workPeriodMs).toISOString() : new Date();
};
window.setWorkPeriod = (dateStr) => {
  const m = moment(dateStr, 'DD.MM.YYYY HH:mm');
  window.workPeriodMs = m.valueOf();
  WebsocketService.start({id: window.cid});
};
window.setWorkPeriodByUpdate = (value) => {
  window.workPeriodMs = value;
};


export const isDigitalAgro = () => {
  return location.hostname.toLowerCase().indexOf('digitalagro') > -1;
  // return location.hostname.toLowerCase().indexOf('localhost') > -1;
};
