import Tree from "../treejs/Tree";
import {url} from "../api/index";
import httpClient from "@infobis/api-module";
import AuthService from "./AuthService";
import {parseTree} from "../helpers/parseTree";
import {Notification} from './../components/common/Notification';

class UserService {
  _tree = Tree.empty();
  _glidMap = {};
  _fetchListeners = [];

  constructor() {
    window.UserService = this;
  }

  async fetch() {
    const user = AuthService.getUser();

    const request = {
      url: url.assignmentUsers,
      params: {
        method: 'GET',
      },
      authorize: true,
      qs: {
        page: 1,
        start: 0,
        limit: 500,
      },
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "serverId": user.serverId,
        "Authorization": "Bearer " + user.accessToken,
      },
    };
    try {
      const response = await httpClient.makeRequest(request);
      if (response && response.success && response.data) {
        this._buildTree(response.data);
        for (const cb of this._fetchListeners) {
          cb();
        }
      }
    } catch (error) {
      Notification.error(error);
    }
  }

  _buildTree(data) {
    const getGlid = n => n.data.id;
    this._usersTree = parseTree(data, getGlid);
    this._usersTree.updateTimestamp();
    this._setGlidMap();
  }

  _setGlidMap() {
    const map = {};
    let data;

    for (const i of this._usersTree.leaves()) {
      data = i.data;
      if (i.parent()) {
        data.groupName = i.parent().data.name;
      }
      map[i.data.id] = data;
    }
    this._glidMap = map;
  }

  getItem(id) {
    const item = this._glidMap[id];
    return item || {};
  }

  getTree() {
    return this._tree;
  }

  reset() {
    this._tree = Tree.empty();
    this._glidMap = {};
  }

  addFetchListener(cb) {
    this._fetchListeners = [...this._fetchListeners, cb];
  }
}

export default new UserService();
