import {combineReducers} from 'redux';
import search from './Search/SearchReducer';
import fields from './Fields/FieldsReducer';
import meteostations from './Meteostations/MeteostationsReducer';
import meteostation from './Meteostations/Meteostation/MeteostationReducer';
import employees from './Employees/EmployeesReducer';
import vehicles from './Vehicles/VehiclesReducer';
import vehicleFilter from './Vehicles/VehicleFilter/VehicleFilterReducer';
import department from './Department/DepartmentReducer';
import field from './Fields/Field/FieldReducer';
import vehicle from './Vehicles/Vehicle/VehicleReducer';
import actions from './Actions/ActionsReducer';
import action from './Actions/Action/ActionReducer';
import operations from './Operations/OperationsReducer';
import fieldWorkList from './Fields/Field/FieldWorkList/FieldWorkReducer';
import vehicleWorkList from './Vehicles/VehicleWorkList/VehicleWorkReducer';
import warnings from './Warnings/WarningsReducer';
import warning from './Warnings/Warning/WarningReducer';
import fieldWork from './FieldWork/WorkReducer';
import vehicleWork from './VehicleWork/WorkReducer';
import workShiftList from './WorkShift/WorkShiftReducer';
import workShift from './WorkShift/Shift/ShiftReducer';
import mapCanvas from './Map/MapCanvas/MapCanvasReducer';
import map from './Map/MapReducer';
import layersPref from './Map/LayersSelect/LayersSelectReducer'
import createTask from './Tasks/CreateTask/CreateTaskReducer';
import task from './Tasks/TaskReducer';
import taskFilter from './Tasks/TaskFilter/TaskFilterReducer';
import transportRoutes from './TransportRoutes/TransportRoutesReducer';
import geoZoneLoadingTypes from './GeoZoneLoadingTypes/GeoZoneLoadingTypesReducer';
import goods from './Goods/GoodsReducer';
import goodsTmc from './GoodsTMC/GoodsReducer';
import goodsItem from './GoodsTMC/GoodsItem/GoodsItemReducer';
import goodsItemEdit from './GoodsTMC/GoodsItemEdit/GoodsItemEditReducer';
import plans from './Plans/PlansReducer';
import planFact from './Plans/PlanFact/PlanFactReducer';
import notes from './Notes/NotesReducer';
import noteCreate from './Notes/CreateNote/CreateNoteReducer';
import track from './Track/TrackReducer';
import trackFilter from './Track/TrackFilter/TrackFilterReducer';
import weighted from './Weighted/WeightedReducer';
import weightedFilter from './Weighted/WeightedFilter/WeightedFilterReducer';
import weightedCard from './Weighted/WeightedCard/WeightedCardReducer';
import harvesterUpload from './HarvesterUpload/HarvesterUploadReducer';
import harvesterUploadCard from './HarvesterUpload/HarvesterUploadCard/HarvesterUploadCardReducer';
import harvesterUploadFilter from './HarvesterUpload/HarvesterUploadFilter/HarvesterUploadReducer';
import tractorRecord from './TractorRecord/TractorRecordReducer'
import assignments from './Assignment/AssignmentsReducer';
import assignmentFilter from './Assignment/AssignmentFilter/AssignmentFilterReducer';
import assignmentCard from './Assignment/Assignment/AssignmentCardReducer';
import reportEdit from './Assignment/Report/ReportReducer';
import assignmentEdit from './Assignment/AssignmentCreate/AssignmentCreateReducer';
import shifts from './Shifts/ShiftsReducer';
import shiftCreate from './Shifts/CreateShift/ShiftCreateReducer';
import shiftFilter from './Shifts/ShiftFilter/ShiftFilterReducer';
import shiftCard from './Shifts/ShiftCard/ShiftCardReducer';
import scheduleList from './Shifts/ScheduleList/ScheduleListReducer';
import modal from './Modal/ModalReducer';

import {
  MENU_OPEN,
  MENU_CLOSE,
  LOADER_TOGGLE,
  UPDATE,
  CONNECTION_ON,
} from './MainActions';

const defaultState = {
  menuOpened: false,
  loadersActive: [],
  connection: true,
  isModalOpened: false,
  modalConfig: null,
};

const main = (state = defaultState, action) => {
  switch (action.type) {
    case MENU_OPEN:
      state = Object.assign({}, state, {menuOpened: true});
      break;
    case MENU_CLOSE:
      state = Object.assign({}, state, {menuOpened: false});
      break;
    case LOADER_TOGGLE:
      state = Object.assign({}, state, {loadersActive: action.payload.loadersActive});
      break;
    case UPDATE:
      state = Object.assign({}, state, {...action.payload});
      break;
    case CONNECTION_ON:
      state = Object.assign({}, state, {...action.payload});
  }

  return state;
};

export default combineReducers({
  main,
  search,
  department,
  fields,
  meteostations,
  meteostation,
  employees,
  vehicles,
  field,
  vehicle,
  actions,
  operations,
  fieldWorkList,
  vehicleWorkList,
  fieldWork,
  vehicleWork,
  warnings,
  warning,
  mapCanvas,
  map,
  workShiftList,
  workShift,
  action,
  createTask,
  task,
  taskFilter,
  transportRoutes,
  geoZoneLoadingTypes,
  goods,
  goodsTmc,
  goodsItem,
  goodsItemEdit,
  vehicleFilter,
  plans,
  planFact,
  notes,
  noteCreate,
  track,
  trackFilter,
  weighted,
  weightedCard,
  weightedFilter,
  harvesterUpload,
  harvesterUploadCard,
  harvesterUploadFilter,
  tractorRecord,
  assignments,
  assignmentFilter,
  assignmentCard,
  reportEdit,
  assignmentEdit,
  shifts,
  shiftCreate,
  shiftFilter,
  shiftCard,
  scheduleList,
  layersPref,
  modal
});
