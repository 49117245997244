/* eslint-disable import/default */
import React, {} from 'react';
import {render} from 'react-dom';
import {AppContainer} from 'react-hot-loader';
import store from './store/store';
import {history} from './store/configureStore';
import Root from './components/Root/Root';
import { isDigitalAgro } from './helpers/utils';
require('./favicon.ico');

const title = document.querySelector('head title');
if (isDigitalAgro()) {
  const app = document.getElementById('app');
  app.classList.add('digitalAgro');
  title.text = 'DigitalAgro';
} else {
  title.text = 'Agrosignal';
}

render(
    <AppContainer>
      <Root store={store} history={history}/>
    </AppContainer>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./components/Root/Root', () => {
    const NewRoot = require('./components/Root/Root').default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history}/>
      </AppContainer>,
      document.getElementById('app')
    );
  });
}
