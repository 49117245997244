import {types} from './WeightedFilterActions';

const defaultState = {
  operations: [],
  allOperations: []
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.SET_PERIOD:
    case types.OPERATION_ADD:
    case types.OPERATION_REMOVE:
    case types.OPERATIONS_SET_BULK:
    case types.OPERATION_ADD_BULK:
      state = Object.assign({}, state, {...action.payload});
      break;
  }

  return state;
}
