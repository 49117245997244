import {createActions} from '../../../../../baseClasses/ActionFactory';
import FieldWorkService from '../../../../../services/FieldWorkService';
import OperationService from  '../../../../../services/OperationService';

export const FIELD_WORK_LIST_SHAPE_LOADED = 'FIELD_WORK_LIST_SHAPE_LOADED';
export const FIELD_WORK_LIST_SHAPE_CLEAR = 'FIELD_WORK_LIST_SHAPE_CLEAR';

function onFetch(id) {
  FieldWorkService.fetch(id);
}

function selectWork({operationId, dateEnd, color, field}) {
  return async (dispatch, getState) => {
    const shape = await OperationService.getOperationShape(field.glid, operationId, dateEnd);
    dispatch({
      type: FIELD_WORK_LIST_SHAPE_LOADED,
      payload: {
        shape,
        operationId,
        dateEnd,
        workColor: color
      },
    });
  };
}

function clearSelectedWork() {
  return async (dispatch, getState) => {
    dispatch({
      type: FIELD_WORK_LIST_SHAPE_CLEAR,
      payload: {
        shape: undefined,
        operationId: undefined,
        dateEnd: undefined,
        workColor: undefined
      }
    })
  };
}

let {types, actions} = createActions({store: 'FIELD_WORK_LIST', onFetch});
actions.selectWork = selectWork;
actions.clearSelectedWork = clearSelectedWork;
export {types, actions};
