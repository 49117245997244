export const mapValuesForKeys = (src, params) => {
  const res = {};
  for (const key of params) {
    if (typeof key === 'string') {
      res[key] = src[key];
    } else if (typeof key === 'object') {
      res[key.key] = key.value;
    }
  }
  return res;
};
