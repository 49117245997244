import { routes } from "../../../../routes";
import { push } from 'react-router-redux';

import {createActions} from "../../../../baseClasses/ActionFactory";
import {changeLayerType} from "../../Map/LayersSelect/LayersSelectActions";

import { featureSelectOutside } from "../../Map/MapActions";

const SELECT_THERMBAR = 'SELECT_THERMBAR';

const {types, actions} = createActions({store: 'METEOSTATION'});
types.SELECT_THERMBAR = SELECT_THERMBAR;

function selectThermBar(thermalBarName) {
  return (dispatch, getState) => {
    const {meteostation: {meteostation: {thermalBar: thermalBar}}} = getState().main;
    const thermBarPayLoad = {...thermalBar, thermalBarName: thermalBarName.type};
    dispatch({
      type: SELECT_THERMBAR,
      payload: {meteostation: {thermalBar: thermBarPayLoad}},
    });
  };
}

function dispFeatureSelectOut(dispatch, props) {
  dispatch(featureSelectOutside({
    ...props,
    type: "meteostation",
  }));
}

function gotoMap(props, viewport) {
  return dispatch => {
    dispatch(push(routes.map));
    dispFeatureSelectOut(dispatch, props, viewport)
  };
}


Object.assign(actions, {changeLayerType}, {selectThermBar}, {gotoMap});

export {types, actions};
