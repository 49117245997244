import {types} from './AssignmentActions';

const defaultState = {
  pending: true,
  filterStr: '',
  timestamp: Date.now(),
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.RESET_STATE:
      state = Object.assign({}, defaultState);
      break;
    case types.FILTER:
    case types.FETCH:
    case types.FETCH_PENDING:
    case types.FETCH_SUCCESS:
    case types.FETCH_ERROR:
    case types.LIST_UPDATED:
    case types.FETCH_NO_PENDING:
      state = Object.assign({}, state, {...action.payload});
  }
  return state;
}
