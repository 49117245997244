import httpClient from '@infobis/api-module';
import {Notification} from './../components/common/Notification';
import {url} from '../api/index';

export class AddressService {
  async getAddress(coordinates, onSuccess) {
    const geoUrl = url.geocoder;
    const request = {
      url: geoUrl,
      params: {
        method: 'GET',
        mode: undefined,
        credentials: undefined,
      },
      qs: {
        lat: JSON.stringify(coordinates[0]),
        lon: JSON.stringify(coordinates[1])
      },
    };

    try {
      const address = await httpClient.makeRequest(request);
      if (address) {
        onSuccess(address.address);
      }
    }

    catch (error) {
      Notification.error(error);
    }
  }
}

export default new AddressService();
