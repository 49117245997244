import {push} from 'react-router-redux';
import routes from '../../routes';
import AuthService from '../../services/AuthService';
import {toggleMenu} from '../Main/MainActions';

export const LOADER_LOGIN = 'AUTHORIZE_LOADER_LOGIN';
export const AUTHORIZE_LOADER_CLEAR = 'AUTHORIZE_LOADER_CLEAR';
export const AUTHORIZE_PENDING = 'AUTHORIZE_PENDING';
export const AUTHORIZE_SUCCESS = 'AUTHORIZE_SUCCESS';
export const AUTHORIZE_ERROR = 'AUTHORIZE_ERROR';
export const AUTHORIZE_LOGIN_ENTER = 'AUTHORIZE_LOGIN_ENTER';
export const AUTHORIZE_PASSWORD_ENTER = 'AUTHORIZE_PASSWORD_ENTER';
export const AUTHORIZE_LOGOUT = 'AUTHORIZE_LOGOUT';

export function authorize(username, password) {
  return async dispatch => {
    dispatch({
      type: AUTHORIZE_PENDING
    });
    await AuthService.authorize(username, password);
  };
}

export function authorizeSuccess(user) {
  return dispatch => {
    dispatch({
      type: AUTHORIZE_SUCCESS,
      payload: user,
    });
    dispatch(push(routes.home));
    dispatch(toggleMenu());
  };
}

export function authorizeError(error) {
  return dispatch => {
    dispatch({
      type: AUTHORIZE_ERROR,
      payload: error
    });
  };
}

export function loginChange(login) {
  return dispatch => {
    dispatch({
      type: AUTHORIZE_LOGIN_ENTER,
      payload: login
    });
  };
}

export function passwordChange(password) {
  return dispatch => {
    dispatch({
      type: AUTHORIZE_PASSWORD_ENTER,
      payload: password
    });
  };
}

export function logOut() {
  return dispatch => {
    dispatch({
      type: AUTHORIZE_LOGOUT
    });
    dispatch(push(routes.login));
  };
}

export function clearLoader() {
  return dispatch => {
    dispatch({
      type: AUTHORIZE_LOADER_CLEAR
    });
  };
}
