import immutable from 'immutable';
import {
  VEH_STATUS as vehicleStatus, statusTitleMap, statusColorMap,
  VEH_TYPE as vehicleType, typeTitleMap,
} from '../../../../const/vehicleConstants';
import { types } from './VehicleFilterActions';

const defaultState = {
  status: {},
  type: {},
};

Object.values(vehicleStatus).map(status => {
  defaultState.status[status] = {
    title: statusTitleMap[status].toLowerCase(),
    color: statusColorMap[status],
    isActive: true,
    isHide: true,
  };
});

Object.values(vehicleType).map(type => {
  defaultState.type[type] = {
    title: typeTitleMap[type].toLowerCase(),
    isActive: true,
    isHide: true,
  };
});

defaultState.status[vehicleStatus.NO_DEVICE].isActive = false;
defaultState.status[vehicleStatus.OFF].isActive = false;
defaultState.status[vehicleStatus.ANY].isHide = true;
defaultState.type[vehicleType.WAREHOUSE].isActive = false;

export default function (state = immutable.fromJS(defaultState), action) {
  switch (action.type) {
    case types.VEHICLE_FILTER__STATUS:
      state = state.setIn(['status', action.status, 'isActive'], action.isActive);
      break;
    case types.VEHICLE_FILTER__HIDE_STATUS:
      state = state.setIn(['status', action.status, 'isHide'], action.isHide);
      break;
    case types.VEHICLE_FILTER__TYPE:
      state = state.setIn(['type', action.vehicleType, 'isActive'], action.isActive);
      break;
    case types.VEHICLE_FILTER__HIDE_TYPE:
      state = state.setIn(['type', action.vehicleType, 'isHide'], action.isHide);
      break;
    case types.VEHICLE_FILTER__RESET:
      state = immutable.fromJS(defaultState);
      break;
  }
  return state;
}
