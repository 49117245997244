import immutable from 'immutable';
import {types} from './GoodsItemEditActions';

const defaultState = {};

export default function (state = immutable.fromJS(defaultState), action) {
  switch (action.type) {
    case types.FETCH:
    case types.FETCH_PENDING:
    case types.FETCH_ERROR:
    case types.FETCH_SUCCESS:
      state = Object.assign({}, state, {...action.payload});
      break;
  }
  return state;
}
