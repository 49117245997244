import React, { Component, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute } from  './PrivateRoute';
import routes from '../../routes';
import MainLoader from './../Main/MainLoader/MainLoader';
import loadable from 'react-loadable';

const Main = () => { return import('../Main/MainContainer')}
const Login = () => { return import('../Login/LoginContainer')}
const Department = () => { return import('../Main/Department/Department')}


const AsyncDepartmentComponent = loadable({
    loader: Department,
    loading: () => <MainLoader/>
});

const AsyncLoginComponent = loadable({
  loader: Login,
  loading: () => <MainLoader/>
});

const AsyncMainComponent = loadable({
  loader: Main,
  loading: () => <MainLoader/>
});

const Routes = () => {
  return (

    <Suspense fallback={<MainLoader/>}>
      <Switch>
        <PrivateRoute path={routes.home} component={AsyncMainComponent}/>
        <PrivateRoute exact path={routes.department} component={AsyncDepartmentComponent}/>
        <Route path={routes.login} component={AsyncLoginComponent}/>
        <Redirect from="/*" to={routes.home}/>
      </Switch>
    </Suspense>

  )
}

export default Routes;
