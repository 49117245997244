import isEmpty from 'lodash/isEmpty';
import {url} from '../api';
import BaseDataService, {responseDataShape} from '../baseClasses/BaseDataService';
import {actions} from '../components/Main/FieldTools/FieldToolsActions';

class FieldToolService extends BaseDataService {
  _listCache = [];

  constructor() {
    super({urlAll: url.fieldTools, ...actions, dataShape: responseDataShape.ARRAY});
    window.FieldToolService = this;
  }

  getItem(id) {
    return isEmpty(this.data) ? {} : this.data.find(x => Number(x.id) === Number(id));
  }

  getListWithDefault() {
    const defaultItem = {
      id: -1,
      title: 'По умолчанию',
      key: 'default',
    };

    const items = this.getList();

    return [defaultItem].concat(items);
  }
}

export default new FieldToolService();
