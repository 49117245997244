import immutable from 'immutable';

import {types} from './DepartmentActions';

const defaultState = {
  currentDepartment: {},
  pending: true,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.FETCH_SUCCESS:
    case types.SET_DEPARTMENT:
      state = Object.assign({}, state, {...action.payload});
      break;
  }
  return state;
}
