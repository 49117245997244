export const parseFolderPath = (folderStr, withSelf = false, withRoot = false) => {
  if (!folderStr) {
    return '';
  }
  let parts = folderStr.split('->');
  // Отсекает рандомную часть пути (добавленную, для того, чтобы в дереве не перетирались элементы с одинаковыми названиями)
  parts = parts.map(part => part.split('@@')[0]);
  parts.shift();
  if (!withSelf) {
    parts.pop();
  }
  if (withRoot) {
    parts.unshift('Все');
  }
  return parts.reverse().join(' — ');
};

export const currentFolder = folderStr => {
  if (!folderStr) {
    return '';
  }
  let parts = folderStr.split('->');
  // Отсекает рандомную часть пути (добавленную, для того, чтобы в дереве не перетирались элементы с одинаковыми названиями)
  parts = parts.map(part => part.split('@@')[0]);
  
  return parts.pop() || '';
};
