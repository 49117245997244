import isEmpty from 'lodash/isEmpty';
import httpClient from '@infobis/api-module';
import store from '../store/store';
import { authorizeSuccess, authorizeError, clearLoader, logOut } from '../components/Login/LoginActions';
import { url } from '../api';
import MainService from './MainService';
import { localStorageItems } from "./LocalstorageService";
import get from "lodash/get";
import intersection from "lodash/intersection";
import { ROLE } from "../const/roles";
import { Notification } from './../components/common/Notification';
import { b64EncodeUnicode } from './../helpers/passwordEncrypt'
export class AuthService {

  constructor() {
    window.AuthService = this;
  }

  async authorize(username, password) {
    const request = {
      url: url.auth.login,
      params: {
        method: 'POST',
      },
      body: JSON.stringify({
        login: username,
        password: password
      }),
      authorize: false
    };

    try {
      const response = await httpClient.makeRequest(request);
      if (response) {
        localStorage.removeItem('isManualLastLogout')
        this._saveUser(response);
        this.setIsLoggedIn();
        store.dispatch(authorizeSuccess(response || {}));
        this.saveCredentials(username, password)
      }
    } catch (error) {
      store.dispatch(authorizeError(error || {}));
      Notification.error(error);
    }
  }


  saveCredentials(username, password) {
    localStorage.setItem('credentials', JSON.stringify({
      username,
      password: b64EncodeUnicode(password)
    }))
  }

  isLoggedIn() {
    return localStorage.getItem(localStorageItems.isLoggedIn) === 'true';
  }

  clearLoader() {
    store.dispatch(clearLoader());
  }

  setIsLoggedIn() {
    localStorage.setItem(localStorageItems.isLoggedIn, true);
  }

  canEdit() {
    const user = this.getUser();
    if (!user || user === 'undefined') {
      return false;
    }
    return user.userInfo && user.userInfo.roles &&
      (!isEmpty(user.userInfo.roles.find(i => i === ROLE.superAdmin)) || isEmpty(user.userInfo.roles.find(i => i === ROLE.readOnlyUser)));
  }

  getUser() {
    const localStorageUser = localStorage.getItem(localStorageItems.lastLogin);
    if (localStorageUser && localStorageUser !== 'undefined') {
      return JSON.parse(localStorageUser);
    }
    return null;
  }

  logOut() {
    localStorage.setItem(localStorageItems.isLoggedIn, false);
    localStorage.removeItem(localStorageItems.lastLogin);
    localStorage.removeItem(localStorageItems.mapPosition);
    localStorage.removeItem(localStorageItems.errorLogs);
    MainService.clearAll();
    store.dispatch(logOut());
  }

  hasRoles(roles) {
    const depRoles = get(this.getUser(), 'userInfo.depRoles', []);
    return !isEmpty(intersection(depRoles, roles));
  }

  _saveUser(user) {
    localStorage.setItem(localStorageItems.lastLogin, JSON.stringify(user));
  }
}

export default new AuthService();
