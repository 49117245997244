import {createActions} from "../../../../baseClasses/ActionFactory";
import WarningService from "../../../../services/WarningService";

function onFetch({id, hash}) {
  WarningService.fetch(id);

  return {vehicleId: id, hash};
}

const {types, actions} = createActions({store: 'WARNING', onFetch});
export {types, actions};
