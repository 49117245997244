import {url} from '../api';
import BaseDataService, {responseDataShape} from '../baseClasses/BaseDataService';
import {actions} from '../components/Main/LimitSpeeds/LimitSpeedsActions';
import httpClient from "@infobis/api-module";
import AuthService from './AuthService';
import {Notification} from './../components/common/Notification';

class LimitSpeedService extends BaseDataService {
  _listCache = [];

  constructor() {
    super({urlAll: url.limitSpeedNames, ...actions, dataShape: responseDataShape.ARRAY});
    window.LimitSpeedService = this;
  }

  async fetch() {
    const user = AuthService.getUser();
    const request = {
      url: this._urlAll,
      params: {
        method: 'GET'
      },
      headers: {
        "Authorization": "Bearer " + user.accessToken,
        "serverId" : user.serverId
      },
      authorize: true,
    };

    if (this._isFetching) {
      return;
    }

    this._isFetching = true;

  try {
      const response = await httpClient.makeRequest(request);
      if (response) {
        this._parseFetchResponse({data: response});
      }
    } catch (error) {
      Notification.error(error);
    }
  }

  _setGlidMapForArray() {
    const map = {};
    for (const i of this.data) {
      map[i.limitSpeedId] = i;
    }
    this._glidMap = map;
    this._lastUpdated = Date.now();
  }
}

export default new LimitSpeedService();
