const url = {
  auth: {
    login: '/auth/login',
    refresh: '/auth/refresh'
  },
  field: '/fieldTree',
  vehicle: '/unitTree',
  employee: '/peopleTree',
  meteostations: '/meteostationsTree',
  plans: '/scheduleTree',
  department: '/clientsTree',
  setDepartment: '/setSelectedCompany',
  operations: '/operations',
  reportData: '/data/reportData',
  cultivationDetails: '/getCultivationDetails',
  groupAppointments: '/groupAppointments',
  appointments: '/appointments',
  fieldTools: '/fieldTools',
  limitSpeedNames: '/limitSpeedNames',
  fieldOperationShape: '/fieldOpShape',
  transportRoute: '/transportRoute',
  geoZoneLoadingTypes: '/geoZoneLoadingTypes',
  notes: '/notes',
  inventoryItems: '/inventoryItems',
  companies: '/companies',
  unitTrack: '/unitTrack',
  fileInfo: '/storage/files',
  fileUpload: '/storage/upload?sourceType=agrosignal',
  assignment: '/issues',
  formTemplates: '/form-templates',
  assignmentCommit: '/issues/commit',
  assignmentUsers: '/users',
  weighBridgeTree: '/weighBridgeTree',
  shiftTime: '/shiftTime',
  units: '/units',
  weekend: '/noWorkReasons',
  calendarDriver: '/calendarDriver',
  departments: '/departments',
  geocoder: '/geo/geocode2',
  harmfulObject: '/dictionary/harmfulObject/findByCropsAndType',
  cropBaseIds: '/cropBaseIds',
};

export {url};
