import {createActions} from "../../../baseClasses/ActionFactory";
import AssignmentService from '../../../services/AssignmentService';
import {createTreeActions} from "../../../baseClasses/TreeActionFactory";

const LIST_UPDATED = 'LIST_UPDATED__ASSIGNMENTS';
const RESET_STATE = 'RESET_STATE__ASSIGNMENTS';
const FETCH_NO_PENDING = 'FETCH_NO_PENDING__ASSIGNMENTS';

function onFetch() {
  AssignmentService.fetch();
}

function fetchNoPending() {
  return dispatch => {
    AssignmentService.fetch();
    dispatch({
      type: FETCH_NO_PENDING,
      payload: {pending: false},
    });
  };
}

function listUpdated() {
  return dispatch => {
    dispatch({
      type: LIST_UPDATED,
      payload: {timestamp: Date.now()},
    });
  };
}

function resetState() {
  return dispatch => {
    dispatch({
      type: RESET_STATE,
    });
  };
}

const {types: generalTypes, actions: generalActions} = createActions({store: 'ASSIGNMENT', onFetch});
const {types: treeTypes, actions: treeActions} = createTreeActions({store: 'ASSIGNMENT'});

const allTypes = {...generalTypes, ...treeTypes, LIST_UPDATED, FETCH_NO_PENDING, RESET_STATE};
const allActions = {...generalActions, ...treeActions, listUpdated, fetchNoPending, resetState};
export {allActions as actions, allTypes as types};
