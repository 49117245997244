import {createActions} from "../../../baseClasses/ActionFactory";
import ShiftService from '../../../services/ShiftService';

const SET_PERIOD = 'SET_PERIOD__SHIFTS';
const SET_FILTER = 'SET_FILTER__SHIFTS';
const DELETE_SHIFT = 'DELETE_SHIFT__SHIFTS';

function onFetch(id, getState) {
  const {main: {shifts: {from, to}}} = getState();
  ShiftService.fetch(from, to);
}

function deleteShift(id) {
  return async (dispatch, getState) => {
    const {shifts: {from, to}} = getState().main;
    dispatch({
      type: DELETE_SHIFT,
    });
    await ShiftService.deleteShift(id);
    ShiftService.fetch(from, to, true);
  };
}

function setPeriod({from, to}) {
  return (dispatch) => {
    dispatch({
      type: SET_PERIOD,
      payload: {from, to, timestamp: Date.now()},
    });
  };
}

function setFilterStr(filterStr) {
  return dispatch => {
    dispatch({
      type: SET_FILTER,
      payload: {filterStr},
    });
  };
}

const {types: typesPre, actions: actionsPre} = createActions({store: 'SHIFTS', onFetch});
const actions = {...actionsPre, setPeriod, setFilterStr, deleteShift};
const types = {...typesPre, SET_PERIOD, SET_FILTER, DELETE_SHIFT};

export {actions, types};
