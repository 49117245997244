export const assignmentStatus = {
  open: 'open',
  progress: 'progress',
  inCheck: 'inCheck',
  complete: 'complete',
  failed: 'failed',
};

export const stateNameMap = {
  open: 'Созданные',
  progress: 'В работе',
  inCheck: 'На проверке',
  complete: 'Закрытые',
  failed: 'Незаконченные в срок',
};

export const stateHistoryTitle = {
  open: 'Открыто',
  progress: 'В работе',
  inCheck: 'Отчет',
  complete: 'Закрыто',
};

export const stateUnitNameMap = {
  open: 'Создано',
  progress: 'В работе',
  inCheck: 'На проверке',
  complete: 'Закрыто',
  failed: 'В работе',
};

export const stateOrderMap = {
  open: 0,
  progress: 1,
  inCheck: 2,
  failed: 3,
  complete: 4,
};

export const assigneeTypesDict = {
  asAssignee: 'Поручены мне',
  asCreator: 'Созданные мной',
};

export const assigneeTypes = [assigneeTypesDict.asCreator, assigneeTypesDict.asAssignee];
