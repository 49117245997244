import {types} from './VehicleWorkActions';

const defaultState = {};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.FETCH:
    case types.FETCH_DATA:
    case types.FETCH_PENDING:
    case types.FETCH_SUCCESS:
    case types.FETCH_ERROR:
      state = Object.assign({}, state, {...action.payload});
      break;
  }

  return state;
}
