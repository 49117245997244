import {createActions} from "../../../../baseClasses/ActionFactory";
import ActionService from "../../../../services/ActionService";

export const FETCH_UPDATE__ACTION = 'FETCH_UPDATE__ACTION';

function onFetch(id) {
  ActionService.fetch(id);
}

export function update() {
  return (dispatch) => {
    dispatch({
      type: FETCH_UPDATE__ACTION,
    });
  };
}

const {types, actions} = createActions({store: 'ACTION', onFetch});
export {types, actions};
