import {createActions} from "../../../baseClasses/ActionFactory";
import ActionService from '../../../services/ActionService';

export const FETCH_UPDATE__ACTIONS = 'FETCH_UPDATE__ACTIONS';

function onFetch(id) {
  ActionService.fetch(id);
}

export function update() {
  return (dispatch) => {
    dispatch({
      type: FETCH_UPDATE__ACTIONS,
    });
  };
}

const {types, actions} = createActions({store: 'ACTIONS', onFetch});
export {actions, types};
