import NoteService from "../../../../services/NoteService";
import { actions as notesActions } from '../NotesActions';
import { FileService } from "../../../../services/FileService";
import moment from "moment";
import { isEmpty, isNil, isNumber, map, uniqueId } from "lodash";
import AssignmentService from "../../../../services/AssignmentService";

export const types = {
  LOAD_NOTE: 'LOAD_NOTE__NOTE_EDIT',
  SET_TITLE: 'SET_TITLE__NOTE_EDIT',
  SET_TEXT: 'SET_TEXT__NOTE_EDIT',
  SET_GEOPOINT: 'SET_GEOPOINT__NOTE_EDIT',
  SET_ATTRIBUTE: 'SET_ATTRIBUTE__NOTE_EDIT',
  ADD_CLONE: 'ADD_CLONE',
  SET_TEMPLATE_NAME: 'SET_TEMPLATE_NAME__NOTE_EDIT',
  CLEAR_ATTRIBUTES: 'CLEAR_ATTRIBUTES',
  SET_DATE_START: 'SET_DATE_START__NOTE_EDIT',
  SET_DATE_END: 'SET_DATE_END__NOTE_EDIT',
  SET_CLIENT: 'SET_CLIENT__NOTE_EDIT',
  RESET_FORM: 'RESET_FORM__NOTE_EDIT',
  PENDING: 'PENDING__NOTE_EDIT',
  FILE_UPLOAD: 'FILE_UPLOAD__NOTES',
  FILE_UPLOAD_DONE: 'FILE_UPLOAD_DONE__NOTES',
  FILE_UPLOAD_FAILURE: 'FILE_UPLOAD_FAILURE_NOTES',
  FILE_DELETE: 'FILE_DELETE__NOTES',
  FILE_DELETE_DONE: 'FILE_DELETE_DONE__NOTES',
  TOGGLE_ATTRIBUTE: 'TOGGLE_ATTRIBUTE',
};

export const actions = {
  loadNote: (note) => {
    return dispatch => {
      dispatch({
        type: types.LOAD_NOTE,
        payload: { note }
      });
    };
  },
  setTitle: (title) => {
    return (dispatch, getState) => {
      const state = getState();
      const { main: { noteCreate: { note: oldNote } } } = state;

      const note = Object.assign({}, oldNote, { title });

      dispatch({
        type: types.SET_TEXT,
        payload: { note }
      });
    };
  },

  setGeoPoint: (geoPoint) => {
    return (dispatch, getState) => {
      const state = getState();
      const { main: { noteCreate: { note: oldNote } } } = state;
      const note = { ...oldNote, geoPoint };
      dispatch(
        {
          type: types.SET_GEOPOINT,
          payload: { note },
        }
      )
    }
  },

  setNoteTemplate: (formTemplatesName, nTemplate) => {
    return (dispatch, getState) => {
      const state = getState();
      const { main: { noteCreate: { note: oldNote } } } = state;
      const note = Object.assign({}, oldNote, { formTemplatesName }, { nTemplate });

      dispatch({
        type: types.SET_TEMPLATE_NAME,
        payload: { note }
      });
    };
  },

  clearAttributes: () => {
    return (dispatch, getState) => {
      const state = getState();
      const { main: { noteCreate: { note: oldNote } } } = state;
      const { en, st } = oldNote.attributes;
      const attributes = { en, st };
      const note = Object.assign({}, oldNote, { attributes });

      dispatch({
        type: types.CLEAR_ATTRIBUTES,
        payload: { note }
      });
    }
  },

  toggleAttribute: (field, value) => {
    return (dispatch, getState) => {
      const state = getState();
      const { main: { noteCreate: { note: oldNote } } } = state;

      const note = {...oldNote};

      // Если атрибут по ключу существует
      if (note.attributes[field.key]) {
        // Проверяем, есть ли значение
        if (note.attributes[field.key][1].indexOf(value) !== -1) {
          // Если есть, то удаляем его
          note.attributes[field.key][1] = note.attributes[field.key][1].filter(v => v !== value);
        } else {
          // Если нет, то добавляем
          note.attributes[field.key][1] = [...note.attributes[field.key][1], value];
        }
      } else {
        // иначе создаем его
        note.attributes[field.key] = [field.label || field.key, isNil(value) ? [] : [value]];
      }

      dispatch({
        type: types.TOGGLE_ATTRIBUTE,
        payload: { note }
      });
    }
  },

  setAttribute: (field, value, groupKey, reactKey, isNeedUpdate = true) => {
    const formatValue = (value) => !isNil(value) ? [value] : [];
    const getKeyValue = () => {
      const key = field.key;
      return { [key]: [field.label || key, formatValue(value)]}
    }

    return (dispatch, getState) => {
      const state = getState();
      const { main: { noteCreate: { note: oldNote } } } = state;
      const getAttrObj = () => {
        if (groupKey) {
          const getGroup = () => {
            const group = oldNote.attributes[groupKey];
            if (group) {
              const idx = group.map(i => i.reactKey).indexOf(reactKey);
              if (idx !== -1) {
                return [...group.slice(0, idx), { ...group[idx], ...getKeyValue(), reactKey  }, ...group.slice(idx + 1, group.length)];
              }
              return [...group, { ...getKeyValue(), reactKey  }];
            } else {
              return [{ ...getKeyValue(), reactKey  }]
            }
          }
          return { ...oldNote.attributes, [groupKey]: getGroup() };
        } else {
          return { ...oldNote.attributes, ...getKeyValue() }
        }
      }
      const note = { ...oldNote, attributes: getAttrObj() };

      dispatch({
        type: types.SET_ATTRIBUTE,
        payload: { note }
      });
    };
  },

  addClone: (groupKey, parent) => {

    const createComponent = (components, reactKey) => {
      return {
        ...components.reduce(
          (acc, cur) => {
            return { ...acc, [cur.key]: [cur.label, []] }
          }, {}
        ), reactKey
      };
    };

    const components = parent.components.filter(c => c.type !== 'button');

    return (dispatch, getState) => {
      const state = getState();
      const { main: { noteCreate: { note: oldNote } } } = state;
      const reactKey = uniqueId(groupKey);
      const attributes = {
        ...oldNote.attributes, [groupKey]: oldNote.attributes[groupKey]
          ? [...oldNote.attributes[groupKey], createComponent(components, reactKey)]
          : [createComponent(components, reactKey)]
      }

      const note = { ...oldNote, attributes };

      dispatch({
        type: types.ADD_CLONE,
        payload: { note }
      });
    }
  },

  deleteClone: (groupKey, reactKey) => {
    return (dispatch, getState) => {
      const state = getState();
      const { main: { noteCreate: { note: oldNote } } } = state;

      const attributes = { ...oldNote.attributes, [groupKey]: oldNote.attributes[groupKey] && oldNote.attributes[groupKey].filter(g => g.reactKey !== reactKey) }

      const note = { ...oldNote, attributes }

      dispatch({
        type: types.ADD_CLONE,
        payload: { note }
      });
    }
  },

  setText: (text) => {
    return (dispatch, getState) => {
      const state = getState();
      const { main: { noteCreate: { note: oldNote } } } = state;

      const note = Object.assign({}, oldNote, { text });

      dispatch({
        type: types.SET_TEXT,
        payload: { note }
      });
    };
  },
  setStartDate: (st) => {
    return (dispatch, getState) => {
      const state = getState();
      const { main: { noteCreate: { note: oldNote } } } = state;
      const attributes = Object.assign({}, oldNote.attributes, { st: moment(st).format() });
      const note = Object.assign({}, oldNote, { attributes });

      dispatch({
        type: types.SET_DATE_START,
        payload: { note }
      });
    };
  },
  setEndDate: (en) => {
    return (dispatch, getState) => {
      const state = getState();
      const { main: { noteCreate: { note: oldNote } } } = state;
      const attributes = Object.assign({}, oldNote.attributes, { en: moment(en).format() });
      const note = Object.assign({}, oldNote, { attributes });

      dispatch({
        type: types.SET_DATE_END,
        payload: { note }
      });
    };
  },
  saveNote: ({ note, entityName, entityId, entityType, routeAddon }) => {
    let correctEntityType, correctEntityId, correctSources, correctSourceType, assignmentPointIdx;
    return async (dispatch, getState) => {
      const { main: { noteCreate: { note: oldNote } } } = getState();
      let sources = [entityId];


      // Если сущность - поручение, то нужно переопределить entityType и entityId с этого поручения на нужный юнит.
      if (routeAddon && JSON.parse(routeAddon)) {
        const routeAddonObj = JSON.parse(routeAddon);
        correctSources = [...routeAddonObj.sources];
        correctEntityType = routeAddonObj.entityType;
        correctEntityId = routeAddonObj.entityId;
        correctSourceType = routeAddonObj.sourceType;
        assignmentPointIdx = routeAddonObj.assignmentPointIdx;
      }

      dispatch({
        type: types.PENDING,
        payload: {
          pending: true,
          note: oldNote,
        },
      });
      const newNote = await NoteService.saveNote(
        {
          note,
          entityName,
          sources: correctSources || sources,
          entityType: correctEntityType || entityType,
          initialEntityType: entityType,
          sourceType: correctSourceType,
          assignmentId: entityId,
          assignmentPointIdx,
        });

      dispatch(actions.resetForm());
      dispatch(notesActions.fetch({
        entityId: correctEntityId === '*' ? undefined : (correctEntityId || entityId),
        entityType,
      }));

      return newNote;
    };
  },
  resetForm: () => {
    return dispatch => {
      dispatch({
        type: types.RESET_FORM,
        payload: { pending: false },
      });
    };
  },
  onSelectDepartment: (client) => {
    return (dispatch, getState) => {
      const { main: { noteCreate: { note: oldNote } } } = getState();
      dispatch({
        type: types.SET_CLIENT,
        payload: {
          note: { ...oldNote, client },
        }
      });
    };
  },
  deleteFile: function (id) {
    return async (dispatch) => {
      dispatch({
        type: types.FILE_DELETE,
      });
      await FileService.deleteFile(id).then(() => {
        dispatch(actions.deleteFileDone(id));
      })
    };
  },
  deleteFileDone: function (id) {
    return async (dispatch, getState) => {
      const { main: { noteCreate: { note } } } = getState();
      let attachments = [...note.attachments].filter((item) => {
        return item !== id;
      })
      dispatch({
        type: types.FILE_DELETE_DONE,
        payload: {
          note: { ...note, attachments }
        }
      });
    };
  },
  uploadFile: function (file) {
    return async (dispatch) => {
      dispatch({
        type: types.FILE_UPLOAD,
      });
      await FileService.uploadFile(file, null, null,
        () => dispatch({
          type: types.FILE_UPLOAD_FAILURE,
          payload: { isProcessing: false }
        }))
        .then((fileInfo) => {
          fileInfo && dispatch(actions.uploadDone(fileInfo));
        })
    };
  },
  uploadDone: function (fileInfo) {
    return (dispatch, getState) => {
      const { main: { noteCreate: { note } } } = getState();
      let attachments = [...note.attachments];

      if (fileInfo) {
        attachments = [...note.attachments, fileInfo.id];
      }

      dispatch({
        type: types.FILE_UPLOAD_DONE,
        payload: {
          note: { ...note, attachments }
        }
      });
    };
  }
};

export const batchActions = {
  changeNoteTemplate: (formTemplatesName, nTemplate) => {
    return dispatch => {
      dispatch(actions.setNoteTemplate(formTemplatesName, nTemplate));
      dispatch(actions.clearAttributes());
    }
  },
}
