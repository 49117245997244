import get from 'lodash/get';
import {createActions} from "../../../baseClasses/ActionFactory";
import WeightedService from '../../../services/WeightedService';
import {createTreeActions} from "../../../baseClasses/TreeActionFactory";

const {types: generalTypes, actions: generalActions} = createActions({store: 'WEIGHTED', onFetch});
const {types: treeTypes, actions: treeActions} = createTreeActions({store: 'WEIGHTED'});

const SET_START_DATE = 'SET_START_DATE__WEIGHTED';
const SET_END_DATE = 'SET_START_DATE__WEIGHTED';
const SET_PERIOD = 'SET_PERIOD__WEIGHTED';
const FETCH_NO_PENDING = 'FETCH_NO_PENDING__WEIGHTED';

function fetchSaved() {
  return (dispatch) => {
    const period = WeightedService.loadPeriod();
    // const statePeriod = pick(getState().main.weighted, ['from', 'to']);

    if (period) {
      dispatch(setPeriod(period));
      dispatch(fetchNoPending({...period}));
    } else {
      dispatch(generalActions.fetch());
    }
  };
}

function fetchNoPending({from, to}) {
  WeightedService.fetch({from, to});
  return (dispatch) => {
    dispatch({
      type: FETCH_NO_PENDING,
    });
  };
}

function onFetch(props, getState) {
  const {from, to} = get(getState(), 'main.weighted', {});
  WeightedService.fetch({from, to});
}

function setStartDate(from) {
  return (dispatch, getState) => {
    const {to} = getState().main.weighted;
    WeightedService.savePeriod({from, to});
    dispatch({
      type: SET_START_DATE,
      payload: {from}
    });
    // dispatch(generalActions.fetch());
  };
}

function setEndDate(to) {
  return (dispatch, getState) => {
    const {from} = getState().main.weighted;
    WeightedService.savePeriod({from, to});
    dispatch({
      type: SET_END_DATE,
      payload: {to}
    });
    // dispatch(generalActions.fetch());
  };
}

function setPeriod({from, to}) {
  return dispatch => {
    WeightedService.savePeriod({from, to});
    dispatch({
      type: SET_PERIOD,
      payload: {to, from}
    });
    // dispatch(generalActions.fetch());
  };
}

const allTypes = {...generalTypes, ...treeTypes, SET_START_DATE, SET_END_DATE, SET_PERIOD, FETCH_NO_PENDING};
const allActions = {...generalActions, ...treeActions, setStartDate, setEndDate, setPeriod, fetchSaved};
export {allActions as actions, allTypes as types};
