import {
  FETCH_SUCCESS,
  FETCH_PENDING,
  FETCH_ERROR,
} from './OperationsActions';

const defaultState = {
  pending: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case FETCH_PENDING:
      state = Object.assign({}, state, {pending: true});
      break;
    case FETCH_SUCCESS:
    case FETCH_ERROR:
      state = Object.assign({}, defaultState);
      break;
  }
  return state;
}
