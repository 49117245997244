import { routes } from "../../../../routes";
import { push } from 'react-router-redux';
import { createActions } from "../../../../baseClasses/ActionFactory";

import { featureSelectOutside } from "../../Map/MapActions";

function dispFeatureSelectOut(dispatch, props) {
  dispatch(featureSelectOutside({
    ...props,
    type: "vehicle",
  }));
}

function gotoMap(props, viewport) {
  return dispatch => {
    dispatch(push(routes.map));
    dispFeatureSelectOut(dispatch, props, viewport)
  };
}

const { types, actions } = createActions({ store: 'VEHICLE' });

Object.assign(actions, { gotoMap });

export { types, actions };
