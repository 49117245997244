export const ROLE = {
  admin: 'admin',
  superAdmin: 'superAdmin',
  readOnlyUser: 'readOnlyUser',
  issuesOwner: 'issuesOwner',
  issuesAssignee: 'issuesAssignee',
  weightingBridgeUser: 'weightingBridgeUser',
  'readOnly with GGR': 'readOnly with GGR',
  externalService: 'externalService',
  restrictedDispatcherRole: 'restrictedDispatcherRole',
  restrictedAgronomistRole: 'restrictedAgronomistRole',
  restrictedUserRole: 'restrictedUserRole',
  superAdminWithTelemetry: 'superAdminWithTelemetry',
  userWithFuelLimits: 'userWithFuelLimits',
  notesEditRole: 'notesEditRole',
  noteAdminRole: 'noteAdminRole',
  noteUserRole: 'noteUserRole',
  tmcWritedownMonitor: 'TMCwritedownMonitor',
};
