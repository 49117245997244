import React, { useEffect, useState } from 'react';
import values from 'lodash/values';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import { Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import { iconType, svgMap } from "../controls/icon/iconTypes";

export const ArrowUrl = (azimuth = null) => {
  let arrow = svgMap[iconType.arrow];
  if (azimuth !== null) {
    arrow = arrow.replace('rotate(90', `rotate(${azimuth}`);
  }
  return encodeURI("data:image/svg+xml," + arrow).replace(new RegExp('#', 'g'), '%23');
};

export const swapLatLon = (point) => [point[1], point[0]];

export const getMultiPolygonCoordinates = (coordinates, reverseCoordinates = true) => {
  const mapper = (coordinate => coordinate.map(([lon, lat]) => (reverseCoordinates ? [lat, lon] : [lon, lat])));
  return coordinates.flatMap(coordinate => coordinate.map(mapper));
}

export const setCenter = (points, map, options) => {
  const bounds = L.latLngBounds(points);
  if (map) {
    map.fitBounds(bounds, { ...options, animate: false });
  }
};

export const changeCoordsType = (map, layerType) => {
  map.options.crs = L.CRS[layerType.CRS || 'EPSG3857'];
};

export const getPolygonCenterPoint = points => {
  const latLngBounds = new L.latLngBounds(points);
  if (latLngBounds.isValid()) {
    return latLngBounds.getCenter();
  } else {
    return null;
  }
};

export const setZoom = (zoom, map, options) => {
  if (map?.setZoom) {
    map.setZoom(zoom, options);
  }
};

export const getZoom = map => {
  if (map?.getZoom) {
    return map.getZoom();
  }
  return null;
};

export const getIconDataByName = name => encodeURI("data:image/svg+xml," + svgMap[name]).replace(new RegExp('#', 'g'), '%23');

const distanceSegment = (map, latlng, latlngA, latlngB) => {
  let p = map.latLngToLayerPoint(latlng),
    p1 = map.latLngToLayerPoint(latlngA),
    p2 = map.latLngToLayerPoint(latlngB);

  return L.LineUtil.pointToSegmentDistance(p, p1, p2);
}

const closestOnSegment = (map, latlng, latlngA, latlngB) => {
  let maxzoom = map.getMaxZoom();
  if (maxzoom === Infinity)
    maxzoom = map.getZoom();
  let p = map.project(latlng, maxzoom),
    p1 = map.project(latlngA, maxzoom),
    p2 = map.project(latlngB, maxzoom),
    closest = L.LineUtil.closestPointOnSegment(p, p1, p2);

  return map.unproject(closest, maxzoom);
}

const findClosestPoint = (map, layer, latlng) => {
  let latlngs,
    mindist = Infinity,
    result = null,
    i, n, distance;

  // deep copy of latlngs
  latlngs = JSON.parse(JSON.stringify(L.polyline(layer).getLatLngs().slice(0)));

  // Keep the closest point of all segments
  for (i = 0, n = latlngs.length; i < n - 1; i++) {
    let latlngA = latlngs[i],
      latlngB = latlngs[i + 1];
    distance = distanceSegment(map, latlng, latlngA, latlngB);
    if (distance <= mindist) {
      mindist = distance;
      result = closestOnSegment(map, latlng, latlngA, latlngB);
      result.distance = distance;
    }
  }

  return result;
}

export const findClosestTrack = (map, layers, latlng) => {
  let mindist = Infinity,
    distance = Infinity;

  return layers.reduce((closest, layer) => {
    const closestPoint = findClosestPoint(map, layer.points, latlng);

    if (closestPoint) {
      distance = closestPoint.distance;
    }

    if (distance < mindist) {
      mindist = distance;

      return {
        ...layer,
        closestPoint,
      };
    }

    return closest;
  }, null);
};

export const getVehiclePosition = ({ rt_position, position }) => {
  if (rt_position) {
    return rt_position[0] && swapLatLon(rt_position[0]);
  }
  if (position) {
    return position[0] && swapLatLon(position[0]);
  }
};

const getBondsCoordinates = (targetsCoordinates) => {
  const [X, Y] = [0, 1];

  let [minX, maxX, minY, maxY] = [180, 0, 180, 0];

  values(targetsCoordinates).forEach((target) => {
    target.coordinates.forEach((coords) => {
      if (coords[X] < minX) minX = coords[X];
      if (coords[X] > maxX) maxX = coords[X];
      if (coords[Y] < minY) minY = coords[Y];
      if (coords[Y] > maxY) maxY = coords[Y];
    });
  });

  const coordinates = [
    [minX, minY],
    [maxX, minY],
    [maxX, maxY],
    [minX, maxY],
    [minX, minY],
  ];

  return coordinates;
};

export const getFitCoordinates = (target) => {
  if (target) {
    const targetArray = Array.isArray(target) ? target : [target];
    const targetsCoordinates = targetArray.map(target => (
      { coordinates: target.geometry?.type === 'MultiPolygon'
          ? getMultiPolygonCoordinates(target.geometry?.coordinates, false).reduce((acc, current) => [...acc, ...current], [])
          : target.geometry?.coordinates[0] || target.position }
    )).filter(item => item.coordinates);

    const coordinates = getBondsCoordinates(targetsCoordinates);

    return {
      coordinates,
      options: {
        padding: [10, 10, 10, 10],
        constrainResolution: false,
      },
    };
  }
};

export const fitMapPos = (map, points, options) => {
  const pointsSwapped = points.map(swapLatLon);
  setCenter(pointsSwapped, map, options);
}

export const fitMapVehicle = (map, vehicle, options) => {
  const points = [swapLatLon(getCenter(vehicle))];
  setCenter(points, map, options);
}

export const fitMapFeature = (map, feature, options) => {
  if (feature) {

    if (feature.position) {
      switch (feature.type) {
        case 'field':
          fitMapPos(map, [[feature.position.lat, feature.position.lng]], options);
          break;
        case 'vehicle':
          fitMapVehicle(map, feature, options);
          break;
      }
    }
  } else { onFitClick(); }
};


export const onFitClick = (map, fields) => {
  const fit = getFitCoordinates(fields);
  fit && fitMapPos(map, fit.coordinates[0], { padding: [10, 10] });
};

export const onPositionClick = (map, position) => {
  const { longitude, latitude } = position.coords;
  fitMapPos(map, [[longitude, latitude]], { maxZoom: 17 });
};

export const getMarkerIcon = () => {
  const anchor = new L.Point(23, 23);
  return new L.Icon({
    iconUrl: getIconDataByName(iconType.marker),
    iconSize: new L.Point(47, 47),
    iconAnchor: anchor,
    popupAnchor: anchor,
    tooltipAnchor: anchor,
  });
};

export const renderPoint = ([x, y]) => (
  <Marker position={swapLatLon([x, y])} icon={getMarkerIcon()} />
);

export const getClosestToClickPoint = (isFeature, track, selectedPoint) => {
  let coords = [];

  if (isFeature) {
    coords = [...track[0]];
  } else {
    let trackCopy = cloneDeep(track)
    trackCopy.shift();
    trackCopy.forEach((item) => {
      coords.push(item[0])
    })
    coords = flatten(coords)
  }

  let distance = Infinity;
  let index = -1;

  for (const ind in coords) {
    const coord = swapLatLon(coords[ind]);
    const x = Math.abs(coord[0] - selectedPoint.lat);
    const y = Math.abs(coord[1] - selectedPoint.lng);
    const dist = Math.sqrt(x * x + y * y);
    if (dist <= distance) {
      distance = dist;
      index = ind;
    }
  }
  return coords[index];
};

export const getDataByPoint = (point, isFeature, track, selectedPoint) => (
  (point == null || !track) ? null : getClosestToClickPoint(isFeature, track, selectedPoint)
);

export const getCenter = (vehicle) => {
  const position = vehicle.position || vehicle.rt_position;
  let center = [0, 0];
  if (position) {
    center = [position[0][0], position[0][1]];
  }
  return center;
}

export const filterFields = (fields, filterValue) => {
  if (filterValue.type === 'all') {
    return fields;
  }
  return fields.filter(field => field.zoneType === filterValue.type)
};

export const useMapPointsFilter = (viewport, geozoneType, mapItems, getItemCoordinates) => {
  const map = useMap();
  const [filteredMapItems, setFilteredMapItems] = useState(mapItems);

  useEffect(() => {
    if (map) {
      const filteredItems = mapItems.filter((item) => {
        const coords = getItemCoordinates(item)
        return coords && map.getBounds().contains(L.latLng(coords))
      });

      setFilteredMapItems(filteredItems);

      return () => {
        setFilteredMapItems([]);
      }
    }
  }, [viewport, geozoneType]);
  return [filteredMapItems];
}

export const useMapItemsFilter = (viewport, geozoneType, mapItems, getItemCoordinates) => {

  const map = useMap();
  const [filteredMapItems, setFilteredMapItems] = useState(mapItems);

  useEffect(() => {
    if (map) {
      const mapBounds = map.getBounds();
      const newBounds = JSON.parse(JSON.stringify(mapBounds));
      newBounds.__proto__ = mapBounds.__proto__;
      newBounds._northEast.lat *= 1.001;
      newBounds._northEast.lng *= 1.001;
      newBounds._southWest.lat *= 0.999;
      newBounds._southWest.lng *= 0.999;

      const filteredItems = mapItems.filter((item) => {
        const coordinates = getItemCoordinates(item);
        return (coordinates && newBounds) && newBounds.contains(coordinates);
      });

      setFilteredMapItems(filteredItems);

      return () => {
        setFilteredMapItems([]);
      }
    }
  }, [viewport, geozoneType]);
  return [filteredMapItems];
}
