import React from 'react';
import {ConnectedRouter} from 'react-router-redux';
import {LocalstorageService} from "../../services/LocalstorageService";
import {Provider} from 'react-redux';
import st from './Root.scss';
import {NotificationContainer} from '../common/Notification';
import Routes from '../Routes/Routes';

const Root = (props) => {
  const {store, history} = props;
  LocalstorageService.compareMark();
  return (
    <div className={st.root}>
      <Provider store={store} className={st.root}>
        <ConnectedRouter history={history}>
          <Routes/>
        </ConnectedRouter>
      </Provider>
      <NotificationContainer/>
    </div>
  )
}

export default Root;

