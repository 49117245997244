import {url} from '../api';
import BaseDataService, {responseDataShape} from "../baseClasses/BaseDataService";
import {actions} from "../components/Main/Goods/GoodsActions";

class GoodsService extends BaseDataService {
  constructor() {
    super({
      urlAll: url.goods,
      fetchPending: actions.fetchPending,
      fetchSuccess: actions.fetchSuccess,
      fetchError: actions.fetchError,
      dataShape: responseDataShape.ARRAY,
      qs: {
        page: 1,
        start: 0,
        limit: 100000,
      }
    });
    window.GoodsService = this;
  }
}



export default new GoodsService();
