import keys from 'lodash/keys';
import values from 'lodash/values';
import isArray from 'lodash/isArray';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import get from 'lodash/get';
import httpClient from '@infobis/api-module';
import {parseTree} from '../helpers/parseTree';
import Tree from "../treejs/Tree";
import AuthService from '../services/AuthService';
import {emptyTree} from "../helpers/treeHelper";
import DepartmentService from "../services/DepartmentService";
import store from "../store/store";
import {localStorageItems, LocalstorageService} from "../services/LocalstorageService";
import {expandTree} from "../helpers/expandTree";
import {canFit} from "../helpers/localstorageHelpers";
import {Notification} from './../components/common/Notification';

export const responseDataShape = {
  TREE: 'TREE',
  ARRAY: 'ARRAY',
};

export default class BaseDataService {
  data = null;
  _cacheOn = false;
  _treeStateSaveOn = false;
  _inCacheUpdates = [];
  _listMap = {};
  _glidMap = {};
  _cacheTimeStamp = 0;
  _lastUpdated = Date.now();
  _isFetching = false;
  _dataShape = responseDataShape.TREE;
  _inited = false;
  _qs = {};

  constructor({urlAll, fetchPending, fetchSuccess, fetchError, dataShape = responseDataShape.TREE, qs = {}}) {
    this._urlAll = urlAll;
    this._fetchPending = fetchPending;
    this._fetchSuccess = fetchSuccess;
    this._fetchError = fetchError;
    this._dataShape = dataShape;
    this._qs = qs;
  }

  get count() {
    return this._dataCount;
  }

  get inited() {
    return this._inited;
  }

  async fetch() {
    const user = AuthService.getUser();
    if (!user) {
      AuthService.logOut();
      return Promise.reject();
    }
    const depParams = DepartmentService.getCurrentDepartmentForRequest();

    const request = {
      url: this._urlAll,
      params: {
        method: 'GET'
      },
      qs: {...depParams, ...this._qs},
      headers: {
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId
      },
      authorize: true
    };

    if (this._isFetching) {
      return Promise.resolve({success: false});
    }
    this._isFetching = true;
    store.dispatch(this._fetchPending());

    if (this._cacheOn) {
      const cached = this._getCache();
      const isSameUser = LocalstorageService.isCacheForSameUserDepartment(this._urlAll);
      if (!isEmpty(cached)) {
        if (isSameUser) {
          this._inCacheState = true;
          this.onFetch(cached.data);
          try {
            const data = await httpClient.makeRequest(request, {contentTypeAuto: true});
            this._setCache(data);
            this.onFetch(data);
            this.update(this._inCacheUpdates, true);
            this._inCacheUpdates = [];
            this._inCacheState = false;
            return Promise.resolve({success: true});
          } catch (error) {
            Notification.error(error);
          }
          finally {
            this._isFetching = false;
          }

        } else {
          localStorage.removeItem(this._urlAll);
        }
      }
    }

    try {
      const user = AuthService.getUser()
      if (user) {
        const data = await httpClient.makeRequest(request, {contentTypeAuto: true});
        if (this._cacheOn) {
          if (data && canFit(data)) {
            this._setCache(data);
          } else {
            this._cacheOn = false;
          }
        }
        this.onFetch(data);
        return Promise.resolve({success: true});
      }

    } catch (error) {
      Notification.error(error);
    }
    finally {
      this._isFetching = false;
    }
  }

  _setCache(data) {
    if (isEmpty(data)) {
      // localStorage.removeItem(this._urlAll);
      console.log(this._urlAll, 'to cache failed - empty data');
      return;
    }
    console.log(this._urlAll, 'to cache');
    localStorage.setItem(this._urlAll, JSON.stringify(LocalstorageService.getCacheForUserDepartment(data)));
  }

  _getCache() {
    return JSON.parse(localStorage.getItem(this._urlAll) || null);
  }

  onFetch(data) {
    if (!this.inited && (
        this._dataShape === responseDataShape.TREE && isEmpty(data) ||
        this._dataShape === responseDataShape.ARRAY && data === undefined)) {
      this.reset();
      return;
    }
    if (this.inited && (
        this._dataShape === responseDataShape.TREE && isEmpty(data) ||
        this._dataShape === responseDataShape.ARRAY && data === undefined)) {
      return;
    }
    this._inited = true;
    this._parseFetchResponse(data);
  }

  getTree() {
    return this.data || new Tree({getId: i => i});
  }

  getLeaves() {
    return this.data && this.data.leaves() || [];
  }

  /**
   * Memoized filtered collection
   * @type {Function}
   */
  getList() {
    if (this._lastUpdated > this._cacheTimeStamp) {
      this._listCache = values(this._listMap).filter(x => !x.removed);
    }

    return this._listCache;
  }

  getItem(id) {
    const item = this._glidMap[id];
    return item || {};
  }

  getShortenTree() {
    if (this._dataShape !== responseDataShape.TREE) {
      return;
    }
    if (isEmpty(this.data)) {
      return emptyTree();
    }
    if (this._lastUpdated > this._cacheTimeStamp) {
      const leaves = this.data.leaves();
      leaves.forEach(x => {
        if (x.data) {
          x.data.listItem = this._listMap[Number(x.data.glid)];
        }
      });
    }

    return this.data;
  }

  getFolder(id) {
    if (isEmpty(this.data) || isEmpty(this.data.map)) {
      return null;
    }
    const obj = values(this.data.map).find(n => Number(n.data.glid) === Number(id));
    return obj ? obj.path : '';
  }

  getNodeForId(id) {
    if (isEmpty(this.data) || isEmpty(this.data.map)) {
      return null;
    }
    return values(this.data.map).find(n => n.data.glid === id);
  }

  getTreeForId(id) {
    if (isEmpty(this.data) || isEmpty(this.data.map)) {
      return Tree.empty();
    }
    const {path} = this.getNodeForId(id);
    return this.data.subtractBranch(path);
  }

  /**
   * Override in inherit classes
   */
  _afterFetch() {
    this._dataCount = values(this._listMap).length;
    if (this._dataShape === responseDataShape.TREE) {
      for (const node of this.data.leaves()) {
        node.data.pending = true;
      }
    }
    this._dataShape === responseDataShape.TREE && expandTree(this.data, 0);
    if (this._treeStateSaveOn) {
      this._loadTreeState();
    }
  }

  /**
   * Override in inherit classes
   */
  beforeUpdate() {
  }

  /**
   * Override in inherit classes
   */
  afterUpdate() {
  }

  async update(data, isCache) {
    //data from websocket
    // if (data) {
    //   data.map((data) => {
    //     if (data.values && data.values.unit) {
    //       console.warn(data);
    //     }
    //   })
    // }
    const newDataIDs = data.map(i => Number(i.id));
    const thisGlids = keys(this._glidMap).map(i => Number(i));
    const updatedGlids = intersection(newDataIDs, thisGlids);
    if (isEmpty(updatedGlids)) {
      return;
    }
    this._lastUpdated = Date.now();
    this._mergeDelta(data, isCache);
    this._updateList(updatedGlids);
  }

  dispatchDataChange() {
    // store.dispatch(actions.fetchSuccess());
  }

  reset({_dataShape = responseDataShape.TREE} = {}) {
    this.clear({_dataShape});
    store.dispatch(this._fetchPending());
  }

  clear({_dataShape = responseDataShape.TREE} = {}) {
    this.data = null;
    this._listMap = {};
    this._glidMap = {};
    this._cacheTimeStamp = 0;
    this._lastUpdated = Date.now();
    this._isFetching = false;
    this._dataShape = _dataShape;
  }

  saveTreeState() {
    const treeMapState = {};
    const user = AuthService.getUser();
    const depParams = DepartmentService.getCurrentDepartmentForRequest();

    for (const nodePath in this.data.map) {
      if (!isEmpty(this.data.map[nodePath]) && this.data.map[nodePath].isNode) {
        treeMapState[nodePath] = pick(this.data.map[nodePath], ['opened']);
      }
    }
    const storageItem = {userId: user.userInfo.id, department: depParams, data: treeMapState};
    localStorage.setItem(localStorageItems.treeState(this._urlAll), JSON.stringify(storageItem));
  }

  _mergeDelta(newData, isCache) {
    for (const newObj of newData) {
      if (this._glidMap[newObj.id]) {
        if (!isCache && this._inCacheState) {
          this._inCacheUpdates.push(newObj);
        }

        const valKeys = keys(newObj.values);
        const simpleDataKeys = valKeys.filter(k => !isArray(newObj.values[k]));
        const arrayDataKeys = difference(valKeys, simpleDataKeys);
        const val = this._glidMap[newObj.id];

        val._lastUpdated = this._lastUpdated;
        val.pending = false;

        for (const k of simpleDataKeys) {
          this._mergeCustoms(val, newObj, k, false);
        }

        for (const k of arrayDataKeys) {
          this._mergeCustoms(val, newObj, k, true);
        }

        this._glidMap[newObj.id] = val;
      }
    }
  }

  /**
   * Override in inherit classes to customize merging logic for fields
   */
  _mergeCustoms(dest, src, key, isArray) {
    if (!isArray) {
      dest[key] = src.values[key];
    } else {
      const v = this._glidMap[src.id][key] || [];
      dest[key] = [...v, src.values[key]];
    }
  }

  _updateList(idsToUpdate) {
    for (const glid of idsToUpdate) {
      this._listMap[glid] = this._mapListItem(this._glidMap[glid]);
    }
    this.getList();
    this.getShortenTree();
    this._cacheTimeStamp = this._lastUpdated;
  }

  /**
   * Used to map full obj to it's reduced version for list
   * Override in inherit classes
   * @param i - item
   * @returns mapped item
   * @private
   */
  _mapListItem(i) {
    return i;
  }

  _setGlidMap() {
    const map = {};
    let data;

    for (const i of this.data.leaves()) {
      data = i.data;
      if (i.parent()) {
        data.groupName = i.parent().data.name;
      }
      map[i.data.glid] = data;
    }
    this._glidMap = map;
    this._lastUpdated = Date.now();
  }

  _setGlidMapForArray() {
    const map = {};
    for (const i of this.data) {
      map[i.id] = i;
    }
    this._glidMap = map;
    this._lastUpdated = Date.now();
  }

  _setListMap() {
    this._updateList(keys(this._glidMap));
  }

  _parseFetchResponse(response) {
    this.data = this._dataShape === responseDataShape.TREE ? parseTree(response.data, n => n.data.name) : response.data;

    this._afterFetch();
    if (this._dataShape === responseDataShape.TREE) {
      this._setGlidMap();
    } else {
      this._setGlidMapForArray();
    }
    this._setListMap();

    //TODO: implement fetchError
    this._isFetching = false;
    this.dispatchDataChange();
    store.dispatch(this._fetchSuccess());
  }

  _loadTreeState() {
    const storageItem = JSON.parse(localStorage.getItem(localStorageItems.treeState(this._urlAll)));
    const treeMapState = get(storageItem, 'data');
    const user = AuthService.getUser();
    const depParams = DepartmentService.getCurrentDepartmentForRequest();

    if (isEmpty(treeMapState)) {
      return;
    }

    if (storageItem.userId === user.userInfo.id &&
      storageItem.department.clientId === depParams.clientId &&
      storageItem.department.departmentId === depParams.departmentId &&
      storageItem.department.companyId === depParams.companyId) {

      for (const nodePath in treeMapState) {
        this.data.map[nodePath] && Object.assign(this.data.map[nodePath], treeMapState[nodePath]);
      }
    }
  }
}
