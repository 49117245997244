import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import login from './components/Login/LoginReducer';
import main from './components/Main/MainReducer';

const rootReducer = combineReducers({
  routing: routerReducer,
  login,
  main
});

export default rootReducer;
