import get from 'lodash/get';
import values from 'lodash/values';
import {checkArguments} from './memoizeFilterVehicle';

const isEqualList = (list1, list2) => {
  if (list1.length !== list2.length) {
    return false;
  }
  let result = true;
  for (let i = 0; i < list1.length; i++) {
    if (get(list1, `${i}.listItems._hash`, 0) !== get(list2, `${i}.listItems._hash`, 0)) {
      result = false;
      break;
    }
  }
  return result;
};

const memoizeFilterListVehicle = () => {
  let _cache = {};
  let _list = [];
  let _filtered = [];
  return ({list, status, type}) => {
    
    const newList = values(list);
    if (!isEqualList(newList, _list)) {
      // console.log('----> Update cache');
      _list = newList;
      _cache = {};
    }

    const key = checkArguments(Object.keys(_cache), status, type);
    if (key && _cache[key]) {
      // console.log('----> Get from cache', helperDate(a));
      return _filtered;
    }

    Object.keys(_cache).forEach(key => _cache[key] = false);
    _cache[JSON.stringify({status, type})] = true;
    _filtered = _list.filter(v => {
      const unitState = get(v, 'unitState');
      const vehicleType = get(v, 'type');
      if (!unitState && !vehicleType) {
        return true;
      } else if (!unitState) {
        return (type.indexOf(vehicleType) !== -1);
      } else if (!vehicleType) {
        return (type.indexOf(unitState) !== -1);
      }
      return status.indexOf(unitState) !== -1 &&
        type.indexOf(vehicleType) !== -1;
    });
    // console.log('----> function takes', helperDate(a));
    return _filtered;
  };
};

export default new memoizeFilterListVehicle();
