import { types } from './VehicleActions';

const defaultState = { pending: true, };

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.FETCH_SUCCESS:
      state = Object.assign({}, state, { ...action.payload });
      break;
    case types.FEATURE_SELECTED:
      state = Object.assign({}, state, { selectedFeatureOutside: undefined, selectedFeature: action.data });
      break;
    case types.FEATURE_SELECTED_OUTSIDE:
      state = Object.assign({}, state, { selectedFeatureOutside: action.data });
      break;
    case types.FEATURE_DESELECTED:
      state = Object.assign({}, state, { selectedFeatureOutside: undefined, selectedFeature: undefined });
      break;
  }

  return state;
}
