import httpClient from "@infobis/api-module";
import isEmpty from 'lodash/isEmpty';
import { url } from "../api/index";
import store from '../store/store';
import { actions } from '../components/Main/Notes/NotesActions';
import AuthService from "./AuthService";
import DepartmentService from "./DepartmentService";
import moment from "moment";
import get from "lodash/get";
import intersection from "lodash/intersection";
import { ROLE } from "../const/roles";
import AssignmentService from "./AssignmentService";
import { agrosignalEntityType, entityType as entityTypes } from "../const";
import { Notification } from './../components/common/Notification';
import { uniqueId } from "lodash";
import LocationService from "./LocationService";

class NoteService {
  data = {};

  constructor() {
    this.reset();
    window.NoteService = this;
  }

  reset() {
    this.data = {};
  }

  async fetchCropBaseIds(sources) {
    const user = AuthService.getUser();
    const request = {
      url: url.cropBaseIds,
      params: {
        method: 'GET',
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
      authorize: true,
      qs: {
        sources,
      },
    };

    return await httpClient.makeRequest(request);
  }

  async fetchHarmfulDicts(source) {
    const makeRequest = async (harmfulTypes, cropBaseIds) => {
      const user = AuthService.getUser();
      const request = {
        url: url.harmfulObject,
        params: {
          method: 'GET',
        },
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + user.accessToken,
          "serverId": user.serverId,
        },
        authorize: true,
        qs: {
          harmfulTypes,
          page: 1,
          start: 0,
          limit: 25,
        },
      };

      try {
        const response = await httpClient.makeRequest(request);
        if (response) {
          store.dispatch(actions.fetchSuccess());
          const needFilter = !isEmpty(cropBaseIds);
          const payload = needFilter
            ? response.filter(harmful => cropBaseIds.some(cropBaseId => harmful.onCrops.includes(cropBaseId)))
            : response;
          const actionType = `load${harmfulTypes.replace(/^\w/, (c) => c.toUpperCase())}`;
          store.dispatch(actions[actionType]({ [harmfulTypes]: payload }));
        } else {
          store.dispatch(actions.fetchError());
        }
      } catch (error) {
        store.dispatch(actions.fetchError());
        Notification.error(error);
      }
    }
    const cropBaseIds = await this.fetchCropBaseIds(source);
    const promises = ['diseases', 'pests', 'weeds'].map(async (type) => await makeRequest(type, cropBaseIds));
    await Promise.all(promises);
  }

  async fetchNoteTemplates() {
    const user = AuthService.getUser();
    const request = {
      url: url.formTemplates,
      params: {
        method: 'GET',
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
      authorize: true,
      qs: {
        page: 1,
        start: 0,
        limit: 25,
      },
    };

    try {

      const response = await httpClient.makeRequest(request);
      if (response && response.success && response.data) {
        store.dispatch(actions.fetchSuccess());
        const result = response.data.filter((item) => {
          return item.json;
        });
        return result;
      } else {
        store.dispatch(actions.fetchError());
      }
    } catch (error) {
      store.dispatch(actions.fetchError());
      Notification.error(error);
    }
  }

  async fillHarmfulDicts(noteTemplates, source) {
    await this.fetchHarmfulDicts(source);
    const replaceHarmful = (key) => {
      const noteCreateStore = store.getState().main.noteCreate;
      return noteCreateStore && noteCreateStore[key] && noteCreateStore[key];
    }

    const recursive = (f) => {
      return Array.isArray(f)
        ? f.map(f => recursive(f))
        : !f.whenShow ? f : {
          ...f, whenShow: {
            ...f.whenShow, reactKey: f.whenShow.reactKey || uniqueId(f.whenShow.key), components: f.whenShow.components.map(c => ({
              ...c, values: c.values && (
                Array.isArray(c.values) ? c.values : replaceHarmful(f.key)
              )
            }))
          }
        }
    }

    return noteTemplates.map(nT => ({
      ...nT, contentJson: {
        ...nT.contentJson, step1: {
          ...nT.contentJson.step1, fields: nT.contentJson.step1.fields.map(f => recursive(f))
        }
      }
    }));
  }

  async fetch(entityId, noteType) {
    const user = AuthService.getUser();
    const request = {
      url: url.notes,
      params: {
        method: 'GET',
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
      authorize: true,
      qs: { sources: entityId, noteType },
    };

    if (isEmpty(this.data[entityId])) {
      store.dispatch(actions.fetchPending());
    }

    try {
      const response = await httpClient.makeRequest(request);
      if (response && response.success && response.data) {
        this.addData(entityId, response.data);
        store.dispatch(actions.fetchSuccess());
      } else {
        store.dispatch(actions.fetchError());
      }
    } catch (error) {
      store.dispatch(actions.fetchError());
      Notification.error(error);
    }
  }

  async fetchId(entityId, ids) {
    const user = AuthService.getUser();
    const request = {
      url: url.notes,
      params: {
        method: 'GET',
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
      authorize: true,
      qs: {
        id: ids.join(',')
      },
    };

    if (isEmpty(this.data[entityId])) {
      store.dispatch(actions.fetchPending());
    }

    try {
      const response = await httpClient.makeRequest(request);
      if (response.success) {
        this.addData(entityId, response.data);
        store.dispatch(actions.fetchSuccess());
      } else {
        store.dispatch(actions.fetchError());
      }
    } catch (error) {
      store.dispatch(actions.fetchError());
      Notification.error(error);
    }
  }

  mapNote(note) {

    const {extrapolation} = note;
    const mappedExtrapolation = extrapolation && extrapolation.map(field => field.fieldId || field);

    if (!note.formTemplatesName) {
      return {...note, extrapolation: mappedExtrapolation};
    }

    const { en, st, ...otherAttrs } = note.attributes;

    return {
      ...note,
      attributes: otherAttrs,
      extrapolation: mappedExtrapolation,
    }
  }

  async saveNote({ note, entityName, sources, entityType, initialEntityType, sourceType, assignmentId, assignmentPointIdx }) {
    const user = AuthService.getUser();
    const { clientId } = DepartmentService.getCurrentDepartment();

    const request = {
      url: url.notes,
      params: {
        method: note.id ? 'PUT' : 'POST',
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
      authorize: true,
      body: JSON.stringify({
        ...this.mapNote(note),
        client: note.client || clientId,
        sourceType: agrosignalEntityType[entityType] || sourceType,
        sources,
        sourceObjTitle: entityName,
        issueId: initialEntityType !== entityTypes.ASSIGNMENT ? null : assignmentId,
      })
        .replace(/"reactKey":"\w+",?/g, '')
        .replace(/(.)?{}/g, (match, p1) => (p1 === ':') ? match : p1)
        .replace(/,}/g, '}')
        .replace(/,]/g, ']')
    };

    try {
      const response = await httpClient.makeRequest(request);
      const ntId = get(response, 'data.id');
      const assIds = intersection(AssignmentService.data.map(x => x.id), [assignmentId]);
      if (ntId && !isEmpty(assIds)) {
        const assId = assIds[0];
        const comments = [...get(AssignmentService.getItem(assId), 'comments', []), ntId];
        const points = get(AssignmentService.getItem(assId), 'points', []).map((point, idx) => {
            const noteId = point.noteId.filter(id => comments.includes(id));
            const correctedNoteId = (idx === assignmentPointIdx) ? [...noteId, ntId] : noteId;
            return {
              ...point,
              noteId: correctedNoteId,
            }
          });
        await AssignmentService.saveAssignment(assId, { comments, points });
        AssignmentService.tryFetch();
        const t = setTimeout(() => {
          this.fetchId(assId, comments);
          clearTimeout(t);
        }, 100);
      }
      return response;
    } catch (error) {
      Notification.error(error);
    }
  }

  async deleteNote({ entityId, note }) {
    const user = AuthService.getUser();
    const { clientId } = DepartmentService.getCurrentDepartment();
    const request = {
      url: `${url.notes}/${note.id}`,
      params: {
        method: 'DELETE',
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
      authorize: true,
      body: JSON.stringify({
        ...note,
        client: clientId,
        sources: [entityId],
      })
    };

    try {
      const response = await httpClient.makeRequest(request);
      if (response && response.success) {
        this.addData(entityId, this.data[entityId].filter(x => x.id !== note.id));
        store.dispatch(actions.deleteNoteSuccess());
      }
    } catch (error) {
      Notification.error(error);
    }
  }

  addData(entityId, data) {
    this.data[entityId] = data.map(x => Object.assign({}, x, {
      text: x.text.replace(/<.*?>/ig, ''),
      attributes: Object.assign({}, x.attributes, this._formatNotePeriod(x.attributes.st, x.attributes.en)),
      updateAt: null
    }));
  }

  getNotes(id) {
    return this.data[id] || [];
  }

  newNote(props) {
    const note = {
      title: '',
      text: '',
      attributes: this._defaultNotePeriod(),
      attachments: [],
      nTemplate: 0,
    };

    return Object.assign({}, { ...note }, { ...props });
  }

  _formatNotePeriod(st, en) {
    if (st && en) {
      return {
        st: moment(st).format(),
        en: moment(en).format(),
      };
    }
    return undefined;
  }

  _defaultNotePeriod() {
    return {
      st: moment(new Date()).format(),
      en: moment({ hour: 23, minute: 45, second: 0, millisecond: 0 }).format(),
    };
  }

  hasPermissions() {
    const depRoles = get(AuthService.getUser(), 'userInfo.depRoles', []);
    return !isEmpty(intersection(depRoles, [ROLE.issuesOwner, ROLE.issuesAssignee,
    ROLE.notesEditRole, ROLE.admin, ROLE.superAdmin]));
  }

  canCreate() {
    const depRoles = get(AuthService.getUser(), 'userInfo.depRoles', []);
    return !isEmpty(intersection(depRoles, [ROLE.issuesOwner, ROLE.issuesAssignee,
    ROLE.notesEditRole, ROLE.admin, ROLE.superAdmin]));
  }

  canEdit() {
    const depRoles = get(AuthService.getUser(), 'userInfo.depRoles', []);
    return !isEmpty(intersection(depRoles, [ROLE.issuesOwner, ROLE.notesEditRole, ROLE.admin, ROLE.superAdmin]));
  }
}

export default new NoteService();
