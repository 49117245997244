import { LOAD_TRACK, PENDING_TRACK, SET_PERIOD, DESELECT_FEATURE, SET_SELECTED } from './TrackActions';
import moment from "moment";

const defaultState = {
  pending: true,
  from: moment().startOf('day').toISOString(),
  to: moment().endOf('day').toISOString(),
  trackTs: Date.now(),
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case LOAD_TRACK:
    case PENDING_TRACK:
    case SET_PERIOD:
      state = Object.assign({}, state, action.payload);
      break;
    case DESELECT_FEATURE:
      state = { ...state, selectedPoint: null };
      break;
    case SET_SELECTED:
      state = { ...state, ...action.selected }
      break;
    default:
      break;
  }

  return state;
}
