const createTreeActions = ({store}) => {
  const types = {
    FILTER: `FILTER__${store}`,
    FILTER_PATH: `FILTER_PATH__${store}`,
    FILTER_MODE: `FILTER_MODE__${store}`,
  };
  const actions = {
    filter: (filterStr = '') => {
      return dispatch => {
        dispatch({
          type: types.FILTER,
          payload: {
            filterStr,
          },
        });
      };
    },
    changePath: (filterPath = 'root') => {
      return dispatch => {
        dispatch({
          type: types.FILTER_PATH,
          payload: {
            filterPath,
          },
        });
        dispatch(actions.changeMode(false));
      };
    },
    changeMode: (filterMode = false) => {
      return dispatch => {
        dispatch({
          type: types.FILTER_MODE,
          payload: {
            filterMode,
          },
        });
      };
    }
  };

  return {types, actions};
};

export {createTreeActions};
