import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {pure} from 'recompose';
import {Spinner} from '../../common/Loader';

import st from './loader.scss';

const Loader = ({isActive}) => (
  <React.Fragment>
    {isActive && <div className={cn(st.bg, {[st.bgShow]: isActive})}/>}
    {isActive && <Spinner />}
  </React.Fragment>
);

Loader.propTypes = {
  isActive: PropTypes.bool,
};

Loader.defaultProps = {
  isActive: false,
};

export default pure(Loader);
