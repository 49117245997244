import {url} from '../api';
import get from 'lodash/get';
import BaseDataService from "../baseClasses/BaseDataService";
import {actions} from "../components/Main/Employees/EmployeesActions";
import {mapValuesForKeys} from "../helpers/mapValuesForKeys";
import FieldService from "./FieldService";
import {stringHash} from "../helpers/stringHash";

class EmployeeService extends BaseDataService {
  constructor() {
    super({urlAll: url.employee, ...actions});
    window.EmployeeService = this;
    this._treeStateSaveOn = true;
  }

  getVehicle(vehicle) {
    return vehicle ? {
      name: vehicle.name,
      status: vehicle.unitState,
    } : null;
  }

  _mapListItem(employee) {
    const {relatedVehicle} = employee;
    const vehicle = this.getVehicle(relatedVehicle);
    const zone = get(FieldService.getItem(get(relatedVehicle, 'zoneId', '')), 'name', '');
    const operations = get(relatedVehicle, 'operation', '');

    const hash = employee._lastUpdated + stringHash(zone) * 100 + stringHash(get(vehicle, 'name', '')) * 1000 + stringHash(operations || '') * 10000;

    return mapValuesForKeys(employee, [
      '_lastUpdated',
      'glid',
      'name',
      'post',
      'removed',
      'pending',
      {
        key: '_hash',
        value: hash,
      },
      {
        key: 'zone',
        value: zone,
      },
      {
        key: 'vehicle',
        value: vehicle,
      },
      {
        key: 'operations',
        value: operations,
      }
    ]);
  }
}

export default new EmployeeService();
