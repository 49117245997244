import moment from "moment";

export function formatToShift(val) {
  const duration = moment.duration(val, 'ms');
  const hours = duration.hours().toString();
  const minutes = duration.minutes().toString();
  const hoursStr = ['0'].concat(hours.split('')).splice(-2).join('');
  const minutesStr = ['0'].concat(minutes.split('')).splice(-2).join('');

  return `${hoursStr}:${minutesStr}`;
}

const shiftDurationsOptions = [];

for (let i = 0; i < 24 * 4; i++) {
  const duration = moment.duration(i * 15, 'm');
  shiftDurationsOptions.push({
    value: duration.valueOf(),
    str: formatToShift(duration.asMilliseconds()),
  });
}

export {shiftDurationsOptions};
