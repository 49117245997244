import moment from 'moment';

import {types} from './VehiclesActions';

const defaultState = {
  filterStr: '',
  filterPath: 'root',
  filterMode: false,
  pending: true,

  tracks: {
    vehicles: [],
    date: {
      from: moment().startOf('day').toISOString(),
      to: moment().endOf('day').toISOString()
    },
  }
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.FETCH:
    case types.FETCH_PENDING:
    case types.FETCH_ERROR:
    case types.FETCH_SUCCESS:
    case types.FILTER:
    case types.FILTER_PATH:
    case types.FILTER_MODE:
      state = Object.assign({}, state, {...action.payload});
      break;

    case types.TOGGLE_VEHICLE_TRACK: {
      const { vehicles } = state.tracks;

      const hasVehicle = vehicles.some(({ glid }) => glid === action.payload.glid);

      return {
        ...state,
        tracks: {
          ...state.tracks,
          vehicles: hasVehicle
            ? vehicles.filter(({ glid }) => glid !== action.payload.glid)
            : vehicles.concat(action.payload)
        }
      };
    }

    case types.SET_VEHICLE_TRACKS_PERIOD: {
      return {
        ...state,
        tracks: {
          ...state.tracks,
          date: action.payload,
        }
      };
    }

    case types.LOAD_VEHICLE_TRACKS_REQUEST: {
      return {
        ...state,
        tracks: {
          ...state.tracks,
          pending: true,
        }
      }
    }

    case types.LOAD_VEHICLE_TRACKS_SUCCESS: {
      return {
        ...state,
        tracks: {
          ...state.tracks,
          vehicles: state.tracks.vehicles.map((vehicle, index) => ({
            ...vehicle,
            track: action.payload[index].track,
            report: action.payload[index].report,
          })),
          pending: false,
        }
      };
    }

    case types.LOAD_VEHICLE_TRACKS_FAIL: {
      return {
        ...state,
        tracks: {
          ...state.tracks,
          pending: false,
        }
      }
    }

    case types.REMOVE_VEHICLE_TRACK: {
      return {
        ...state,
        tracks: {
          ...state.tracks,
          vehicles: state.tracks.vehicles.filter(({ glid }) => (
            glid !== action.payload.glid
          )),
        }
      }
    }
  }

  return state;
}
