import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import every from 'lodash/every';
import { walkChildren } from "./treeFilterKey";
import { geozoneTypes } from "../components/Main/Map/LayersSelect/layerTypes";
import { cloneDeep } from 'lodash';
import Tree from '../treejs/Tree';

export const objName = (n) => n.name;

export const filter = (arr, query, stringify = objName) => {
  if (!query) {
    return arr;
  }
  return arr.filter(i => {
    return stringify(i).toLowerCase().indexOf(query.toLowerCase()) !== -1;
  }
  );
};

export const filterChecked = (arr, query, stringify = objName, checkedIds = []) => {
  if (!query) {
    return arr;
  }

  return arr.filter(i => checkedIds.indexOf(stringify(i)) > -1);
};

export const filterTree = ({
  tree,
  query = '',
  stringify = n => get(n, 'data.name', ''),
  terminal = n => n.leaf,
  filterPath = 'root'
}, geozoneType, isFields) => {
  for (const nodeKey in tree.map) {
    const node = tree.map[nodeKey];
    node.setAttr('filterShow', false);
  }
  const node = tree.map[filterPath];
  if (node) {
    node.parents().map(n => tree.map[n.path].setAttr('filterShow', true));
    walkChildren(node, n => {
      const _node = tree.map[n.path];
      const filter = (geozoneType !== geozoneTypes.all.type && isFields) ? _node.data.zoneType === geozoneType : true;
      if (terminal(_node)) {
        _node.setAttr('filterShow', filter && !_node.data.removed && !_node.exceptNode &&
          stringify(_node).toLowerCase().indexOf(query.toLowerCase()) !== -1);
      } else {
        _node.setAttr('filterShow', true);
      }
    });
  }
  tree.traverseDF(n => {
    const isNodeValid = isEmpty(n.data) || (!terminal(n) && (isEmpty(n.children) || every(n.children, c => !c.filterShow)));
    if (isNodeValid) {
      n.setAttr('filterShow', false);
    }
  });
  return tree;
};

export const filterVirtualizedTree = (tree) => {
  const getNodes = (result, object) => {
    if (object.leaf && object.filterShow) {
      result.push(object);

      return result;
    }

    if (object.children.length !== 0) {
      const children = object.children.reduce(getNodes, []);

      if (children.length) {
        result.push({ ...object, children });
      }
    }

    return result;
  }

  return tree
    .reduce(getNodes, []);
};

export const mergeTrees = (...trees) => {
  const tree = Tree.empty();
  for (const subTree of trees) {
    if (subTree.root.filterShow) {
      tree.map = { ...subTree.map, ...tree.map };
      tree.root.children = [...subTree.root.children, ...tree.root.children]
    }
  }
  return tree;
}
