import {types} from './FieldActions';

const defaultState = {pending: true, isNdviLoading: false};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.FETCH_SUCCESS:
    case types.LOAD_NDVI:
      state = Object.assign({}, state, {...action.payload});
      break;
  }

  return state;
}
