import {createStore, compose, applyMiddleware} from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createHashHistory } from 'history';
import rootReducer from '../reducers';

const history = createHashHistory();

function configureStore(initialState) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middleware = [
    thunk,
    reactRouterMiddleware,
  ];

  return createStore(rootReducer, initialState, compose(
    applyMiddleware(...middleware)
    )
  );
}

export {configureStore, history};
