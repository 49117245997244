/*eslint no-unused-vars: 0*/
/*eslint react/prop-types: 0*/

import React, {Component} from 'react';
import {Route, Redirect} from 'react-router-dom';
import AuthService from '../../services/AuthService';

export const PrivateRoute = ({component: Component, componentProps, ...rest}) => (
  <Route {...rest} render={props => (
    AuthService.isLoggedIn()
      ? <Component {...componentProps} {...props}/>
      : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
  )}/>
);
