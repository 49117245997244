import {types} from './MeteostationActions';

const defaultState = {pending: true, meteostation: {thermalBar: {thermalBarName: ''}}};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.FETCH_SUCCESS:
      state = Object.assign({}, state, {...action.payload});
      break;
    case types.SELECT_THERMBAR:
      state = {...state, ...action.payload};
      break;
  }

  return state;
}
