import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEqualWith from 'lodash/isEqualWith';
import cloneDeep from 'lodash/cloneDeep';
import { VEH_STATUS, VEH_TYPE } from '../const';

const filterStatusAndType = ({ tree, status, type }) => {
  // console.log('----> Inside filterStatusAndType');
  for (const nodeKey in tree.map) {
    const node = tree.map[nodeKey];
    if (node.isRoot) {
      continue;
    }
    if (node.leaf) {
      const unitState = get(node, 'data.unitState');
      const vehicleType = get(node, 'data.type');
      if (!unitState && !vehicleType) {
        node.exceptNode = false;
        continue;
      } else if (!unitState) {
        node.exceptNode = (type.indexOf(vehicleType) === -1);
        continue;
      } else if (!vehicleType) {
        node.exceptNode = (status.indexOf(unitState) === -1);
        continue;
      }
      if (status[0] === VEH_STATUS.ANY && type[0] === VEH_TYPE.WAREHOUSE) {
        node.exceptNode = type.indexOf(vehicleType) === -1;
        continue;
      }
      node.exceptNode = !(status.indexOf(unitState) !== -1 &&
        type.indexOf(vehicleType) !== -1);
    }
  }
  return tree;
};

const isEqualTree = (t1, t2) => {
  let equal = true;
  for (let path of Object.keys(t1.map)) {
    if (!t1.map[path].leaf) {
      continue;
    }
    const data1 = get(t1.map[path], 'data', {});
    const data2 = get(t2.map[path], 'data', {});
    equal = equal && isEqualWith(data1, data2, (v1, v2) => v1.unitState === v2.unitState && v1.type === v2.type);
    if (!equal) {
      break;
    }
  }
  return equal;
};

export const checkArguments = (keys, status, type) => {
  let resultKey = '';
  keys.forEach(key => {
    const objectKey = JSON.parse(key);
    if (isEqual(objectKey.status, status) && isEqual(objectKey.type, type)) {
      resultKey = key;
    }
  });
  return resultKey;
};

const updateTreeData = tree => {
  const newTree = { map: {} };
  Object.keys(tree.map)
    .filter(path => tree.map[path].leaf)
    .forEach(path => {
      newTree.map[path] = {
        data: cloneDeep(get(tree.map[path], 'data', {})),
      };
    });
  return newTree;
};

export const helperDate = (a) => {
  const b = new Date();
  return b.getTime() - a.getTime();
};

const memoizeFilterVehicle = () => {
  let _treeData = { map: {} };
  let _cache = {};
  let _tree = {};
  return ({ tree, status, type }) => {
    if (!isEqualTree(tree, _treeData)) {
      // console.log('----> Update cache');
      _cache = {};
      _treeData = updateTreeData(tree);
      _tree = tree;
    }

    const key = checkArguments(Object.keys(_cache), status, type);
    if (key && _cache[key]) {
      // console.log('----> Get from cache', helperDate(a));
      return _tree;
    }

    Object.keys(_cache).forEach(key => _cache[key] = false);
    _tree = filterStatusAndType({ tree, status, type });
    _cache[JSON.stringify({ status, type })] = true;
    // console.log('----> function takes', helperDate(a));
    return _tree;
  };
};

export default new memoizeFilterVehicle();
