import values from 'lodash/values';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {url} from '../api';
import {actionsByCulture} from '../components/Main/Plans/PlansActions';
import BaseDataService from "../baseClasses/BaseDataService";
import {mapValuesForKeys} from "../helpers/mapValuesForKeys";
import FieldService from "./FieldService";
import store from "../store/store";
import {actions} from "../components/Main/Plans/PlanFact/PlanFactActions";
import moment from "moment/moment";
import reportType from "../const/reportType";
import AuthService from "./AuthService";
import httpClient from "@infobis/api-module";
import pick from "lodash/pick";
import keys from "lodash/keys";
import Tree from "../treejs/Tree";
import DepartmentService from "./DepartmentService";
import {parseFolderPath} from "../helpers/parseFolderPath";
import {stringHash} from "../helpers/stringHash";
import {Notification} from './../components/common/Notification';

class PlanByCultureService extends BaseDataService {
  _planOperationHistory = {};
  _planOperationHistoryByMonth = {};

  constructor() {
    super({
      urlAll: url.plans,
      ...actionsByCulture,
      qs: {}
    });

    window.PlanByCultureService = this;
  }

  fetchData = (id) => store.dispatch(actions.fetch({id, viewCulture: true}));

  _afterFetch() {
    super._afterFetch();

    const plans = values(this.data.map).filter(n => n.depth === 3);
    for (const planNode of plans) {
      const listItem = mapValuesForKeys(planNode.data, [
        'glid',
        'name',
        'from',
        'to',
        {
          key: 'works',
          value: get(planNode.data, 'data', []).map(x => x.name),
        },
        {
          key: 'fields',
          value: planNode.data.fields.map(fieldId => get(FieldService.getItem(fieldId), 'name'))
        },
      ]);
      planNode.data.listItem = listItem;
    }
  }

  async fetchPlanOperationHistory(glid) {
    const plan = !isEmpty(this.data) ? get(values(this.data.map).find(x => x.data.glid === glid), 'data') : null;

    if (isEmpty(this._planOperationHistory[glid])) {
      store.dispatch(actions.fetchPlanOperationHistory(glid));
    }

    if (isEmpty(plan)) {
      const t = setTimeout(() => {
        this.fetchPlanOperationHistory(glid);
        clearTimeout(t);
      }, 500);
      return;
    }

    await this.loadPlanOperationHistory(plan, glid);
    await this.loadPlanOperationHistory(plan, glid, reportType.PLAN_BY_MONTH);
  }

  async loadPlanOperationHistory(plan, glid, reportId = reportType.PLAN) {
    const now = moment();
    const user = AuthService.getUser();

    const request = {
      url: url.reportData,
      params: {
        method: 'POST',
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
      authorize: true,
      body: JSON.stringify({
        from: now.toJSON(),
        to: now.toJSON(),
        reportId,
        entityId: plan.fields,
        strEntityId: plan.appointments,
        tzOffset: -now.utcOffset(),
        limit: 25,
        page: 1,
        start: 0,
        filters: {
          fieldsFilter: [],
          unitsFilter: [],
          driversFilter: [],
        },
      }),
    };


    try {
      const response = await httpClient.makeRequest(request);
      if (response && response.success && response.data) {
        if (reportId === reportType.PLAN) {
          this.updateStuff(glid, response.data, plan.fields);
        } else if (reportId === reportType.PLAN_BY_MONTH) {
          this.updateStuffByMonth(glid, response.data, plan.fields);
        }
      }
    } catch (error) {
      Notification.error(error);
    }

    store.dispatch(actions.fetchPlanOperationHistoryDone(glid));
  }

  updateStuff(glid, data) {
    for (const operation of data) {
      operation.fieldsIds = [operation.idField];
      operation.operation = operation.operation.replace('/', '\'');
    }
    this._planOperationHistory[glid] = data;
  }

  updateStuffByMonth(glid, data) {
    const grouped = {};
    let curOp = '';

    data.forEach((o) => {
      if (o.operation) {
        curOp = stringHash(o.operation.replace(/\\/ig, ''));
        grouped[curOp] = [o];
      } else if (o.numberMonth !== undefined) {
        grouped[curOp] = [...grouped[curOp], o];
      }
    });
    this._planOperationHistoryByMonth[glid] = grouped;
  }

  getPlanOperationHistory() {
    const grouped = {};

    for (const key of keys(this._planOperationHistory)) {
      grouped[key] = {};
      const operations = this._planOperationHistory[key];
      for (const operation of operations) {
        operation.fieldsData = operation.fieldsIds.map(glid => pick(FieldService.getItem(glid), ['glid', 'name', 'area']));
        operation.operationName = operation.operation.replace(operation.planTitle, '').slice(0, -2).trim();

        const dep = values(DepartmentService.data.map).find(x => x.data.name === operation.department);
        operation.departmentPath = parseFolderPath(get(dep, 'path', ''), true);
      }

      grouped[key] = operations;
    }

    return grouped;
  }

  getFactOperationHistory(groupId, oHash) {
    return get(this._planOperationHistoryByMonth, `[${groupId}][${oHash}]`, []);
  }

  saveTreeState(node) {
    const treeNode = values(this.data.map).find(n => n.data.glid === node.data.glid);
    treeNode.opened = node.opened;
    super.saveTreeState();
  }

  syncNodeExpandState(node) {
    const t = setTimeout(() => {
      clearTimeout(t);
      const nd = get(this.data, `map[${node.path}]`);

      if (isEmpty(nd)) {
        this.syncNodeExpandState(node);
        return;
      }

      nd.opened = node.opened;
    }, 50);
  }

  getTreeForId(id) {
    const tree = super.getTreeForId(id);
    const node = values(tree.map).find(n => !n.isRoot && n.data.glid === id);
    if (isEmpty(node)) {
      return tree;
    }

    node.removeChildren();
    Tree._setParents(tree.root);
    tree.setMap();

    return tree;
  }

  fetchPlanOperationHistoryForCulture({id}) {
    values(this._glidMap).find(x => x.glid === id);
  }
}

export default new PlanByCultureService();
