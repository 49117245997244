import moment from 'moment';
import {types as wTypes} from '../ShiftsActions';
import {types} from './ShiftCreateActions';
import ShiftService from "../../../../services/ShiftService";

const defaultState = () => Object.assign({
  shift: ShiftService.newShift(),
  employeeFilterStr: '',
  employeeShowChecked: false,
  operatorsShiftMode: {},
  relatedEntityListPending: false,
  selectedDriver: null,
  selectedDay: moment().locale('ru').format('DD.MM.YYYY'),
  weekendDay: null,
  operatorDayShiftMode: {},
  isSaving: false,
});

export default function (state = defaultState(), action) {
  switch (action.type) {
    case types.RESET_FORM:
      state = Object.assign({}, defaultState());
      break;
    case wTypes.FETCH:
    case wTypes.FETCH_PENDING:
    case wTypes.FETCH_SUCCESS:
    case wTypes.FETCH_ERROR:
    case types.SELECT_DEPARTMENT:
    case types.SELECT_INITIAL_DEPARTMENT:
    case types.SET_DURATION:
    case types.SET_WORKER_STR:
    case types.TOGGLE_WORKER_CHECKALL:
    case types.SET_ASSIGNEE:
    case types.SET_PERIOD:
    case types.SET_DEFAULT_SHIFT_TYPE_FOR_WORKER:
    case types.SET_SHIFT_FOR_WORKER:
    case types.SET_SHIFT_FROM_FOR_WORKER:
    case types.FETCH_SHIFTLIST_ENTITIES_PENDING:
    case types.SET_DEFAULT_VEHICLE_FOR_WORKER:
    case types.SET_SELECTED_DRIVER:
    case types.SET_SELECTED_DAY:
    case types.SET_DAYS_FOR_OPERATORS:
    case types.SET_WEEKEND:
    case types.TOGGLE_WEEKEND:
    case types.SET_VEHICLE_FOR_SHIFT:
    case types.SET_DAY_SHIFT_MODE:
    case types.SET_DAY_SHIFT_DEFAULT:
    case types.SET_SHIFT_FROM_FOR_DAY:
    case types.SET_SHIFT_TO_FOR_DAY:
    case types.EDIT_SHIFT:
    case types.DUPLICATE_SHIFT:
    case types.SAVING:
      state = Object.assign({}, state, {...action.payload});
      break;
  }

  return state;
}
