export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';

// action для открытия модального окна
export function modalOpen(modalConfig) {
  return dispatch => {
    dispatch({
      type: MODAL_OPEN,
      payload: {
        modalConfig,
        isModalOpened: true,
      }
    });
  };
}

// action для закрытия модального окна
export function modalClose() {
  return dispatch => {
    dispatch({
      type: MODAL_CLOSE,
      payload: {
        modalConfig: null,
        isModalOpened: false,
      }
    });
  };
}
