import store from '../store/store';
import orderBy from 'lodash/orderBy';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import {actions as listActions} from '../components/Main/WorkShift/WorkShiftActions';
import {actions as shiftActions} from '../components/Main/WorkShift/Shift/ShiftActions';
import FieldWorkService from './FieldWorkService';
import OperationService from './OperationService';
import {agroWsEvents, WebsocketService} from "./WebsocketService";
import FieldService from "./FieldService";
import fetchStatus from "../const/fetchStatus";
import moment from "moment";
import TrackService from "./TrackService";

class WorkShiftService {
  _props = {};
  _prevProps = {};
  _operation = {};
  _field = {};
  _shiftList = [];

  constructor() {
    window.WorkShiftService = this;
  }

  async fetch({fieldId, operationId, sourceId, dateStart}) {
    if (isEqual(this._props, {fieldId, operationId, sourceId, dateStart})) {
      return Promise.resolve();
    }

    this._status = fetchStatus.PENDING;

    this._props = {fieldId, operationId, sourceId, dateStart};

    if (!isEqual(this._props, this._prevProps)) {
      if (this._prevProps.dateStart === this._props.dateStart) {
        this._field = {};
        this._shiftList = [];
      }

      this._operation = {};

      store.dispatch(listActions.fetchSuccess());
      store.dispatch(shiftActions.fetchSuccess());
    }

    this._prevProps = {...this._props};

    FieldWorkService.fetch(fieldId).then(() => {
      WebsocketService.cbOnOpen(() => {
        WebsocketService.removeListener(agroWsEvents.AG_CHANGES, () => this.onUpdate(sourceId));
        WebsocketService.addListener(agroWsEvents.AG_CHANGES, () => this.onUpdate(sourceId));
        this.onUpdate();
        this._field = FieldService.getItem(fieldId);
        this.loadOperationDetails();
      });
    });
  }

  reset() {
    this._props = {};
    this._operation = {};
    this._field = {};
    this._shiftList = [];
    this._status = fetchStatus.DEFAULT;
  }

  clear() {
    this.reset();
  }

  isPending() {
    return this._status === fetchStatus.PENDING;
  }

  onUpdate() {
    if (!window.updatingOn) {
      return;
    }
    this._status = fetchStatus.DEFAULT;
    this._setOperation();
  }

  _setOperation() {
    const {fieldId, sourceId} = this._props;
    const work = FieldWorkService.getItem(this._props);
    if (isEmpty(work) || isEmpty(work.operations)) {
      return;
    }
    this._operation = work.operations.find(i => Number(i.fieldId) === Number(fieldId) && Number(i.sourceId) === Number(sourceId));
  }

  async loadOperationDetails() {
    if (isEmpty(this._operation)) {
      this._setOperation();
    }
    if (isEmpty(this._operation)) {
      return Promise.resolve();
    }
    const work = FieldWorkService.getItem(this._props);

    const shifts = await OperationService.getCultivationDetails({
      //...this._operation,
      from: work.from,
      to: work.to,
      unitId: this._props.sourceId,
      geoZoneId: this._props.fieldId,
      operationId: this._props.operationId,
    });

    if (shifts) {
      for (const shift of shifts) {
        shift.percent = ((shift.areaCultivated / 10000) / this._field.area) * 100;
      }

      this._shiftList = orderBy(shifts, 'endShift', 'desc');

      store.dispatch(listActions.fetchSuccess());
      store.dispatch(shiftActions.fetchSuccess());
    }

  }

  getShiftList() {
    return this._shiftList;
  }

  getOperation() {
    return this._operation;
  }

  getShift({dateShiftStart}) {
    if (isEmpty(this._shiftList)) {
      return {};
    }
    return this._shiftList.find(i => Number(i.startTimeZone) === Number(dateShiftStart));
  }

  getShiftTracks(shiftId, tracks, setTracks, operationId, fieldId) {
    this._shiftList.forEach(async shift => {
      if (shift.startTimeZone === shiftId && shift.areaCultivated > 0) {
        const from = moment(shift.startShift).toISOString();
        const to = moment(shift.endShift).toISOString();
        const track = await TrackService.loadTrack(String(shift.unitId), from, to, operationId, fieldId);
        tracks = {...tracks, [shift.startTimeZone]: track};
        setTracks({tracks: tracks});
      }
    })
  }
}

export default new WorkShiftService();
