import uniq from "lodash/uniq";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import AssignmentService from "../../../../services/AssignmentService";
import filter from "lodash/filter";
import store from "../../../../store/store";

export const types = {
  STATUS_SET_BULK: 'STATUS_SET_BULK__ASSIGNMENT_FILTER',
  STATUS_ADD_BULK: 'STATUS_ADD_BULK__ASSIGNMENT_FILTER',
  STATUS_ADD: 'STATUS_ADD__ASSIGNMENT_FILTER',
  STATUS_REMOVE: 'STATUS_REMOVE__ASSIGNMENT_FILTER',
  SELECT_ASSIGNEE: 'SELECT_ASSIGNEE__ASSIGNMENT_FILTER',
  RESET_STATE: 'RESET_STATE__ASSIGNMENT_FILTER',
  SET_ENTITY_ID: 'SET_ENTITY_ID__ASSIGNMENT_FILTER',
};

export const actions = {
  setEntityId: function (entityId) {
    return (dispatch, getState) => {
      const {main: {assignmentFilter}} = getState();
      dispatch({
        type: types.SET_ENTITY_ID,
        payload: Object.assign({}, assignmentFilter, {entityId}),
      });
      dispatch(actions.statusSetBulk());
    };
  },
  statusSetBulk: function () {
    return (dispatch, getState) => {
      const {main: {assignmentFilter: {entityId, status: {all: prevAll, selected}}}} = getState();
      const allStatuses = uniq(filter(filter(AssignmentService.data, x => !isEmpty(get(x, 'sourcesMapped'))), x => entityId && entityId !== '*'
        ? x.sources.indexOf(entityId) !== -1
        : x
      ).map(x => x.status));
      store.dispatch(actions.statusAddBulk(!isEmpty(allStatuses) ? allStatuses : []));

      dispatch({
        type: types.STATUS_SET_BULK,
        payload: {status: {all: allStatuses, selected}}
      });
      if (isEmpty(prevAll)) {
        dispatch(actions.statusAddBulk(allStatuses));
      }
    };
  },
  statusAddBulk: function (bulkStatuses) {
    return (dispatch, getState) => {
      const {main: {assignmentFilter: {status: {all, selected: prevSelected}}}} = getState();
      const selected = uniq([...prevSelected, ...bulkStatuses]);
      dispatch({
        type: types.STATUS_ADD_BULK,
        payload: {status: {all, selected}}
      });
    };
  },
  statusAdd: function (status) {
    return (dispatch, getState) => {
      const {main: {assignmentFilter: {status: {all, selected: prevSelected}}}} = getState();
      const selected = [...prevSelected, status];

      dispatch({
        type: types.STATUS_ADD,
        payload: {status: {all, selected}}
      });
    };
  },
  statusRemove: function (status) {
    return (dispatch, getState) => {
      const {main: {assignmentFilter: {status: {all, selected: prevSelected}}}} = getState();
      const selected = [...prevSelected].filter(o => o !== status);

      dispatch({
        type: types.STATUS_REMOVE,
        payload: {status: {all, selected}}
      });
    };
  },
  selectAssignee: function (assignee) {
    return (dispatch, getState) => {
      const {main: {assignmentFilter: {assignee: {all}}}} = getState();

      dispatch({
        type: types.SELECT_ASSIGNEE,
        payload: {assignee: {all, selected: assignee}}
      });
    };
  },
  resetState: function () {
    return dispatch => {
      dispatch({
        type: types.RESET_STATE,
      });
    };
  }
};
