export const VEHICLE_FILTER__STATUS = 'VEHICLE_FILTER__STATUS';
export const VEHICLE_FILTER__HIDE_STATUS = 'VEHICLE_FILTER__HIDE_STATUS';
export const VEHICLE_FILTER__TYPE = 'VEHICLE_FILTER__TYPE';
export const VEHICLE_FILTER__HIDE_TYPE = 'VEHICLE_FILTER__HIDE_TYPE';
export const VEHICLE_FILTER__RESET = 'VEHICLE_FILTER__RESET';

export function onChangeStatusActive(status, isActive = true) {
  return dispatch => {
    dispatch({
      type: VEHICLE_FILTER__STATUS,
      status,
      isActive,
    });
  };
}

export function onHideStatus(status, isHide = false) {
  return dispatch => {
    dispatch({
      type: VEHICLE_FILTER__HIDE_STATUS,
      status,
      isHide,
    });
  };
}

export function onChangeTypeActive(vehicleType, isActive = true) {
  return dispatch => {
    dispatch({
      type: VEHICLE_FILTER__TYPE,
      vehicleType,
      isActive,
    });
  };
}

export function onHideType(vehicleType, isHide = false) {
  return dispatch => {
    dispatch({
      type: VEHICLE_FILTER__HIDE_TYPE,
      vehicleType,
      isHide,
    });
  };
}

export function reset() {
  return dispatch => {
    dispatch({
      type: VEHICLE_FILTER__RESET,
    });
  };
}

const types = {
  VEHICLE_FILTER__STATUS,
  VEHICLE_FILTER__HIDE_STATUS,
  VEHICLE_FILTER__TYPE,
  VEHICLE_FILTER__HIDE_TYPE,
  VEHICLE_FILTER__RESET,
};

const actions = {
  onChangeStatusActive,
  onHideStatus,
  onChangeTypeActive,
  onHideType,
  reset,
};

export {types, actions};
