import {createActions} from "../../../../baseClasses/ActionFactory";
import VehicleWorkService from "../../../../services/VehicleWorkService";

function onFetch(vehicleId) {
  VehicleWorkService.fetch(vehicleId);
  return {vehicleId};
}

export function fetchSuccessOld(data) {
  return (dispatch, getState) => {
    const vehicle = getState().main.vehicle.get('vehicle'),
      operations = getState().main.operations,
      vehicleId = vehicle.glid;
    let vehicleWork = [],
      vehicleWorkItem = {};
    data.forEach((work) => {
      if (work.sourceId === Number(vehicleId)) {
        vehicleWorkItem = work;
        vehicleWorkItem.workName = operations.data[work.operationId].title;
        vehicleWorkItem.color = operations.data[work.operationId].color;
        vehicleWork.push(vehicleWorkItem);
      }
    });

    if (vehicleWork.length > 0) {
      dispatch({
        data: vehicleWork
      });
    }
  };
}

const {types, actions} = createActions({store: 'VEHICLE_WORK_LIST', onFetch});
export {types, actions};
