import {url} from "../api";
import appConfig from '../appConfig';
import DepartmentService from "./DepartmentService";
import AuthService from "./AuthService";
import isEmpty from "lodash/isEmpty";
import {Notification} from '../components/common/Notification';

const items = {
  currentDepartment: 'currentDepartment',
  isLoggedIn: 'isLoggedIn',
  lastLogin: 'lastLogin',
  mapPosition: 'mapPosition',
  localStorageMark: 'localStorageMark',
  weightedPeriod: 'weightedPeriod',
  harvesterUploadPeriod: 'harvesterUploadPeriod',
  tractorRecordPeriod: 'tractorRecordPeriod',
  errorLogs: 'errorLogs',

  treeState: (treeId) => `treeState${treeId}`,

  departments: url.department,
  vehicles: url.vehicle,
  employees: url.employee,
  fields: url.field,
  fieldTools: url.fieldTools,
};

class LocalstorageService {
  static compareMark() {
    const prevMark = new Date(localStorage.getItem(items.localStorageMark));
    const currentMark = new Date(appConfig.localStorageMark);

    if (!prevMark || prevMark < currentMark) {
      LocalstorageService.dropStorage();
      this.setMark(currentMark);
    }
  }

  static setMark(newMark) {
    localStorage.setItem(items.localStorageMark, newMark.toISOString());
  }

  static dropStorage() {
    localStorage.clear();
  }

  static getCacheForUserDepartment(data) {
    const user = AuthService.getUser();
    if (!user) {
      Notification.error('Пользователь не найден');
      AuthService.logOut();
      return false;
    }
    const depParams = DepartmentService.getCurrentDepartmentForRequest();

    return {userId: user.userInfo.id, department: depParams, data};
  }

  static getCacheForUser(data) {
    const user = AuthService.getUser();
    if (!user) {
      Notification.error('Пользователь не найден');
      AuthService.logOut();
      return false;
    }
    return {userId: user.userInfo.id, data};
  }

  static isCacheForSameUserDepartment(key) {
    const user = AuthService.getUser();
    if (!user) {
      Notification.error('Пользователь не найден');
      AuthService.logOut();
      return false;
    }
    const depParams = DepartmentService.getCurrentDepartmentForRequest();
    const cached = JSON.parse(localStorage.getItem(key));
    if (isEmpty(cached)) {
      return false;
    }

    return cached.userId === user.userInfo.id
      && cached.department.clientId === depParams.clientId
      && cached.department.departmentId === depParams.departmentId
      && cached.department.companyId === depParams.companyId;
  }

  static isCacheForSameUser(key) {
    const user = AuthService.getUser();
    if (!user) {
      Notification.error('Пользователь не найден');
      AuthService.logOut();
      return false;
    }
    const cached = JSON.parse(localStorage.getItem(key));
    if (isEmpty(cached)) {
      return false;
    }

    return cached.userId === user.userInfo.id;
  }
}

export {items as localStorageItems, LocalstorageService};
