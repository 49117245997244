import immutable from 'immutable';
import {push} from 'react-router-redux';
import get from 'lodash/get';
import {createActions} from '../../../../baseClasses/ActionFactory';
import {tabTypes} from './constants';
import {routes} from '../../../../routes';
import TaskService from '../../../../services/TaskService';
import {update} from '../../MainActions';

export const CREATE_TASK__SUCCESS = 'CREATE_TASK__SUCCESS';
export const CREATE_TASK__ERROR = 'CREATE_TASK__ERROR';
export const CREATE_TASK__PENDING = 'CREATE_TASK__PENDING';

export const CREATE_TASK__EDIT_SUCCESS = 'CREATE_TASK__EDIT_SUCCESS';
export const CREATE_TASK__EDIT_ERROR = 'CREATE_TASK__EDIT_ERROR';
export const CREATE_TASK__EDIT_PENDING = 'CREATE_TASK__EDIT_PENDING';

export const CREATE_TASK__CLEAR_STORE = 'CREATE_TASK__CLEAR_STORE';
export const CREATE_TASK__CLEAR_STORE_FOR_TAB = 'CREATE_TASK__CLEAR_STORE_FOR_TAB';
export const CREATE_TASK__SELECT_TAB = 'SELECT_TAB';
export const CREATE_TASK__STATE_FROM_TASK = 'CREATE_TASK__STATE_FROM_TASK';

export const PREVIEW_MODE = 'PREVIEW_MODE';

export const WORK__SELECT_WORK = 'SELECT_WORK';
export const WORK__SELECT_DEPARTMENT = 'SELECT_DEPARTMENT';

export const DATE__SELECT = 'DATE__SELECT';

export const FIELD__FILTER = 'FIELD__FILTER';
export const FIELD__FILTER_CHECKED = 'FIELD__FILTER_CHECKED';
export const FIELD__SELECT = 'FIELD__SELECT';

export const VEHICLE__FILTER = 'VEHICLE__FILTER';
export const VEHICLE__FILTER_CHECKED = 'VEHICLE__FILTER_CHECKED';
export const VEHICLE__SELECT = 'VEHICLE__SELECT';

export const FIELD_TOOL__CHECKED = 'FIELD_TOOL__CHECKED';
export const FIELD_TOOL__SELECT = 'FIELD_TOOL__SELECT';
export const FIELD_TOOL__SELECT_VEHICLE = 'FIELD_TOOL__SELECT_VEHICLE';

export const TRANSPORT_ROUTE__RENAME = 'TRANSPORT_ROUTE__RENAME';
export const TRANSPORT_ROUTE__SELECT = 'TRANSPORT_ROUTE__SELECT';
export const TRANSPORT_ROUTE__UPDATE_FIELDS = 'TRANSPORT_ROUTE__UPDATE_FIELDS';
export const TRANSPORT_ROUTE__SELECT_DURATION = 'TRANSPORT_ROUTE__SELECT_DURATION';
export const TRANSPORT_ROUTE__SWITCHED = 'TRANSPORT_ROUTE__SWITCHED';
export const TRANSPORT_ROUTE__START_END_RACE = 'TRANSPORT_ROUTE__START_END_RACE';

export const GOODS__UPDATE_VEHICLES = 'GOODS__UPDATE_VEHICLES';
export const GOODS__SELECT = 'GOODS__SELECT';
export const GOODS__INPUT_LOADING_WEIGHT = 'GOODS__INPUT_LOADING_WEIGHT';
export const GOODS__INPUT_TRAILER_WEIGHT = 'GOODS__INPUT_TRAILER_WEIGHT';

export const LIMIT_SPEED__CHECKED = 'LIMIT_SPEED__CHECKED';
export const LIMIT_SPEED__SELECT = 'LIMIT_SPEED__SELECT';
export const LIMIT_SPEED__SELECT_VEHICLE = 'LIMIT_SPEED__SELECT_VEHICLE';

export const CREATE_TASK__HORIZONTAL_SCROLL = 'CREATE_TASK__HORIZONTAL_SCROLL';

function reset() {
  return dispatch => {
    dispatch({type: CREATE_TASK__CLEAR_STORE});
  };
}

function onSelectTab(tabType = tabTypes.WORK) {
  return dispatch => {
    dispatch({
      type: CREATE_TASK__SELECT_TAB,
      tab: tabType,
    });
  };
}

function onCreatingTask(params) {
  return async dispatch => {
    dispatch({
      type: CREATE_TASK__PENDING,
    });
    await TaskService.createTask(params);
  };
}

function createTaskSuccess(response) {
  return (dispatch, getState) => {
    const {main: {task: {from, to}}} = getState();
    dispatch({
      type: CREATE_TASK__SUCCESS,
      operationName: get(response, 'data.operation', 'Без имени'),
    });
    dispatch({
      type: CREATE_TASK__CLEAR_STORE,
    });
    dispatch(push(routes.task('')));
    TaskService.fetch(from, to, true);
    dispatch(update());
  };
}

function createTaskError(error) {
  return dispatch => {
    dispatch({
      type: CREATE_TASK__ERROR,
      error,
    });
  };
}

function onEditingTask(taskId, params) {
  return async dispatch => {
    dispatch({
      type: CREATE_TASK__EDIT_PENDING,
    });
    await TaskService.editTask(taskId, params);
  };
}

function editTaskSuccess(response) {
  return (dispatch, getState) => {
    const {main: {task: {from, to}}} = getState();

    dispatch({
      type: CREATE_TASK__EDIT_SUCCESS,
      operationName: get(response, 'data.operation', 'Без имени'),
    });
    dispatch({
      type: CREATE_TASK__CLEAR_STORE,
    });
    dispatch(push(routes.task('')));
    TaskService.fetch(from, to, true);
  };
}

function editTaskError(error) {
  return dispatch => {
    dispatch({
      type: CREATE_TASK__EDIT_ERROR,
      error,
    });
  };
}

function stateFromTask(task, isEdit = false) {
  return dispatch => {
    dispatch({
      type: CREATE_TASK__STATE_FROM_TASK,
      task,
      isEdit,
    });
  };
}

function switchPreviewMode(preview = false) {
  return dispatch => {
    dispatch({
      type: PREVIEW_MODE,
      preview,
    });
  };
}

function onSelectWork(work) {
  return dispatch => {
    dispatch({
      type: WORK__SELECT_WORK,
      work,
    });
    if (get(work, 'operationType') === 'transportation') {
      dispatch({
        type: CREATE_TASK__CLEAR_STORE_FOR_TAB,
        tab: tabTypes.FIELD_TOOL,
      });
    }
  };
}

function onSelectDepartment(selectedId) {
  return dispatch => {
    dispatch({
      type: WORK__SELECT_DEPARTMENT,
      selectedId,
    });
  };
}

function onSelectDate(date, endDate) {
  return dispatch => {
    dispatch({
      type: DATE__SELECT,
      date,
      endDate,
    });
  };
}

function onCheckedFilterField(checked = false) {
  return dispatch => {
    dispatch({
      type: FIELD__FILTER_CHECKED,
      checked,
    });
  };
}


function onSelectFields(selected = []) {
  return dispatch => {
    dispatch({
      type: FIELD__SELECT,
      selectedIds: immutable.fromJS(selected.map(item => item.glid)),
    });
    dispatch({
      type: TRANSPORT_ROUTE__UPDATE_FIELDS,
      selected,
    });
  };
}

function onCheckedFilterVehicle(checked = false) {
  return dispatch => {
    dispatch({
      type: VEHICLE__FILTER_CHECKED,
      checked,
    });
  };
}

function onSelectVehicles(selected = []) {
  const vehicleIds = selected.map(item => item.glid);
  return dispatch => {
    dispatch({
      type: VEHICLE__SELECT,
      selectedIds: immutable.fromJS(vehicleIds),
    });
    dispatch({
      type: FIELD_TOOL__SELECT_VEHICLE,
      vehicleIds,
    });
    dispatch({
      type: LIMIT_SPEED__SELECT_VEHICLE,
      vehicleIds,
    });
    dispatch({
      type: GOODS__UPDATE_VEHICLES,
      selected,
    });
  };
}

function onCheckedFieldTool(checked = false) {
  return dispatch => {
    dispatch({
      type: FIELD_TOOL__CHECKED,
      checked,
    });
  };
}

function onSelectFieldTool(selectObject, dependedObjectId = null) {
  return dispatch => {
    dispatch({
      type: FIELD_TOOL__SELECT,
      selectObject,
      dependedObjectId,
    });
  };
}

function onCheckedLimitSpeed(checked = false) {
  return dispatch => {
    dispatch({
      type: LIMIT_SPEED__CHECKED,
      checked,
    });
  };
}

function onSelectLimitSpeed(selectObject, dependedObjectId = null) {
  return dispatch => {
    dispatch({
      type: LIMIT_SPEED__SELECT,
      selectObject,
      dependedObjectId,
    });
  };
}

function onScroll(left = 0) {
  return dispatch => {
    dispatch({
      type: CREATE_TASK__HORIZONTAL_SCROLL,
      left,
    });
  };
}

function onChangeName(name = '') {
  return dispatch => {
    dispatch({
      type: TRANSPORT_ROUTE__RENAME,
      name,
    });
  };
}

function onSelectTransportRoute(transportRoute) {
  return dispatch => {
    dispatch({
      type: TRANSPORT_ROUTE__SELECT,
      transportRoute,
    });
    dispatch({
      type: TRANSPORT_ROUTE__RENAME,
      name: get(transportRoute, 'title', 'Без имени'),
      isSelectRoute: true,
    });
  };
}

function onSelectDuration(limitDurationMs = 0, fieldId) {
  return dispatch => {
    dispatch({
      type: TRANSPORT_ROUTE__SELECT_DURATION,
      limitDurationMs,
      fieldId,
    });
  };
}

function onSwitchLoadingType(loadingType, fieldId) {
  return dispatch => {
    dispatch({
      type: TRANSPORT_ROUTE__SWITCHED,
      loadingType,
      fieldId,
    });
  };
}

function onToggleStartEndRace(startEndRace = false, fieldId) {
  return dispatch => {
    dispatch({
      type: TRANSPORT_ROUTE__START_END_RACE,
      startEndRace,
      fieldId,
    });
  };
}

function onSelectGoods(goods, vehicleId) {
  return dispatch => {
    dispatch({
      type: GOODS__SELECT,
      goods,
      vehicleId,
    });
  };
}

function onInputLoadingWeight(loadingWeight, vehicleId) {
  return dispatch => {
    dispatch({
      type: GOODS__INPUT_LOADING_WEIGHT,
      loadingWeight,
      vehicleId,
    });
  };
}

function onInputTrailerWeight(trailerWeight, vehicleId) {
  return dispatch => {
    dispatch({
      type: GOODS__INPUT_TRAILER_WEIGHT,
      trailerWeight,
      vehicleId,
    });
  };
}

const {types: generalTypes, actions: generalActions} = createActions({store: 'CREATE_TASK'});
const allActions = {
  ...generalActions,
  reset,
  createTaskSuccess,
  createTaskError,
  onCreatingTask,
  editTaskSuccess,
  editTaskError,
  onEditingTask,
  onSelectTab,
  stateFromTask,
  switchPreviewMode,
  onSelectWork,
  onSelectDepartment,
  onSelectDate,
  onCheckedFilterField,
  // onSearchField,
  onSelectFields,
  onCheckedFilterVehicle,
  // onSearchVehicle,
  onSelectVehicles,
  onCheckedFieldTool,
  onSelectFieldTool,
  onCheckedLimitSpeed,
  onSelectLimitSpeed,
  onScroll,
  onChangeName,
  onSelectTransportRoute,
  onSelectDuration,
  onSwitchLoadingType,
  onToggleStartEndRace,
  onSelectGoods,
  onInputLoadingWeight,
  onInputTrailerWeight,
};
const allTypes = {
  ...generalTypes,
  CREATE_TASK__SELECT_TAB,
  CREATE_TASK__SUCCESS,
  CREATE_TASK__ERROR,
  CREATE_TASK__PENDING,
  CREATE_TASK__EDIT_SUCCESS,
  CREATE_TASK__EDIT_ERROR,
  CREATE_TASK__EDIT_PENDING,
  CREATE_TASK__CLEAR_STORE,
  CREATE_TASK__CLEAR_STORE_FOR_TAB,
  CREATE_TASK__STATE_FROM_TASK,
  PREVIEW_MODE,
  WORK__SELECT_DEPARTMENT,
  WORK__SELECT_WORK,
  DATE__SELECT,
  FIELD__FILTER_CHECKED,
  FIELD__SELECT,
  VEHICLE__FILTER_CHECKED,
  VEHICLE__SELECT,
  FIELD_TOOL__CHECKED,
  FIELD_TOOL__SELECT,
  FIELD_TOOL__SELECT_VEHICLE,
  LIMIT_SPEED__CHECKED,
  LIMIT_SPEED__SELECT,
  LIMIT_SPEED__SELECT_VEHICLE,
  CREATE_TASK__HORIZONTAL_SCROLL,
  TRANSPORT_ROUTE__RENAME,
  TRANSPORT_ROUTE__SELECT,
  TRANSPORT_ROUTE__UPDATE_FIELDS,
  TRANSPORT_ROUTE__SELECT_DURATION,
  TRANSPORT_ROUTE__SWITCHED,
  TRANSPORT_ROUTE__START_END_RACE,
  GOODS__UPDATE_VEHICLES,
  GOODS__SELECT,
  GOODS__INPUT_LOADING_WEIGHT,
  GOODS__INPUT_TRAILER_WEIGHT
};

export {allTypes as types, allActions as actions};
