import immutable from 'immutable';
import {types} from './TransportRoutesActions';

const defaultState = {};

export default function (state = immutable.fromJS(defaultState), action) {
  switch (action.type) {
    case types.FETCH_SUCCESS:
      state = {...state};
      break;
  }
  return state;
}
