import {types as lTypes} from '../ShiftsActions';

const defaultState = {
  timeStamp: Date.now(),
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case lTypes.FETCH:
    case lTypes.FETCH_PENDING:
    case lTypes.FETCH_SUCCESS:
    case lTypes.FETCH_ERROR:
      state = Object.assign({}, defaultState, {timestamp: Date.now()});
  }

  return state;
}
