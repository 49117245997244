import {url} from '../api';
import BaseDataService, {responseDataShape} from "../baseClasses/BaseDataService";
import {actions} from "../components/Main/GeoZoneLoadingTypes/GeoZoneLoadingTypesActions";

class GeoZoneLoadingTypesService extends BaseDataService {
  constructor() {
    super({
      urlAll: url.geoZoneLoadingTypes,
      fetchPending: actions.fetchPending,
      fetchSuccess: actions.fetchSuccess,
      fetchError: actions.fetchError,
      dataShape: responseDataShape.ARRAY,
      qs: {
        page: 1,
        start: 0,
        limit: 100000,
      }
    });
    window.GeoZoneLoadingTypesService = this;
  }

  _setGlidMapForArray() {
    const map = {};
    for (const i of this.data) {
      map[i.name] = i;
    }
    this._glidMap = map;
    this._lastUpdated = Date.now();
  }

  _parseFetchResponse(response) {
    super._parseFetchResponse({data: response});
  }
}

export default new GeoZoneLoadingTypesService();
