import {url} from '../api';
import BaseDataService, {responseDataShape} from "../baseClasses/BaseDataService";
import {fetchPending, fetchSuccess, fetchError} from "../components/Main/Operations/OperationsActions";
import httpClient from "@infobis/api-module";
import AuthService from "./AuthService";
import FieldService from "./FieldService";
import MainService from "./MainService";
import {Notification} from './../components/common/Notification';

class OperationService extends BaseDataService {
  constructor() {
    super({urlAll: url.operations, fetchPending, fetchSuccess, fetchError, dataShape: responseDataShape.ARRAY});
    window.OperationService = this;
  }

  _afterFetch() {
    super._afterFetch();
    FieldService._setListMap();
    MainService.dispatchChanges();
  }

  async getCultivationDetails({from, to, unitId, geoZoneId, operationId}) {
    const user = AuthService.getUser();

    const request = {
      url: url.cultivationDetails,
      params: {
        method: 'GET',
      },
      authorize: true,
      qs: {
        from: new Date(from).toISOString(),
        to: new Date(to).toISOString(),
        unitIdList: unitId,
        geoZones: [geoZoneId],
        operations: [operationId],
        tzOffset: new Date().getTimezoneOffset(),
        isEnoughData: true
      },
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "serverId": user.serverId,
        "Authorization": "Bearer " + user.accessToken,
      },
    };

    try {
      const response = await httpClient.makeRequest(request);
      return response;
    } catch (error) {
      Notification.error(error);
    }
  }

  async getOperationShape(fieldId, operationId, dateEnd){
    const user = AuthService.getUser();
    const request = {
      url: url.fieldOperationShape,
      params: {
        method: 'GET'
      },
      authorize: true,
      qs: {
        zoneId: fieldId,
        operationId: operationId,
        date: dateEnd && new Date(dateEnd).toISOString() || new Date().toISOString()
      },
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "serverId": user.serverId,
        "Authorization": "Bearer " + user.accessToken,
      },
    };
    try {
      const response = await httpClient.makeRequest(request);
      return response;
    } catch (error) {
      Notification.error(error);
    }
  }
}

export default new OperationService();
