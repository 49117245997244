import httpClient from "@infobis/api-module";
import {Notification} from "../components/common/Notification";
import AuthService from "./AuthService";
import isEmpty from "lodash/isEmpty";
import {url} from "../api";
import store from "../store/store";
import {actions} from "../components/Main/GoodsTMC/GoodsActions";
import {actions as itemActions} from "../components/Main/GoodsTMC/GoodsItem/GoodsItemActions";
import {actions as itemEditActions} from "../components/Main/GoodsTMC/GoodsItemEdit/GoodsItemEditActions";
import groupBy from "lodash/groupBy";
import OperationService from "./OperationService";
import {ROLE} from "../const/roles";
import get from "lodash/get";
import intersection from "lodash/intersection";
import {uniq, isNull} from 'lodash';
import getAgrosignalTime from '../utils/getAgrosignalTime';
import moment from 'moment';

class InventoryItemsService {
  items = {};
  cultivatedDetails = [];
  storages = [];

  constructor() {
    window.InventoryItemsService = this;
  }

  _getCurrentWriteDowns(params) {
    return this.items[`${params.fieldId}-${params.operationId}-${params.dateStart}`] || [];
  }

  async fetch({work, fieldId, operationId, dateStart}) {
    const user = AuthService.getUser();
    const ids = this.getWriteDowns(work).map(item => item.inventoryItemId);

    const request = {
      url: url.inventoryItems,
      params: {
        method: 'GET',
      },
      qs: {
        id: ids.join(',')
      },
      authorize: true,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
    };

    store.dispatch(actions.fetchPending());

    try {
      const response = await httpClient.makeRequest(request);
      if (response && response.success && response.data) {
        this.items = {...this.items, [`${fieldId}-${operationId}-${dateStart}`]: this.getGoodsList({work}, response.data)};
        store.dispatch(actions.fetchSuccess());
      }
    } catch (error) {
      Notification.error(error);
      store.dispatch(actions.fetchError());
    }
  }

  async getAllInventoryItems() {
    const user = AuthService.getUser();
    const request = {
      url: url.inventoryItems,
      params: {
        method: 'GET',
      },
      authorize: true,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
    };

    store.dispatch(actions.fetchPending());

    try {
      const response = await httpClient.makeRequest(request);
      if (response && response.success && response.data) {
        store.dispatch(actions.fetchSuccess());
        return response.data;
      }
    } catch (error) {
      store.dispatch(actions.fetchError())
      Notification.error(error);
    }
  }

  async getCategories() {
    const user = AuthService.getUser();

    const request = {
      url: '/weighProductionCategorys',
      params: {
        method: 'GET',
      },
      authorize: true,
      headers: {
        "serverId": user.serverId,
        "Authorization": "Bearer " + user.accessToken,
      },
    };

    store.dispatch(actions.fetchPending());

    try {
      const response = await httpClient.makeRequest(request);
      store.dispatch(actions.fetchSuccess());
      return response
    } catch (error) {
      store.dispatch(actions.fetchError())
      Notification.error(error);
    }
  }

  async getCultivatedDetailsEdit(work, params) {
    const unitIdList = work.operations.map(wd => wd.sourceId);

    const data = {
      from: work.from,
      to: work.to,
      unitId: unitIdList,
      geoZoneId: params.fieldId,
      operationId: params.operationId
    }

    store.dispatch(itemEditActions.fetchPending());

    try {
      const res = await OperationService.getCultivationDetails(data);
      store.dispatch(itemEditActions.fetchSuccess());
      return res;
    } catch (error) {
      store.dispatch(itemEditActions.fetchError());
      Notification.error(error);
    }
  }

  async getTmcPrices(goodId, table) {
    const user = AuthService.getUser();

    const params = table.map(x => ({
      goodId: goodId,
      shiftId: x.shiftUniqueId,
      period: [moment(x.startShift).format(), moment(x.endShift).format()]
    }))

    const tmcPricesRequest = {
      url: '/tmc-prices',
      params: {
        method: 'POST',
      },
      authorize: true,
      body: JSON.stringify(params),
      headers: {
        "serverId": user.serverId,
        "Authorization": "Bearer " + user.accessToken,
      },
    }

    store.dispatch(actions.fetchPending());

    try {
      const response = await httpClient.makeRequest(tmcPricesRequest);
      store.dispatch(actions.fetchSuccess());
      return response;
    } catch (error) {
      store.dispatch(actions.fetchError());
      Notification.error(error);
    }
  }

  async getCultivatedDetails(item, params) {
    const unitIdList = uniq(item.table.map(tb => tb.unitId));

    const data = {
      from: item.workFrom,
      to: item.workTo,
      unitId: unitIdList,
      geoZoneId: params.fieldId,
      operationId: params.operationId
    }
    store.dispatch(itemActions.fetchPending());
    const groupedTableData = groupBy(item.table, x => x.shiftUniqueId)

    try {
      this.cultivatedDetails = await OperationService.getCultivationDetails(data);
      const formedCultivatedDetails = this.cultivatedDetails.map(el => {
        const {price, cost, amount} = groupedTableData[el.shiftUniqueId][0];
        return {...el, price, cost, amount};
      });
      store.dispatch(itemActions.fetchSuccess())
      return formedCultivatedDetails;
    } catch (error) {
      store.dispatch(itemActions.fetchError());
      Notification.error(error);
    }
  }

  async getStorage(id) {
    const user = AuthService.getUser();

    const request = {
      url: '/storages',
      params: {
        method: 'GET',
      },
      authorize: true,
      headers: {
        "serverId": user.serverId,
        "Authorization": "Bearer " + user.accessToken,
      },
    };

    store.dispatch(actions.fetchPending());

    try {
      const response = await httpClient.makeRequest(request);
      store.dispatch(actions.fetchSuccess());
      this.storages = response;
      if (id) {
        return response.data.filter(storage => storage.id === id)[0];
      }
      return response.data
    } catch (error) {
      store.dispatch(actions.fetchError());
      Notification.error(error);
    }
  }

  getWriteDowns = (work) => {
    if (isEmpty(work) || isEmpty(work.notes)) return [];

    const filteredNotes = work.notes.filter(x => x.nTemplate === -3);
    return filteredNotes.flatMap(note => note.writeDowns);
  }

  getGoodsList = (props, inventoryItems) => {
    const {work} = props;
    const writeDowns = this.getWriteDowns(work) || [];
    const groupedItems = groupBy(inventoryItems, item => item.id);
    const noteId = work && work.notes[0] && work.notes[0].id

    return writeDowns.reduce((acc, curr) => {
      const currentItem = groupedItems[curr.inventoryItemId]
      if (currentItem === undefined) {
        return [];
      }
      acc.push({
        ...currentItem[0],
        totalAmount: curr.totalAmount,
        isWrittenDownFromStorage: curr.isWrittenDownFromStorage,
        manually: curr.manually,
        table: curr.table,
        productionCategory: curr.productionCategory,
        warehouseId: curr.warehouseId,
        workFrom: work.from,
        workTo: work.to,
        noteId: noteId,
      });
      return acc
    }, []);
  }

  formRequestWriteDowns(params) {
    const {goodId} = params;
    return this._getCurrentWriteDowns(params).filter(x => Number(x.id) !== Number(goodId));
  }

  getGoodsItem(params) {
    return this._getCurrentWriteDowns(params) &&
      this._getCurrentWriteDowns(params).filter(item => Number(item.id) === Number(params.routeAddon || params.goodId))[0];
  }

  hasPermissions() {
    const accessRights =  get(AuthService.getUser(), 'userInfo.accessRights', []);
    const permissionName = "TMC:writeDown";
    return !isEmpty(intersection(accessRights, [permissionName]));
  }

  async deleteTmc(work, items, params) {
    const {fieldId, operationId, dateStart, goodId} = params;
    const writeDown = this._getCurrentWriteDowns(params)[0];
    const {noteId, clientId, workFrom} = writeDown;
    const user = AuthService.getUser();
    const writeDowns = this.formRequestWriteDowns(params).reduce((acc, curr) => {
      acc.push({
        inventoryItemId: curr.id,
        isWrittenDownFromStorage: curr.isWrittenDownFromStorage,
        manually: curr.manually,
        productionCategory: curr.productionCategory,
        totalAmount: curr.totalAmount,
        warehouseId: curr.warehouseId,
        table: curr.table
      });
      return acc;
    }, []);

    const request = {
      url: url.notes,
      params: {
        method: 'PUT',
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
      authorize: true,
      body: JSON.stringify({
        attachments: [],
        attributes: {
          startTime: getAgrosignalTime(work.dateStart),
          endTime: getAgrosignalTime(work.dateEnd),
          idleUnitPartId: null
        },
        client: clientId,
        sourceType: "Field",
        sources: [params.fieldId],
        createdBy: user.id,
        nTemplate: -3,
        noteType: "writeDown",
        text: "",
        title: "Списание ТМЦ",
        operationId: Number(params.operationId),
        createdAt: moment(workFrom).format(),
        writeDowns: writeDowns,
        updateAt: moment().format(),
        id: noteId
      })
    }

    store.dispatch(actions.fetchPending());

    try {
      const response = await httpClient.makeRequest(request);
      this.items = {
        ...this.items,
        [`${fieldId}-${operationId}-${dateStart}`]: this._getCurrentWriteDowns(params).filter(x => Number(x.id) !== Number(goodId))
      };
      store.dispatch(actions.fetchSuccess());
      return response;
    } catch (error) {
      store.dispatch(actions.fetchError());
      Notification.error(error);
    }
  }

  _createWriteDown(good, storageCheck, category, totalAmount, storage, cultivatedDetails, work, goodPrice, existingWriteDown) {
    const isToggleManually = existingWriteDown && existingWriteDown.id === good.id && existingWriteDown.manually;

    const getCost = (item) => {
      return goodPrice[item.shiftUniqueId]
        ? goodPrice[item.shiftUniqueId] * (item.areaCultivated / work.cultivatedArea) * totalAmount
        : 0
    }

    const getTable = () => {
      return cultivatedDetails.map(item => {
        const tableItem = existingWriteDown.table?.find(el => item.shiftUniqueId === el.shiftUniqueId);
        const price = (isToggleManually ? tableItem.price : goodPrice[item.shiftUniqueId]) || 0;

        return ({
          amount: item.areaCultivated / work.cultivatedArea * totalAmount,
          cost: getCost(item),
          driverId: item.driverId,
          price,
          shiftUniqueId: item.shiftUniqueId,
          startShift: Number(getAgrosignalTime(item.startShift)),
          unitId: item.unitId,
          flagAutoTmc: item.flagAutoTmc,
        })
      })
    }

    return {
      id: good.id,
      inventoryItemId: good.id,
      title: good.title,
      isWrittenDownFromStorage: storageCheck,
      measureUnit: good.measureUnit,
      measureId: good.measureId,
      clientId: good.clientId,
      client: good.client,
      group: good.group,
      manually: isToggleManually,
      productionCategory: category ? category.weighProductionCategory : null,
      totalAmount: Number(totalAmount),
      warehouseId: storage ? storage.id : null,
      workFrom: work.from,
      workTo: work.to,
      table: getTable(),
    }
  }

  _createNote(work, good, params, user, operationId, writeDowns, noteId) {
    return JSON.stringify({
      attachments: [],
      attributes: {
        startTime: getAgrosignalTime(work.dateStart),
        endTime: getAgrosignalTime(work.dateEnd),
        idleUnitPartId: null
      },
      client: good.clientId,
      sourceType: "Field",
      sources: [params.fieldId],
      createdBy: user.id,
      nTemplate: -3,
      noteType: "writeDown",
      text: "",
      title: "Списание ТМЦ",
      operationId: operationId,
      createdAt: moment(work.from).format(),
      writeDowns: writeDowns,
      updateAt: moment().format(),
      id: noteId
    })
  }

  async saveTmc(work, cultivatedDetails, good, item, storage, category, goodPrice, storageCheck, params, totalAmount) {
    const {fieldId, dateStart, routeAddon} = params;
    const operationId = Number(params.operationId);
    const isNew = routeAddon === 'new';
    const user = AuthService.getUser();
    const currentWriteDowns = this._getCurrentWriteDowns(params);
    const itemsFilteredById = currentWriteDowns.filter(x => Number(x.id) !== Number(item.id));
    const writeDown = this._createWriteDown(good, storageCheck, category, totalAmount, storage, cultivatedDetails, work, goodPrice, item);
    const isGoodExist = currentWriteDowns.some(x => x.id === writeDown.id);

    if (isEmpty(good)) {
      Notification.error("Выберите товар!");
      return;
    }
    if (isNull(totalAmount)) {
      Notification.error("Введите количество!");
      return;
    }
    if (totalAmount <= 0) {
      Notification.error("Введите корректное количество!");
      return;
    }

    let writeDowns = !isEmpty(currentWriteDowns)
      ? this.formRequestWriteDowns(params).reduce((acc, curr) => {
        acc.push({
          inventoryItemId: curr.id,
          isWrittenDownFromStorage: curr.isWrittenDownFromStorage,
          manually: curr.manually,
          productionCategory: curr.productionCategory,
          totalAmount: curr.totalAmount,
          warehouseId: curr.warehouseId,
          table: curr.table
        });
        return acc;
      }, [])
      : [];

    if (isNew) {
      if (isGoodExist) {
        Notification.error("Нельзя списать такой же товар!");
        return;
      } else {
        writeDowns = isEmpty(currentWriteDowns) ? [writeDown] : [...writeDowns, writeDown];
      }
    } else {
      if (writeDown.id === item.id || !itemsFilteredById.find(x => x.id === writeDown.id)) {
        writeDowns = [...writeDowns.filter(x => x.inventoryItemId !== item.id), writeDown];
      } else {
        Notification.error("Нельзя списать такой же товар!");
        return;
      }
    }

    if (storageCheck) {
      if (isNull(storage)) {
        Notification.error("Вы должны выбрать склад!");
        return;
      }
      if (isNull(category)) {
        Notification.error("Вы должны выбрать категорию!");
        return;
      }
    }

    const request = {
      url: url.notes,
      params: {
        method: isNew && isEmpty(currentWriteDowns) ? 'POST' : 'PUT',
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
      authorize: true,
      body: this._createNote(
        work,
        good,
        params,
        user,
        operationId,
        writeDowns,
        currentWriteDowns.length > 0
          ? currentWriteDowns[0].noteId
          : null)
    };

    store.dispatch(actions.fetchPending());

    try {
      const response = await httpClient.makeRequest(request);
      this.items = {
        ...this.items,
        [`${fieldId}-${operationId}-${dateStart}`]: [
          ...itemsFilteredById,
          {
            ...writeDown,
            noteId: response.data.id,
          },
        ]
      };
      store.dispatch(actions.fetchSuccess());
      return response;
    } catch (error) {
      store.dispatch(actions.fetchError());
      Notification.error(error);
    }
  }
}

export default new InventoryItemsService();
