import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
import remove from 'lodash/remove';
import isEmpty from 'lodash/isEmpty';
import httpClient from "@infobis/api-module";
import {url} from "../api";
import AuthService from "./AuthService";
import store from "../store/store";
import {Notification} from './../components/common/Notification';



const MAX_FILE_SIZE = 10000000;

class FileService {
  processingInfo = [];
  processingFiles = [];
  cachedInfo = {};

  constructor() {
    window.FileService = this;
  }

  async fetchFileInfo(ids) {
    this.processingInfo = [...this.processingInfo, ...ids];
    const user = AuthService.getUser();

    const request = {
      url: url.fileInfo,
      params: {
        method: 'GET',
      },
      authorize: true,
      qs: {
        ids: ids.join(','),
        page: 1,
        start: 0,
        limit: 25,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "serverId": user.serverId,
        "Authorization": "Bearer " + user.accessToken,
      },
    };


    try {
      const response = await httpClient.makeRequest(request);
      if (response && response.success && response.data) {
        response.data.forEach(f => {
          this.cachedInfo[f.id] = f;
        });
        this.processingInfo = difference(this.processingInfo, response.data.map(f => f.id));
      }
    } catch (error) {
      Notification.error(error);
    }
  }

  async deleteFile(id, onFetch, onFetchDone) {
    const user = AuthService.getUser();
    const fileInfo = this.cachedInfo[id];

    const request = {
      url: url.fileInfo,
      params: {
        method: 'DELETE',
      },
      authorize: true,
      qs: {
        ids: id,
        page: 1,
        start: 0,
        limit: 25,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "serverId": user.serverId,
        "Authorization": "Bearer " + user.accessToken,
      },
    };

    this.processingFiles = uniq([...this.processingFiles, fileInfo.name]);
    !isEmpty(onFetch) && store.dispatch(onFetch());
    try {   
      const response = await httpClient.makeRequest(request);
      if (response && response.success) {
        this.processingFiles = remove(this.processingFiles, fileInfo.name);
        delete this.cachedInfo[id];
      }
      !isEmpty(onFetchDone) && store.dispatch(onFetchDone());
    } catch (error) {
      Notification.error(error);
    }
  }

  handleLargeFileError(file) {
    Notification.error(`Размер загружаемого файла не должен быть более ${parseInt(MAX_FILE_SIZE)/1000000} мб.`);
    this.processingFiles = remove(this.processingFiles, file.name);
    return null;
  }
  async uploadFile(file, onFetch, onFetchDone, onFetchFailure) {
    file.size > MAX_FILE_SIZE && this.handleLargeFileError(file);
    const user = AuthService.getUser();
    const data = new FormData();
    data.append('file', file, file.name);

    const request = {
      url: url.fileUpload,
      params: {
        method: 'POST',
      },
      authorize: true,
      body: data,
      headers: {
        "serverId": user.serverId,
        "Authorization": "Bearer " + user.accessToken,
      },
    };

    this.processingFiles = uniq([...this.processingFiles, file.name]);
    !isEmpty(onFetch) && store.dispatch(onFetch());

    try {
      const response = await httpClient.makeRequest(request, {contentTypeAuto: true});
      if (response && response.success && response.data) {
        const file = response.data;
        this.processingFiles = remove(this.processingFiles, file.name);
        this.cachedInfo[file.id] = file;
        return file;
      }
      !isEmpty(onFetchDone) && store.dispatch(onFetchDone());
      return null;
    } catch (error) {
      // пока вероятен один ошибочный вариант - файл слишком большой.
      this.handleLargeFileError();
      store.dispatch((onFetchFailure()));
    }
  }

  getFileInfo(note, callbackAction) {
    const ids = note.attachments;
    const cachedIds = Object.keys(this.cachedInfo);
    const toFetch = difference(ids, uniq([...this.processingInfo, ...cachedIds]));
    const readyIds = intersection(cachedIds, ids);

    if (!isEmpty(toFetch)) {
      this.fetchFileInfo(toFetch).then(() => {
        !isEmpty(callbackAction) && store.dispatch(callbackAction());
      });
    }
    return readyIds.map(id => this.cachedInfo[id]);
  }

  isProcessing(name) {
    return !isEmpty(this.processingFiles.find(x => x === name));
  }
}

const FS = new FileService();

export {FS as FileService};
