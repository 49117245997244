import immutable from 'immutable';
import moment from 'moment';
import get from 'lodash/get';
import toString from 'lodash/toString';
import cloneDeep from 'lodash/cloneDeep';
import {tabTypes} from "./constants";
import {types} from './CreateTaskActions';
import {changeStateOnChecked, changeStateOnSelect, deleteExpiresItem, transformTask,
  updateTransportRouteFields, updateGoodsVehicles, renameTransportRoute} from './helpers';
import {Notification} from '../../../common/Notification';

const defaultState = {
  id: null,
  header: 'Создание задания',
  currentTab: tabTypes.DEPARTMENTS,
  preview: false,
  horizontalScroll: 0,
  tabs: {
    [tabTypes.DEPARTMENTS]: {
      selectedId: null,
    },
    [tabTypes.WORK]: {
      selectedId: null,
    },
    [tabTypes.DATE]: {
      from: moment().hours(0).minutes(0).seconds(0).toISOString(),
      to: moment().hours(23).minutes(45).seconds(0).toISOString(),
    },
    [tabTypes.FIELD]: {
      checked: false,
      selectedIds: [],
    },
    [tabTypes.VEHICLE]: {
      checked: false,
      selectedIds: [],
    },
    [tabTypes.FIELD_TOOL]: {
      // checked: true,
      general: null,
      mapValue: {},
    },
    [tabTypes.TRANSPORT_ROUTE]: {
      name: '',
      selectedId: null,
      mapValue: {},
    },
    [tabTypes.GOODS]: {
      mapValue: {},
    },
    [tabTypes.LIMIT_SPEED]: {
      checked: true,
      general: null,
      mapValue: {},
    },
  },
};

export default function (state = immutable.fromJS(defaultState), action) {
  switch (action.type) {
    case types.CREATE_TASK__SELECT_TAB:
      state = state.set('currentTab', action.tab);
      break;
    case types.CREATE_TASK__STATE_FROM_TASK:
      state = transformTask(cloneDeep(defaultState), action.task, action.isEdit);
      break;
    case types.PREVIEW_MODE:
      state = state.set('preview', action.preview);
      break;
    case types.WORK__SELECT_DEPARTMENT:
      state = state.setIn(['tabs', toString(tabTypes.DEPARTMENTS), 'selectedId'], get(action, 'selectedId', null));
      break;
    case types.WORK__SELECT_WORK:
      state = state.setIn(['tabs', toString(tabTypes.WORK), 'selectedId'], get(action, 'work.id', null));
      break;
    case types.DATE__SELECT:
      state = state.setIn(['tabs', toString(tabTypes.DATE), action.endDate ? 'to' : 'from'], action.date);
      break;
    case types.FIELD__FILTER_CHECKED:
      state = state.setIn(['tabs', toString(tabTypes.FIELD), 'checked'], action.checked);
      break;
    case types.FIELD__SELECT:
      state = state.setIn(['tabs', toString(tabTypes.FIELD), 'selectedIds'], action.selectedIds);
      break;
    case types.VEHICLE__FILTER_CHECKED:
      state = state.setIn(['tabs', toString(tabTypes.VEHICLE), 'checked'], action.checked);
      break;
    case types.VEHICLE__SELECT:
      state = state.setIn(['tabs', toString(tabTypes.VEHICLE), 'selectedIds'], action.selectedIds);
      break;
    case types.FIELD_TOOL__CHECKED:
      state = changeStateOnChecked(
        state, ['tabs', toString(tabTypes.FIELD_TOOL)], ['tabs', toString(tabTypes.VEHICLE)], action
      );
      break;
    case types.FIELD_TOOL__SELECT:
      state = changeStateOnSelect(state, ['tabs', toString(tabTypes.FIELD_TOOL)], action);
      break;
    case types.FIELD_TOOL__SELECT_VEHICLE:
      state = deleteExpiresItem(state, ['tabs', toString(tabTypes.FIELD_TOOL)], action.vehicleIds);
      break;
    case types.LIMIT_SPEED__CHECKED:
      state = changeStateOnChecked(
        state, ['tabs', toString(tabTypes.LIMIT_SPEED)], ['tabs', toString(tabTypes.VEHICLE)], action
      );
      break;
    case types.LIMIT_SPEED__SELECT:
      state = changeStateOnSelect(state, ['tabs', toString(tabTypes.LIMIT_SPEED)], action, 'limitSpeedId');
      break;
    case types.LIMIT_SPEED__SELECT_VEHICLE:
      state = deleteExpiresItem(state, ['tabs', toString(tabTypes.LIMIT_SPEED)], action.vehicleIds);
      break;
    case types.CREATE_TASK__SUCCESS:
      Notification.success(`Ежедневное задание «${action.operationName}» создано`);
      break;
    case types.CREATE_TASK__EDIT_SUCCESS:
      Notification.success(`Ежедневное задание «${action.operationName}» отредактировано`);
      break;
    case types.CREATE_TASK__CLEAR_STORE:
      state = immutable.fromJS(defaultState);
      break;
    case types.CREATE_TASK__CLEAR_STORE_FOR_TAB:
      state = state.setIn(['tabs', toString(action.tab)], immutable.fromJS(defaultState.tabs[toString(action.tab)]));
      break;
    case types.CREATE_TASK__HORIZONTAL_SCROLL:
      state = state.set('horizontalScroll', action.left);
      break;
    case types.TRANSPORT_ROUTE__RENAME:
      state = renameTransportRoute(state, ['tabs', toString(tabTypes.TRANSPORT_ROUTE), 'name'], action.name, action.isSelectRoute);
      break;
    case types.TRANSPORT_ROUTE__SELECT:
      state = state.setIn(['tabs', toString(tabTypes.TRANSPORT_ROUTE), 'selectedId'], get(action, 'transportRoute.id', null));
      break;
    case types.TRANSPORT_ROUTE__UPDATE_FIELDS:
      state = updateTransportRouteFields(state, ['tabs', toString(tabTypes.TRANSPORT_ROUTE)], action.selected);
      break;
    case types.TRANSPORT_ROUTE__SELECT_DURATION:
      state = state.setIn(['tabs', toString(tabTypes.TRANSPORT_ROUTE), 'mapValue', toString(action.fieldId), 'limitDurationMs'], action.limitDurationMs);
      break;
    case types.TRANSPORT_ROUTE__SWITCHED:
      state = state.setIn(['tabs', toString(tabTypes.TRANSPORT_ROUTE), 'mapValue', toString(action.fieldId), 'loadingType'], action.loadingType);
      break;
    case types.TRANSPORT_ROUTE__START_END_RACE:
      state = state.setIn(['tabs', toString(tabTypes.TRANSPORT_ROUTE), 'mapValue', toString(action.fieldId), 'startEndRace'], action.startEndRace);
      break;
    case types.GOODS__SELECT:
      state = state.setIn(['tabs', toString(tabTypes.GOODS), 'mapValue', toString(action.vehicleId), 'goodId'], get(action, 'goods.id', null));
      break;
    case types.GOODS__UPDATE_VEHICLES:
      state = updateGoodsVehicles(state, ['tabs', toString(tabTypes.GOODS)], action.selected);
      break;
    case types.GOODS__INPUT_LOADING_WEIGHT:
      state = state.setIn(['tabs', toString(tabTypes.GOODS), 'mapValue', toString(action.vehicleId), 'loadingWeight'], action.loadingWeight);
      break;
    case types.GOODS__INPUT_TRAILER_WEIGHT:
      state = state.setIn(['tabs', toString(tabTypes.GOODS), 'mapValue', toString(action.vehicleId), 'trailerWeight'], action.trailerWeight);
      break;
  }
  return state;
}
