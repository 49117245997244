const through = (props) => Object.assign({}, {data: props});

const createFileActions = ({store, onFetch = through, onPending = through, onSuccess = through, onError = through}) => {
  const types = {
    FILE_FETCH: `FILE_FETCH__${store}`,
    FILE_FETCH_PENDING: `FILE_FETCH_PENDING__${store}`,
    FILE_FETCH_SUCCESS: `FILE_FETCH_SUCCESS__${store}`,
    FILE_FETCH_ERROR: `FILE_FETCH_ERROR__${store}`,
  };
  const actions = {
    fileFetch: (props) => {
      return (dispatch, getState) => {
        const payload = {pendingFileInfo: true, ...onFetch(props, getState)};
        dispatch({
          type: types.FILE_FETCH,
          payload
        });
      };
    },
    fileFetchPending: (props) => {
      return (dispatch, getState) => {
        const payload = {pendingFileInfo: true, ...onPending(props, getState)};
        dispatch({
          type: types.FILE_FETCH_PENDING,
          payload
        });
      };
    },
    fileFetchSuccess: (props) => {
      return (dispatch, getState) => {
        const payload = {pendingFileInfo: false, ...onSuccess(props, getState)};
        dispatch({
          type: types.FILE_FETCH_SUCCESS,
          payload
        });
      };
    },
    fileFetchError: (props) => {
      return (dispatch, getState) => {
        const payload = {pendingFileInfo: false, ...onError(props, getState)};
        dispatch({
          type: types.FILE_FETCH_ERROR,
          payload
        });
      };
    },
  };

  return {types, actions};
};

export {createFileActions};
