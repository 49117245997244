import { isEmpty } from "lodash";
import AssignmentService from "../../../../services/AssignmentService";
import NoteService from "../../../../services/NoteService";

const ACCEPT_ASSIGNMENT = 'ACCEPT_ASSIGNMENT__ASSIGNMENT_CARD';
const NOTE_FETCH = 'ASS_NOTE_FETCH__ASSIGNMENT_CARD';
const NOTE_FETCH_DONE = 'ASS_NOTE_FETCH_DONE__ASSIGNMENT_CARD';
const DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT__ASSIGNMENT_CARD';

function noteFetch(entityId, ids) {
  return dispatch => {
    dispatch({
      type: NOTE_FETCH,
      payload: {pendingNote: true},
    });
    !isEmpty(ids) && NoteService.fetchId(entityId, ids).then(() => dispatch(noteFetchSuccess()));
  };
}

function noteFetchSuccess() {
  return dispatch => {
    dispatch({
      type: NOTE_FETCH_DONE,
      payload: {pendingNote: false},
    });
  };
}

function acceptAssignment(id) {
  return dispatch => {
    dispatch(setSaving(true));
    AssignmentService.acceptAssignment(id)
      .then(() => {
        AssignmentService.tryFetch();
        dispatch(setSaving(false));
      });
  };
}

function setSaving(saving = false) {
  return dispatch => {
    dispatch({
      type: ACCEPT_ASSIGNMENT,
      payload: {saving},
    });
  };
}

function deleteAssignment(id, after) {
  return dispatch => {
    dispatch(setSaving(true));
    AssignmentService.deleteAssignment(id)
      .then(() => {
        dispatch(setSaving(false));
        after();
      });
  };
}

export const types = {ACCEPT_ASSIGNMENT, NOTE_FETCH, NOTE_FETCH_DONE, DELETE_ASSIGNMENT};
export const actions = {acceptAssignment, setSaving, noteFetch, noteFetchSuccess, deleteAssignment};
