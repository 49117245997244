import {types} from "./AssignmentCardActions";
import {types as lTypes} from '../AssignmentActions';

const defaultState = {
  saving: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case lTypes.FILTER:
    case lTypes.FETCH:
    case lTypes.FETCH_PENDING:
    case lTypes.FETCH_SUCCESS:
    case lTypes.FETCH_ERROR:
    case lTypes.LIST_UPDATED:
    case lTypes.FETCH_NO_PENDING:
      state = Object.assign({}, state);
      break;
    case types.ACCEPT_ASSIGNMENT:
    case types.NOTE_FETCH:
    case types.NOTE_FETCH_DONE:
      state = Object.assign({}, state, {...action.payload});
  }

  return state;
}
