import locationService from '../../../../services/LocationService';
export const MAP_START_WATCH_LOCATION = 'MAP_START_WATCH_LOCATION';
export const MAP_LOCATION_SUCCESS = 'MAP_LOCATION_SUCCESS';
export const MAP_STOP_WATCH_LOCATION = 'MAP_STOP_WATCH_LOCATION';
export const MAP_LOADED = 'MAP_LOADED';
export const SET_VIEW_PORT = 'SET_VIEW_PORT';

export function startWatchLocation() {
  return (dispatch) => {
    locationService.startWatchLocation();
    dispatch({
      type: MAP_START_WATCH_LOCATION
    });
  };
}

export function stopWatchLocation() {
  return (dispatch) => {
    locationService.stopWatchLocation();
    dispatch({
      type: MAP_STOP_WATCH_LOCATION
    });
  };
}

export function locationSuccess(position) {
  return (dispatch) => {
    dispatch({
      type: MAP_LOCATION_SUCCESS,
      data: position
    });
  };
}

export function mapLoaded() {
  return (dispatch) => {
    dispatch({
      type: MAP_LOADED,
    });
  };
}


export function setViewPort(viewport) {
  return (dispatch) => {
    dispatch({
      type: SET_VIEW_PORT,
      viewport: viewport
    });
  };
}
