import AuthService from '../services/AuthService';
import DepartmentService from "../services/DepartmentService";

class WebsocketClient {
  ws = null;

  async connect() {
    const user = AuthService.getUser();
    const dep = DepartmentService.getCurrentDepartmentForRequest();

    //define wss or ws (based on startup REACT_APP_SSL value)
    let wsUrl = `ws${process.env.REACT_APP_SSL == "true" ? 's' : ''}://` + process.env.REACT_APP_API + '/data/play';

    const url = wsUrl + `?jwt=Bearer ${user.accessToken}&serverId=${user.serverId}${dep.companyId ? `&companyId=${dep.companyId}` : ''}${dep.departmentId ? `&departmentId=${dep.departmentId}` : ''}`;

    this.ws = new WebSocket(url);
    return this.ws;
  }

  async send(msg) {
    try {
      this.ws.send(msg);
    }
    catch (error) {
      window.location.href = "";
    }
  }
}

export default new WebsocketClient();
