import {types} from './TaskActions';
import {Notification} from '../../common/Notification';
import {getInitialPeriod} from "../../../helpers/initialPeriod";

const {from, to} = getInitialPeriod(30);
const defaultState = {
  from,
  to,
  timestamp: Date.now(),
};

export default function (state = Object.assign({}, defaultState), action) {
  switch (action.type) {
    case types.DELETE_TASK__SUCCESS:
      Notification.success('Задание успешно удалено');
      break;
    case types.DELETE_TASK__ERROR:
      Notification.error(action.payload);
      break;
    case types.SET_PERIOD:
    case types.FETCH:
    case types.FETCH_PENDING:
    case types.FETCH_SUCCESS:
    case types.FETCH_ERROR:
      state = Object.assign({}, state, {...action.payload});
      break;
  }
  return state;
}
