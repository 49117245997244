import Tree from "../../treejs/Tree";
import {url} from "../../api";
import httpClient from "@infobis/api-module";
import AuthService from "../AuthService";
import {parseTree} from "../../helpers/parseTree";
import {Notification} from './../../components/common/Notification';

export class WeightedService {
  _tree = Tree.empty();
  _glidMap = {};

  async fetch(cb) {
    const user = AuthService.getUser();

    const request = {
      url: url.weighBridgeTree,
      params: {
        method: 'GET',
      },
      authorize: true,
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "serverId": user.serverId,
        "Authorization": "Bearer " + user.accessToken,
      },
    };

    try {
      const response = await httpClient.makeRequest(request);
      if (response && response.success && response.data) {
        this._buildTree(response.data);
        cb && cb();
      }
    } catch (error) {
      Notification.error(error);
    }
  }

  _buildTree(data) {
    const getGlid = n => n.data.glid;
    this._tree = parseTree(data, getGlid);
    this._tree.leaves().forEach(n => {
      this._glidMap[n.data.glid] = n.data;
    });
  }

  getItem(glid) {
    const item = this._glidMap[glid];
    return item || {};
  }
}
