import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import AuthService from "./AuthService";
import {url} from "../api";
import reportType from "../const/reportType";
import httpClient from "@infobis/api-module";
import DepartmentService from './DepartmentService';
import Tree from "../treejs/Tree";
import {actions as fActions} from '../components/Main/Weighted/WeightedFilter/WeightedFilterActions';
import {actions} from '../components/Main/Weighted/WeightedActions';
import {parseTree} from "../helpers/parseTree";
import {expandTree} from "../helpers/expandTree";
import store from "../store/store";
import {localStorageItems, LocalstorageService} from "./LocalstorageService";
import {getInitialPeriod} from "../helpers/initialPeriod";
import {filterTreeKey} from "../helpers/treeFilterKey";
import {Notification} from './../components/common/Notification';

class WeightedService {
  constructor() {
    this.reset();
    window.WeightedService = this;
  }

  reset() {
    this.data = Tree.empty();
    this.rawData = [];
    this.dates = {
      from: null,
      to: null,
    };
  }

  async fetch({from, to}) {
    const user = AuthService.getUser();
    const now = moment();
    const {companyId} = DepartmentService.getCurrentDepartmentForRequest();
    const datesBefore = {from: this.dates.from, to: this.dates.to};

    if (isEqual({from, to}, this.dates)) {
      const t = setTimeout(() => {
        store.dispatch(actions.fetchSuccess());
        clearTimeout(t);
      }, 0);
      return;
    }

    this.savePeriod({from, to});

    const request = {
      url: url.reportData,
      params: {
        method: 'POST',
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
      authorize: true,
      qs: {
        reportId: reportType.WEIGHTING,
      },
      body: JSON.stringify({
        activeChecks: "",
        companyId,
        entityId: [],
        forLeafs: null,
        filters: {
          fieldsFilter: [],
          unitsFilter: [],
          driversFilter: [],
        },
        from,
        to,
        isDay: false,
        limit: 25,
        page: 1,
        reportId: reportType.WEIGHTING,
        start: 0,
        strEntityId: [],
        tzOffset: -now.utcOffset(),
      }),
    };

    this.dates = {from, to};
    store.dispatch(actions.fetchPending());

    try {
      const response = await httpClient.makeRequest(request);
      if (response && response.success && response.data) {
        this.onFetchSuccess(response);
        this.rawData = response.data;
        store.dispatch(actions.fetchSuccess());
      } else {
        this.dates = datesBefore;
        store.dispatch(actions.fetchError());
      }
    } catch (error) {
      store.dispatch(actions.fetchError());
      Notification.error(error);
    }
  }

  onFetchSuccess(response) {
    this.buildTree(response.data);
    this.setOperationsList(response.data);
  }

  setOperationsList() {
    const operations = uniq(this.data.leaves().map(x => x.data.operation));
    store.dispatch(fActions.operationSetBulk(!isEmpty(operations) ? operations : []));
  }

  buildTree(data) {
    const getGlid = n => n.data.glid || n.data.wGuid;
    let groupes = uniqBy(data.map(d =>
      Object.assign({}, {glid: d.weighBridgeUnitId, name: d.weighBridgeUnit})), 'glid');
    groupes = orderBy(groupes, x => x.name.toLowerCase(), ['asc']);
    groupes.forEach(x => {
      x.data = orderBy(data.filter(d => d.weighBridgeUnitId === x.glid), ['time'], ['desc']);
    });
    this.data = parseTree(groupes, getGlid);
    this.data.lastUpdate = Date.now();
    expandTree(this.data, 0);
  }

  getTree() {
    return this.data;
  }

  getItem(id) {
    return this.rawData.find(x => x.wGuid === id) || {};
  }

  getGroupName(id) {
    return this.data && get(this.data.nodes().find(n => isObject(n.data) && Number(n.data.glid) === Number(id)), 'data.name', '');
  }

  loadPeriod() {
    if (LocalstorageService.isCacheForSameUser(localStorageItems.weightedPeriod)) {
      return get(JSON.parse(localStorage.getItem(localStorageItems.weightedPeriod)), 'data');
    } else {
      localStorage.removeItem(localStorageItems.weightedPeriod);
      return getInitialPeriod();
    }
  }

  savePeriod({from, to}) {
    if (from && to) {
      const cache = LocalstorageService.getCacheForUser({from, to});
      localStorage.setItem(localStorageItems.weightedPeriod, JSON.stringify(cache));
    }
  }

  getFilteredTree({operation}) {
    return filterTreeKey({tree: this.data, query: {operation}});
  }
}

export default new WeightedService();
