import {WebsocketService, agroWsEvents} from './WebsocketService';
import store from '../store/store';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';
import uniqWith from 'lodash/uniqWith';
import sum from 'lodash/sum';
import min from 'lodash/min';
import max from 'lodash/max';
import get from 'lodash/get';
import defer from 'promise-defer';
import {aspect} from "../const";
import moment from 'moment';
import {actions as fieldWorkListActions} from "../components/Main/Fields/Field/FieldWorkList/FieldWorkActions";
import {actions as fieldWorkActions} from "../components/Main/FieldWork/WorkActions";
import {actions as actionListActions} from "../components/Main/Actions/ActionsActions";
import FieldService from "./FieldService";
import {mapValuesForKeys} from "../helpers/mapValuesForKeys";
import OperationService from "./OperationService";
import VehicleService from "./VehicleService";
import fetchStatus from "../const/fetchStatus";
import TrackService from "./TrackService";

window.moment = moment;

const isOperationsEqual = (o1, o2) => {
  return o1.operationId === o2.operationId &&
    o1.sourceId === o2.sourceId &&
    o1.from === o2.from &&
    o1.duration === o2.duration &&
    o1.to === o2.to &&
    o1.area === o2.area &&
    o1.factor === o2.factor;
};

const addEndTime = (newItem, oldItem) => {
  if (newItem.endTime && newItem.endTime >= oldItem.to) {
    return {};
  }

  return {
    endTime: oldItem.to,
  };
};

const calculateAreaByOperations = (operations) => {
  return operations.reduce((all, item) => {
    return {
      area: all.area + item.area,
      cultivatedArea: all.area + item.area * item.factor,
      percent: all.percent + item.percent,
    };
  }, {
    area: 0,
    cultivatedArea: 0,
    percent: 0,
  });
};

class FieldWorkService {
  _fieldId = null;
  _field = null;
  _data = [];
  _status = fetchStatus.PENDING;

  constructor() {
    window.FieldWorkService = this;
  }

  fetch(id) {
    this._fetchDefer = defer();
    if (this._fieldId !== id) {
      this._data = [];
    } else {
      return Promise.resolve();
    }
    this._fieldId = id;
    this._field = FieldService.getItem(this._fieldId);
    this._fieldOperationsIds = this._field.fieldOps || [];
    this._status = fetchStatus.PENDING;
    store.dispatch(actionListActions.fetchPending());

    WebsocketService.cbOnOpen(() => {
      WebsocketService.setDynamic({
        id,
        aspect: aspect.FIELDS_OPERATION_HISTORY,
        additionalAspect: aspect.FIELDS_OPERATION_HISTORY2,
        additionalIds: this._fieldOperationsIds
      });
      WebsocketService.removeListener(agroWsEvents.AG_CHANGES, data => this.onUpdate(data));
      WebsocketService.addListener(agroWsEvents.AG_CHANGES, data => this.onUpdate(data));
    });

    return this._fetchDefer.promise;
  }

  onUpdate(data) {
    if (!window.updatingOn) {
      return;
    }
    const currentFieldUpdate = data.items.find(i => Number(i.id) === Number(this._fieldId));
    if (!currentFieldUpdate || !currentFieldUpdate.values) {
      return;
    }
    if (isEmpty(this._field) && this._fieldId) {
      this._field = FieldService.getItem(this._fieldId);
    }

    let fieldWork = {};
    const fieldOperationHistory = get(currentFieldUpdate, 'values.fieldOperationHistory');
    this._status = fetchStatus.DEFAULT;
    if (!isEmpty(fieldOperationHistory)) {
      fieldWork = fieldOperationHistory
        .filter(({ operationId }) => operationId)
        .reduce((all, item) => {
          const id = item.operationId.toString() + item.startTime;

          const newItem = {
            ...(all[id] || {}),
            ...item,
          };

          const notFoundOperation = {
            ...item,
            vehicleName: item.sourceTitle,
          };

          const allOperations = !newItem.operations
            ? [notFoundOperation]
            : [...newItem.operations, notFoundOperation];

          const mappedUniqOperations = uniqWith(allOperations, isOperationsEqual)
            .map((operation) => ({
              ...operation,
              cultivatedArea: operation.area * operation.factor,
            }));

          return {
            ...all,
            [id]: {
              ...newItem,
              operations: mappedUniqOperations,
              ...calculateAreaByOperations(mappedUniqOperations),
              fieldId: this._fieldId.glid,
              ...addEndTime(newItem, item),
            },
          };
        }, {});

      this._data = values(fieldWork);

      for (const work of this._data) {
        work.duration = sum(work.operations.map(o => o.duration));
        work.from = min(work.operations.map(o => o.from));
        work.to = max(work.operations.map(o => o.to));
      }
    }

    this._fetchDefer.resolve();

    store.dispatch(fieldWorkListActions.fetchSuccess());
    store.dispatch(fieldWorkActions.fetchSuccess());
  }

  hasData() {
    return !isEmpty(this._data);
  }

  getItem({operationId, fieldId, dateStart}) {
    const item =  this._data.find(i => (
      Number(i.operationId) === Number(operationId)
        && i.operations.find((o) => (
          Number(o.fieldId) === Number(fieldId)
          && Number(o.from) === Number(dateStart)
        ))
    )) || {};
    if (isEmpty(item)) {
      return {};
    }
    return {...item, ...this._mapForList(item)}
  }

  reset() {
    this._fieldId = null;
    this._field = null;
    this._data = [];
    this._status = fetchStatus.PENDING;
  }

  getList() {
    const arr = this._data.map(this._mapForList);
    return orderBy(arr, 'dateEnd', 'desc');
  }

  getField() {
    this._field = FieldService.getItem(this._fieldId);
    return this._field;
  }

  isPending() {
    return this._status === fetchStatus.PENDING;
  }

  _mapForList = (work) => {
    const cultivatedArea = work.area * work.factor;
    const {color, title} = OperationService.getItem(work.operationId);

    return mapValuesForKeys(work, [
      'sourceId',
      'operationId',
      'percent',
      'startTime',
      {
        key: 'dateStart',
        value: work.from,
      },
      {
        key: 'dateEnd',
        value: work.to,
      },
      {
        key: 'title',
        value: work.title,
      },
      {
        key: 'color',
        value: work.color,
      }, {
        key: 'cultivatedArea',
        value: cultivatedArea,
      }
    ]);
  }

  async getOperationTracks(work, unitId, tracks, setTracks, operationId) {
    const operation = work.operations && work.operations.find(operation => operation.sourceId === unitId);
    const from = moment(operation.from).toISOString();
    const to = moment(operation.to).toISOString();
    const track = await TrackService.loadTrack(String(operation.sourceId), from, to, operationId, operation.fieldId);
    tracks = {...tracks, [operation.sourceId]: track};
    setTracks({tracks: tracks});
  }
}

export default new FieldWorkService();
