function getRandomArbitrary(min, max) {
  return Math.floor(Math.random() * (max - min) + min)
}

let getRandomId = () => getRandomArbitrary(1000000, 9999999)

export {
  getRandomArbitrary,
  getRandomId
}
