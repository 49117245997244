import {createActions} from "../../../../baseClasses/ActionFactory";
import {createTreeActions} from '../../../../baseClasses/TreeActionFactory';
import PlanByWorkService from "../../../../services/PlanByWorkService";
import PlanByCultureService from "../../../../services/PlanByCultureService";

const TOGGLE_COLLAPSE__PLANFACT = 'TOGGLE_COLLAPSE__PLANFACT';
const FETCH_PLAN_OPERATION_HISTORY__PLANFACT = 'FETCH_PLAN_OPERATION_HISTORY__PLANFACT';
const FETCH_PLAN_OPERATION_HISTORY_DONE__PLANFACT = 'FETCH_PLAN_OPERATION_HISTORY_DONE__PLANFACT';
const FETCH_PLAN_OPERATION_HISTORY_FOR_CULTURE__PLANFACT = 'FETCH_PLAN_OPERATION_HISTORY_FOR_CULTURE__PLANFACT';
const FILTER__PLANFACT = 'FILTER__PLANFACT';
const SELECT_TAB__PLANFACT = 'SELECT_TAB__PLANFACT';

function onFetch({id, viewCulture}) {
  if (viewCulture) {
    PlanByCultureService.fetchPlanOperationHistory(id);
  } else {
    PlanByWorkService.fetchPlanOperationHistory(id);
  }
}

function toggleCollapse(id) {
  return (dispatch, getState) => {
    const {planFact} = getState().main;
    const collapseState = Object.assign({}, planFact.collapseState);
    collapseState[id] = !collapseState[id];

    dispatch({
      type: TOGGLE_COLLAPSE__PLANFACT,
      payload: {collapseState},
    });
  };
}

function fetchPlanOperationHistory(glid) {
  return (dispatch, getState) => {
    const {planFact} = getState().main;
    const pendingDetails = Object.assign({}, planFact.pendingDetails);
    pendingDetails[glid] = true;

    dispatch({
      type: FETCH_PLAN_OPERATION_HISTORY__PLANFACT,
      payload: {pendingDetails},
    });
  };
}

function fetchPlanOperationHistoryDone(glid) {
  return (dispatch, getState) => {
    const {planFact} = getState().main;
    const pendingDetails = Object.assign({}, planFact.pendingDetails);
    pendingDetails[glid] = false;

    dispatch({
      type: FETCH_PLAN_OPERATION_HISTORY_DONE__PLANFACT,
      payload: {pendingDetails},
    });
  };
}

function fetchPlanOperationHistoryForCulture(id) {
  return dispatch => {
    dispatch({
      type: FETCH_PLAN_OPERATION_HISTORY_FOR_CULTURE__PLANFACT,
    });

    PlanByCultureService.fetchPlanOperationHistoryForCulture(id);
  };
}

function filter(filterStr) {
  return dispatch => {
    dispatch({
      type: FILTER__PLANFACT,
      payload: {filterStr}
    });
  };
}

function selectTab(tabIndex) {
  return dispatch => {
    dispatch({
      type: SELECT_TAB__PLANFACT,
      payload: {tabIndex}
    });
  };
}

const {types: generalTypes, actions: generalActions} = createActions({store: 'PLANFACT', onFetch});
const {types: treeTypes, actions: treeActions} = createTreeActions({store: 'PLANFACT'});
const allActions = {
  ...generalActions,
  ...treeActions,
  toggleCollapse,
  fetchPlanOperationHistory,
  fetchPlanOperationHistoryDone,
  fetchPlanOperationHistoryForCulture,
  filter,
  selectTab,
};
const allTypes = {
  ...generalTypes,
  ...treeTypes,
  TOGGLE_COLLAPSE__PLANFACT,
  FETCH_PLAN_OPERATION_HISTORY__PLANFACT,
  FETCH_PLAN_OPERATION_HISTORY_DONE__PLANFACT,
  FETCH_PLAN_OPERATION_HISTORY_FOR_CULTURE__PLANFACT,
  FILTER__PLANFACT,
  SELECT_TAB__PLANFACT,
};

export {allTypes as types, allActions as actions};
