import React from 'react';
import PropTypes from 'prop-types';
import Icon, {iconType} from "../../../controls/icon/Icon";
import st from './notification.scss';

const CloseButton = ({closeToast}) => (
  <div className={st.icon} onClick={closeToast}>
    <Icon type={iconType.cross} />
  </div>
);

CloseButton.propTypes = {
  closeToast: PropTypes.func,
};

export default CloseButton;
