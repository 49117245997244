import get from 'lodash/get';
import {createActions} from "../../../baseClasses/ActionFactory";
import TractorRecordService from '../../../services/TractorRecordService';
import {createTreeActions} from "../../../baseClasses/TreeActionFactory";
import tractorRecord from "./TractorRecordReducer";

const {types: generalTypes, actions: generalActions} = createActions({store: 'TRACTOR_RECORD', onFetch});
const {types: treeTypes, actions: treeActions} = createTreeActions({store: 'TRACTOR_RECORD'});

const SET_START_DATE = 'SET_START_DATE__TRACTOR_RECORD';
const SET_END_DATE = 'SET_START_DATE__TRACTOR_RECORD';
const SET_PERIOD = 'SET_PERIOD__TRACTOR_RECORD';
const FETCH_NO_PENDING = 'FETCH_NO_PENDING__TRACTOR_RECORD';

function fetchSaved() {
  return (dispatch) => {
    const period = TractorRecordService.loadPeriod();

    if (period) {
      dispatch(setPeriod(period));
      dispatch(fetchNoPending({...period}));
    } else {
      dispatch(generalActions.fetch());
    }
  };
}

function fetchNoPending({from, to}) {
  //в это мместе происходит вызов заполнения сервиса данными
  TractorRecordService.fetch({from, to});
  return (dispatch) => {
    dispatch({
      type: FETCH_NO_PENDING,
    });
  };
}

function onFetch(props, getState) {
  const {from, to} = get(getState(), 'main.tractorRecord', {});
  TractorRecordService.fetch({from, to});
}

function setStartDate(from) {
  return (dispatch, getState) => {
    const {to} = getState().main.tractorRecord;
    TractorRecordService.savePeriod({from, to});
    dispatch({
      type: SET_START_DATE,
      payload: {from}
    });
  };
}

function setEndDate(to) {
  return (dispatch, getState) => {
    const {from} = getState().main.tractorRecord;
    TractorRecordService.savePeriod({from, to});
    dispatch({
      type: SET_END_DATE,
      payload: {to}
    });
  };
}

function setPeriod({from, to}) {
  return dispatch => {
    TractorRecordService.savePeriod({from, to});
    dispatch({
      type: SET_PERIOD,
      payload: {to, from}
    });
  };
}

const allTypes = {...generalTypes, ...treeTypes, SET_START_DATE, SET_END_DATE, SET_PERIOD, FETCH_NO_PENDING};
const allActions = {...generalActions, ...treeActions, setStartDate, setEndDate, setPeriod, fetchSaved};
export {allActions as actions, allTypes as types};
