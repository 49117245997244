import st from './icon.scss';

import arrow from '../../assets/icons/icon-arrow.svg';
import burger from '../../assets/icons/icon-menu.svg';
import close from '../../assets/icons/icon-close.svg';
import dashboard from '../../assets/icons/icon-dashboard.svg';
import department from '../../assets/icons/icon-department.svg';
import document from '../../assets/icons/icon-dailytasks.svg';
import field from '../../assets/icons/icon-fields.svg';
import logo from '../../assets/icons/logo-menu.svg';
import agroLogo from '../../assets/icons/agro-logo.svg';
import digitalAgroLogo from '../../assets/icons/DA-logo.svg';
import map from '../../assets/icons/icon-map.svg';
import people from '../../assets/icons/icon-workers.svg';
import worker from '../../assets/icons/icon-worker.svg';
import search from '../../assets/icons/icon-search.svg';
import check from '../../assets/icons/icon-check.svg';
import agronom from '../../assets/icons/icon-agronom.svg';
import vehicle from '../../assets/icons/icon-technic.svg';
import fieldsLg from '../../assets/icons/icon-fields-lg.svg';
import plansLg from '../../assets/icons/icon-plans-lg.svg';
import crop from '../../assets/icons/icon-crop.svg';
import fieldOnMap from '../../assets/icons/icon-field.svg';
import work from '../../assets/icons/icon-work.svg';
import fav from '../../assets/icons/icon-fav.svg';
import chevRight from '../../assets/icons/chevron-right.svg';
import chevDown from '../../assets/icons/chevron-down.svg';
import chevLeft from '../../assets/icons/chevron-left.svg';
import chevUp from '../../assets/icons/chevron-up.svg';
import chevLong from '../../assets/icons/longchevron.svg';
import area from '../../assets/icons/icon-area.svg';
import calendar from '../../assets/icons/icon-calendar.svg';
import workCalendarNoShadow from '../../assets/icons/icon-calendar-withoutshadow.svg';
import workCalendarWeakShadow from '../../assets/icons/icon-calendar-wea-shadow.svg';
import culture from '../../assets/icons/icon-culture.svg';
import group from '../../assets/icons/icon-group.svg';
import workShadow from '../../assets/icons/icon-work-shadow.svg';
import location from '../../assets/icons/icon-location.svg';
import time from '../../assets/icons/icon-time.svg';
import cloudines from '../../assets/icons/icon-cloudiness.svg';
import dewPoint from '../../assets/icons/icon-dewpoint.svg';
import pressure from '../../assets/icons/icon-pressure.svg';
import wet from '../../assets/icons/icon-wet.svg';
import wind from '../../assets/icons/icon-wind.svg';
import canister from '../../assets/icons/icon-canister.svg';
import canisterOut from '../../assets/icons/icon-canisterOut.svg';
import locationShadow from '../../assets/icons/icon-location-shadow.svg';
import status from '../../assets/icons/icon-status.svg';
import technicShadow from '../../assets/icons/icon-technic-shadow.svg';
import timeShadow from '../../assets/icons/icon-time-shadow.svg';
import inMove from '../../assets/icons/icon-inmove.svg';
import insufficientData from '../../assets/icons/icon-insufficientdata.svg';
import noConnection from '../../assets/icons/icon-noconnection.svg';
import noDevice from '../../assets/icons/icon-nodevice.svg';
import stop from '../../assets/icons/icon-stop.svg';
import inWork from '../../assets/icons/icon-inwork.svg';
import downtime from '../../assets/icons/icon-downtime.svg';
import machineOperator from '../../assets/icons/icon-machineoperator.svg';
import harvester from '../../assets/icons/icon-combine.svg';
import tanker from '../../assets/icons/icon-refueler.svg';
import tractor from '../../assets/icons/icon-tractor.svg';
import tractorSm from '../../assets/icons/icon-tractor-sm.svg';
import tractorWork from '../../assets/icons/icon-tractor-work.svg';
import agregat from '../../assets/icons/icon-agregat.svg';
import truck from '../../assets/icons/icon-truck.svg';
import automobile from '../../assets/icons/icon-avto.svg';
import speedometer from '../../assets/icons/icon-speedometer.svg';
import speedometerSmall from '../../assets/icons/icon-speed.svg';
import workCalendar from '../../assets/icons/icon-workcalendar.svg';
import plan from '../../assets/icons/icon-plan.svg';
import allObjectsMap from '../../assets/icons/icon-allobjectsMap.svg';
import myLocationMap from '../../assets/icons/icon-mapMylocation.svg';
import manMap from '../../assets/icons/icon-manMap.svg';
import layers from '../../assets/icons/icon-layers.svg';
import add from '../../assets/icons/icon-add.svg';
import completeCircle from '../../assets/icons/icon-completedstep.svg';
import calendarTab from '../../assets/icons/icon-calendar-tab.svg';
import workTab from '../../assets/icons/icon-work-tab.svg';
import tractorTab from '../../assets/icons/icon-tractor-tab.svg';
import aggregateTab from '../../assets/icons/icon-aggregate.svg';
import aggregateVeh from '../../assets/icons/icon-aggregate-veh.svg';
import aggregateGreen from '../../assets/icons/icon-aggregate2.svg';
import speedometerTab from '../../assets/icons/icon-speedometer-tab.svg';
import fieldsTab from '../../assets/icons/icon-fields-tab.svg';
import speedometerPreviewInfo from '../../assets/icons/icon-speedometer2.svg';
import aggregatePreviewInfo from '../../assets/icons/icon-aggregate3.svg';
import automobileShadow from '../../assets/icons/icon-avto-shadow.svg';
import harvesterShadow from '../../assets/icons/icon-combine-shadow.svg';
import tankerShadow from '../../assets/icons/icon-refueler-shadow.svg';
import statusVehicle from '../../assets/icons/icon-status-vehicle.svg';
import tractorShadow from '../../assets/icons/icon-tractor-shadow.svg';
import truckShadow from '../../assets/icons/icon-truck-shadow.svg';
import deleteIcon from '../../assets/icons/icon-delete.svg';
import combineDT from '../../assets/icons/icon-combineDT.svg';
import refuelerDT from '../../assets/icons/icon-refuelerDT.svg';
import truckDT from '../../assets/icons/icon-truckDT.svg';
import autoDT from '../../assets/icons/icon-avtoDT.svg';
import exit from '../../assets/icons/icon-exit.svg';
import back from '../../assets/icons/icon-back.svg';
import edit from '../../assets/icons/icon-edit.svg';
import duplicate from '../../assets/icons/icon-duplicate.svg';
import contextMenu from '../../assets/icons/icon-contextmenu.svg';
import distance from '../../assets/icons/icon-distance.svg';
import bigChevronDown from '../../assets/icons/bigchevron-down.svg';
import bigChevronUp from '../../assets/icons/bigchevron-up.svg';
import cloud from '../../assets/icons/icon-cloud.svg';
import filter from '../../assets/icons/icon-filter.svg';
import filterAutoSm from '../../assets/icons/icon-filter-auto.svg';
import filterAutoSmActive from '../../assets/icons/icon-filter-auto-active.svg';
import filterTruckSm from '../../assets/icons/icon-filter-truck.svg';
import filterTruckSmActive from '../../assets/icons/icon-filter-truck-active.svg';
import filterTractorSm from '../../assets/icons/icon-filter-tractor.svg';
import filterTractorSmActive from '../../assets/icons/icon-filter-tractor-active.svg';
import filterTankerSm from '../../assets/icons/icon-filter-refueler.svg';
import filterTankerSmActive from '../../assets/icons/icon-filter-refueler-active.svg';
import filterHarvesterSm from '../../assets/icons/icon-filter-combine.svg';
import filterHarvesterSmActive from '../../assets/icons/icon-filter-combine-active.svg';
import cardField from '../../assets/icons/icon-card-fields.svg';
import cardPeople from '../../assets/icons/icon-card-people.svg';
import cardVehicle from '../../assets/icons/icon-card-technics.svg';
import message from '../../assets/icons/icon-message.svg';
import fieldMini from '../../assets/icons/icon-field-mini.svg';
import organization from '../../assets/icons/icon-organization.svg';
import freeday from '../../assets/icons/icon-freeday.svg';
import noteTab from '../../assets/icons/icon-note.svg';
import fueling from '../../assets/icons/icon-fueling.svg';
import additionally from '../../assets/icons/icon-additionally.svg';
import marker from '../../assets/icons/icon-marker.svg';
import sink from '../../assets/icons/icon-sink.svg';
import parking from '../../assets/icons/icon-parking.svg';
import dataLoss from '../../assets/icons/icon-dataloss.svg';
import track from '../../assets/icons/icon-track.svg';
import trackCurve from '../../assets/icons/icon-track-curve.svg';
import attachments from '../../assets/icons/icon-attachments.svg';
import attachment from '../../assets/icons/icon-attachment.svg';
import fileAudio from '../../assets/icons/icon-audio.svg';
import filePhoto from '../../assets/icons/icon-foto.svg';
import fileVideo from '../../assets/icons/icon-video.svg';
import weighterMenu from '../../assets/icons/icon-weighter-menu.svg';
import goods from '../../assets/icons/icon-goods-sm.svg';
import truckSm from '../../assets/icons/icon-truck-sm.svg';
import weight from '../../assets/icons/icon-weigh.svg';
import name from '../../assets/icons/icon-name.svg';
import weightingPlace from '../../assets/icons/icon-weighing_place.svg';
import documentLabel from '../../assets/icons/icon-document-label.svg';
import tag from '../../assets/icons/icon-tag.svg';
import withoutTrailer from '../../assets/icons/icon-without_trailer.svg';
import withoutGoods from '../../assets/icons/icon-withoutgoods.svg';
import weighningSeparate from '../../assets/icons/icon-separate_weighing.svg';
import weighningJoined from '../../assets/icons/icon-articulated_vehicle.svg';
import trailerSm from '../../assets/icons/icon-trailer-sm.svg';
import weightGoods from '../../assets/icons/icon-goods.svg';
import donut from '../../assets/icons/icon-donut.svg';
import datepickerSm from '../../assets/icons/icon-datepicker-sm.svg';
import aggregateShadow from '../../assets/icons/icon-aggregate-shadow.svg';
import assignment from '../../assets/icons/icon-assignment.svg';
import deadline from '../../assets/icons/icon-deadline.svg';
import alert from '../../assets/icons/icon-alert.svg';
import canceled from '../../assets/icons/icon-canceled.svg';
import completed from '../../assets/icons/icon-completed.svg';
import scouts from '../../assets/icons/icon-scouts.svg';
import verification from '../../assets/icons/icon-verification.svg';
import assignments from '../../assets/icons/icon-assignments.svg';
import finish from '../../assets/icons/icon-finish.svg';
import distanceMap from '../../assets/icons/icon-distance-map.svg';
import timer from '../../assets/icons/icon-timer.svg';
import scheduleTab from '../../assets/icons/icon-schedule.svg';
import shiftTab from '../../assets/icons/icon-shift.svg';
import workerSuspender from '../../assets/icons/icon-worker_border.svg';
import fullHeightAgronom from '../../assets/icons/icon-worker_full-length.svg';
import smSchedule from '../../assets/icons/icon-sm_schedule.svg';
import smShift from '../../assets/icons/icon-sm_shift.svg';
import shiftTractorXL from '../../assets/icons/icon-tractor-work-opacity50.svg';
import shiftWorker from '../../assets/icons/icon-shift-worker.svg';
import mainMenuSearch from '../../assets/icons/icon-menu-search.svg';
import mainMenuDashboard from '../../assets/icons/icon-menu-dashboard.svg';
import mainMenuMap from '../../assets/icons/icon-menu-map.svg';
import mainMenuVehicle from '../../assets/icons/icon-menu-vehicle.svg';
import mainMenuField from '../../assets/icons/icon-menu-fields.svg';
import mainMenuEmployee from '../../assets/icons/icon-menu-workers.svg';
import mainMenuDailyTask from '../../assets/icons/icon-menu-dailywork.svg';
import mainMenuPlan from '../../assets/icons/icon-menu-plans.svg';
import mainMenuAssignment from '../../assets/icons/icon-menu-assignments.svg';
import mainMenuShift from '../../assets/icons/icon-shifts-menu.svg';
import mainMenuReports from '../../assets/icons/icon-menu-reports.svg';
import mainMenuDepartment from '../../assets/icons/icon-menu-department.svg';
import mainMenuExit from '../../assets/icons/icon-menu-exit.svg';
import weather01d from '../../assets/icons/icon-weather-01d.svg';
import weather02d from '../../assets/icons/icon-weather-02d.svg';
import weather03d from '../../assets/icons/icon-weather-03d.svg';
import weather04d from '../../assets/icons/icon-weather-04d.svg';
import weather09d from '../../assets/icons/icon-weather-09d.svg';
import weather10d from '../../assets/icons/icon-weather-10d.svg';
import weather11d from '../../assets/icons/icon-weather-11d.svg';
import weather13d from '../../assets/icons/icon-weather-13d.svg';
import weather50d from '../../assets/icons/icon-weather-50d.svg';
import weather01n from '../../assets/icons/icon-weather-01n.svg';
import weather02n from '../../assets/icons/icon-weather-02n.svg';
import weather10n from '../../assets/icons/icon-weather-10n.svg';
import technicsLg from '../../assets/icons/icon-technics-lg.svg';
import workerLg from '../../assets/icons/icon-worker-lg.svg';
import workWhite from '../../assets/icons/icon-buttonWork.svg';
import cropWhite from '../../assets/icons/icon-buttonCulture.svg';
import avgTemp from '../../assets/icons/icon-avgTemp.svg';
import widthHeight from '../../assets/icons/icon-width-height.svg';
import temperatureHigh from '../../assets/icons/icon-temperature-high.svg';
import temperatureLow from '../../assets/icons/icon-temperature-low.svg';
import humidity from '../../assets/icons/icon-humidity.svg';
import windSpeed from '../../assets/icons/icon-wind-speed.svg';
import pressureData from '../../assets/icons/icon-pressure-data.svg';
import precipitation from '../../assets/icons/icon-precipitation.svg';
import soilHumidity from '../../assets/icons/icon-soil-humidity.svg';
import battery from '../../assets/icons/icon-battery.svg';
import luminance from '../../assets/icons/icon-luminance.svg';
import dewPointSoil from '../../assets/icons/icon-dew-point.svg';
import soilTemp from '../../assets/icons/icon-soil-temp.svg';
import mainMenuMeteostations from '../../assets/icons/icon-main-menu-meteostations.svg';
import eye from '../../assets/icons/icon-eye.svg';
import eyeSlash from '../../assets/icons/icon-eye-slash.svg';

export const iconType = {
  arrow: 'arrow',
  none: 'none',
  burger: 'burger',
  cross: 'cross',
  dashboard: 'dashboard',
  department: 'department',
  document: 'document',
  eye: 'eye',
  eyeSlash: 'eyeSlash',
  field: 'field',
  logo: 'logo',
  agroLogo: 'agroLogo',
  digitalAgroLogo: 'digitalAgroLogo',
  map: 'map',
  worker: 'worker',
  people: 'people',
  search: 'search',
  check: 'check',
  agronom: 'agronom',
  agronomRed: 'agronomRed',
  vehicle: 'vehicle',
  fieldsLg: 'fieldsLg',
  plansLg: 'plansLg',
  crop: 'crop',
  cropWhite: 'cropWhite',
  fieldOnMap: 'fieldOnMap',
  work: 'work',
  workWhite: 'workWhite',
  area: 'area',
  calendar: 'calendar',
  workCalendarNoShadow: 'workCalendarNoShadow',
  workCalendarWeakShadow: 'workCalendarWeakShadow',
  culture: 'culture',
  group: 'group',
  groupColored: 'groupColored',
  workShadow: 'workShadow',
  fav: 'fav',
  chevRight: 'chevRight',
  chevUp: 'chevUp',
  chevLeft: 'chevLeft',
  chevDown: 'chevDown',
  chevLong: 'chevLong',
  location: 'location',
  time: 'time',
  cloudiness: 'cloudiness',
  dewPoint: 'dewpoint',
  pressure: 'pressure',
  wet: 'wet',
  wind: 'wind',
  canister: 'canister',
  canisterOut: 'canisterOut',
  locationShadow: 'locationShadow',
  status: 'status',
  technicShadow: 'technicShadow',
  timeShadow: 'timeShadow',
  inMove: 'inMove',
  machineOperator: 'machineOperator',
  harvester: 'harvester',
  tanker: 'tanker',
  tractor: 'tractor',
  tractorSm: 'tractorSm',
  tractorWork: 'tractorWork',
  agregat: 'agregat',
  truck: 'truck',
  automobile: 'automobile',
  insufficientData: 'insufficientData',
  noConnection: 'noConnection',
  noDevice: 'noDevice',
  stop: 'stop',
  inWork: 'inWork',
  downtime: 'downtime',
  speedometer: 'speedometer',
  workCalendar: 'workCalendar',
  technic: 'technic',
  plan: 'plan',
  allObjectsMap: 'allObjectsMap',
  myLocationMap: 'myLocationMap',
  manMap: 'manMap',
  layers: 'layers',
  add: 'add',
  completeCircle: 'completeCircle',
  calendarTab: 'calendarTab',
  workTab: 'workTab',
  tractorTab: 'tractorTab',
  aggregateTab: 'aggregateTab',
  aggregateGreen: 'aggregateGreen',
  aggregateVeh: 'aggregateVeh',
  speedometerTab: 'speedometerTab',
  fieldsTab: 'fieldsTab',
  speedometerPreviewInfo: 'speedometerPreviewInfo',
  aggregatePreviewInfo: 'aggregatePreviewInfo',
  automobileShadow: 'automobileShadow',
  harvesterShadow: 'harvesterShadow',
  tankerShadow: 'tankerShadow',
  statusVehicle: 'statusVehicle',
  tractorShadow: 'tractorShadow',
  truckShadow: 'truckShadow',
  deleteIcon: 'deleteIcon',
  deleteIconBlack: 'deleteIconBlack',
  combineDT: 'combineDT',
  refuelerDT: 'refuelerDT',
  truckDT: 'truckDT',
  autoDT: 'autoDT',
  exit: 'exit',
  back: 'back',
  edit: 'edit',
  duplicate: 'duplicate',
  contextMenu: 'contextMenu',
  distance: 'distance',
  bigChevronUp: 'bigChevronUp',
  bigChevronDown: 'bigChevronDown',
  cloud: 'cloud',
  filter: 'filter',
  filterAutoSm: 'filterAutoSm',
  filterAutoSmActive: 'filterAutoSmActive',
  filterTruckSm: 'filterTruckSm',
  filterTruckSmActive: 'filterTruckSmActive',
  filterTractorSm: 'filterTractorSm',
  filterTractorSmActive: 'filterTractorSmActive',
  filterTankerSm: 'filterTankerSm',
  filterTankerSmActive: 'filterTankerSmActive',
  filterHarvesterSm: 'filterHarvesterSm',
  filterHarvesterSmActive: 'filterHarvesterSmActive',
  cardField: 'cardField',
  cardPeople: 'cardPeople',
  cardVehicle: 'cardVehicle',
  message: 'message',
  messageGreen: 'messageGreen',
  fieldMini: 'fieldMini',
  speedometerSmall: 'speedometerSmall',
  organization: 'organization',
  freeday: 'freeday',
  noteTab: 'noteTab',
  fueling: 'fueling',
  additionally: 'additionally',
  marker: 'marker',
  sink: 'sink',
  parking: 'parking',
  dataLoss: 'dataLoss',
  track: 'track',
  trackCurve: 'trackCurve',
  attachments: 'attachments',
  attachment: 'attachment',
  fileAudio: 'fileAudio',
  filePhoto: 'filePhoto',
  fileVideo: 'fileVideo',
  weighterMenu: 'weighterMenu',
  goods: 'goods',
  truckSm: 'truckSm',
  weight: 'weight',
  name: 'name',
  weightingPlace: 'weightingPlace',
  documentLabel: 'documentLabel',
  tag: 'tag',
  withoutTrailer: 'withoutTrailer',
  withoutGoods: 'withoutGoods',
  weighningSeparate: 'weighningSeparate',
  weighningJoined: 'weighningJoined',
  trailerSm: 'trailerSm',
  weightGoods: 'weightGoods',
  donut: 'donut',
  datepickerSm: 'datepickerSm',
  aggregateShadow: 'aggregateShadow',
  assignment: 'assignment',
  deadline: 'deadline',
  alert: 'alert',
  canceled: 'canceled',
  completed: 'completed',
  scouts: 'scouts',
  verification: 'verification',
  assignments: 'assignments',
  finishWork: 'finishWork',
  finishOpen: 'finishOpen',
  distanceMap: 'distanceMap',
  timer: 'timer',
  scheduleTab: 'scheduleTab',
  shiftTab: 'shiftTab',
  workerSuspender: 'workerSuspender',
  mainMenuShift: 'mainMenuShift',
  fullHeightAgronom: 'fullHeightAgronom',
  smSchedule: 'smSchedule',
  smShift: 'smShift',
  shiftTractorXL: 'shiftTractorXL',
  shiftWorker: 'shiftWorker',
  mainMenuSearch: 'mainMenuSearch',
  mainMenuDashboard: 'mainMenuDashboard',
  mainMenuMap: 'mainMenuMap',
  mainMenuVehicle: 'mainMenuVehicle',
  mainMenuField: 'mainMenuField',
  mainMenuEmployee: 'mainMenuEmployee',
  mainMenuDailyTask: 'mainMenuDailyTask',
  mainMenuPlan: 'mainMenuPlan',
  mainMenuAssignment: 'mainMenuAssignment',
  mainMenuReports: 'mainMenuReports',
  mainMenuDepartment: 'mainMenuDepartment',
  mainMenuExit: 'mainMenuExit',
  weather01d: 'weather01d',
  weather02d: 'weather02d',
  weather03d: 'weather03d',
  weather04d: 'weather04d',
  weather09d: 'weather09d',
  weather10d: 'weather10d',
  weather11d: 'weather11d',
  weather13d: 'weather13d',
  weather50d: 'weather50d',
  weather01n: 'weather01n',
  weather02n: 'weather02n',
  weather10n: 'weather10n',
  technicsLg: 'technicsLg',
  workerLg: 'workerLg',
  avgTemp: 'avgTemp',
  widthHeight: 'widthHeight',
  temperatureHigh: 'temperatureHigh',
  temperatureLow: 'temperatureLow',
  humidity: 'humidity',
  windSpeed: 'windSpeed',
  pressureData: 'pressureData',
  precipitation: 'precipitation',
  soilHumidity: 'soilHumidity',
  battery: 'battery',
  luminance: 'luminance',
  dewPointSoil: 'dewPointSoil',
  soilTemp: 'soilTemp',
  mainMenuMeteostations: 'mainMenuMeteostations',
  addTrack: 'addTrack',
};

export const svgMap = {
  [iconType.arrow]: arrow,
  [iconType.none]: '',
  [iconType.burger]: burger,
  [iconType.cross]: close,
  [iconType.dashboard]: dashboard,
  [iconType.department]: department,
  [iconType.document]: document,
  [iconType.eye]: eye,
  [iconType.eyeSlash]: eyeSlash,
  [iconType.field]: field,
  [iconType.logo]: logo,
  [iconType.agroLogo]: agroLogo,
  [iconType.digitalAgroLogo]: digitalAgroLogo,
  [iconType.map]: map,
  [iconType.people]: people,
  [iconType.search]: search,
  [iconType.check]: check,
  [iconType.agronom]: agronom,
  [iconType.agronomRed]: agronom,
  [iconType.vehicle]: vehicle,
  [iconType.worker]: worker,
  [iconType.fieldsLg]: fieldsLg,
  [iconType.plansLg]: plansLg,
  [iconType.crop]: culture,
  [iconType.cropWhite]: cropWhite,
  [iconType.culture]: culture,
  [iconType.fieldOnMap]: fieldOnMap,
  [iconType.work]: work,
  [iconType.workWhite]: workWhite,
  [iconType.fav]: fav,
  [iconType.chevRight]: chevRight,
  [iconType.chevUp]: chevUp,
  [iconType.chevLeft]: chevLeft,
  [iconType.chevDown]: chevDown,
  [iconType.chevLong]: chevLong,
  [iconType.location]: location,
  [iconType.time]: time,
  [iconType.fav]: fav,
  [iconType.area]: area,
  [iconType.calendar]: calendar,
  [iconType.workCalendarNoShadow]: workCalendarNoShadow,
  [iconType.workCalendarWeakShadow]: workCalendarWeakShadow,
  [iconType.group]: group,
  [iconType.groupColored]: group,
  [iconType.workShadow]: workShadow,
  [iconType.cloudiness]: cloudines,
  [iconType.dewPoint]: dewPoint,
  [iconType.pressure]: pressure,
  [iconType.wet]: wet,
  [iconType.wind]: wind,
  [iconType.canister]: canister,
  [iconType.canisterOut]: canisterOut,
  [iconType.locationShadow]: locationShadow,
  [iconType.status]: status,
  [iconType.technicShadow]: technicShadow,
  [iconType.timeShadow]: timeShadow,
  [iconType.inMove]: inMove,
  [iconType.machineOperator]: machineOperator,
  [iconType.harvester]: harvester,
  [iconType.tanker]: tanker,
  [iconType.tractor]: tractor,
  [iconType.tractorSm]: tractorSm,
  [iconType.tractorWork]: tractorWork,
  [iconType.agregat]: agregat,
  [iconType.truck]: truck,
  [iconType.automobile]: automobile,
  [iconType.insufficientData]: insufficientData,
  [iconType.noConnection]: noConnection,
  [iconType.noDevice]: noDevice,
  [iconType.stop]: stop,
  [iconType.inWork]: inWork,
  [iconType.downtime]: downtime,
  [iconType.speedometer]: speedometer,
  [iconType.speedometerSmall]: speedometerSmall,
  [iconType.workCalendar]: workCalendar,
  [iconType.technic]: vehicle,
  [iconType.plan]: plan,
  [iconType.allObjectsMap]: allObjectsMap,
  [iconType.myLocationMap]: myLocationMap,
  [iconType.layers]: layers,
  [iconType.manMap]: manMap,
  [iconType.add]: add,
  [iconType.completeCircle]: completeCircle,
  [iconType.calendarTab]: calendarTab,
  [iconType.workTab]: workTab,
  [iconType.tractorTab]: tractorTab,
  [iconType.aggregateTab]: aggregateTab,
  [iconType.aggregateGreen]: aggregateGreen,
  [iconType.aggregateVeh]: aggregateVeh,
  [iconType.speedometerTab]: speedometerTab,
  [iconType.fieldsTab]: fieldsTab,
  [iconType.speedometerPreviewInfo]: speedometerPreviewInfo,
  [iconType.aggregatePreviewInfo]: aggregatePreviewInfo,
  [iconType.automobileShadow]: automobileShadow,
  [iconType.harvesterShadow]: harvesterShadow,
  [iconType.tankerShadow]: tankerShadow,
  [iconType.statusVehicle]: statusVehicle,
  [iconType.tractorShadow]: tractorShadow,
  [iconType.truckShadow]: truckShadow,
  [iconType.deleteIcon]: deleteIcon,
  [iconType.deleteIconBlack]: deleteIcon,
  [iconType.combineDT]: combineDT,
  [iconType.refuelerDT]: refuelerDT,
  [iconType.truckDT]: truckDT,
  [iconType.autoDT]: autoDT,
  [iconType.exit]: exit,
  [iconType.back]: back,
  [iconType.edit]: edit,
  [iconType.duplicate]: duplicate,
  [iconType.contextMenu]: contextMenu,
  [iconType.distance]: distance,
  [iconType.bigChevronDown]: bigChevronDown,
  [iconType.bigChevronUp]: bigChevronUp,
  [iconType.cloud]: cloud,
  [iconType.filter]: filter,
  [iconType.filterAutoSm]: filterAutoSm,
  [iconType.filterAutoSmActive]: filterAutoSmActive,
  [iconType.filterTruckSm]: filterTruckSm,
  [iconType.filterTruckSmActive]: filterTruckSmActive,
  [iconType.filterTractorSm]: filterTractorSm,
  [iconType.filterTractorSmActive]: filterTractorSmActive,
  [iconType.filterTankerSm]: filterTankerSm,
  [iconType.filterTankerSmActive]: filterTankerSmActive,
  [iconType.filterHarvesterSm]: filterHarvesterSm,
  [iconType.filterHarvesterSmActive]: filterHarvesterSmActive,
  [iconType.cardField]: cardField,
  [iconType.cardPeople]: cardPeople,
  [iconType.cardVehicle]: cardVehicle,
  [iconType.message]: message,
  [iconType.messageGreen]: message,
  [iconType.fieldMini]: fieldMini,
  [iconType.organization]: organization,
  [iconType.freeday]: freeday,
  [iconType.noteTab]: noteTab,
  [iconType.fueling]: fueling,
  [iconType.additionally]: additionally,
  [iconType.marker]: marker,
  [iconType.sink]: sink,
  [iconType.parking]: parking,
  [iconType.dataLoss]: dataLoss,
  [iconType.track]: track,
  [iconType.trackCurve]: trackCurve,
  [iconType.attachments]: attachments,
  [iconType.attachment]: attachment,
  [iconType.fileAudio]: fileAudio,
  [iconType.filePhoto]: filePhoto,
  [iconType.fileVideo]: fileVideo,
  [iconType.weighterMenu]: weighterMenu,
  [iconType.goods]: goods,
  [iconType.truckSm]: truckSm,
  [iconType.weight]: weight,
  [iconType.name]: name,
  [iconType.weightingPlace]: weightingPlace,
  [iconType.documentLabel]: documentLabel,
  [iconType.tag]: tag,
  [iconType.withoutTrailer]: withoutTrailer,
  [iconType.withoutGoods]: withoutGoods,
  [iconType.weighningSeparate]: weighningSeparate,
  [iconType.weighningJoined]: weighningJoined,
  [iconType.trailerSm]: trailerSm,
  [iconType.weightGoods]: weightGoods,
  [iconType.donut]: donut,
  [iconType.datepickerSm]: datepickerSm,
  [iconType.aggregateVeh]: aggregateVeh,
  [iconType.assignment]: assignment,
  [iconType.deadline]: deadline,
  [iconType.alert]: alert,
  [iconType.canceled]: canceled,
  [iconType.completed]: completed,
  [iconType.scouts]: scouts,
  [iconType.verification]: verification,
  [iconType.assignments]: assignments,
  [iconType.finishWork]: finish,
  [iconType.finishOpen]: finish,
  [iconType.distanceMap]: distanceMap,
  [iconType.timer]: timer,
  [iconType.scheduleTab]: scheduleTab,
  [iconType.shiftTab]: shiftTab,
  [iconType.workerSuspender]: workerSuspender,
  [iconType.mainMenuShift]: mainMenuShift,
  [iconType.fullHeightAgronom]: fullHeightAgronom,
  [iconType.smSchedule]: smSchedule,
  [iconType.smShift]: smShift,
  [iconType.shiftTractorXL]: shiftTractorXL,
  [iconType.shiftWorker]: shiftWorker,
  [iconType.mainMenuSearch]: mainMenuSearch,
  [iconType.mainMenuDashboard]: mainMenuDashboard,
  [iconType.mainMenuMap]: mainMenuMap,
  [iconType.mainMenuVehicle]: mainMenuVehicle,
  [iconType.mainMenuField]: mainMenuField,
  [iconType.mainMenuEmployee]: mainMenuEmployee,
  [iconType.mainMenuDailyTask]: mainMenuDailyTask,
  [iconType.mainMenuPlan]: mainMenuPlan,
  [iconType.mainMenuAssignment]: mainMenuAssignment,
  [iconType.mainMenuReports]: mainMenuReports,
  [iconType.mainMenuDepartment]: mainMenuDepartment,
  [iconType.mainMenuExit]: mainMenuExit,
  [iconType.weather01d]: weather01d,
  [iconType.weather02d]: weather02d,
  [iconType.weather03d]: weather03d,
  [iconType.weather04d]: weather04d,
  [iconType.weather09d]: weather09d,
  [iconType.weather10d]: weather10d,
  [iconType.weather11d]: weather11d,
  [iconType.weather13d]: weather13d,
  [iconType.weather50d]: weather50d,
  [iconType.weather01n]: weather01n,
  [iconType.weather02n]: weather02n,
  [iconType.weather10n]: weather10n,
  [iconType.technicsLg]: technicsLg,
  [iconType.workerLg]: workerLg,
  [iconType.avgTemp]: avgTemp,
  [iconType.widthHeight]: widthHeight,
  [iconType.temperatureHigh]: temperatureHigh,
  [iconType.temperatureLow]: temperatureLow,
  [iconType.humidity]: humidity,
  [iconType.windSpeed]: windSpeed,
  [iconType.pressureData]: pressureData,
  [iconType.precipitation]: precipitation,
  [iconType.soilHumidity]: soilHumidity,
  [iconType.battery]: battery,
  [iconType.luminance]: luminance,
  [iconType.dewPointSoil]: dewPointSoil,
  [iconType.soilTemp]: soilTemp,
  [iconType.mainMenuMeteostations]: mainMenuMeteostations,
};

export const classNameMap = {
  [iconType.none]: st.arrow,
  [iconType.none]: '',
  [iconType.burger]: st.burger,
  [iconType.cross]: st.cross,
  [iconType.dashboard]: st.dashboard,
  [iconType.department]: st.department,
  [iconType.document]: st.document,
  [iconType.eye]: st.eye,
  [iconType.eyeSlash]: st.eye,
  [iconType.field]: st.field,
  [iconType.logo]: st.logo,
  [iconType.agroLogo]: st.agroLogo,
  [iconType.digitalAgroLogo]: st.digitalAgroLogo,
  [iconType.map]: st.map,
  [iconType.people]: st.people,
  [iconType.search]: st.search,
  [iconType.groupColored]: st.groupColored,
  [iconType.group]: st.group,
  [iconType.check]: st.check,
  [iconType.agronom]: st.agronom,
  [iconType.agronomRed]: st.agronomRed,
  [iconType.vehicle]: st.vehicle,
  [iconType.fieldsLg]: st.fieldsLg,
  [iconType.plansLg]: st.plansLg,
  [iconType.crop]: st.crop,
  [iconType.cropWhite]: st.cropWhite,
  [iconType.fieldOnMap]: st.fieldOnMap,
  [iconType.work]: st.work,
  [iconType.workWhite]: st.workWhite,
  [iconType.fav]: st.fav,
  [iconType.chevUp]: st.chevronUD,
  [iconType.chevLeft]: st.chevronLeft,
  [iconType.chevDown]: st.chevronUD,
  [iconType.chevRight]: st.chevronRL,
  [iconType.chevLong]: st.chevronLong,
  [iconType.time]: st.time,
  [iconType.calendar]: st.calendar,
  [iconType.workCalendar]: st.workCalendar,
  [iconType.workCalendarNoShadow]: st.workCalendarNoShadow,
  [iconType.workCalendarWeakShadow]: st.workCalendarWeakShadow,
  [iconType.cloudiness]: st.cloudines,
  [iconType.dewPointSoil]: st.dewPointSoil,
  [iconType.pressure]: st.pressure,
  [iconType.worker]: st.worker,
  [iconType.wet]: st.wet,
  [iconType.wind]: st.wind,
  [iconType.canister]: st.canister,
  [iconType.canisterOut]: st.canisterOut,
  [iconType.locationShadow]: st.locationShadow,
  [iconType.location]: st.location,
  [iconType.status]: st.status,
  [iconType.technicShadow]: st.technicShadow,
  [iconType.timeShadow]: st.timeShadow,
  [iconType.harvester]: st.harvester,
  [iconType.tanker]: st.tanker,
  [iconType.tractor]: st.tractor,
  [iconType.tractorSm]: st.tractorSm,
  [iconType.truck]: st.truck,
  [iconType.automobile]: st.automobile,
  [iconType.insufficientData]: st.insufficientData,
  [iconType.noConnection]: st.noConnection,
  [iconType.noDevice]: st.noDevice,
  [iconType.stop]: st.stop,
  [iconType.inWork]: st.inWork,
  [iconType.inMove]: st.inMove,
  [iconType.downtime]: st.downtime,
  [iconType.speedometer]: st.speedometer,
  [iconType.speedometerSmall]: st.speedometerSmall,
  [iconType.technic]: st.technic,
  [iconType.plan]: st.plan,
  [iconType.allObjectsMap]: st.allObjectsMap,
  [iconType.myLocationMap]: st.myLocationMap,
  [iconType.manMap]: st.manMap,
  [iconType.layers]: st.layers,
  [iconType.tractorWork]: st.tractorWork,
  [iconType.agregat]: st.agregat,
  [iconType.add]: st.add,
  [iconType.completeCircle]: st.completeCircle,
  [iconType.calendarTab]: st.calendarTab,
  [iconType.workTab]: st.workTab,
  [iconType.tractorTab]: st.tractorTab,
  [iconType.aggregateTab]: st.aggregateTab,
  [iconType.aggregateGreen]: st.aggregateGreen,
  [iconType.aggregateVeh]: st.aggregateVeh,
  [iconType.speedometerTab]: st.speedometerTab,
  [iconType.fieldsTab]: st.fieldsTab,
  [iconType.speedometerPreviewInfo]: st.speedometerPreviewInfo,
  [iconType.aggregatePreviewInfo]: st.aggregatePreviewInfo,
  [iconType.automobileShadow]: st.automobileShadow,
  [iconType.harvesterShadow]: st.harvesterShadow,
  [iconType.tankerShadow]: st.tankerShadow,
  [iconType.statusVehicle]: st.statusVehicle,
  [iconType.tractorShadow]: st.tractorShadow,
  [iconType.truckShadow]: st.truckShadow,
  [iconType.deleteIcon]: st.deleteIcon,
  [iconType.deleteIconBlack]: st.deleteIconBlack,
  [iconType.combineDT]: st.combineDT,
  [iconType.refuelerDT]: st.refuelerDT,
  [iconType.truckDT]: st.truckDT,
  [iconType.autoDT]: st.autoDT,
  [iconType.exit]: st.exit,
  [iconType.back]: st.back,
  [iconType.edit]: st.edit,
  [iconType.duplicate]: st.duplicate,
  [iconType.contextMenu]: st.contextMenu,
  [iconType.distance]: st.distance,
  [iconType.bigChevronDown]: st.bigChevron,
  [iconType.bigChevronUp]: st.bigChevron,
  [iconType.cloud]: st.cloud,
  [iconType.filter]: st.filter,
  [iconType.filterAutoSm]: st.filterAutoSm,
  [iconType.filterAutoSmActive]: st.filterAutoSmActive,
  [iconType.filterTruckSm]: st.filterTruckSm,
  [iconType.filterTruckSmActive]: st.filterTruckSmActive,
  [iconType.filterTractorSm]: st.filterTractorSm,
  [iconType.filterTractorSmActive]: st.filterTractorSmActive,
  [iconType.filterTankerSm]: st.filterTankerSm,
  [iconType.filterTankerSmActive]: st.filterTankerSmActive,
  [iconType.filterHarvesterSm]: st.filterHarvesterSm,
  [iconType.filterHarvesterSmActive]: st.filterHarvesterSmActive,
  [iconType.cardField]: st.cardField,
  [iconType.cardPeople]: st.cardPeople,
  [iconType.cardVehicle]: st.cardVehicle,
  [iconType.culture]: st.culture,
  [iconType.message]: st.message,
  [iconType.messageGreen]: st.messageGreen,
  [iconType.fieldMini]: st.fieldMini,
  [iconType.organization]: st.organization,
  [iconType.noteTab]: st.noteTab,
  [iconType.fueling]: st.fueling,
  [iconType.additionally]: st.additionally,
  [iconType.marker]: st.marker,
  [iconType.sink]: st.sink,
  [iconType.parking]: st.parking,
  [iconType.dataLoss]: st.dataLoss,
  [iconType.track]: st.track,
  [iconType.trackCurve]: st.trackCurve,
  [iconType.attachments]: st.attachments,
  [iconType.attachment]: st.attachment,
  [iconType.fileAudio]: st.fileAudio,
  [iconType.filePhoto]: st.filePhoto,
  [iconType.fileVideo]: st.fileVideo,
  [iconType.weighterMenu]: st.weighterMenu,
  [iconType.goods]: st.goods,
  [iconType.truckSm]: st.truckSm,
  [iconType.weight]: st.weight,
  [iconType.name]: st.name,
  [iconType.weightingPlace]: st.weightingPlace,
  [iconType.documentLabel]: st.documentLabel,
  [iconType.tag]: st.tag,
  [iconType.withoutTrailer]: st.withoutTrailer,
  [iconType.withoutGoods]: st.withoutGoods,
  [iconType.weighningSeparate]: st.weighningSeparate,
  [iconType.weighningJoined]: st.weighningJoined,
  [iconType.trailerSm]: st.trailerSm,
  [iconType.weightGoods]: st.weightGoods,
  [iconType.donut]: st.donut,
  [iconType.datepickerSm]: st.datepickerSm,
  [iconType.aggregateShadow]: st.aggregateShadow,
  [iconType.assignment]: st.assignment,
  [iconType.deadline]: st.deadline,
  [iconType.alert]: st.alert,
  [iconType.canceled]: st.canceled,
  [iconType.completed]: st.completed,
  [iconType.scouts]: st.scouts,
  [iconType.verification]: st.verification,
  [iconType.assignments]: st.assignments,
  [iconType.finishWork]: st.finishWork,
  [iconType.finishOpen]: st.finishOpen,
  [iconType.distanceMap]: st.distanceMap,
  [iconType.timer]: st.timer,
  [iconType.scheduleTab]: st.scheduleTab,
  [iconType.shiftTab]: st.shiftTab,
  [iconType.workerSuspender]: st.workerSuspender,
  [iconType.mainMenuShift]: st.mainMenuShift,
  [iconType.fullHeightAgronom]: st.fullHeightAgronom,
  [iconType.smSchedule]: st.smSchedule,
  [iconType.smShift]: st.smShift,
  [iconType.shiftTractorXL]: st.shiftTractorXL,
  [iconType.shiftWorker]: st.shiftWorker,
  [iconType.mainMenuSearch]: st.mainMenuSearch,
  [iconType.mainMenuDashboard]: st.mainMenuDashboard,
  [iconType.mainMenuMap]: st.mainMenuMap,
  [iconType.mainMenuVehicle]: st.mainMenuVehicle,
  [iconType.mainMenuField]: st.mainMenuField,
  [iconType.mainMenuEmployee]: st.mainMenuEmployee,
  [iconType.mainMenuDailyTask]: st.mainMenuDailyTask,
  [iconType.mainMenuPlan]: st.mainMenuPlan,
  [iconType.mainMenuAssignment]: st.mainMenuAssignment,
  [iconType.mainMenuReports]: st.mainMenuReports,
  [iconType.mainMenuDepartment]: st.mainMenuDepartment,
  [iconType.mainMenuExit]: st.mainMenuExit,
  [iconType.weather01d]: st.weather01d,
  [iconType.weather02d]: st.weather02d,
  [iconType.weather03d]: st.weather03d,
  [iconType.weather04d]: st.weather04d,
  [iconType.weather09d]: st.weather09d,
  [iconType.weather10d]: st.weather10d,
  [iconType.weather11d]: st.weather11d,
  [iconType.weather13d]: st.weather13d,
  [iconType.weather50d]: st.weather50d,
  [iconType.weather01n]: st.weather01n,
  [iconType.weather02n]: st.weather02n,
  [iconType.weather10n]: st.weather10n,
  [iconType.technicsLg]: st.technicsLg,
  [iconType.workerLg]: st.workerLg,
  [iconType.avgTemp]: st.avgTemp,
  [iconType.widthHeight]: st.widthHeight,
  [iconType.temperatureHigh]: st.temperatureHigh,
  [iconType.temperatureLow]: st.temperatureLow,
  [iconType.humidity]: st.humidity,
  [iconType.windSpeed]: st.windSpeed,
  [iconType.pressureData]: st.pressureData,
  [iconType.precipitation]: st.precipitation,
  [iconType.soilHumidity]: st.soilHumidity,
  [iconType.battery]: st.battery,
  [iconType.luminance]: st.luminance,
  [iconType.dewPointSoil]: st.dewPointSoil,
  [iconType.soilTemp]: st.soilTemp,
  [iconType.mainMenuMeteostations]: st.mainMenuMeteostations,
};
