import moment from 'moment';
import httpClient from "@infobis/api-module";
import {url} from "../api/index";
import reportType from '../const/reportType';
import store from '../store/store';
import {actions} from '../components/Main/Warnings/WarningsActions';
import VehicleService from "./VehicleService";
import AuthService from "./AuthService";
import fetchStatus from '../const/fetchStatus';
import {stringHash} from "../helpers/stringHash";
import {Notification} from './../components/common/Notification';

class WarningService {
  _status = '';

  constructor() {
    this.reset();
    window.WarningService = this;
  }

  getList() {
    return this.data;
  }

  getVehicle() {
    return VehicleService.getItem(this._vehId);
  }

  isPending() {
    return this._status === fetchStatus.PENDING;
  }

  reset() {
    this.data = [];
    this._vehId = null;
    this._status = fetchStatus.DEFAULT;
  }

  async fetch(vehicleId) {
    if (Number(vehicleId) !== this._vehId) {
      store.dispatch(actions.fetchPending());
      this.data = [];
    } else {
      return;
    }
    this._vehId = Number(vehicleId);
    const now = moment();
    const user = AuthService.getUser();
    const request = {
      url: url.reportData,
      params: {
        method: 'POST',
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
      authorize: true,
      body: JSON.stringify({
        reportId: reportType.WARNINGS,
        entityId: [Number(vehicleId)],
        strEntityId: [vehicleId.toString()],
        to: now.toJSON(),
        limit: 25,
        page: 1,
        start: 0,
        tzOffset: -now.utcOffset(),
        from: now.add(-1, 'M').toJSON(),
        filters: {
          fieldsFilter: [],
          unitsFilter: [],
          driversFilter: [],
        },
      }),
    };

    this._status = fetchStatus.PENDING;
    try {
      const response = await httpClient.makeRequest(request);
      this._status = fetchStatus.DEFAULT;
      if (response && response.success && response.data) {
        this.data = response.data;
        this._setHash();
        this._setMessage();
        store.dispatch(actions.fetchSuccess());
      } else {
        store.dispatch(actions.fetchError());
      }
    } catch (error) {
      store.dispatch(actions.fetchError());
      Notification.error(error);
    }



  }

  _setHash() {
    for (const warning of this.data) {
      warning._hash = (stringHash(warning.message) +
        warning.startTime + warning.endTime +
        (warning.startLat + warning.startLon +
        warning.endLat + warning.endLon) * 1000).toFixed(0);
    }
  }

  _setMessage() {
    for (const warning of this.data) {
      const parts = warning.message.split(/<[^>]*>/ig);
      warning.messageShort = parts[0];
      warning.messageFull = parts;
    }
  }
}

export default new WarningService();
