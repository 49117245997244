import {
  configureStore as configureStoreDev,
  history as historyDev
} from './configureStore.dev';
import {
  configureStore as configureStoreProd,
  history as historyProd
} from './configureStore.prod';

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev,
  history = process.env.NODE_ENV === 'production' ? historyProd : historyDev;

export { configureStore, history };
