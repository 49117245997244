import values from 'lodash/values';
import isEmpty from "lodash/isEmpty";
import isEqual from 'lodash/isEqual';
import { url } from '../api';
import BaseDataService from "../baseClasses/BaseDataService";
import AddressService from "./AddressService";
import { actions as meteostationsActions } from "../components/Main/Meteostations/MeteostationsActions";
import store from "../store/store";
import { formatVehicleFilter } from '../helpers/formatVehicleFilter';
import VehicleService from './VehicleService';
import { filterTree, mergeTrees } from '../helpers/simpleFilter';
import { agroWsEvents, WebsocketService } from './WebsocketService';
import { aspect, VEH_TYPE } from '../const';
import { compact } from 'lodash';

class MeteostationsService extends BaseDataService {
  _posCache = { pos: [], value: '', id: -1 };

  constructor() {
    super({ urlAll: url.meteostations, ...meteostationsActions });
    window.MeteostationsService = this;

  }

  dispatchDataChange() {
    super.dispatchDataChange();
    store.dispatch(meteostationsActions.fetchSuccess());
  }

  getItem(id) {
    return this._glidMap[id];
  }
  getMeteostationsList() {
    return values(this._glidMap);
  }

  getAddess(id) {
    const meteostation = this.getItem(id);
    if (isEmpty(meteostation) || (!meteostation.position && !meteostation.rt_position)) {
      return '';
    }
    if (!isEqual(Number(this._posCache.id), Number(id))) {
      this._posCache.id = Number(id);
      this._posCache.value = '';
    }

    const pos = meteostation.rt_position ? [...meteostation.rt_position[0]].reverse() : [...meteostation.position[0]].reverse();

    if (isEqual(this._posCache.pos, pos) && isEqual(Number(this._posCache.id), Number(id))) {
      return this._posCache.value;
    }

    this._posCache.pos = [...pos];

    AddressService.getAddress(pos, (value) => {
      this._posCache.value = value;
    });
    return this._posCache.value;
  }

  updateSensorsData = (data, ids) => {
    const items = data.items;

    if (!isEmpty(items)) {
      const thermMeas = items.filter(i => !isEmpty(i.values) && i.values.thermorodMeasurements).reduce(
        (acc, cur) => {
          const { id, value, time: last } = cur.values.thermorodMeasurements;
          return { ...acc, [id]: { value, last } };
        }, {}
      );

      if (!isEmpty(thermMeas)) {
        const stateSensors = store.getState().main.meteostations.sensors;
        const needUpdate = isEmpty(stateSensors) || Object.keys(thermMeas).some((id) => stateSensors[id].value !== thermMeas[id].value);
        if (needUpdate) {
          const data = ids.reduce((acc, cur) => {
            const { value, last } = (thermMeas[cur] || {});
            return {
              ...acc,
              [cur]: { value, last }
            }
          }, {});
          store.dispatch(meteostationsActions.setSensors(data));
        }
      }
    }
  };

  async startWsConnection(tree) {
    const getLeafs = (meteoTree) => meteoTree.reduce((acc, cur) => [...acc, ...(cur.leaf ? [cur] : getLeafs(cur.children))], []);
    const meteoTree = !isEmpty(tree.root.children) && tree.root.children.filter(n => n.filterShow);

    const warehouseLeafs = meteoTree && getLeafs(meteoTree).filter(leaf => leaf.data.type === VEH_TYPE.WAREHOUSE);

    const ids = warehouseLeafs && compact(warehouseLeafs.map(leaf =>
      leaf.data.sensors
        .filter(station => (station.chartType === 'sensorData' && /^Термоштанга*/.test(station.partTitle)))
        .map(s => s.sourceId)
    )).flat();

    const wsListenerBind = data => this.updateSensorsData(data, ids);

    if (!isEmpty(ids)) {

      const [id, ...rest] = ids;

      WebsocketService.cbOnOpen(() => {
        WebsocketService.setDynamic(
          {
            id,
            aspect: aspect.THERMOROD_MEASUREMENTS,
            additionalAspect: aspect.THERMOROD_MEASUREMENTS,
            additionalIds: rest,
            needRestart: true,
          }
        );
        WebsocketService.removeListener(agroWsEvents.AG_CHANGES, this._wsListener);
        WebsocketService.addListener(agroWsEvents.AG_CHANGES, wsListenerBind);
      })

      this._wsListener = wsListenerBind;
    }
  }


  getShortenTree() {
    const data = super.getShortenTree();
    const status = {
      "any": {
        "title": "любой",
        "isActive": true,
        "isHide": false,
      }
    };
    const type = {
      "warehouse": {
        "title": "склад",
        "isActive": true,
        "isHide": false
      }
    };

    const { filterStatus, filterType } = formatVehicleFilter(status, type);
    const vehicleTree = VehicleService.getFilteredTree(filterStatus, filterType, true);
    const meteoFilteredTree = filterTree({ tree: data });
    const vehicleFilteredTree = filterTree({ tree: vehicleTree });
    const tree = mergeTrees(meteoFilteredTree, vehicleFilteredTree);
    this.startWsConnection(tree)
    return tree;
  }
}

export default new MeteostationsService();
