import {types} from './PlanFactActions';

const defaultState = {
  viewCulture: false,
  filterStr: '',
  collapseState: {},
  pendingDetails: {},
  tabIndex: 0,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.FETCH:
    case types.FETCH_PENDING:
    case types.FETCH_ERROR:
    case types.FETCH_SUCCESS:
    case types.FILTER:
    case types.FILTER_PATH:
    case types.FILTER_MODE:
    case types.TOGGLE_COLLAPSE__PLANFACT:
    case types.FETCH_PLAN_OPERATION_HISTORY__PLANFACT:
    case types.FETCH_PLAN_OPERATION_HISTORY_DONE__PLANFACT:
    case types.FILTER__PLANFACT:
    case types.SELECT_TAB__PLANFACT:
      state = Object.assign({}, state, {...action.payload});
      break;
  }

  return state;
}
