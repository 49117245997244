import { WebsocketService, agroWsEvents } from './WebsocketService';
import store from '../store/store';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import sum from 'lodash/sum';
import min from 'lodash/min';
import max from 'lodash/max';
import { actions } from '../components/Main/Vehicles/VehiclesActions';
import FieldService from "./FieldService";
import VehicleService from "./VehicleService";
import moment from "moment/moment";
import OperationService from "./OperationService";
import fetchStatus from "../const/fetchStatus";

class VehicleWorkService {
  _vehicleId = null;
  _vehicle = null;
  _data = [];
  _list = [];

  constructor() {
    window.VehicleWorkService = this;
  }

  async fetch(id) {
    if (this._vehicleId !== id) {
      this._data = [];
    } else {
      return;
    }
    this._vehicleId = Number(id);
    this._vehicle = FieldService.getItem(this._vehicleId);

    this._status = fetchStatus.PENDING;

    this.onUpdate();
    WebsocketService.cbOnOpen(() => {
      WebsocketService.addListener(agroWsEvents.AG_CHANGES, data => this.onUpdate(data));
    });
  }

  onUpdate() {
    if (!window.updatingOn) {
      return;
    }
    this._setWorkList();
    store.dispatch(actions.fetchSuccess());
  }

  reset() {
    this._vehicleId = null;
    this._vehicle = null;
    this._data = [];
    this._list = [];
    this._status = fetchStatus.PENDING;
  }

  isPending() {
    return this._status === fetchStatus.PENDING;
  }

  getList() {
    return this._list || [];
  }

  getVehicle() {
    this._vehicle = VehicleService.getItem(this._vehicleId);
    return this._vehicle;
  }

  getItem({ operationId, startTime }) {
    return this._list.find(i => Number(i.operationId) === Number(operationId) && Number(i.startTime) === Number(startTime)) || {};
  }

  getOperationsForWork(operationId) {
    return this._data.filter(i => Number(i.operationId) === Number(operationId));
  }

  _setWorkList() {
    // берем поля в которых есть cultivationInfo.

    const fields = values(FieldService._glidMap)
      .filter(f => f.cultivationInfo);

    // фильтруем поля по такому критерию:
    // если есть непустая информация о cultivationInfo.base или cultivationInfo.insert и внутри них присутствует информация о нашей технике.
    // Т.е. если в полях были работы, в которых участвовала наша техника, то поле берем.
    
    const fieldsFiltered = fields.filter(f => {
      let allWorks = [];
      let tempBase = Object.values(f.cultivationInfo).map(item => item.base && item.base[0]);
      let tempInsert = Object.values(f.cultivationInfo).map(item => item.insert && item.insert[0]);
      tempBase && tempBase[0] !== undefined && allWorks.push(...tempBase);
      tempInsert && tempInsert[0] !== undefined && allWorks.push(...tempInsert);
      return allWorks.find(w =>w.unitInfo && w.unitInfo[this._vehicleId]);
    });

    // инициализируем пустой массив работ.

    const works = [];

    // заполняем массив работ.
    // он будет состояить из элементов такой структуры:
    // base[0], insert[0], fieldId, field, operationId, operation.

    for (const f of fieldsFiltered) {
      // const baseArr = [...(f.cultivationInfo.base || [])];
      // const insertArr = [...(f.cultivationInfo.insert || [])];
      // const worksOnField = [...baseArr];
      Object.values(f.cultivationInfo).forEach(work => {

        if (!isEmpty(work.base[0].unitInfo[this._vehicleId])) {
          const w = Object.assign({},
            work.base[0],
            work.insert && work.insert[0],
            {
              fieldId: f.glid,
              field: FieldService.getItem(f.glid),
              operationId: work.operationId,
              operation: OperationService.getItem(work.operationId),
            });
          works.push(w);
        }
      })
  }

   
    this._status = fetchStatus.DEFAULT;


works.forEach(w => {
  // w.startTime = min(values(w.unitInfo).map(i => i.startTime));
  // w.endTime = max(values(w.unitInfo).map(i => i.endTime));
  w.startTime = w.unitInfo[this._vehicleId].startTime;
  w.endTime = w.unitInfo[this._vehicleId].endTime;
  w.duration = w.unitInfo[this._vehicleId].duration;
});

this._data = works;

this._list = values(groupBy(this._data, 'operationId')).map(group => {
  const w = {
    operation: group[0].operation,
    operationId: group[0].operationId,
  };
  w.startTime = min(group.map(o => o.startTime));
  w.endTime = max(group.map(o => o.endTime));
  w.avgSpeed = group.map(o => o.avgSpeed * o.distance).reduce((acc, v) => acc + v) / sum(group.map(o => o.distance));

  w.group = group;
  w.units = group.map(o => o.unitInfo && o.unitInfo[this._vehicleId] && o.unitInfo[this._vehicleId]);

  w.group.forEach(operation => {
    const totalDistance = sum(values(operation.unitInfo).map(u => u.distance));
    operation.areaByDistance = sum(values(operation.unitInfo).map(u => u.area));
    operation.factor = operation.areaByDistance / operation.areaCultivated;
    operation.percent = (operation.unitInfo[this._vehicleId].distance / totalDistance) * ((operation.areaCultivated / 10000) / operation.field.area) * 100;
  });

  w.areaCultivated = sum(group.map(o => o.unitInfo[this._vehicleId].area));
  w.areaCultivatedFact = sum(group.map(o => o.unitInfo[this._vehicleId].area / o.factor));

  w.duration = sum(group.map(o => o.unitInfo && o.unitInfo[this._vehicleId] && o.unitInfo[this._vehicleId].duration));
  w.productivity = (w.areaCultivatedFact / 10000) / moment.duration(w.duration, 'ms').asHours();


  return w;
});
  }
}

export default new VehicleWorkService();
