import isEmpty from "lodash/isEmpty";
import indexOf from 'lodash/indexOf';
import isBoolean from 'lodash/isBoolean';
import isArray from 'lodash/isArray';
import get from "lodash/get";
import every from "lodash/every";

export const walkChildren = (node, cb = () => null) => {
  cb(node);
  node && (node.children || []).map(n => walkChildren(n, cb));
};

const comparators = {
  array: (query, queryKey, _node) => indexOf(query[queryKey], String(get(_node.data, `[${queryKey}]`, '')).trim()) !== -1,
  bool: (query, queryKey, _node) => query[queryKey] === get(_node.data, `[${queryKey}]`, false),
};

const compare = (query, queryKey, _node) => {
  if (isArray(query[queryKey])) {
    return comparators.array(query, queryKey, _node);
  }
  if (isBoolean(query[queryKey])) {
    return comparators.bool(query, queryKey, _node);
  }
};

export function filterTreeKey({
                                tree,
                                query = {},
                                predicate = x => true,
                                nodePredicate = n => true,
                                terminal = n => n.leaf,
                                filterPath = 'root',
                              }) {
  const queryKeys = Object.keys(query);

  for (const mapKey in tree.map) {
    const node = tree.map[mapKey];
    node.setAttr('exceptNode', !isEmpty(queryKeys));
  }

  const node = tree.map[filterPath];
  if (node) {
    node.parents().map(n => tree.map[n.path].setAttr('exceptNode', false));

    walkChildren(node, n => {
      const _node = tree.map[n.path];

      // query filtering
      if (!isEmpty(queryKeys)) {
        if (terminal(_node)) {
          _node.setAttr('exceptNode', false);
          const same = every(queryKeys.map(queryKey => {
            const hasOptions = isBoolean(query[queryKey]) || !isEmpty(query[queryKey]);
            return hasOptions ? compare(query, queryKey, _node) : false;
          }), x => x === true);
          _node.setAttr('exceptNode', !same);
        } else {
          _node.setAttr('exceptNode', true);
        }
      }

      // pedicate filtering
      if (!predicate(_node.data) || !nodePredicate(_node)) {
        _node.setAttr('exceptNode', true);
      }
    });
  }
  tree.traverseDF(n => {
    if (isEmpty(n.data) || (!terminal(n) && (isEmpty(n.children) || every(n.children, c => !c.exceptNode)))) {
      n.setAttr('exceptNode', false);
    }
  });
  return tree;
}
