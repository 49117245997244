import * as React from 'react';
import {ToastContainer, toast, style} from 'react-toastify';
import CloseButton from './CloseButton';

const NotificationContainer = () => (
  <ToastContainer
    position={toast.POSITION.BOTTOM_CENTER}
    type={toast.TYPE.SUCCESS}
    autoClose={4000}
    newestOnTop={false}
    hideProgressBar
    closeOnClick
    pauseOnHover
    style={style({mobile: "only screen and (max-width : 960px)"})}
    closeButton={<CloseButton />}
  />
);

export {NotificationContainer};
