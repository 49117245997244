import values from 'lodash/values';
import get from 'lodash/get';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import {url} from '../api';
import {actionsByWork} from '../components/Main/Plans/PlansActions';
import BaseDataService from "../baseClasses/BaseDataService";
import {groupConditions} from "../components/Main/Tasks/CreateTask/constants";
import {mapValuesForKeys} from "../helpers/mapValuesForKeys";
import FieldService from "./FieldService";
import reportType from "../const/reportType";
import AuthService from "./AuthService";
import moment from "moment/moment";
import httpClient from "@infobis/api-module";
import store from "../store/store";
import {actions} from '../components/Main/Plans/PlanFact/PlanFactActions';
import {parseFolderPath} from "../helpers/parseFolderPath";
import DepartmentService from "./DepartmentService";
import {Notification} from './../components/common/Notification';

class PlanByWorkService extends BaseDataService {
  _planOperationHistory = {};

  constructor() {
    super({
      urlAll: url.plans,
      ...actionsByWork,
      qs: {
        groupCondition: groupConditions.OPERATIONS,
      }
    });

    window.PlanByWorkService = this;
  }

  fetchData = (id) => store.dispatch(actions.fetch({id, viewCulture: false}));

  _afterFetch() {
    super._afterFetch();

    const plans = values(this.data.map).filter(n => n.depth === 3);
    for (const planNode of plans) {
      planNode.data.listItem = mapValuesForKeys(planNode.data, [
        'glid',
        'name',
        'from',
        'to',
        {
          key: 'crops',
          value: planNode.data.data.map(x => x.name),
        },
        {
          key: 'fields',
          value: planNode.data.fields.map(fieldId => get(FieldService.getItem(fieldId), 'name'))
        },
      ]);
    }
  }

  async fetchPlanOperationHistory(glid) {
    const now = moment();
    const user = AuthService.getUser();
    const plan = this.getItem(glid);

    const request = {
      url: url.reportData,
      params: {
        method: 'POST',
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
      authorize: true,
      body: JSON.stringify({
        from: now.toJSON(),
        to: now.toJSON(),
        reportId: reportType.PLAN,
        entityId: plan.fields,
        strEntityId: plan.appointments,
        tzOffset: -now.utcOffset(),
        limit: 25,
        page: 1,
        start: 0,
        filters: {
          fieldsFilter: [],
          unitsFilter: [],
          driversFilter: [],
        },
      }),
    };

    store.dispatch(actions.fetchPlanOperationHistory(glid));

    try {
      const response = await httpClient.makeRequest(request);
      if (response && response.success && response.data) {
        this.updateStuff(glid, response.data);
      }
    } catch (error) {
      Notification.error(error);
    }

    store.dispatch(actions.fetchPlanOperationHistoryDone(glid));
  }

  updateStuff(glid, data) {
    this._planOperationHistory[glid] = data;
  }

  getPlanOperationHistory() {
    const grouped = {};

    for (const key of keys(this._planOperationHistory)) {
      grouped[key] = {};
      const operations = this._planOperationHistory[key];
      const plansGrouped = {};

      for (const operation of operations) {
        operation.fieldData = pick(FieldService.getItem(operation.idField), ['glid', 'name']);
        operation.fieldData.area = operation.area;
        operation.fieldData.fromP = operation.startP;
        operation.fieldData.toP = operation.endP;
        operation.fieldData.fromF = operation.startF;
        operation.fieldData.toF = operation.endF;
        operation.fieldData.areaPercent = operation.areaPercent;
        const dep = values(DepartmentService.data.map).find(x => x.data.name === operation.department);
        operation.departmentPath = parseFolderPath(get(dep, 'path', ''), true);
        operation.operationName = operation.operation.replace(operation.planTitle, '').slice(0, -2).trim();
      }

      grouped[key] = groupBy(operations, 'planTitle');

      Object.assign(grouped[key], plansGrouped);
    }

    return grouped;
  }

  saveTreeState(node) {
    const treeNode = values(this.data.map).find(n => n.data.glid === node.data.glid);
    treeNode.opened = node.opened;
    super.saveTreeState();
  }

  syncNodeExpandState(node) {
    const t = setTimeout(() => {
      clearTimeout(t);
      const nd = get(this.data, `map[${node.path}]`);

      if (isEmpty(nd)) {
        this.syncNodeExpandState(node);
        return;
      }

      nd.opened = node.opened;
    }, 50);
  }
}

export default new PlanByWorkService();
