const MAIN_ROUTE = '/main';

const routes = {
  about: `${MAIN_ROUTE}/about`,
  dashboard: `${MAIN_ROUTE}/dashboard`,
  department: `${MAIN_ROUTE}/department`,
  employee: (id = ':id') => `${MAIN_ROUTE}/employee/${id}`,
  field: (id = ':id') => `${MAIN_ROUTE}/field/${id}`,
  meteostation: (id = ':id') => `${MAIN_ROUTE}/meteostations/${id}`,
  fieldWork: ({ fieldId = ':fieldId', operationId = ':operationId', dateStart = ':dateStart' } = {}) => `${MAIN_ROUTE}/fieldWork/${fieldId}-${operationId}-${dateStart}`,
  goods: ({
            fieldId = ':fieldId',
            operationId = ':operationId',
            dateStart = ':dateStart',
          } = {}) => `${MAIN_ROUTE}/fieldWork/${fieldId}-${operationId}-${dateStart}/goods/`,
  goodsItem: ({
            goodId = ':goodId',
            fieldId = ':fieldId',
            operationId = ':operationId',
            dateStart = ':dateStart',
          } = {}) => `${MAIN_ROUTE}/fieldWork/${fieldId}-${operationId}-${dateStart}/goods/${goodId}`,
  goodsItemEdit: ({
            routeAddon = ':routeAddon',
            fieldId = ':fieldId',
            operationId = ':operationId',
            dateStart = ':dateStart',
          } = {}) => `${MAIN_ROUTE}/fieldWork/${fieldId}-${operationId}-${dateStart}/goodsEdit/${routeAddon}`,
  fieldWorkList: (id = ':id') => `${MAIN_ROUTE}/field/${id}/work`,
  home: MAIN_ROUTE,
  login: '/login',
  map: `${MAIN_ROUTE}/map`,
  track: (entityId = ':entityId') => `${MAIN_ROUTE}/track/${entityId}`,
  reports: `${MAIN_ROUTE}/reports`,
  plan: `${MAIN_ROUTE}/plan/`,
  planFact: (id = ':id', view = ':view') => `${MAIN_ROUTE}/plan_fact/${view}/${id}`,
  fact: ({
    id = ':id',
    view = ':view',
    groupId = ':groupId',
    planName = ':planName',
    factId = ':factId',
    from = ':from',
    to = ':to',
  } = {}) => `${MAIN_ROUTE}/fact/${view}/${id}/${groupId}--${planName}--${factId}--${from}--${to}`,
  planDetails: ({
    id = ':id',
    view = ':view',
    groupId = ':groupId',
    planName = ':planName',
    factId = ':factId',
    from = ':from',
    to = ':to',
  } = {}) => `${MAIN_ROUTE}/plan-details/${view}/${id}/${groupId}--${planName}--${factId}--${from}--${to}`,
  stub: `${MAIN_ROUTE}/stub`,
  search: `${MAIN_ROUTE}/search`,
  vehicle: (entityId = ':entityId') => `${MAIN_ROUTE}/vehicle/${entityId}`,
  vehicleFilter: `${MAIN_ROUTE}/vehicle/filter`,
  vehicleWork: ({ vehicleId = ':vehicleId', operationId = ':operationId', startTime = ':startTime' } = {}) => `${MAIN_ROUTE}/vehicleWork/${vehicleId}-${operationId}-${startTime}`,
  vehicleWorkList: (id = ':id') => `${MAIN_ROUTE}/vehicle/${id}/work`,
  vehicleTracksPeriod: `${MAIN_ROUTE}/vehicle/tracks_period`,
  vehicleTracks: `${MAIN_ROUTE}/vehicle/tracks`,
  action: (id = ':id', from = ':from') => `${MAIN_ROUTE}/action/${id}/${from}`,
  actions: (id = ':id') => `${MAIN_ROUTE}/action/${id}`,
  warning: (id = ':id', hash = ':hash') => `${MAIN_ROUTE}/warning/${id}/${hash}`,
  warnings: (id = ':id') => `${MAIN_ROUTE}/warning/${id}`,
  workShiftList: ({ fieldId = ':fieldId', operationId = ':operationId', sourceId = ':sourceId', dateStart = ':dateStart' } = {}) => `${MAIN_ROUTE}/workShift/${fieldId}-${sourceId}-${operationId}-${dateStart}`,
  workShift: ({
    fieldId = ':fieldId',
    operationId = ':operationId',
    sourceId = ':sourceId',
    dateStart = ':dateStart',
    dateShiftStart = ':dateShiftStart',
  } = {}) => `${MAIN_ROUTE}/workShift/${fieldId}-${sourceId}-${operationId}-${dateStart}-${dateShiftStart}`,
  taskCreate: `${MAIN_ROUTE}/task/create`,
  task: (id = ':id') => `${MAIN_ROUTE}/task/${id}`,
  taskFilter: `${MAIN_ROUTE}/task_filter`,
  note: ({
    entityType = ':entityType',
    entityId = ':entityId',
    noteId = ':noteId',
    routeAddon = ':routeAddon',
  } = {}) => `${MAIN_ROUTE}/note/${entityType}/${entityId}&${routeAddon}/${noteId}`,
  noteEdit: ({ id = ':id', entityId = ':entityId', entityType = ':entityType', routeAddon = ':routeAddon' } = {}) => `${MAIN_ROUTE}/note_edit/${entityId}/${entityType}/${id}/${routeAddon}`,
  trackFilter: `${MAIN_ROUTE}/track_filter/`,

  weighted: (id = ':id') => `${MAIN_ROUTE}/weighted/${id}`,
  weightedFilter: `${MAIN_ROUTE}/weighted-filter/`,

  harvesterUpload: (id = ':id') => `${MAIN_ROUTE}/harvester_upload/${id}`,
  harvesterUploadFilter: `${MAIN_ROUTE}/harvester_upload_filter/`,

  tractorRecord: (id = ':id') => `${MAIN_ROUTE}/tractor_record/${id}`,
  tractorRecordFilter: `${MAIN_ROUTE}/tractor_record_filter/`,

  assignment: ({
    entityType = ':entityType',
    entityId = ':entityId',
    id = ':id',
  } = {}) => `${MAIN_ROUTE}/assignment/${entityType}/${entityId}/${id}`,
  assignmentFilter: ({ entityType = ':entityType', entityId = ':entityId' } = {}) => `${MAIN_ROUTE}/assignment_filter/${entityType}/${entityId}`,
  assignmentReport: ({
    id = ':id',
    entityType = ':entityType',
    entityId = ':entityId',
    toStatus = ':toStatus',
  } = {}) => `${MAIN_ROUTE}/assignment_report/${entityId}/${entityType}/${id}-${toStatus}`,
  assignmentEdit: ({
    id = ':id',
    entityType = ':entityType',
    entityId = ':entityId',
  } = {}) => `${MAIN_ROUTE}/assignment_edit/${entityId}/${entityType}/${id}`,
  shift: ({ id = ':id' } = {}) => `${MAIN_ROUTE}/shift/${id}`,
  shifts: `${MAIN_ROUTE}/shifts`,
  shiftEdit: ({ id = ':id' } = {}) => `${MAIN_ROUTE}/shift_edit/${id}`,
  shiftDuplicate: ({ id = ':id' } = {}) => `${MAIN_ROUTE}/shift_duplicate/${id}`,
  shiftFilter: `${MAIN_ROUTE}/shift_filter`,
  shiftSchedule: ({ id = ':id', driverId = ':driverId' } = {}) => `${MAIN_ROUTE}/shiftSchedule/${id}/${driverId}`,
};

export default routes;
export { routes, MAIN_ROUTE };
