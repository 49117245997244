export default class Queue {
    constructor() {
        this._queue = [];
    }

    add(obj) {
        this._queue.push(obj);
    }

    get() {
        return this._queue.shift();
    }

    peek() {
        return this._queue[0];
    }
}
