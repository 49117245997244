import { FEATURE_SELECTED, FEATURE_SELECTED_OUTSIDE, DESELECT_FEATURE } from './MapActions';

const defaultState = { pending: true };

export default function (state = defaultState, action) {
  switch (action.type) {
    case FEATURE_SELECTED:
      state = Object.assign({}, state, { selectedFeatureOutside: undefined, selectedFeature: action.data });
      break;
    case FEATURE_SELECTED_OUTSIDE:
      state = Object.assign({}, state, { selectedFeatureOutside: action.data });
      break;
    case DESELECT_FEATURE:
      state = Object.assign({}, state, { selectedFeatureOutside: undefined, selectedFeature: undefined });
      break;
  }
  return state;
}
