export default {
  WARNINGS: 'warningsDataLosses',
  VEHICLE_WORK: 'operationHistory',
  PLAN: 'geoZoneOperationsReport',
  PLAN_BY_MONTH: 'geoZoneOperationsReportToMonth',
  WEIGHTING: 'unitWeighing2Report',
  HARVESTER_UPLOAD: 'harvesterUnloadingReport',
  TRACTOR_RECORD: 'unitOperationsReport',
  TRACK: 'simpleFuelTankReportByDay',
};
