import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import set from 'lodash/set';

export default class Node {
  constructor(data = 'no data') {
    this.data = data;
    this.children = [];
    this.exceptNode = false;
    this.parent = () => null;
  }

  get leaf() {
    return isEmpty(this.children);
  }

  get isNode() {
    return !this.leaf;
  }

  setPath() {
    if (this.isRoot) {
      this.path = this.name;
      return;
    }
    // Добавляется рандомная часть пути (для того, чтобы в дереве не перетирались элементы с одинаковыми названиями)
    this.path = this.parent() ? `${this.parent().path}->${this.getId()}`+ '@@' + Math.random() : this.getId()+ '@@' +  Math.random();
  }

  addNode(node, setMap = true) {
    this.children.push(node);
    node.parent = () => this;
    if (setMap) {
      this.tree().setMap();
      this.tree().updateTimestamp();
    }
  }

  addBunch(nodes, setMap = true) {
    this.children = [...this.children, ...nodes];
    for (const node of nodes) {
      node.parent = () => this;
    }
    if (setMap) {
      this.tree().setMap();
      this.tree().updateTimestamp();
    }
  }

  removeNode(node) {
    this.tree().removeNode(node);
    this.tree().updateTimestamp();
  }

  remove() {
    this.tree().removeNode(this);
    this.tree().updateTimestamp();
  }

  removeChildren() {
    this.children = [];
    this.tree().updateTimestamp();
  }

  parents() {
    return this.parent() ? [...this.parent().parents(), this.parent()] : [];
  }

  childrenRec() {
    return flatten([...this.children, ...this.children.map(x => x.childrenRec())].filter(x => !isEmpty(x)));
  }

  setAttr(key, value) {
    set(this, key, value);
    this.tree().updateTimestamp();
  }
}
