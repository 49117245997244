export function localStorageSpace(detailed = false) {
  let data = '';
  const detailedO = {
    used: 0,
    remained: 0,
  };

  for (var key in window.localStorage) {
    if (Object.prototype.hasOwnProperty.call(window.localStorage, key)) {
      data += window.localStorage[key];
      if (detailed) {
        detailedO[key] = Number(((window.localStorage[key].length * 16) / (8 * 1024)).toFixed(2));
      }
    }
  }

  const capacity = isIOS() ? 4000 : 8000;

  const used = ((data.length * 16) / (8 * 1024)).toFixed(2);
  const remained = (capacity - ((data.length * 16) / (8 * 1024)).toFixed(2));

  detailedO.used = Number(used);
  detailedO.remained = Number(remained);
  detailedO.capacity = capacity;

  return detailedO;
}

export function canFit(obj) {
  const {remained} = localStorageSpace();
  const objSize = Number(((JSON.stringify(obj).length * 16) / (8 * 1024)).toFixed(2));
  return objSize <= remained;
}

export function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}
