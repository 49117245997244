import {VEH_TYPE as vehicleType} from '../../../../../const/vehicleConstants';
import {iconType} from '../../../../../controls/icon/Icon';

const tabs = {
  DEPARTMENTS: 0,
  WORK: 1,
  DATE: 2,
  FIELD: 3,
  VEHICLE: 4,
  FIELD_TOOL: 5,
  TRANSPORT_ROUTE: 6,
  GOODS: 7,
  LIMIT_SPEED: 8,
};

const iconMap = {
  [vehicleType.AUTOMOBILE]: iconType.autoDT,
  [vehicleType.TRACTOR]: iconType.tractorTab,
  [vehicleType.TRUCK]: iconType.truckDT,
  [vehicleType.TANKER]: iconType.refuelerDT,
  [vehicleType.FUEL_STATION]: iconType.tractorTab,
  [vehicleType.GRAIN_CART]: iconType.tractorTab,
  [vehicleType.NONE]: iconType.tractorTab,
  [vehicleType.PLANE]: iconType.tractorTab,
  [vehicleType.WAREHOUSE]: iconType.tractorTab,
  [vehicleType.SNAIL]: iconType.tractorTab,
};

const departmentRequired = 'Для создания ежедневного задания необходимо выбрать департамент';

const defaultHideTabs = {
  [tabs.TRANSPORT_ROUTE]: true,
  [tabs.GOODS]: true,
};

const transportationHideTabs = {
  [tabs.FIELD_TOOL]: true,
};

const insideFarmHideTabs = {
  [tabs.TRANSPORT_ROUTE]: true,
  [tabs.GOODS]: true,
  [tabs.FIELD_TOOL]: true,
}

const getDurations = () => {
  let h = 0;
  let m = 15;

  const getMs = (h, m) => h * 60 * 60 * 1000 + m * 60 * 1000;
  const result = [];

  while(h !== 24) {
    if (m === 60) {
      m = 0;
      h++;
      if (h === 24) {
        break;
      }
    }
    const hh = String(h).length === 1 ? `0${h}` : String(h);
    const mm = String(m).length === 1 ? `0${m}` : String(m);
    const title = `${hh}:${mm}`;
    result.push({
      ms: getMs(h, m),
      title,
    });
    m += 15;
  }
  return result;
};

const durations = getDurations();

export {tabs as tabTypes, iconMap, departmentRequired, defaultHideTabs, transportationHideTabs, durations, insideFarmHideTabs};

export const groupConditions = {
  CULTURE: null,
  OPERATIONS: 'operations',
};
