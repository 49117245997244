import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import AuthService from "./AuthService";
import {url} from "../api";
import reportType from "../const/reportType";
import httpClient from "@infobis/api-module";
import DepartmentService from './DepartmentService';
import Tree from "../treejs/Tree";
import {actions} from '../components/Main/HarvesterUpload/HarvesterUploadActions';
import {parseTree} from "../helpers/parseTree";
import {expandTree} from "../helpers/expandTree";
import store from "../store/store";
import {localStorageItems, LocalstorageService} from "./LocalstorageService";
import {getInitialPeriod} from "../helpers/initialPeriod";
import {filterTreeKey} from "../helpers/treeFilterKey";
import {harvestUploadObjId} from "../helpers/harvesterUploadObjId";
import {Notification} from './../components/common/Notification';

class HarvesterUploadService {
  constructor() {
    this.reset();
    window.HarvesterUploadService = this;
  }

  reset() {
    this.data = Tree.empty();
    this.rawData = [];
    this.dates = {
      from: null,
      to: null,
    };
  }

  async fetch({from, to}) {
    const user = AuthService.getUser();
    const now = moment();
    const {companyId} = DepartmentService.getCurrentDepartmentForRequest();
    const datesBefore = {from: this.dates.from, to: this.dates.to};

    if (isEqual({from, to}, this.dates)) {
      const t = setTimeout(() => {
        store.dispatch(actions.fetchSuccess());
        clearTimeout(t);
      }, 0);
      return;
    }

    this.savePeriod({from, to});

    const request = {
      url: url.reportData,
      params: {
        method: 'POST',
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
      authorize: true,
      qs: {
        reportId: reportType.HARVESTER_UPLOAD,
      },
      body: JSON.stringify({
        activeChecks: "",
        companyId,
        entityId: [],
        forLeafs: null,
        filters: {
          fieldsFilter: [],
          unitsFilter: [],
          driversFilter: [],
        },
        from,
        to,
        isDay: false,
        limit: 25,
        page: 1,
        reportId: reportType.HARVESTER_UPLOAD,
        start: 0,
        strEntityId: [],
        tzOffset: -now.utcOffset(),
      }),
    };

    this.dates = {from, to};
    store.dispatch(actions.fetchPending());

    try {
      const response = await httpClient.makeRequest(request);
      if (response && response.success) {
        this.onFetchSuccess(response);
        store.dispatch(actions.fetchSuccess());
      } else {
        this.dates = datesBefore;
        store.dispatch(actions.fetchError());
      }
    } catch (error) {
      store.dispatch(actions.fetchError());
      Notification.error(error);
    }
  }

  onFetchSuccess(response) {
    this.rawData = response.data;
    this.rawData.forEach(x => this._mapUpload(x));
    this.buildTree(this.rawData);
  }

  buildTree(data) {
    const getGlid = n => n.data.id;
    let groupes = uniqBy(data.map(d =>
      Object.assign({}, {id: d.sourceId, name: d.sourceName})), 'id');
    groupes = orderBy(groupes, x => x.name.toLowerCase(), ['asc']);
    groupes.forEach(x => {
      x.data = orderBy(data.filter(d => d.sourceId === x.id), ['start'], ['desc']);
    });
    this.data = parseTree(groupes, getGlid);
    this.data.lastUpdate = Date.now();
    expandTree(this.data, 0);
  }

  getTree() {
    return this.data;
  }

  getItem(id) {
    return this.rawData.find(x => x.id === id) || {};
  }

  getGroupName(id) {
    return this.data && get(this.data.nodes().find(n => isObject(n.data) && Number(n.data.id) === Number(id)), 'data.name', '');
  }

  loadPeriod() {
    if (LocalstorageService.isCacheForSameUser(localStorageItems.harvesterUploadPeriod)) {
      return get(JSON.parse(localStorage.getItem(localStorageItems.harvesterUploadPeriod)), 'data');
    } else {
      localStorage.removeItem(localStorageItems.harvesterUploadPeriod);
      return getInitialPeriod();
    }
  }

  savePeriod({from, to}) {
    if (from && to) {
      const cache = LocalstorageService.getCacheForUser({from, to});
      localStorage.setItem(localStorageItems.harvesterUploadPeriod, JSON.stringify(cache));
    }
  }

  getFilteredTree({operation}) {
    return filterTreeKey({tree: this.data, query: {operation}});
  }

  _mapUpload(src) {
    src.id = harvestUploadObjId(src);
    src.group = get(/^(.*) - /ig.exec(src.sourceName), '[1]', '').trim();
    src.sourceVehicle = get(/- (.*)$/ig.exec(src.sourceName), '[1]', '').trim();
  }
}

export default new HarvesterUploadService();
