import {url} from '../api';
import BaseDataService, {responseDataShape} from "../baseClasses/BaseDataService";
import {actions} from "../components/Main/TransportRoutes/TransportRoutesActions";

class TransportRouteService extends BaseDataService {
  constructor() {
    super({
      urlAll: url.transportRoute,
      fetchPending: actions.fetchPending,
      fetchSuccess: actions.fetchSuccess,
      fetchError: actions.fetchError,
      dataShape: responseDataShape.ARRAY,
      qs: {
        page: 1,
        start: 0,
        limit: 100000,
      }
    });
    window.TransportRouteService = this;
  }
}

export default new TransportRouteService();
