import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import intersectionWith from "lodash/intersectionWith";
import {url} from '../api';
import BaseDataService from "../baseClasses/BaseDataService";
import store from '../store/store';
import httpClient from '@infobis/api-module';
import {actions} from "../components/Main/Department/DepartmentActions";
import MainService from "./MainService";
import AuthService from "./AuthService";
import {localStorageItems} from "./LocalstorageService";
import uniqBy from "lodash/uniqBy";
import {Notification} from './../components/common/Notification';

class DepartmentService extends BaseDataService {
  constructor() {
    super({urlAll: url.department, ...actions});
    window.DepartmentService = this;
    this._cacheOn = true;
    this.departmentsOptions = [];
  }

  async fetchOptions() {
    const {clientId: companyId} = this.getCurrentDepartment();
    const user = AuthService.getUser();
    const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "serverId": user.serverId,
      "Authorization": "Bearer " + user.accessToken,
      companyId,
    };

    const request = {
      url: url.departments,
      params: {
        method: 'GET',
      },
      authorize: true,
      headers,
    };


    try {
      const response = await httpClient.makeRequest(request);
      if (response) {
        this.departmentsOptions = uniqBy(response, x => x.id);
      }
      
    } catch (error) {
      Notification.error(error);
    }
  }

  async setInitialDepartment() {
    if (!this.data) {
      await this.fetch();
    }

    const cached = this._loadDepartmentForCurrentUser();
    if (!isEmpty(cached) && this.data.allNodes().find(n => n.data.glid === cached.glid)) {
      store.dispatch(actions.setDepartment(cached, true));
      return;
    }

    store.dispatch(actions.setDepartment(this.data.root.children[0].data, true));
  }

  getDepartments() {
    return this.getDepartmentsNodes().map(x => x.data);
  }

  getDepartmentsNodes() {
    if (!this.data) {
      return [];
    }

    return intersectionWith(
      this.data.allNodes().filter(x => x.data.clientId !== x.data.glid),
      this.departmentsOptions,
      (n, o) => Number(n.data.glid) === Number(o.id),
    );
  }

  reset() {
    this.data = null;
  }

  resetOptions() {
    this.departmentsOptions = [];
  }

  getCurrentDepartment() {
    return this._loadDepartmentForCurrentUser() || get(this.data, 'root.children[0].data', {});
  }

  _saveDepartmentForCurrentUser(department) {
    const {userInfo} = AuthService.getUser() || {};
    const depSave = JSON.parse(localStorage.getItem(localStorageItems.currentDepartment)) || {};

    depSave[userInfo.id] = department;
    localStorage.setItem(localStorageItems.currentDepartment, JSON.stringify(depSave));
  }

  _loadDepartmentForCurrentUser() {
    const {userInfo} = AuthService.getUser() || {};
    if (isEmpty(userInfo)) {
      return null;
    }
    const depSave = JSON.parse(localStorage.getItem(localStorageItems.currentDepartment)) || {};

    return depSave[userInfo.id];
  }

  dispatchDataChange() {
    this._fetchSuccess && store.dispatch(this._fetchSuccess());
  }

  getCurrentDepartmentForRequest() {
    const {clientId, glid} = this.getCurrentDepartment();
    const dep = {
      companyId: clientId,
      departmentId: glid,
    };
    const depParams = {companyId: dep.companyId || null};
    if (dep.departmentId !== dep.companyId && dep.departmentId) {
      depParams.departmentId = dep.departmentId;
    }
    return depParams;
  }

  async setDepartment({clientId, glid}) {
    const user = AuthService.getUser();
    const request = {
      url: url.setDepartment,
      params: {
        method: 'POST',
      },
      headers: {
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId
      },
      authorize: true,
      body: JSON.stringify({
        companyId: clientId,
        departmentId: glid,
      })
    };

    let response = { success: false };

    try {
      response = await httpClient.makeRequest(request);
      if (response && response.success) {
        this._saveDepartmentForCurrentUser({clientId, glid});
        await MainService.resetDepartment();
      }
    }

    catch (error) {
      Notification.error(error);
    }
    
    return response.success;
  }
}

export default new DepartmentService();
