export const FETCH_SUCCESS = 'FETCH_SUCCESS__OPERATIONS';
export const FETCH_PENDING = 'FETCH_PENDING__OPERATIONS';
export const FETCH_ERROR = 'FETCH_ERROR__OPERATIONS';

export function fetchSuccess() {
  return async (dispatch) => {
    dispatch({
      type: FETCH_SUCCESS,
    });
  };
}

export function fetchPending() {
  return async (dispatch) => {
    dispatch({
      type: FETCH_PENDING
    });
  };
}

export function fetchError() {
  return async (dispatch) => {
    dispatch({
      type: FETCH_ERROR
    });
  };
}
