import {setPeriod} from '../TrackActions';

export const types = {
  SET_PERIOD: 'SET_PERIOD__TRACK_FILTER',
};

export const actions = {
  setPeriod: function ({from, to}) {
    return dispatch => {
      dispatch(setPeriod({from, to}));
      dispatch({
        type: types.SET_PERIOD,
      });
    };
  },
};
