import get from 'lodash/get';
import {createActions} from "../../../baseClasses/ActionFactory";
import HarvesterUploadService from '../../../services/HarvesterUploadService';
import {createTreeActions} from "../../../baseClasses/TreeActionFactory";
import harvesterUpload from "./HarvesterUploadReducer";

const {types: generalTypes, actions: generalActions} = createActions({store: 'HARVESTER_UPLOAD', onFetch});
const {types: treeTypes, actions: treeActions} = createTreeActions({store: 'HARVESTER_UPLOAD'});

const SET_START_DATE = 'SET_START_DATE__HARVESTER_UPLOAD';
const SET_END_DATE = 'SET_START_DATE__HARVESTER_UPLOAD';
const SET_PERIOD = 'SET_PERIOD__HARVESTER_UPLOAD';
const FETCH_NO_PENDING = 'FETCH_NO_PENDING__HARVESTER_UPLOAD';

function fetchSaved() {
  return (dispatch) => {
    const period = HarvesterUploadService.loadPeriod();

    if (period) {
      dispatch(setPeriod(period));
      dispatch(fetchNoPending({...period}));
    } else {
      dispatch(generalActions.fetch());
    }
  };
}

function fetchNoPending({from, to}) {
  HarvesterUploadService.fetch({from, to});
  return (dispatch) => {
    dispatch({
      type: FETCH_NO_PENDING,
    });
  };
}

function onFetch(props, getState) {
  const {from, to} = get(getState(), 'main.harvesterUpload', {});
  HarvesterUploadService.fetch({from, to});
}

function setStartDate(from) {
  return (dispatch, getState) => {
    const {to} = getState().main.harvesterUpload;
    HarvesterUploadService.savePeriod({from, to});
    dispatch({
      type: SET_START_DATE,
      payload: {from}
    });
  };
}

function setEndDate(to) {
  return (dispatch, getState) => {
    const {from} = getState().main.harvesterUpload;
    HarvesterUploadService.savePeriod({from, to});
    dispatch({
      type: SET_END_DATE,
      payload: {to}
    });
  };
}

function setPeriod({from, to}) {
  return dispatch => {
    HarvesterUploadService.savePeriod({from, to});
    dispatch({
      type: SET_PERIOD,
      payload: {to, from}
    });
  };
}

const allTypes = {...generalTypes, ...treeTypes, SET_START_DATE, SET_END_DATE, SET_PERIOD, FETCH_NO_PENDING};
const allActions = {...generalActions, ...treeActions, setStartDate, setEndDate, setPeriod, fetchSaved};
export {allActions as actions, allTypes as types};
