import {Tree, Node} from '../treejs';

const parseTree = (data, getPathNodeName = getId) => {
  const tree = new Tree({getId: getPathNodeName});
  if (!data) {
    return tree;
  }
  data.map(o => buildTree([o], tree.root, tree));
  tree.setMap();
  return tree;
};

function buildTree(oArr, parent, tree) {
  const nArr = oArr
    // .filter(o => !o.removed)
    .map(o => new Node(o));
  parent.addBunch(nArr, false);

  for (const n of nArr) {
    if (n.data.data && n.data.data.length) {
      buildTree(n.data.data, n, tree);
    }
  }
}

function getId(node) {
  return node.data.glid;
}

export {parseTree};
