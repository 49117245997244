import {createActions} from '../../../../baseClasses/ActionFactory';
import InventoryItemsService from "../../../../services/InventoryItemsService";

function onFetch({work, fieldId, operationId, dateStart}) {
  InventoryItemsService.fetch({work, fieldId, operationId, dateStart});
  return {work, fieldId, operationId, dateStart};
}

const {types: generalTypes, actions: generalActions} = createActions({store: 'GOODS_ITEM', onFetch});
const allActions = {...generalActions};
const allTypes = {...generalTypes};

export {allTypes as types, allActions as actions};
