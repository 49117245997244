import getAnimation from 'react-toastify/src/animation';
import {css} from 'glamor';

const animate = {
  animationDuration: '0.75s',
  animationFillMode: 'both'
};

export const styles = pos => {
  const {enter} = getAnimation(pos);
  const enterAnimation = css.keyframes({
    'from, 60%, 75%, 90%, to': {
      animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    ...enter
  });

  const exit = {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0,
    }
  };

  const exitAnimation = css.keyframes(exit);

  return {
    enter: css({ ...animate, animationName: enterAnimation }),
    exit: css({ ...animate, animationName: exitAnimation })
  };
};
