import {types} from './CreateNoteActions';
import {types as noteTypes} from './../NotesActions';
import NoteService from "../../../../services/NoteService";

export const defaultState = {
  pending: false,
  note: NoteService.newNote(),
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.RESET_FORM:
      state = {...defaultState};
      break;
    case types.PENDING:
    case types.LOAD_NOTE:
    case types.SET_TEMPLATE_NAME:
    case types.CLEAR_ATTRIBUTES:
    case types.SET_TITLE:
    case types.SET_TEXT:
    case types.SET_GEOPOINT:
    case types.SET_ATTRIBUTE:
    case types.TOGGLE_ATTRIBUTE:
    case types.ADD_CLONE:
    case types.SET_DATE_START:
    case types.SET_DATE_END:
    case types.SET_CLIENT:
    case types.FILE_UPLOAD:
    case types.FILE_UPLOAD_DONE:
    case types.FILE_UPLOAD_FAILURE:
    case types.FILE_DELETE:
    case types.FILE_DELETE_DONE:
    case noteTypes.FILE_FETCH:
    case noteTypes.FILE_FETCH_PENDING:
    case noteTypes.FILE_FETCH_SUCCESS:
    case noteTypes.FILE_FETCH_ERROR:
    case noteTypes.LOAD_DISEASES:
    case noteTypes.LOAD_PESTS:
    case noteTypes.LOAD_WEEDS:
      state = {...state, ...action.payload};
      break;
  }
  return state;
}
