import {createActions} from "../../../../baseClasses/ActionFactory";
import {routes} from "../../../../routes";
import {changeGeozoneType, changeLayerType} from "../../Map/LayersSelect/LayersSelectActions";
import { featureSelectOutside } from "../../Map/MapActions";
import {geozoneTypes} from '../../Map/LayersSelect/layerTypes';
import {push} from "react-router-redux";

const LOAD_NDVI = 'LOAD_NDVI';

function gotoMap(props) {
  return dispatch => {
    dispatch(push(routes.map));
    dispatch(changeGeozoneType(geozoneTypes.all));
    dispatch(featureSelectOutside({
      ...props,
      type: "field",
    }));
  };
}

function setNdviLoading(isLoading) {
  return (dispatch) => {
    dispatch({
      type: LOAD_NDVI,
      payload: {isLoading: isLoading}
    });
  };
}

const {types, actions} = createActions({store: 'FIELD'});

Object.assign(actions, {gotoMap, changeLayerType, setNdviLoading});
Object.assign(types, {LOAD_NDVI});

export {types, actions};
