import {url} from '../api';
import AuthService from "./AuthService";
import fetchStatus from "../const/fetchStatus";
import httpClient from "@infobis/api-module";
import {Notification} from './../components/common/Notification';

class CompanyService {
  data = [];

  constructor() {
    this.reset();
    window.CompanyService = this;
  }

  async fetch() {
    if (!this.isPending()) {
      this.data = [];
    } else {
      return;
    }
    const user = AuthService.getUser();
    const request = {
      url: url.companies,
      params: {
        method: 'GET',
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.accessToken,
        "serverId": user.serverId,
      },
      authorize: true,
      qs: {
        full: false,
        page: 1,
        start: 0,
        limit: 25,
      },
    };

    this._status = fetchStatus.PENDING;
    try {
      const response = await httpClient.makeRequest(request);
      if (response && response.success && response.data) {
        this.data = response.data;
      }
    } catch (error) {
      Notification.error(error);
    }
    this._status = fetchStatus.DEFAULT;
  }

  getList() {
    return this.data;
  }

  reset() {
    this.data = [];
  }

  clear() {
    this.reset();
  }

  isPending() {
    return this._status === fetchStatus.PENDING;
  }

  hasMultipleOgranizations() {
    return this.data.length > 1;
  }
}

export default new CompanyService();
