const through = (props) => Object.assign({}, {data: props});

const createActions = ({store, onFetch = through, onPending = through, onSuccess = through, onError = through}) => {
  const types = {
    FETCH: `FETCH__${store}`,
    FETCH_PENDING: `FETCH_PENDING__${store}`,
    FETCH_SUCCESS: `FETCH_SUCCESS__${store}`,
    FETCH_ERROR: `FETCH_ERROR__${store}`,
  };
  const actions = {
    fetch: (props) => {
      return (dispatch, getState) => {
        const payload = {pending: true, ...onFetch(props, getState)};
        dispatch({
          type: types.FETCH,
          payload
        });
      };
    },
    fetchPending: (props) => {
      return (dispatch, getState) => {
        const payload = {pending: true, ...onPending(props, getState)};
        dispatch({
          type: types.FETCH_PENDING,
          payload
        });
      };
    },
    fetchSuccess: (props) => {
      return (dispatch, getState) => {
        const payload = {pending: false, ...onSuccess(props, getState)};
        dispatch({
          type: types.FETCH_SUCCESS,
          payload
        });
      };
    },
    fetchError: (props) => {
      return (dispatch, getState) => {
        const payload = {pending: false, ...onError(props, getState)};
        dispatch({
          type: types.FETCH_ERROR,
          payload
        });
      };
    },
  };

  return {types, actions};
};

export {createActions};
