import {types, FIELD_WORK_LIST_SHAPE_LOADED, FIELD_WORK_LIST_SHAPE_CLEAR} from './FieldWorkActions';

const defaultState = {
  selectedOperationId: 1,
  pending: true,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.FETCH:
    case types.FETCH_PENDING:
    case types.FETCH_SUCCESS:
    case types.FETCH_ERROR:
    case FIELD_WORK_LIST_SHAPE_LOADED:
      state = Object.assign({}, state, {...action.payload});
      break;
    case FIELD_WORK_LIST_SHAPE_CLEAR:
      state = Object.assign({}, state, {...action.payload});
      break;
  }
  return state;
}
