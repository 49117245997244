import get from 'lodash/get';
import store from '../store/store';
import {onHideStatus, onHideType} from '../components/Main/Vehicles/VehicleFilter/VehicleFilterActions';

const updateStoreFilter = (obj, oldValue, newValue, key) => {
  const cb = key === 'type' ? onHideType : onHideStatus;
  if (obj[key][oldValue] === 0) {
    store.dispatch(cb(oldValue, true));
  }
  if (obj[key][newValue] === 1) {
    store.dispatch(cb(newValue, false));
  }
};

export const updateFilterInfo = (oldValue, newValue, key, obj) => {
  const isInit = !!obj[key][newValue];
  if (!isInit) {
    obj[key][newValue] = 0;
  }
  if (!oldValue) {
    obj[key][newValue] += 1;
  } else if (oldValue !== newValue) {
    obj[key][oldValue] -= 1;
    obj[key][newValue] += 1;
  }
  updateStoreFilter(obj, oldValue, newValue, key);
};

export const initFilterInfo = (data, key, obj) => {
  Object.values(data.map)
    .filter(el => el.leaf && get(el, `data.${key}`))
    .map(el => {
      const value = get(el, `data.${key}`);
      obj[key][value] = obj[key][value] || 0;
      obj[key][value] += 1;
      updateStoreFilter(obj, '', value, key);
    });
};
