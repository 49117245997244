import {actions as wActions} from '../HarvesterUploadActions';

export const types = {
  SET_PERIOD: 'SET_PERIOD__HARVESTER_UPLOAD_FILTER',
};

export const actions = {
  setPeriod: function ({from, to}) {
    return dispatch => {
      dispatch(wActions.setPeriod({from, to}));
      dispatch({
        type: types.SET_PERIOD,
      });
    };
  },
};
