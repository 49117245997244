import React, {Component} from 'react';
import {toast} from 'react-toastify';
import cn from 'classnames';
import Fade from './Fade';
import st from './notification.scss';

const showTime = 2000;
const {TYPE} = toast;
const getOptions = type => ({
  className: cn(st.notification, {
    [st.notificationSuccess]: type === TYPE.SUCCESS,
    [st.notificationError]: type === TYPE.ERROR,
  }),
  transition: Fade,
  autoClose: showTime,
});

class Notification extends Component {
  constructor(props) {
    super(props);
    window.Notification = this;
    this.errorStack = [];
  }

  checkDuplicate(id) {
    return toast.isActive(id);
  }

  info(msg, options, title = 'Информация') {
    const opts = {...getOptions(TYPE.INFO), ...options};
    return toast.info((
      <div>
        {title && <div className={cn(st.title, st.info)}>{title}</div>}
        <div>{msg}</div>
      </div>
    ), opts);
  }

  success(msg, options, title = 'Успешно') {
    const opts = {...getOptions(TYPE.SUCCESS), ...options};
    return toast.success((
      <div>
        {title && <div className={cn(st.title, st.success)}>{title}</div>}
        <div>{msg}</div>
      </div>
    ), opts);
  }

  error(msg, options, title = 'Ошибка') {
    const opts = {...getOptions(TYPE.ERROR), ...options};

    if (this.errorStack.indexOf(msg) !== -1) {
      return;
    }

    this.errorStack.push(msg);

    const id = toast.error((
        <div>
          {title && <div className={cn(st.title, st.error)}>{title}</div>}
          <div>{msg}</div>
        </div>
      ),
      {
        ...opts,
        onClose: () => {
          this.errorStack = this.errorStack.filter(x => x !== msg);
        }
      });
    return id;
  }
}

export default new Notification();
