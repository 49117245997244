import {createActions} from "../../../../baseClasses/ActionFactory";
import WorkShiftService from "../../../../services/WorkShiftService";

function onFetch({fieldId, operationId, sourceId, dateStart, dateShiftStart}) {
  WorkShiftService.fetch({fieldId, operationId, sourceId, dateStart, dateShiftStart});
  return {operationId, sourceId, dateStart, dateShiftStart};
}

const {types, actions} = createActions({store: 'WORK_SHIFT', onFetch});
export {types, actions};
