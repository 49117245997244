export const FEATURE_SELECTED = 'FEATURE_SELECTED';
export const FEATURE_SELECTED_OUTSIDE = 'FEATURE_SELECTED_OUTSIDE';
export const DESELECT_FEATURE = 'DESELECT_FEATURE';


export function deselectFeature() {
  return (dispatch) => {
    dispatch({
      type: DESELECT_FEATURE,
    })
  };
}

export function featureSelect(feature) {
  return (dispatch) => {
    //mapService.saveSelectedFeature(feature);
    dispatch({
      type: FEATURE_SELECTED,
      data: feature,
    });
  };
}

export function featureSelectOutside(feature) {
  return (dispatch) => {
    dispatch({
      type: FEATURE_SELECTED_OUTSIDE,
      data: feature,
    });
  };
}
