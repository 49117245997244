import {
  MAP_LOADED,
  SET_VIEW_PORT,
} from './MapCanvasActions';

const defaultState = {
  viewport:  { center: [51.33, 46], zoom: 9 },
  selectedPoint: null,
  isFeature: null,
  selectedFeatureOutside: null,
  selectedFeature: null
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case MAP_LOADED:
      state = Object.assign({}, state);
      break;
    case SET_VIEW_PORT:
      state = Object.assign({}, state, { viewport: action.viewport });
      break
  }

  return state;
}
