export const CHANGE_LAYER_TYPE = 'CHANGE_LAYER_TYPE';
export const CHANGE_GEOZONE_TYPE = 'CHANGE_GEOZONE_TYPE';
export const CHANGE_METEOSTATIONS_VISIBILITY = 'CHANGE_METEOSTATIONS_VISIBILITY';

export function changeLayerType(type) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_LAYER_TYPE,
      layerType: type
    });
  };
}
export function changeGeozoneType(type) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_GEOZONE_TYPE,
      geozoneType: type
    });
  };
}
export function toggleShowMeteostations(value) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_METEOSTATIONS_VISIBILITY,
      showMeteostations: value
    });
  };
}
