import moment from 'moment';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import first from 'lodash/first';
import slice from 'lodash/slice';
import isEmpty from 'lodash/isEmpty';
import EmployeeService from "../../../../services/EmployeeService";
import ShiftService from "../../../../services/ShiftService";
import {operatorShiftMode} from "../../../../const/operatorsShiftMode";
import {formatToShift} from "../../../../helpers/timeHelpers";
import {getInitialPeriod} from "../../../../helpers/initialPeriod";

const FETCH_SHIFTLIST_ENTITIES_PENDING = 'FETCH_SHIFTLIST_ENTITIES__SHIFT';
const SELECT_DEPARTMENT = 'SELECT_DEPARTMENT__SHIFT';
const SELECT_INITIAL_DEPARTMENT = 'SELECT_INITIAL_DEPARTMENT__SHIFT';
const SET_DURATION = 'SET_DURATION__SHIFT';
const SET_PERIOD = 'SET_PERIOD__SHIFT';
const SET_WORKER_STR = 'SET_WORKER_STR__SHIFT';
const TOGGLE_WORKER_CHECKALL = 'TOGGLE_WORKER_CHECKALL__SHIFT';
const SET_ASSIGNEE = 'SET_ASSIGNEE__SHIFT';
const SET_DEFAULT_SHIFT_TYPE_FOR_WORKER = 'SET_DEFAULT_SHIFT_TYPE_FOR_WORKER__SHIFT';
const SET_SHIFT_FOR_WORKER = 'SET_SHIFT_FOR_WORKER__SHIFT';
const SET_SHIFT_FROM_FOR_WORKER = 'SET_SHIFT_FROM_FOR_WORKER__SHIFT';
const SET_SHIFT_TO_FOR_WORKER = 'SET_SHIFT_TO_FOR_WORKER__SHIFT';
const SET_DEFAULT_VEHICLE_FOR_WORKER = 'SET_DEFAULT_VEHICLE_FOR_WORKER__SHIFT';
const SET_SELECTED_DRIVER = 'SET_SELECTED_DRIVER__SHIFT';
const SET_SELECTED_DAY = 'SET_SELECTED_DAY__SHIFT';
const TOGGLE_WEEKEND = 'TOGGLE_WEEKEND__SHIFT';
const SET_DAYS_FOR_OPERATORS = 'SET_DAYS_FOR_OPERATORS__SHIFT';
const SET_WEEKEND = 'SET_WEEKEND__SHIFT';
const SET_VEHICLE_FOR_SHIFT = 'SET_VEHICLE_FOR_SHIFT__SHIFT';
const SET_DAY_SHIFT_MODE = 'SET_DAY_SHIFT_MODE__SHIFT';
const SET_DAY_SHIFT_DEFAULT = 'SET_DAY_SHIFT_DEFAULT__SHIFT';
const SET_SHIFT_FROM_FOR_DAY = 'SET_SHIFT_FROM_FOR_DAY__SHIFT';
const SET_SHIFT_TO_FOR_DAY = 'SET_SHIFT_TO_FOR_DAY__SHIFT';
const SAVE_SHIFT = 'SAVE_SHIFT__SHIFT';
const RESET_FORM = 'RESET_FORM__SHIFT';
const EDIT_SHIFT = 'EDIT__SHIFT';
const DUPLICATE_SHIFT = 'DUPLICATE__SHIFT';
const SAVING = 'SAVING__SHIFT';

function mergeTime(str) {
  if (isEmpty(str) || str.length === 5) {
    return str;
  }
  const dtPos = str.indexOf(':');
  if (str.substring(0, dtPos).length < 3) {
    str = `0${str}`;
  }
  if (str.substring(dtPos, str.length - 1).length < 3) {
    str = `${str}0`;
  }
  return str;
}

function editShift(id) {
  return (dispatch) => {
    const shift = ShiftService.getItem(id);
    const operatorsShiftMode = {};
    const operatorDayShiftMode = {};

    dispatch(resetForm());

    shift.operators.forEach(o => {
      operatorsShiftMode[o.driverId] = o.defaultShiftId ? operatorShiftMode.choose : operatorShiftMode.manual;

      o.manualShiftDuration = mergeTime(o.manualShiftDuration);

      o.works.forEach((w, i) => {
        w.manualShiftDuration = mergeTime(w.manualShiftDuration);

        operatorDayShiftMode[`${o.driverId}_${i + 1}`] = w.manualShiftDuration ?
          operatorShiftMode.manual :
          operatorShiftMode.choose;
      });
    });

    // shift.from = getInitialPeriod(0).from;


    dispatch({
      type: EDIT_SHIFT,
      payload: {shift, operatorsShiftMode, operatorDayShiftMode},
    });

    shift.operators.forEach(o => {
      const manualShiftFrom = mergeTime(o.manualShiftFrom);
      dispatch(setShiftFromForWorker(o.driverId, o.defaultShiftId || manualShiftFrom));

      o.works.forEach((w, i) => {
        const manualShiftFrom = mergeTime(w.manualShiftFrom);
        dispatch(setShiftFromForDay(o.driverId, i, manualShiftFrom));
      });
    });
  };
}

function duplicateShift(id) {
  return (dispatch) => {
    const shift = ShiftService.getItem(id);
    const operatorsShiftMode = {};
    const operatorDayShiftMode = {};

    dispatch(resetForm());

    shift.operators.forEach(o => {
      operatorsShiftMode[o.driverId] = o.defaultShiftId ? operatorShiftMode.choose : operatorShiftMode.manual;

      o.manualShiftDuration = mergeTime(o.manualShiftDuration);

      o.works.forEach((w, i) => {
        w.manualShiftDuration = mergeTime(w.manualShiftDuration);

        operatorDayShiftMode[`${o.driverId}_${i + 1}`] = w.manualShiftDuration ?
          operatorShiftMode.manual :
          operatorShiftMode.choose;
      });
    });

    shift.from = getInitialPeriod(0).from;

    dispatch({
      type: DUPLICATE_SHIFT,
      payload: {
        shift: {...shift, id: null},
        isDuplicate: true,
        operatorsShiftMode,
        operatorDayShiftMode
      },
    });

    shift.operators.forEach(o => {
      const manualShiftFrom = mergeTime(o.manualShiftFrom);
      dispatch(setShiftFromForWorker(o.driverId, manualShiftFrom));

      o.works.forEach((w, i) => {
        const manualShiftFrom = mergeTime(w.manualShiftFrom);
        dispatch(setShiftFromForDay(o.driverId, i, manualShiftFrom));
      });
    });
  };
}

function fetchShiftSubentitiesList(pending) {
  return (dispatch, getState) => {
    const {main: {shiftCreate: {relatedEntityListPending}}} = getState();

    if (!relatedEntityListPending && pending) {
      ShiftService.fetchDictionaries();
    }
    return dispatch({
      type: FETCH_SHIFTLIST_ENTITIES_PENDING,
      payload: {relatedEntityListPending: pending}
    });
  };
}

function selectDepartment(departmentId) {
  return (dispatch, getState) => {
    const {shiftCreate: {shift: shiftPrev}} = getState().main;
    const shift = {...shiftPrev, departmentId};

    dispatch({
      type: SELECT_DEPARTMENT,
      payload: {shift},
    });
  };
}

function selectInitialDepartment(departmentId) {
  return (dispatch, getState) => {
    const {shiftCreate: {shift: shiftPrev}} = getState().main;

    if (shiftPrev.id) {
      return;
    }

    const shift = {...shiftPrev, departmentId};

    dispatch({
      type: SELECT_INITIAL_DEPARTMENT,
      payload: {shift},
    });
  };
}

function setDuration(days) {
  return (dispatch, getState) => {
    const {main: {shiftCreate: shiftCreatePrev}} = getState();
    const daysNew = isNaN(parseInt(days)) ? '' : parseInt(days);

    dispatch({
      type: SET_DURATION,
      payload: {
        ...shiftCreatePrev,
        shift: {...shiftCreatePrev.shift, days: daysNew},
      },
    });
    dispatch(fillDaysForOperator(days));
    dispatch(selectDay(moment(shiftCreatePrev.shift.from).format('DD.MM.YYYY')));
  };
}

function setPeriod(date, isEnd) {
  return (dispatch, getState) => {
    const {main: {shiftCreate: shiftCreatePrev}} = getState();

    if (isEnd) {
      const days = moment(date).diff(moment(shiftCreatePrev.shift.from), 'days') + 1;
      dispatch(setDuration(days));
    } else {
      const to = moment(shiftCreatePrev.shift.from).add(shiftCreatePrev.shift.days, 'days');
      const days = to.diff(moment(date), 'days');
      dispatch({
        type: SET_PERIOD,
        payload: {
          ...shiftCreatePrev,
          shift: {...shiftCreatePrev.shift, from: moment(date)}
        }
      });
      if (moment(date).valueOf() < moment(shiftCreatePrev.selectedDay).valueOf()) {
        dispatch(selectDay(moment(date).format('DD.MM.YYYY')));
      }
      dispatch(setDuration(days));
    }
  };
}

function setWorkerShiftStr(employeeFilterStr) {
  return dispatch => {
    dispatch({
      type: SET_WORKER_STR,
      payload: {employeeFilterStr},
    });
  };
}

function employeeShowCheckedToggle() {
  return (dispatch, getState) => {
    const {main: {shiftCreate: {employeeShowChecked}}} = getState();

    dispatch({
      type: TOGGLE_WORKER_CHECKALL,
      payload: {employeeShowChecked: !employeeShowChecked},
    });
  };
}

function setAssignee(assigneeGlids) {
  return (dispatch, getState) => {
    const {main: {shiftCreate}} = getState();
    const {shift, selectedDriver, operatorsShiftMode: operatorsShiftModePrev} = shiftCreate;

    const operatorsShiftMode = {};
    const operatorsPrev = [...shift.operators];
    const operatorsNew = [];

    for (let id of assigneeGlids) {
      const prev = operatorsPrev.find(x => Number(x.driverId) === Number(id));
      if (prev) {
        operatorsNew.push(prev);
        operatorsShiftMode[prev.driverId] = operatorsShiftModePrev[id];
      } else {
        const workerSelected = EmployeeService.getItem(id);
        operatorsNew.push(ShiftService.newOperator({driverId: workerSelected.glid, name: workerSelected.name}));
        operatorsShiftMode[workerSelected.glid] = operatorShiftMode.choose;
      }
    }

    const selectedDriverNew = selectedDriver
      ? assigneeGlids.find(x => Number(selectedDriver.driverId) === Number(x)) ? selectedDriver : null
      : selectedDriver;

    dispatch({
      type: SET_ASSIGNEE,
      payload: {
        ...shiftCreate,
        operatorsShiftMode,
        selectedDriver: selectedDriverNew,
        shift: {...shift, operators: operatorsNew}
      },
    });
    dispatch(fillDaysForOperator(shift.days));
  };
}

function setDefaultShiftTypeForWorker(driverId, defaultShift) {
  return (dispatch, getState) => {
    const {main: {shiftCreate: {operatorsShiftMode: operatorsShiftModePrev}}} = getState();

    dispatch({
      type: SET_DEFAULT_SHIFT_TYPE_FOR_WORKER,
      payload: {operatorsShiftMode: {...operatorsShiftModePrev, ...{[driverId]: defaultShift}}},
    });
  };
}

function setShiftForWorker(driverId, defaultShift) {
  return (dispatch, getState) => {
    const {main: {shiftCreate: {operatorsShiftMode, shift: shiftPrev}}} = getState();
    const {operators: operatorsPrev} = shiftPrev;
    const isManual = operatorsShiftMode[driverId] === operatorShiftMode.manual;
    const oIndex = findIndex(operatorsPrev, o => o.driverId === driverId);
    const operator = {...operatorsPrev[oIndex]};

    if (isManual) {
      operator.manualShiftDuration = defaultShift;
      operator.defaultShiftId = null;
    } else {
      operator.defaultShiftId = defaultShift;
      operator.manualShiftDuration = null;
    }

    const operators = [...operatorsPrev];
    operators.splice(oIndex, 1, operator);

    dispatch({
      type: SET_SHIFT_FOR_WORKER,
      payload: {shift: {...shiftPrev, operators}},
    });

    if (isManual) {
      const end = moment.duration(moment.duration(operator.manualShiftFrom || '00:00').valueOf() +
        moment.duration(defaultShift).valueOf()).asMilliseconds();

      dispatch(setShiftToForWorker(operator.driverId, formatToShift(end)));
    }
  };
}

function setShiftFromForWorker(driverId, value) {
  return (dispatch, getState) => {
    const {main: {shiftCreate: {operatorsShiftMode, shift: shiftPrev}}} = getState();
    const {operators: operatorsPrev} = shiftPrev;
    const isManual = operatorsShiftMode[driverId] === operatorShiftMode.manual;
    const oIndex = findIndex(operatorsPrev, o => o.driverId === driverId);
    const operator = {...operatorsPrev[oIndex]};
    if (isManual) {
      operator.manualShiftFrom = value;
    }

    const operators = [...operatorsPrev];
    operators.splice(oIndex, 1, operator);

    dispatch({
      type: SET_SHIFT_FROM_FOR_WORKER,
      payload: {shift: {...shiftPrev, operators}},
    });

    if (isManual) {
      const end = moment.duration(moment.duration(value).valueOf() +
        moment.duration(operator.manualShiftDuration || '00:00').valueOf()).asMilliseconds();

      dispatch(setShiftToForWorker(operator.driverId, formatToShift(end)));
    }
  };
}

function setShiftToForWorker(driverId, value) {
  return (dispatch, getState) => {
    const {main: {shiftCreate: {operatorsShiftMode, shift: shiftPrev}}} = getState();
    const {operators: operatorsPrev} = shiftPrev;
    const isManual = operatorsShiftMode[driverId] === operatorShiftMode.manual;
    const oIndex = findIndex(operatorsPrev, o => o.driverId === driverId);
    const operator = {...operatorsPrev[oIndex]};

    if (isManual) {
      if (operator.manualShiftTo === value) {
        return;
      }
      operator.manualShiftTo = value;
    }

    const operators = [...operatorsPrev];
    operators.splice(oIndex, 1, operator);

    dispatch({
      type: SET_SHIFT_FROM_FOR_WORKER,
      payload: {shift: {...shiftPrev, operators}},
    });

    let end = moment.duration(moment.duration(value).valueOf() -
      moment.duration(operator.manualShiftFrom || '00:00').valueOf()).asMilliseconds();

    if (end.valueOf() <= 0) {
      end += 24 * 3600 * 1000;
    }

    dispatch(setShiftForWorker(operator.driverId, formatToShift(end)));
  };
}

function setDefaultVehicleForWorker(driverId, defaultVehicle) {
  return (dispatch, getState) => {
    const {main: {shiftCreate: {shift: shiftPrev}}} = getState();
    const {operators: operatorsPrev} = shiftPrev;
    const oIndex = findIndex(operatorsPrev, o => o.driverId === driverId);
    const operator = {...operatorsPrev[oIndex]};

    operator.defaultUnitId = defaultVehicle;

    const operators = [...operatorsPrev];
    operators.splice(oIndex, 1, operator);

    dispatch({
      type: SET_DEFAULT_VEHICLE_FOR_WORKER,
      payload: {shift: {...shiftPrev, operators}},
    });
  };
}

function selectDay(selectedDay) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_DAY,
      payload: {selectedDay},
    });
  };
}

function selectDriver(driverId) {
  return (dispatch, getState) => {
    const {main: {shiftCreate: {shift: {operators}}}} = getState();
    const selectedDriver = find(operators, x => x.driverId === driverId);

    dispatch({
      type: SET_SELECTED_DRIVER,
      payload: {selectedDriver},
    });
  };
}

function toggleWeekend(driverId, dayIndex, weekend) {
  return (dispatch, getState) => {
    const {main: {shiftCreate: {shift}}} = getState();
    const {operators: operatorsPrev} = shift;
    const operators = operatorsPrev.map(o => Object.assign({}, {...o, works: [...o.works],}));
    const operator = find(operators, o => o.driverId === driverId);
    const works = [...operator.works];
    works[dayIndex] = {
      ...works[dayIndex],
      noWorkReason: weekend ? 1 : null,
      noWorkReasonName: weekend ? 'Выходной' : null,
    };

    operator.works = works;

    dispatch({
      type: TOGGLE_WEEKEND,
      payload: {shift: {...shift, operators}},
    });
  };
}

function setWeekend(weekend) {
  return (dispatch) => {
    const weekendDay = weekend || first(ShiftService.getShiftOptions());

    dispatch({
      type: SET_WEEKEND,
      payload: {weekendDay},
    });
  };
}

function setVehicleForShift(driverId, dayIndex, vehicle) {
  return (dispatch, getState) => {
    const {main: {shiftCreate: {shift}}} = getState();
    const {operators: operatorsPrev} = shift;
    const operators = operatorsPrev.map(o => Object.assign({}, {...o, works: [...o.works],}));
    const operator = find(operators, o => o.driverId === driverId);
    const works = [...operator.works];
    works[dayIndex] = {
      ...works[dayIndex],
      unitId: vehicle ? vehicle.id : null,
      unitName: vehicle ? vehicle.title : null,
    };

    operator.works = works;

    dispatch({
      type: SET_VEHICLE_FOR_SHIFT,
      payload: {shift: {...shift, operators}},
    });
  };
}

function fillDaysForOperator(daysCount) {
  return (dispatch, getState) => {
    const {main: {shiftCreate: {shift, operatorDayShiftMode: operatorDayShiftModePrev}}} = getState();
    const operatorDayShiftMode = {...operatorDayShiftModePrev};
    const {operators: operatorsPrev} = shift;
    const operators = operatorsPrev.map(o => Object.assign({}, {
      ...o,
      works: slice([...o.works], 0, daysCount),
    }));

    operators.forEach(o => {
      for (let i = 0; i < daysCount; i++) {
        if (!o.works[i]) {
          o.works[i] = ShiftService.newShiftDay();
          o.works[i].day = i + 1;
          operatorDayShiftMode[`${o.driverId}_${o.works[i].day}`] = operatorShiftMode.choose;
        }
      }
    });

    dispatch({
      type: SET_DAYS_FOR_OPERATORS,
      payload: {shift: {...shift, operators}, operatorDayShiftMode},
    });
  };
}

function setDayShiftMode(driverId, day, value) {
  return (dispatch, getState) => {
    const {shiftCreate: {operatorDayShiftMode: operatorDayShiftModePrev}} = getState().main;
    const operatorDayShiftMode = {...operatorDayShiftModePrev, [`${driverId}_${day}`]: value};

    dispatch({
      type: SET_DAY_SHIFT_MODE,
      payload: {operatorDayShiftMode},
    });
  };
}

function setDayShiftDefault(driverId, dayIndex, shift) {
  return (dispatch, getState) => {
    const {main: {shiftCreate: {shift: shiftPrev, operatorDayShiftMode}}} = getState();
    const {operators: operatorsPrev} = shiftPrev;
    const isManual = operatorDayShiftMode[`${driverId}_${dayIndex + 1}`] === operatorShiftMode.manual;
    const oIndex = findIndex(operatorsPrev, o => o.driverId === driverId);
    const operator = {...operatorsPrev[oIndex]};
    const works = [...operator.works];

    if (isManual) {
      works[dayIndex] = {
        ...works[dayIndex],
        manualShiftDuration: shift,
        shiftId: null,
      };
    } else {
      works[dayIndex] = {
        ...works[dayIndex],
        shiftId: shift,
        manualShiftDuration: null,
      };
    }

    const operators = [...operatorsPrev];
    operators.splice(oIndex, 1, operator);
    operator.works = works;

    dispatch({
      type: SET_DAY_SHIFT_DEFAULT,
      payload: {shift: {...shiftPrev, operators}},
    });

    if (isManual) {
      const end = moment.duration(moment.duration(works[dayIndex].manualShiftFrom || '00:00').valueOf() +
        moment.duration(shift).valueOf()).asMilliseconds();

      dispatch(setShiftToForDay(driverId, dayIndex, formatToShift(end)));
    }
  };
}

function setShiftFromForDay(driverId, dayIndex, shift) {
  return (dispatch, getState) => {
    const {main: {shiftCreate: {shift: shiftPrev, operatorDayShiftMode}}} = getState();
    const {operators: operatorsPrev} = shiftPrev;
    const isManual = operatorDayShiftMode[`${driverId}_${dayIndex + 1}`] === operatorShiftMode.manual;
    const oIndex = findIndex(operatorsPrev, o => o.driverId === driverId);
    const operator = {...operatorsPrev[oIndex]};
    const works = [...operator.works];

    if (isManual) {
      works[dayIndex] = {
        ...works[dayIndex],
        manualShiftFrom: shift,
      };
    }

    const operators = [...operatorsPrev];
    operators.splice(oIndex, 1, operator);
    operator.works = works;

    dispatch({
      type: SET_SHIFT_FROM_FOR_DAY,
      payload: {shift: {...shiftPrev, operators}},
    });

    const end = moment.duration(moment.duration(shift).valueOf() +
      moment.duration(works[dayIndex].manualShiftDuration || '00:00').valueOf()).asMilliseconds();

    dispatch(setShiftToForDay(driverId, dayIndex, formatToShift(end)));
  };
}

function setShiftToForDay(driverId, dayIndex, shift) {
  return (dispatch, getState) => {
    const {main: {shiftCreate: {shift: shiftPrev, operatorDayShiftMode}}} = getState();
    const {operators: operatorsPrev} = shiftPrev;
    const isManual = operatorDayShiftMode[`${driverId}_${dayIndex + 1}`] === operatorShiftMode.manual;
    const oIndex = findIndex(operatorsPrev, o => o.driverId === driverId);
    const operator = {...operatorsPrev[oIndex]};
    const works = [...operator.works];

    if (isManual) {
      if (works[dayIndex].manualShiftTo === shift) {
        return;
      }
      works[dayIndex] = {
        ...works[dayIndex],
        manualShiftTo: shift,
      };
    }

    const operators = [...operatorsPrev];
    operators.splice(oIndex, 1, operator);
    operator.works = works;

    dispatch({
      type: SET_SHIFT_TO_FOR_DAY,
      payload: {shift: {...shiftPrev, operators}},
    });

    if (isManual) {
      let end = moment.duration(moment.duration(shift).valueOf() -
        moment.duration(works[dayIndex].manualShiftFrom || '00:00').valueOf()).asMilliseconds();

      if (end.valueOf() <= 0) {
        end += 24 * 3600 * 1000;
      }

      dispatch(setDayShiftDefault(driverId, dayIndex, formatToShift(end)));
    }
  };
}

function saveShift() {
  return (dispatch, getState) => {
    const {main: {shiftCreate: {shift}}} = getState();
    dispatch(resetForm());
    return ShiftService.saveShift(shift);
  };
}

function resetForm() {
  return dispatch => {
    dispatch({type: RESET_FORM});
  };
}

function saving(done = false) {
  return dispatch => {
    dispatch({
      type: SAVING,
      payload: {isSaving: done},
    });
  };
}

const allTypes = {
  SELECT_DEPARTMENT,
  SELECT_INITIAL_DEPARTMENT,
  SET_DURATION,
  SET_PERIOD,
  SET_WORKER_STR,
  TOGGLE_WORKER_CHECKALL,
  SET_ASSIGNEE,
  SET_DEFAULT_SHIFT_TYPE_FOR_WORKER,
  SET_SHIFT_FOR_WORKER,
  SET_SHIFT_FROM_FOR_WORKER,
  SET_SHIFT_TO_FOR_WORKER,
  FETCH_SHIFTLIST_ENTITIES_PENDING,
  SET_DEFAULT_VEHICLE_FOR_WORKER,
  SET_SELECTED_DRIVER,
  SET_SELECTED_DAY,
  TOGGLE_WEEKEND,
  SET_DAYS_FOR_OPERATORS,
  SET_WEEKEND,
  SET_VEHICLE_FOR_SHIFT,
  SET_DAY_SHIFT_MODE,
  SET_DAY_SHIFT_DEFAULT,
  SET_SHIFT_FROM_FOR_DAY,
  SET_SHIFT_TO_FOR_DAY,
  SAVE_SHIFT,
  RESET_FORM,
  EDIT_SHIFT,
  DUPLICATE_SHIFT,
  SAVING,
};
const allActions = {
  selectDepartment,
  selectInitialDepartment,
  fetchShiftSubentitiesList,
  setDuration,
  setPeriod,
  setWorkerShiftStr,
  employeeShowCheckedToggle,
  setAssignee,
  setDefaultShiftTypeForWorker,
  setShiftForWorker,
  setShiftFromForWorker,
  setShiftToForWorker,
  setDefaultVehicleForWorker,
  selectDriver,
  selectDay,
  toggleWeekend,
  fillDaysForOperator,
  setWeekend,
  setVehicleForShift,
  setDayShiftMode,
  setDayShiftDefault,
  setShiftFromForDay,
  setShiftToForDay,
  saveShift,
  resetForm,
  editShift,
  duplicateShift,
  saving,
};
export {allActions as actions, allTypes as types};
