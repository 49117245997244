import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';
import {styles} from "./helpers";

const Fade = ({children, position, ...props}) => {
  const _styles = styles(position);
  const enter = _styles.enter;
  const exit = _styles.exit;

  const onEnter = node => node.classList.add(enter);
  const onEntered = node => node.classList.remove(enter);
  const onExit = node => node.classList.add(exit);

  return (
    <Transition
      {...props}
      timeout={750}
      onEnter={onEnter}
      onEntered={onEntered}
      onExit={onExit}
    >
      {children}
    </Transition>
  );
};

Fade.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.string.isRequired,
};

export default Fade;
