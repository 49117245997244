import immutable from 'immutable';
import {entityType} from "../../../const/index";

import {
  SELECT_TAB,
  FETCH_SUCCESS,
  FILTER_DATA
} from './SearchActions';

import {types as vehTypes} from '../Vehicles/VehiclesActions';
import {types as fieldTypes} from '../Fields/FieldsActions';
import {types as empTypes} from '../Employees/EmployeesActions';
import {AUTHORIZE_LOGOUT} from '../../Login/LoginActions';

const defaultState = {
  currentTab: entityType.FIELD,
  filterStr: '',
};

export default function (state = immutable.fromJS(defaultState), action) {
  switch (action.type) {
    case SELECT_TAB:
      state = state.set('currentTab', action.tab);
      break;
    case FETCH_SUCCESS:
    case vehTypes.FETCH_SUCCESS:
    case fieldTypes.FETCH_SUCCESS:
    case empTypes.FETCH_SUCCESS:
      break;
    case FILTER_DATA:
      state = state.set('filterStr', action.filterStr);
      state = state.set('dataFiltered', action.dataFiltered);
      break;
    case AUTHORIZE_LOGOUT:
      state = immutable.fromJS(defaultState);
      break;
  }
  return state;
}
