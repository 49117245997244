export const layerTypes = {
/*
  yandexPeople:
    {
      title: 'Яндекс.Народная',
      type: 'yandexPeople'
    },
*/
  yandexMap:
    {
      title: 'Яндекс.Карты',
      type:'yandexMap',
      CRS: 'EPSG3395'
    },
  yandexSatellite:
    {
      title: 'Яндекс.Спутник',
      type: 'yandexSatellite',
      CRS: 'EPSG3395'
    },
  GIS:
    {
      title: '2GIS',
      type:'GIS'
    },
  // OSM:
  //   {
  //     title: 'OpenStreetMap',
  //     type: 'OSM'
  //   },
  googleMap:
    {
      title: 'Google.Карта',
      type: 'googleMap'
    },
  googleSatellite:
    {
      title: 'Google.Спутник',
      type: 'googleSatellite'
    },
  googleHybrid:
    {
      title: 'Google.Гибрид',
      type: 'googleHybrid'
    }
};

// типы геозон для фильтра геозон на карте.
export const geozoneTypes = {
    all:
      {
        title: 'Все',
        type: 'all'
      },
    field:
      {
        title: 'Поле',
        type: 'field'
      },
    home:
      {
        title: 'Домашняя зона',
        type: 'home'
      },
    cad:
      {
        title: 'Кадастровая зона',
        type: 'cadastre'
      },
    unused:
      {
        title: 'Неиспользуемые земли',
        type: 'unused'
      },
      // meteostations:
      // {
      //   title: 'Метеостанции и датчики',
      //   type: 'meteostations'
      // },
    another:
      {
        title: 'Другое',
        type: 'other'
      }
  };


// Максимальное приближение для подложки 2gis
export const max2GisZooming = 16;

// Максимальное отдаление для подложки 2gis
export const max2GisZoomOut = 2;

// максимальное приближение для любой подложки (кроме 2gis)
export const maxDefaultMapZooming = 18;

// Максимальное отдаление для любой подложки (кроме 2gis)
export const maxDefaultMapZoomOut = 1;
