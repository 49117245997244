import {url} from '../api';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import BaseDataService from "../baseClasses/BaseDataService";
import {mapValuesForKeys} from "../helpers/mapValuesForKeys";
import {actions} from "../components/Main/Vehicles/VehiclesActions";
import {actions as vehicleActions} from "../components/Main/Vehicles/Vehicle/VehicleActions";
import EmployeeService from './EmployeeService';
import FieldService from "./FieldService";
import store from "../store/store";
import values from "lodash/values";
import AddressService from "./AddressService";
import {stringHash} from "../helpers/stringHash";
import memoizeFilterVehicle from '../helpers/memoizeFilterVehicle';
import memoizeFilterListVehicle from '../helpers/memoizeFilterListVehicle';
import {updateFilterInfo, initFilterInfo} from '../helpers/updateFilterInfo';
import {actions as VehicleFilterActions} from '../components/Main/Vehicles/VehicleFilter/VehicleFilterActions';
import { cloneDeep } from 'lodash';

class VehicleService extends BaseDataService {
  _posCache = {pos: [], value: '', id: -1};
  _filterCache = {unitState: {}, type: {}};

  constructor() {
    super({urlAll: url.vehicle, ...actions});
    window.VehicleService = this;
    this._treeStateSaveOn = true;
  }

  dispatchDataChange() {
    super.dispatchDataChange();
    store.dispatch(vehicleActions.fetchSuccess());
  }

  getVehicleList() {
    return values(this._glidMap);
  }

  getCurrentStatuses() {
    return this._filterCache.unitState;
  }

  getCurrentTypes() {
    return Object.keys(this._filterCache.type);
  }

  getAddess(id) {
    const vehicle = this.getItem(id);
    if (isEmpty(vehicle) || (!vehicle.position && !vehicle.rt_position)) {
      return '';
    }
    if (!isEqual(Number(this._posCache.id), Number(id))) {
      this._posCache.id = Number(id);
      this._posCache.value = '';

    }

    const pos = vehicle.rt_position ? [...vehicle.rt_position[0]].reverse() : [...vehicle.position[0]].reverse();

    if (isEqual(this._posCache.pos, pos) && isEqual(Number(this._posCache.id), Number(id))) {
      return this._posCache.value;
    }

    this._posCache.pos = [...pos];

    AddressService.getAddress(pos, (value) => {
      this._posCache.value = value;
    });

    return this._posCache.value;
  }

  getDriver(vehicle) {
    return vehicle.operator && EmployeeService.getItem(vehicle.operator.id) || {};
  }

  getCurrentOperations(vehicle) {
    return vehicle.operations ? vehicle.operations.map(x => x.title) : []
  }

  getFilteredTree(status, type, withoutMutation) {
    const tree = withoutMutation ? cloneDeep(this.getShortenTree()) : this.getShortenTree();
    return memoizeFilterVehicle({tree, status, type});
  }

  getFilteredList(status, type) {
    return memoizeFilterListVehicle({list: this._glidMap, status, type});
  }

  _mergeCustoms(dest, src, key, isArray) {
    if (!isArray) {
      if (key === 'zoneId') {
        dest[key] = src.values[key];

        //set vehicleCount for related field
        const newField = FieldService.getItem(src.values.zoneId);
        const oldField = FieldService.getItem(dest.zoneId);
        if (!isEmpty(newField)) {
          if (!newField.relatedVehicles) {
            newField.relatedVehicles = {};
          }
          if (this._glidMap[src.id].zoneId !== src.zoneId) {
            oldField.relatedVehicles[src.id] = false;
            newField.relatedVehicles[src.id] = true;
            oldField._lastUpdated = this._lastUpdated;
            newField._lastUpdated = this._lastUpdated;
          }
        }
      } else if (key === 'operator') {
        if (!src.values.operator) {
          dest[key] = src.values[key];
          return;
        }
        //set related vehicle for driver
        const newEmp = EmployeeService.getItem(src.values.operator.id);
        const oldEmp = dest.operator && EmployeeService.getItem(dest.operator.id);
        if (!isEmpty(newEmp)) {
          newEmp.relatedVehicle = dest;
          newEmp._lastUpdated = this._lastUpdated;
          if (oldEmp) {
            oldEmp.relatedVehicle = {};
            oldEmp._lastUpdated = this._lastUpdated;
          }
        }

        dest[key] = src.values[key];
      } else if ((key === 'unitState' || key === 'type') && dest.type !== 'warehouse') {
        updateFilterInfo(dest[key], src.values[key], key, this._filterCache);
        dest[key] = src.values[key];
      } else {
        dest[key] = src.values[key];
      }
    } else {
      const v = this._glidMap[src.id][key] || [];
      if (key === 'operations') {
        dest[key] = src.values[key];
      } else if (key === 'position' || key === 'rt_position') {
        dest[key] = [src.values[key]];
      }
      else if (key === 'track') { //track for vehicle
        const nowDate = new Date();
        dest[key] = v.concat(src.values[key]);
        dest[key] = dest[key].filter(coord => {
            const coordDate = new Date(coord[2]),
              diff = nowDate.getTime() - coordDate.getTime(),
              diffMinutes = Math.round(diff / 60000);
            return diffMinutes <= 26;
          }
        );
        dest[key] = dest[key].sort((firstCoord, secondCoord) => {
          if (firstCoord[2] < secondCoord[2])
            return -1;
          if (firstCoord[2] > secondCoord[2])
            return 1;
          return 0;
        });
      }
      else { // DEFAULT
        dest[key] = [...v, src.values[key]];
      }
    }
  }

  reset() {
    this._filterCache = {unitState: {}, type: {}};
    store.dispatch(VehicleFilterActions.reset());
    super.reset();
  }

  _mapListItem(vehicle) {
    const driver = this.getDriver(vehicle);
    const operations = this.getCurrentOperations(vehicle);
    const zone = FieldService.getItem(vehicle.zoneId || get(operations, 'zones[0]', 0));
    const pos1 = !isEmpty(vehicle.position) && !isEmpty(vehicle.position[0]) ? vehicle.position[0][0] + vehicle.position[0][1] : 0;
    const pos2 = !isEmpty(vehicle.rt_position) && !isEmpty(vehicle.rt_position[0]) ? vehicle.rt_position[0][0] + vehicle.rt_position[0][1] : 0;

    const hash = stringHash(vehicle.name) + stringHash(vehicle.unitState) + stringHash(driver) + stringHash(zone) +
      stringHash(operations.join()) + Number(vehicle.glid) * 10000 + (vehicle.speed * 100).toFixed(0) + Number(pos1 + pos2).toFixed(0);

    return mapValuesForKeys(vehicle, [
      '_lastUpdated',
      'glid',
      'name',
      'type',
      'unitState',
      'speed',
      'lastTime',
      'rt_time',
      'removed',
      'zoneId',
      'position',
      'rt_position',
      'pending',
      {
        key: '_hash',
        value: hash,
      },
      {
        key: 'vehicleType',
        value: vehicle.type,
      },
      {
        key: 'zone',
        value: zone && zone.name,
      },
      {
        key: 'driver',
        value: driver && driver.name,
      },
      {
        key: 'operations',
        value: operations,
      },
      {
        key: 'operatorId',
        value: vehicle.operator && vehicle.operator.id,
      }
    ]);
  }

  _afterFetch() {
    super._afterFetch();
    initFilterInfo(this.data, 'unitState', this._filterCache);
    initFilterInfo(this.data, 'type', this._filterCache);
  }
}

export default new VehicleService();
