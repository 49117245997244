import {typesByCulture, typesByWork} from './PlansActions';

const defaultState = {
  pending: true,
  viewCulture: true,
  filterStr: '',
  byCulture: {
    pending: false,
  },
  byWork: {
    pending: false,
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case typesByWork.FETCH:
    case typesByWork.FETCH_PENDING:
    case typesByWork.FETCH_ERROR:
    case typesByWork.FETCH_SUCCESS:
      state = Object.assign({}, state, {byWork: action.payload});
      break;
    case typesByCulture.FETCH:
    case typesByCulture.FETCH_PENDING:
    case typesByCulture.FETCH_ERROR:
    case typesByCulture.FETCH_SUCCESS:
      state = Object.assign({}, state, {byCulture: action.payload});
      break;
    case typesByWork.FILTER:
    case typesByWork.FILTER_PATH:
    case typesByWork.FILTER_MODE:
    case typesByCulture.FILTER:
    case typesByCulture.FILTER_PATH:
    case typesByCulture.FILTER_MODE:
    case typesByCulture.TOGGLE_VIEW__PLANS:
      state = Object.assign({}, state, {...action.payload});
      break;
  }

  return state;
}
