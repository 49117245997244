import {entityType} from '../../../const/index';

export const SELECT_TAB = 'SELECT_TAB';
export const FETCH_DATA = 'FETCH_DATA__SEARCH';
export const FILTER_DATA = 'FILTER_DATA__SEARCH';
export const FETCH_PENDING = 'FETCH_PENDING__SEARCH';
export const FETCH_SUCCESS = 'FETCH_SUCCESS__SEARCH';
export const FETCH_ERROR = 'FETCH_ERROR__SEARCH';

export function selectTab(tabType = entityType.FIELD) {
  return dispatch => {
    dispatch({
      type: SELECT_TAB,
      tab: tabType,
    });
  };
}

export function filterData(filterStr = '') {
  return dispatch => {
    dispatch({
      type: FILTER_DATA,
      filterStr,
    });
  };
}

export function getData() {
  return async dispatch => {
    dispatch({
      type: FETCH_DATA
    });
  };
}

export function fetchPending() {
  return dispatch => {
    dispatch({
      type: FETCH_PENDING,
    });
  };
}

export function fetchSuccess() {
  return dispatch => {
    dispatch({
      type: FETCH_SUCCESS,
    });
  };
}

export function fetchError() {
  return dispatch =>
    dispatch({
      type: FETCH_ERROR,
    });
}
