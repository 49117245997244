import {types} from './AssignmentFilterActions';
import {assigneeTypes, assigneeTypesDict} from "../../../../const/assignments";

const defaultState = {
  entityId: '*',
  status: {
    all: ['open','progress','inCheck','complete','failed'],
    selected: ['open','progress','inCheck','complete','failed']
  },
  assignee: {
    all: [...assigneeTypes],
    selected: assigneeTypesDict.asAssignee,
  }
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.SET_ENTITY_ID:
    case types.STATUS_SET_BULK:
    case types.STATUS_ADD_BULK:
    case types.STATUS_ADD:
    case types.STATUS_REMOVE:
    case types.SELECT_ASSIGNEE:
      state = Object.assign({}, state, {...action.payload});
      break;
    case types.RESET_STATE:
      state = Object.assign({}, defaultState);
      break;
  }

  return state;
}
