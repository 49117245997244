import {types} from './HarvesterUploadActions';
import {getInitialPeriod} from "../../../helpers/initialPeriod";

const initialPeriod = getInitialPeriod();

const defaultState = {
  from: initialPeriod.from,
  to: initialPeriod.to,
  pending: true,
  filterStr: '',
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.FILTER:
    case types.FETCH:
    case types.FETCH_PENDING:
    case types.FETCH_SUCCESS:
    case types.FETCH_ERROR:
    case types.SET_END_DATE:
    case types.SET_START_DATE:
    case types.SET_PERIOD:
    case types.FETCH_NO_PENDING:
      state = Object.assign({}, state, {...action.payload});
  }

  return state;
}
