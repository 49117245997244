import {types} from './TaskFilterActions';

const defaultState = {
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.SET_PERIOD:
      state = Object.assign({}, state, {...action.payload});
      break;
  }

  return state;
}
