import immutable from 'immutable';
import get from 'lodash/get';
import toString from 'lodash/toString';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import {tabTypes, durations} from './constants';
import {isIOS} from "../../../../helpers/localstorageHelpers";

/**
 * Если не выбран завесимый объект при onSelect, то обновляем общий, иначе обновляем нужный
 *
 * @param state - Хранилище
 * @param tab - Массив содержащий путь до нужного store (['tabs', '0'] - для вкладки Работы)
 * @param action - Содержит выбранный объект и ID для зависимого
 * @param keyName - Поле ID
 * @returns {*}
 */
export const changeStateOnSelect = (state, tab, action, keyName = 'id') => {
  const {selectObject, dependedObjectId} = action;
  if (!dependedObjectId) {
    return state.setIn([...tab, 'general'], get(selectObject, keyName, null));
  }
  return state.setIn([...tab, 'mapValue', toString(dependedObjectId)], get(selectObject, keyName, null));
};

/**
 * Сложное поведения для onCheck на вкладках Агрегаты и Норматив скорости
 * При uncheck нужно всем выбранным зависимым объектам (Техника) проставить общий элемент (к примеру Агрегат)
 * При check нужно проверить отличается ли выбранные элементы (Агрегаты) от общего, если да, то сбрасываем все
 *
 * @param state - Хранилище
 * @param tab - Массив содержащий путь до нужного store (['tabs', '0'] - для вкладки Работы)
 * @param tabDepends - Массив содержащий путь до нужного зависимого store (['tabs', '0'] - для вкладки Работы)
 * @param action - Содержит флаг checked
 */
export const changeStateOnChecked = (state, tab, tabDepends, action) => {
  const {checked} = action;
  let newState = state.setIn([...tab, 'checked'], checked);

  // ID выбранных зависимых элементов, например для Агрегатов, это Техника
  const selectedIds = newState.getIn([...tabDepends, 'selectedIds']).toJS();
  // Общий выбранный элемент, который применим ко всем
  const general = newState.getIn([...tab, 'general']);
  // Словарь который хранит зависимости
  const mapValue = newState.getIn([...tab, 'mapValue']).toJS();
  // Проверяем совпадают ли для всех зависимых элементов выбранный общий объект
  const isEqual = selectedIds.reduce((result, id) => result && mapValue[id] === general, true);
  // Если для всех зависимых элементов совпадает выбранный элемент, то возвращаем state
  if (isEqual) {
    return newState;
  }

  if (checked) {
    // В обратном случае сбрасываем общий элемент
    newState = newState.setIn([...tab, 'general'], null);
    selectedIds.forEach(id => mapValue[id] = null);
    return newState.setIn([...tab, 'mapValue'], immutable.fromJS(mapValue));
  }

  // Проставляем всем общий выбранный элемент
  selectedIds.forEach(id => mapValue[id] = general);
  return newState.setIn([...tab, 'mapValue'], immutable.fromJS(mapValue));
};

/**
 * Возвращает Props для вкладок Поля и Техника
 * @param state - Хранилище
 * @param tab - Массив содержащий путь до нужной вкладки (['tabs', '0'] - для вкладки Работы)
 * @return {{checkedFilter: bool | *, filterStr: string | *, selectedIds: Array | *}}
 */
export const getPropsFromStore = (state, tab) => ({
  checkedFilter: state.getIn([...tab, 'checked']),
  filterStr: state.getIn([...tab, 'filterStr']),
  selectedIds: state.getIn([...tab, 'selectedIds']).toJS(),
});

/**
 * Возвращает Props для вкладок Агрегаты и Норматив скорости
 * @param state
 * @param tab
 * @return {{checked: bool | *, generalId: number | *, mapValue: Object | *}}
 */
export const getPropsMapValueFromStore = (state, tab) => ({
  checked: state.getIn([...tab, 'checked']),
  generalId: state.getIn([...tab, 'general']),
  mapValue: state.getIn([...tab, 'mapValue']).toJS(),
});

/**
 * Возвращает Props для вкладки Маршруты
 * @param state
 * @param tab
 * @return {{name: String | *, selectedId: number | *, mapValue: Object | *}}
 */
export const getPropsTransportRouteFromStore = (state, tab) => ({
  name: state.getIn([...tab, 'name']),
  selectedId: state.getIn([...tab, 'selectedId']),
  mapValue: state.getIn([...tab, 'mapValue']).toJS(),
});

/**
 * Возращает список техники с добавленными объектами "Норматив скорости" и "Агрегаты"
 *
 * @param selectedIds - выбранные зависимые элементы (Техника и т.д)
 * @param filtered - отфильтрованные элементы (Список объектов Техники)
 * @param mapValueTools - словарь, ключи - это ID зависимого элемента (Техники), значения - это ID выбранного объекта (Агрегат)
 * @param mapValueSpeeds - словарь, ключи - это ID зависимого элемента (Техники), значения - это ID выбранного объекта (Норматив скорости)
 * @param fieldTools - список объектов для выбора (Агрегаты)
 * @param limitSpeeds - список объектов для выбора (Норматив скорости)
 * @return Array
 */
export const groupedMap = (
  selectedIds, filtered, mapValueTools, mapValueSpeeds, fieldTools, limitSpeeds,
) => selectedIds.map(glid => {
  const dependsObject = filtered.find(obj => obj.glid === glid);

  const fieldToolId = mapValueTools[glid];
  const fieldTool = fieldToolId ? fieldTools.find(tool => tool.id === fieldToolId) : '';

  const limitSpeedId = mapValueSpeeds[glid];
  const limitSpeed = limitSpeedId ? limitSpeeds.find(speed => speed.limitSpeedId === limitSpeedId) : '';

  return {...dependsObject, fieldTool, limitSpeed};
});

/**
 * Удаляем выбранное значение агрегата или норматива для техники, при удалении этой техники
 * @param state
 * @param tab
 * @param vehicleIds
 * @return {__Cursor.Cursor | List<T> | Map<K, V> | *}
 */
export const deleteExpiresItem = (state, tab, vehicleIds) => {
  const mapValue = state.getIn([...tab, 'mapValue']).toJS();
  const newMapValue = {};

  vehicleIds.forEach(id => newMapValue[id] = get(mapValue, toString(id), null));
  return state.setIn([...tab, 'mapValue'], immutable.fromJS(newMapValue));
};

/**
 * Проверяем выбраны ли для всей техники агрегаты или нормативы скорости
 * @param checked
 * @param generalId
 * @param mapValue
 * @return {boolean}
 */
export const isSelectPartVehicle = (checked, generalId, mapValue) => {
  if (checked) {
    return !!generalId;
  }

  return !isEmpty(mapValue) && !Object.values(mapValue)
    .reduce((result, toolId) => result || isNull(toolId), false);
};

/**
 * Строим стор из данных таски
 * @param state
 * @param task
 * @param isEdit
 * @return {any}
 */
export const transformTask = (state, task, isEdit) => {
  state.id = isEdit ? get(task, 'id', null) : null;
  state.header = isEdit ? 'Редактирование задания' : 'Создание задания';
  // Вкладка департаменты
  state.tabs[tabTypes.DEPARTMENTS].selectedId = Number(get(task, 'departmentId', null));
  // Вкладка работы
  state.tabs[tabTypes.WORK].selectedId = get(task, 'operationId', null);
  // Вкладка даты
  if (isEdit) {
    const defaultFrom = state.tabs[tabTypes.DATE].from;
    state.tabs[tabTypes.DATE].from = get(task, 'from', defaultFrom);
    const defaultTo = state.tabs[tabTypes.DATE].to;
    state.tabs[tabTypes.DATE].to = get(task, 'to', defaultTo);
  }
  // Вкладка поля
  const fieldList = get(task, 'fieldList', []).map(field => String(field.fieldId));
  state.tabs[tabTypes.FIELD].checked = !!fieldList.length;
  state.tabs[tabTypes.FIELD].selectedIds = fieldList;
  // Вкладка техника
  const unitList = get(task, 'unitList', []).map(vehicle => String(vehicle.unitId));
  state.tabs[tabTypes.VEHICLE].checked = !!unitList.length;
  state.tabs[tabTypes.VEHICLE].selectedIds = unitList;
  // Вкладка аггрегаты
  const defaultFieldToolId = get(task, 'defaultFieldToolId', null);
  state.tabs[tabTypes.FIELD_TOOL].checked = !!defaultFieldToolId;
  state.tabs[tabTypes.FIELD_TOOL].general = defaultFieldToolId;
  state.tabs[tabTypes.FIELD_TOOL].mapValue = get(task, 'unitList', []).reduce((result, vehicle) => {
    const unitId = get(vehicle, 'unitId', null);
    const fieldToolId = get(vehicle, 'fieldToolId', null);
    if (unitId) {
      result[unitId] = fieldToolId;
    }
    return result;
  }, {});
  // Вкладка Маршруты
  const transportRoute = get(task, 'transportRoute', {});
  state.tabs[tabTypes.TRANSPORT_ROUTE].name = get(transportRoute, 'transportRouteName', '');
  state.tabs[tabTypes.TRANSPORT_ROUTE].mapValue = get(transportRoute, 'transportRouteElements', []).reduce((result, el) => {
    const fieldId = get(el, 'fieldId', null);
    if (fieldId) {
      result[fieldId] = {
        fieldId,
        limitDurationMs: durations.find(d => d.ms === get(el, 'limitDurationMs', null)) || null,
        loadingType: get(el, 'loadingType', null),
        name: get(el, 'name', null),
        startEndRace: get(el, 'startEndRace', false),
      }
    }
    return result;
  }, {});
  // Вкладка Грузы
  state.tabs[tabTypes.GOODS].mapValue = get(task, 'unitList', []).reduce((result, el) => {
    const unitId = get(el, 'unitId', null);
    if (unitId) {
      result[unitId] = {
        name: get(el, 'unitName', null),
        goodId: get(el, 'goodsId', null), // TODO: check back-end API
        loadingWeight: get(el, 'loadingWeight', null),
        trailerWeight: get(el, 'trailerWeight', null),
      };
    }
    return result;
  }, {});
  // Вкладка лимиты скорости
  const defaultLimitSpeedId = get(task, 'defaultLimitSpeedId', null);
  let isLimitSpeeds = false;
  state.tabs[tabTypes.LIMIT_SPEED].checked = !!defaultLimitSpeedId;
  state.tabs[tabTypes.LIMIT_SPEED].general = defaultLimitSpeedId;
  state.tabs[tabTypes.LIMIT_SPEED].mapValue = get(task, 'unitList', []).reduce((result, vehicle) => {
    const unitId = get(vehicle, 'unitId', null);
    const limitSpeedWithUnitId = get(vehicle, 'limitSpeedWithUnitId', null);
    if (unitId) {
      result[unitId] = limitSpeedWithUnitId;
      isLimitSpeeds = isLimitSpeeds || !!limitSpeedWithUnitId;
    }
    return result;
  }, {});
  // Если не заполнен лимит скорости, то по-умолчанию checked === true
  if (!state.tabs[tabTypes.LIMIT_SPEED].checked && !isLimitSpeeds) {
    state.tabs[tabTypes.LIMIT_SPEED].checked = true;
  }
  return immutable.fromJS(state);
};

/**
 * Обновлем поля для стора Маршруты
 * @param state - Хранилище
 * @param tab - Массив содержащий путь до нужного store
 * @param selected - Массив содержащий выбранные поля
 * @returns {List<T> | Map<K, V> | __Cursor.Cursor | *} - Возвращает обновленный стор
 */
export const updateTransportRouteFields = (state, tab, selected) => {
  const mapValue = state.getIn([...tab, 'mapValue']).toJS();
  const defaultItem = {
    limitDurationMs: null,
    loadingType: 'loadingZone',
    startEndRace: false
  };
  const newMapValue = {};
  selected.forEach(field => newMapValue[field.glid] = get(mapValue, toString(field.glid), {
    ...defaultItem,
    name: field.name,
    fieldId: field.glid,
  }));
  return state.setIn([...tab, 'mapValue'], immutable.fromJS(newMapValue));
};

/**
 * Обновляем технику для стора Грузы
 * @param state - Хранилище
 * @param tab - Массив содержащий путь до нужного store
 * @param selected - Массив содержащий выбранную технику
 * @returns {List<T> | Map<K, V> | __Cursor.Cursor | *}
 */
export const updateGoodsVehicles = (state, tab, selected) => {
  const mapValue = state.getIn([...tab, 'mapValue']).toJS();
  const defaultItem = {
    goodId: null,
    loadingWeight: null,
    trailerWeight: null,
  };
  const newMapValue = {};
  selected.forEach(vehicle => newMapValue[vehicle.glid] = get(mapValue, toString(vehicle.glid), {
    ...defaultItem,
    name: vehicle.name,
  }));
  return state.setIn([...tab, 'mapValue'], immutable.fromJS(newMapValue));
};

/**
 * Функция инициализации имени маршрута. Если он уже заполнен - игнорируем.
 * @param state - Хранилище
 * @param tab - Путь
 * @param name - Имя маршрута, который выбрали
 * @param isSelectRoute - Если действие вызвано выбором транспортной работы
 * @returns {*}
 */
export const renameTransportRoute = (state, tab, name, isSelectRoute = false) => {
  const oldName = state.getIn(tab);
  if (oldName !== '' && isSelectRoute) {
    return state;
  }
  return state.setIn(tab, name);
};

/*
*
* На Apple устройствах клавиатура сдвигает зафиксированные элементы за область видимости экрана,
* функция необходима для плавного скролла (с небольшой задержкой) на область, выделенную под шапку
*
* */
export const handleIOSDeviceScroll = () => {
  if (isIOS()) return setTimeout(() => window.scrollTo({top: 0, behavior: 'smooth'}), 200)
}
