import {types} from "./ShiftCardActions";
import {types as lTypes} from '../ShiftsActions';

const defaultState = {
  saving: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.NOTE_FETCH_DONE:
      state = Object.assign({}, state, defaultState);
  }

  return state;
}
