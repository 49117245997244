import {createActions} from "../../../baseClasses/ActionFactory";
import WorkShiftService from "../../../services/WorkShiftService";
import {changeLayerType} from "../Map/LayersSelect/LayersSelectActions";

function onFetch({fieldId, operationId, sourceId, dateStart}) {
  WorkShiftService.fetch({fieldId, operationId, sourceId, dateStart});
  return {operationId, sourceId, dateStart};
}

const {types, actions} = createActions({store: 'WORK_SHIFT_LIST', onFetch});

Object.assign(actions, {changeLayerType});

export {types, actions};
