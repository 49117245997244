import isEqual from 'lodash/isEqual';
import {push, goBack, replace} from 'react-router-redux';
import mainService from '../../services/MainService';

export const MENU_OPEN = 'MENU_OPEN';
export const MENU_CLOSE = 'MENU_CLOSE';
export const LOADER_TOGGLE = 'LOADER_ON';
export const FETCH_DATA = 'FETCH_DATA__MAIN';
export const FETCH_PENDING = 'FETCH_PENDING__MAIN';
export const FETCH_SUCCESS = 'FETCH_SUCCESS__MAIN';
export const FETCH_ERROR = 'FETCH_PENDING__MAIN';
export const UPDATE = 'UPDATE';
export const CONNECTION_ON = 'CONNECTION_ON';


export function historyGoBack() {
  return dispatch => dispatch(goBack());
}

export function historyReplace(path) {
  return dispatch => dispatch(replace(path));
}

export function update() {
  return dispatch => {
    dispatch({
      type: UPDATE,
    });
  };
}

export function toggleLoader(txtMark, off = false, all = false) {
  return (dispatch, getState) => {
    let {loadersActive} = getState().main.main;
    const initialLoadersActive = [...loadersActive];

    if (off || loadersActive.indexOf(txtMark) !== -1) {
      loadersActive = [...loadersActive.filter(i => i !== txtMark)];
    } else {
      loadersActive = [...loadersActive, txtMark];
    }

    if (all) {
      loadersActive = [];
    }
    if (!isEqual(loadersActive, initialLoadersActive)) {
      dispatch({
        type: LOADER_TOGGLE,
        payload: {loadersActive}
      });
    }
  };
}

export function toggleMenu(open = true) {
  return dispatch =>
    dispatch({
      type: open ? MENU_OPEN : MENU_CLOSE,
    });
}

export function historyPush(path) {
  return dispatch => dispatch(push(path));
}

export function loadInitialData() {
  return async dispatch => {
    dispatch({
      type: FETCH_DATA
    });
    await mainService.fetchInitialData();
  };
}

export function fetchPending() {
  return dispatch => {
    dispatch({
      type: FETCH_PENDING
    });
  };
}

export function fetchSuccess() {
  return dispatch => {
    dispatch({
      type: FETCH_SUCCESS
    });
  };
}

export function fetchError() {
  return dispatch => {
    dispatch({
      type: FETCH_ERROR
    });
  };
}


export function setConnectionState(connectionOK = true) {
  return dispatch => {
    dispatch({
      type: CONNECTION_ON,
      payload: {connection: connectionOK},
    });
  };
}
