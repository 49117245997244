import React from 'react';
import cn from 'classnames';
import st from './spinner.scss';

const Spinner = ({className}) => (
  <div className={cn(st.loaderContainer, className)}>
    <div className={st.loader}/>
    <div className={st.loader}/>
    <div className={st.loader}/>
  </div>
);

export {Spinner};
