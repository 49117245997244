import * as React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import values from 'lodash/values';
import cn from 'classnames';
import {iconType, classNameMap, svgMap} from "./iconTypes";

import st from './icon.scss';

const iconHtml = (icon) => Object.assign({__html: icon});

const Icon = ({className, type, onClick}) => (
  <div className={cn(st.icon, classNameMap[type], className)}
       onClick={onClick}
       dangerouslySetInnerHTML={iconHtml(svgMap[type])}
  />
);

const iconTypeList = values(iconType);

Icon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(iconTypeList),
};

Icon.defaultProps = {
  className: '',
  onClick: noop,
  type: iconType.none,
};

export default Icon;
export {iconType, iconTypeList, svgMap};
