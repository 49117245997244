import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import wsClient from '../../clients/WebsocketClient';
import agroEvents from './agroWsEvents';
import {Notification} from "../../components/common/Notification";
import AuthService from '../AuthService';
import store from '../../store/store';
import {setConnectionState} from "../../components/Main/MainActions";
import MainService from "../MainService";
import httpClient from "@infobis/api-module";
const wsEventTypes = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
  MESSAGE: 'MESSAGE',
  ERROR: 'ERROR',
};

const readyStateConst = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

class WebsocketService {
  _inited = false;
  cid = undefined;
  idError = null;
  idDisconnect = null;

  get initialized() {
    return this._inited;
  }

  constructor() {
    window.WebsocketService = this;
    this.setListenersPool();
    this.addListener(wsEventTypes.OPEN, (e) => this.onOpen(e));
    this.addListener(wsEventTypes.MESSAGE, (e) => this.onMessage(e));
    this.addListener(wsEventTypes.CLOSE, (e) => this.onClose(e));
    this.addListener(wsEventTypes.ERROR, (e) => this.onError(e));

    //TODO: move following to appropriate service
    this.addListener(agroEvents.AG_YOU_ARE, data => {
      this.start(data);
      this.addSwitchableCalc(data);
    });
  }

  // На мобильной версии нам всегда нужен включенным аспект для работ по технике. В основном агросигнале есть переключатель.
  // При вызове этой функции в мобильный агросигнал начинают приходить данные по этому аспекту.
  addSwitchableCalc(data) {
    const cid = data.id;
    const from = window.workPeriod();

    this.cid = cid;
    this._inited = true;
    //for timetravel debug stuff
    window.cid = cid;

    this.ws.send(JSON.stringify({
      event: agroEvents.AG_SWITCHABLE_CALC,
      cid,
      data: {
        list: [
          {entityType: "fieldOp"}
        ]
      }
    }));
  }

  start(data) {
    const cid = data.id;
    const from = window.workPeriod();

    this.cid = cid;
    this._inited = true;
    //for timetravel debug stuff
    window.cid = cid;

    this.ws.send(JSON.stringify({
      event: agroEvents.AG_START,
      cid,
      data: {
        cid,
        from,
        archiveFrom: null,
        dynamic: [],
      }
    }));
  }

  setListenersPool() {
    this.listeners = {};
    const allTypes = values(Object.assign({}, wsEventTypes, agroEvents));
    for (const t of allTypes) {
      this.listeners[t] = [];
    }
  }

  addListener(eType, cb) {
    if (!this.listeners[eType]) {
      console.error('No such listener-type exists');
    }
    this.listeners[eType].push(cb);
  }

  removeListener(eType, cb) {
    if (!this.listeners[eType]) {
      console.error('No such listener-type exists');
    }
    this.listeners[eType] = [...this.listeners[eType].filter(f => (f !== cb))];
  }

  reset() {
    this._inited = false;
    this.ws = null;
  }

  clear() {
    this.reset();
  }

  _callListeners(eType, e) {
    if (!this.listeners[eType]) {
      console.error('No such listener-type exists');
    }
    for (const listener of this.listeners[eType]) {
      typeof listener === 'function' ?
        listener(e) :
        listener.cb(e);
    }
  }

  async connect() {
    this.reconnecting = false;
    if (!this.ws) {
      this.ws = await wsClient.connect();
    }
    this.ws.onopen = (e) => this._callListeners(wsEventTypes.OPEN, e);
    this.ws.onclose = (e) => this._callListeners(wsEventTypes.CLOSE, e);
    this.ws.onmessage = (e) => this._callListeners(wsEventTypes.MESSAGE, e);
    this.ws.onerror = (e) => this._callListeners(wsEventTypes.ERROR, e);
  }

  reconnect() {
    localStorage.removeItem('isRefreshBlocked');
    httpClient.refreshTokenAttempt();
    this.reconnecting = true;
    this.reset();
    this._inited = true;
    console.info('WS try to reconnect');
    this.connect();
  }

  async setDynamic({aspect, id, additionalAspect, additionalIds, needRestart}) {
    if (!this.ws) {
      await this.connect();
    }
    let list = [];
    //old logic
    list.push({id: Number.parseInt(id), tp: aspect, configuration: {state: needRestart && "restart"}})
    //new logic for fieldWorkOperations2
    if (additionalAspect && additionalIds) {
      additionalIds.forEach((id) => {list.push({id: Number.parseInt(id), tp: additionalAspect, configuration: {state: needRestart && "restart"}})})
    }

    const msg = {
      cid: this.cid,
      event: agroEvents.AG_SET_DYNAMIC,
      data: {
        list: list
      }
    };

    await this.ws.send(JSON.stringify(msg));
  }

  onOpen() {
    store.dispatch(setConnectionState(true));
    // Notification.info('WSS Соединение установлено.');

    if (this._inited) {
      MainService.afterReconnect();
    }
  }

  onClose(e) {
    store.dispatch(setConnectionState(false));
    if (e.wasClean) {
      // Notification.info('WSS Соединение закрыто чисто');
    } else {
      if (!Notification.checkDuplicate(this.idDisconnect)) {
        // this.idDisconnect = Notification.error('WSS Обрыв соединения', {autoClose: false});
      }
      if (Number(event.code) === 1006) {
          this.reconnectCoroutine();
      }
    }
    console.log('Код: ' + event.code + ' причина: ' + event.reason);
  }

  //not actual coroutine ;)
  reconnectCoroutine() {
    const t = setTimeout(() => {
      clearTimeout(t);
      if (this.reconnecting) {
        return;
      }
      if (this.ws.readyState !== readyStateConst.CONNECTING) {
        this.reconnect();
      }
    }, 5000);
  }

  close() {
    this.ws.close();
  }

  onMessage(e) {
    const data = JSON.parse(e.data);
    if (!AuthService.isLoggedIn() && !isEmpty(this.ws)) {
      this.close();
      return;
    }
    this._callListeners(data.event, data.data);
  }

  onError(error) {
    console.error('WSS ошибка', error);
  }

  cbOnOpen(cb) {
    if (this.initialized) {
      cb();
    } else {
      this.addListener(wsEventTypes.OPEN, cb);
    }
  }
}

export default new WebsocketService();
export {wsEventTypes};
