import Tree from "../treejs/Tree";

export function getTreeLeaves(tree) {
  let queue = [];
  if (Array.isArray(tree)) {
    queue = queue.concat(tree);
  } else {
    queue.push(tree);
  }
  let node = null,
    leaves = [];
  while (queue.length > 0) {
    node = queue.shift();
    if (!node) {
      continue;
    }
    if (node.leaf) {
      leaves.push(node);
    }
    if (node.data) {
      if (Array.isArray(node.data)) {
        queue = queue.concat(node.data);
      } else {
        queue.push(node.data);
      }
    }
  }

  return leaves;
}

export const emptyTree = () => {
  return new Tree(() => 'nop');
};
