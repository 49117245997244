import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import pick from 'lodash/pick';
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import flatten from "lodash/flatten";
import get from "lodash/get";
import intersection from "lodash/intersection";
import moment from 'moment';
import {url} from '../api';
import BaseDataService, {responseDataShape} from '../baseClasses/BaseDataService';
import {actions} from '../components/Main/Assignment/AssignmentActions';
import Tree from "../treejs/Tree";
import {expandTree} from "../helpers/expandTree";
import {parseTree} from "../helpers/parseTree";
import VehicleService from "./VehicleService";
import FieldService from "./FieldService";
import EmployeeService from "./EmployeeService";
import store from "../store/store";
import {
  assigneeTypesDict,
  assignmentStatus,
  stateHistoryTitle,
  stateNameMap,
  stateOrderMap
} from "../const/assignments";
import UserService from "./UserService";
import {WeightedService} from "./AssignmentsRelatedServices/WeightedService";
import {PlansService} from "./AssignmentsRelatedServices/PlansService";
import {actions as fActions} from "../components/Main/Assignment/AssignmentFilter/AssignmentFilterActions";
import {actions as cActions} from "../components/Main/Assignment/Assignment/AssignmentCardActions";
import {filterTreeKey} from "../helpers/treeFilterKey";
import {filter as simpleFilter} from "../helpers/simpleFilter";
import AuthService from "./AuthService";

import httpClient from "@infobis/api-module";

import {ROLE} from "../const/roles";
import NoteService from "./NoteService";
import {agrosignalEntityType, entityType} from "../const";
import DepartmentService from "./DepartmentService";
import {Notification} from "../components/common/Notification";
import isObject from 'lodash/isObject';
import FieldToolService from './FieldToolService';

class AssignmentService extends BaseDataService {
  data = [];
  _tree = Tree.empty();
  _weightedService = new WeightedService();
  _plansService = new PlansService();
  _expandedNodes = [];
  _inited = false;

  constructor() {
    super({
      urlAll: url.assignment,
      ...actions,
      dataShape: responseDataShape.ARRAY,
      qs: {
        page: 1,
        start: 0,
        limit: 1000,
      },
    });
    UserService.addFetchListener(() => this.setLeavesAttrs());
    window.AssignmentService = this;
  }

  async acceptAssignment(id) {
    //const note = NoteService.newNote({title: stateHistoryTitle.progress});
    //const noteResponse = await NoteService.saveNote({note, sources: [id]});
    const newItem = {...this.getItem(id)};
    const status = assignmentStatus.progress;
    //if (noteResponse.success) {
     // newItem.comments = [...newItem.comments, noteResponse.data.id];
   // }
    return this.commitAssignment(id, {
      status,
      comments: [...newItem.comments]
    }, 'Не удалось стартовать поручение');
  }

  async commitAssignment(id, props, errorMsg = 'Не удалось сохранить поручение') {
    const user = AuthService.getUser();
    const itm = this.getItem(id);
    const item = pick({...itm, ...props},
      ['assignee', 'client', 'comments', 'createdAt', 'createdBy', 'description', 'due',
        'id', 'issueTemplate', 'sourceType', 'sources', 'status', 'title']);

    if (item.status === 'failed') {
      item.status = assignmentStatus.progress;
    }

    if (isEmpty(item)) {
      return;
    }

    const request = {
      url: url.assignmentCommit,
      params: {
        method: 'PUT',
      },
      authorize: true,
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "serverId": user.serverId,
        "Authorization": "Bearer " + user.accessToken,
      },
      body: JSON.stringify({
        ...item,
        points: item.points || [],
        issueTemplate: item.issueTemplate || null,
      }),
    };
    store.dispatch(cActions.setSaving(true));

    try {
      await httpClient.makeRequest(request, {errorMsg});

    }
    catch (error) {
      // такого (что ошибка объект) быть не должно, по идее. (на беке нужны изменения)
      isObject(error) ? Notification.error(errorMsg) : Notification.error(error);
    }
    finally {
      store.dispatch(cActions.setSaving(false));
      this.tryFetch();
    }
  }


  async saveAssignment(id, props, errorMsg = 'Не удалось сохранить поручение') {
    const user = AuthService.getUser();
    const itm = this.getItem(id);
    const item = pick({...itm, ...props},
      ['assignee', 'client', 'comments', 'createdAt', 'createdBy', 'description', 'due', 'startDue',
        'id', 'issueTemplate', 'sourceType', 'points', 'sources', 'status', 'title']);

    if (item.status === 'failed') {
      item.status = assignmentStatus.progress;
    }
    if (!item.sourceType) {
      item.sourceType = itm.sourceType;
    }

    if (isEmpty(item)) {
      return;
    }

    const request = {
      url: url.assignment,
      params: {
        method: 'PUT',
      },
      authorize: true,
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "serverId": user.serverId,
        "Authorization": "Bearer " + user.accessToken,
      },
      body: JSON.stringify({
        ...item,
        points: item.points || [],
        issueTemplate: item.issueTemplate || null,
      }),
    };
    store.dispatch(cActions.setSaving(true));
    try {
      await httpClient.makeRequest(request, {errorMsg});

    }

    catch (error) {
      isObject(error) ? Notification.error(errorMsg) : Notification.error(error);
    }

    finally {
      store.dispatch(cActions.setSaving(false));
      this.tryFetch();
    }
  }

  async saveNewAssignment(item, errorMsg = 'Не удалось сохранить поручение') {
    const user = AuthService.getUser();
    const {clientId} = DepartmentService.getCurrentDepartment();
    const request = {
      url: url.assignment,
      params: {
        method: 'POST',
      },
      authorize: true,
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "serverId": user.serverId,
        "Authorization": "Bearer " + user.accessToken,
      },
      body: JSON.stringify({
        ...item,
        points: item.points || [],
        issueTemplate: item.issueTemplate || null,
        client: clientId,
      }),
    };
    try {
      await httpClient.makeRequest(request, {errorMsg});

    }

    catch (error) {
      isObject(error) ? Notification.error(errorMsg) : Notification.error(error);
    }
    finally {
      this.tryFetch();
    }
  }

  async deleteAssignment(id, errorMsg = 'Не удалось удалить поручение') {
    const user = AuthService.getUser();

    const request = {
      url: url.assignment + `/${id}`,
      params: {
        method: 'DELETE',
      },
      authorize: true,
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "serverId": user.serverId,
        "Authorization": "Bearer " + user.accessToken,
      },
      // body: JSON.stringify({...item}),
    };

    try {
      await httpClient.makeRequest(request, {errorMsg});

    }

    catch (error) {
      isObject(error) ? Notification.error(errorMsg) : Notification.error(error);
    }
    finally {
      this.tryFetch();
    }
  }

  autoFetchOn() {
    if (!this.hasPermissions()) {
      return;
    }
    if (!this._fetchInterval) {
      this.fetch();
    }
    this._fetchInterval = setInterval(() => this.tryFetch(), 20 * 1000);
  }

  autoFetchOff() {
    clearInterval(this._fetchInterval);
  }

  tryFetch() {
    if (!this.hasPermissions() || !this._inited || this._isFetching) {
      return;
    }
    store.dispatch(actions.fetchNoPending());
  }

  _afterFetch() {
    super._afterFetch();
    this._buildTree();
    this._weightedService.fetch(() => this.setLeavesAttrs());
    this._plansService.fetch(() => this.setLeavesAttrs());
    this.setAllStatuses();
    this._inited = true;
    this._fetchInterval = null;
  }

  reset() {
    this.data = [];
    this._tree = Tree.empty();
    this._expandedNodes = [];
    this._inited = false;
    this.autoFetchOff();
    store.dispatch(actions.resetState());
    store.dispatch(fActions.resetState());
  }

  getItem(id) {
    return isEmpty(this.data) ? {} : this.data.find(x => x.id === id);
  }

  _buildTree() {
    const {userInfo: {id}} = AuthService.getUser();
    const getGlid = n => n.data.id;
    const now = Date.now();
    this.data.forEach(x => {
      if (x.status.toLowerCase() === 'progress' && moment(x.due).valueOf() <= now) {
        x.status = 'failed';
      }
      x.asAssignee = x.assignee.indexOf(id) !== -1;
      x.asCreator = x.createdBy === id;
    });
    let groupes = uniqBy(this.data.map(d =>
      Object.assign({}, {
        id: d.status,
        glid: d.status,
        name: stateNameMap[d.status] || 'Неизвестно',
        order: stateOrderMap[d.status]
      })), 'id');
    groupes = orderBy(groupes, x => x.order, ['asc']);
    groupes.forEach(x => {
      x.data = orderBy(this.data.filter(d => d.status === x.id), ['title'], ['desc']);
    });
    this._tree = parseTree(groupes, getGlid);
    this._tree.updateTimestamp();
    expandTree(this._tree, 0);
    this._tree.leaves().forEach(n => {
      n.data.createdDateFormatted = moment(n.data.createdAt).format('DD MMMM YYYY HH:mm');
      n.data.dueFormatted = moment(n.data.due).format('DD MMMM YYYY HH:mm');
    });
    this._setSavedExpandState();
  }

  getTree() {
    return this._tree;
  }

  setAllStatuses() {
    this.setLeavesAttrs();
    store.dispatch(fActions.statusSetBulk());
  }

  setLeavesAttrs() {
    const leaves = this._tree.leaves();

    for (const n of leaves) {
      if (n.data) {
        this._setSourceObj(n);
        this._setAssigneeObj(n);
      }
    }

    this._tree.updateTimestamp();
    store.dispatch(actions.listUpdated());
  }

  _setSourceObj(n) {
    const serviceMap = {
      'field': FieldService,
      'unit': VehicleService,
      'fieldtool': FieldToolService,
      'people': EmployeeService,
      'weighbridge': this._weightedService,
      'schedule': this._plansService,
    };
    const assigType = n.data.sourceType && n.data.sourceType.toLowerCase();
    const service = serviceMap[assigType];

    if (!isEmpty(service) && assigType !== 'schedule') {
      set(n.data, 'sourcesMapped', n.data.sources.map(x => pick(service.getItem(x), ['name', 'title']))
        .filter(x => !isEmpty(x)));
    }
    if (!isEmpty(service) && (assigType === 'schedule')) {
      set(n.data, 'sourcesMapped', flatten(n.data.sources.map(x => this._plansService.getForAppointment(x))
        .filter(x => !isEmpty(x))));
    }
  }

  _setAssigneeObj(n) {
    set(n.data, 'assigneeMapped', n.data.assignee.map(x => pick(UserService.getItem(x), ['title']))
      .filter(x => !isEmpty(x)));
  }

  update() {
    this.setLeavesAttrs();
  }

  onToggleExpand() {
    this._expandedNodes = this._tree.nodes().filter(n => n.opened).map(n => n.path.split('@@')[0]);
  }

  _setSavedExpandState() {
    this._tree.nodes().forEach(n => {
      n.opened = this._expandedNodes.indexOf(n.path.split('@@')[0]) !== -1;
    });
  }

  getFilteredTree({status, assignee, sources}) {
    const asAssignee = assignee === assigneeTypesDict.asAssignee;
    const asCreator = assignee === assigneeTypesDict.asCreator;
    const q = {};
    if (asAssignee) {
      q.asAssignee = true;
    }
    if (asCreator) {
      q.asCreator = true;
    }
    if (sources) {
      q.sources = [sources];
    }
    return filterTreeKey({
      tree: this._tree,
      query: {status, ...q},
      predicate: x => !isEmpty(x.sourcesMapped)
    });
  }

  hasPermissions() {
    const depRoles = get(AuthService.getUser(), 'userInfo.depRoles', []);
    return !isEmpty(intersection(depRoles, [ROLE.issuesOwner, ROLE.issuesAssignee, ROLE.admin, ROLE.superAdmin]));
  }

  canCreate() {
    const depRoles = get(AuthService.getUser(), 'userInfo.depRoles', []);
    return !isEmpty(intersection(depRoles, [ROLE.issuesOwner, ROLE.admin, ROLE.superAdmin]));
  }

  newAssignment(props) {
    const newItem = {
      assignee: [],
      comments: [],
      createdBy: 0,
      description: "",
      due: moment({hour: 23, minute: 45, second: 0, millisecond: 0}).valueOf(),
      startDue: +new Date(),
      issueTemplate: 0,
      sourceType: agrosignalEntityType[entityType.VEHICLE],
      sources: [],
      status: assignmentStatus.open,
      title: "",
    };

    return Object.assign({}, {...newItem}, {...props});
  }

  getUsers(filterStr) {
    if (!isEmpty(UserService._usersTree) && !isEmpty(UserService._usersTree.leaves())) {
      return simpleFilter(UserService._usersTree.leaves().map(x => x.data), filterStr, x => x.title);
    }
    return [];
  }
}

export default new AssignmentService();
