import immutable from 'immutable';
import {types} from './NotesActions';
import {Notification} from '../../common/Notification';

const defaultState = {
  pending: true,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.FETCH:
    case types.FETCH_PENDING:
    case types.FETCH_SUCCESS:
    case types.FILE_FETCH:
    case types.FILE_FETCH_PENDING:
    case types.FILE_FETCH_SUCCESS:
    case types.DELETE_NOTE:
    case types.DELETE_NOTE_SUCCESS:
      state = Object.assign({}, state, {...action.payload});
      break;
    case types.FILE_FETCH_ERROR:
    case types.FETCH_ERROR:
      Notification.error(action.payload);
      break;
  }
  return state;
}
