import values from 'lodash/values';

const entityType = {
  ALL: 'ALL',
  FIELD: 'FIELD',
  VEHICLE: 'VEHICLE',
  EMPLOYEE: 'EMPLOYEE',
  PLAN: 'PLAN',
  PLAN_BY_OPERATION: 'plan-by-operation',
  PLAN_BY_CULTURE: 'plan-by-culture',
  ASSIGNMENT: 'assignment',
};

const entityTypeList = values(entityType).filter(x => x !== entityType.ALL);

export {entityType, entityTypeList};

export const agrosignalEntityType = {
  [entityType.FIELD]: 'Field',
  [entityType.VEHICLE]: 'Unit',
  [entityType.EMPLOYEE]: 'People',
};
